import {
  IonItem,
  IonLabel,
  IonModal,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "../../data/connect";
import BottomButtons from "../BottomButtons";
import * as selectors from "../../data/selectors";
import { User, UserConfiguration } from "../../models/User";
import { setShowOffline } from "../../data/global/global.actions";
import { updateUser } from "../../data/user/user.actions";
import _ from "lodash";
import Header from "../Header";
import { UserSupervised } from "../../models/UserSupervised";
interface OwnProps {
  showSoundNotifications: (show: boolean) => void;
}

interface StateProps {
  config: UserConfiguration;
  supervisedAccount: UserSupervised | null;
  currentUser?: User;
  appOnline: boolean;
}

interface DispatchProps {
  setShowOffline: typeof setShowOffline;
  updateUser: typeof updateUser;
}
interface SoundNotificationsSettingsProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const SoundNotificationsSettings: React.FC<SoundNotificationsSettingsProps> = ({
  config,
  supervisedAccount,
  currentUser,
  appOnline,
  showSoundNotifications,
  setShowOffline,
  updateUser
}) => {

  const [soundEnabledInput, setSoundEnabledInput] = useState(config.soundEnabled);
  useEffect(() => {
    setSoundEnabledInput(config.soundEnabled)
  }, [config.soundEnabled])

  const cancelHandler = () => {
    showSoundNotifications(false);
  };

  const validateHandler = async () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    if(currentUser){
      const userConfig = {... config} as UserConfiguration;
      userConfig.soundEnabled = soundEnabledInput;
      currentUser.config = _.clone(userConfig);
      updateUser(currentUser, supervisedAccount, "Modifications enregistrées");
    }
    showSoundNotifications(false);
  };

  return (
    config && (
      <IonModal
        isOpen={true}
        cssClass="modal-sound-notifications-settings"
        onDidDismiss={() => showSoundNotifications(false)}
      >
        <Header action={cancelHandler}  title="Avertissement sonore des notifications" showHelp/>
        <IonRadioGroup
          style={{ flexGrow: 1, padding: "20px" }}
          value={soundEnabledInput}
          onIonChange={(e) => {
            setSoundEnabledInput(e.detail.value);
          }}
        >
          <IonItem>
            <IonLabel>Silencieux</IonLabel>
            <IonRadio slot="start" value={false} />
          </IonItem>

          <IonItem>
            <IonLabel>Sonnerie</IonLabel>
            <IonRadio slot="start" value={true} />
          </IonItem>
        </IonRadioGroup>
        <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
          <BottomButtons
            onCancel={cancelHandler}
            onValidate={validateHandler}
          />
        </div>
      </IonModal>
    )
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    updateUser,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    config: selectors.getUserConfig(state),
    supervisedAccount: state.supervisor.supervisedAccount || null, 
    currentUser: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore,
    appOnline: state.global.appOnline
  }),
  component: React.memo(SoundNotificationsSettings),
});
