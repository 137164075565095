import { IonCol, IonRow } from "@ionic/react";
import React from "react";
import Button from "./Button";
import { IconSvgEnum } from "./IconSvg";
import { arrowBackCircleOutline } from 'ionicons/icons';
interface BottomButtonsProps {
  cancelBtnLabel?: string;
  validateBtnLabel?: string;
  cancelBtnColor?: string;
  validateBtnColor?: string;
  routerLink?: string;
  cancelBtnIcon?: IconSvgEnum;
  validateBtnIcon?: IconSvgEnum;
  validateBtnDisabled?: boolean;
  cancelBtnDisabled?: boolean;
  onCancel: () => void;
  onValidate: () => void;
}

const BottomButtons: React.FC<BottomButtonsProps> = ({
  cancelBtnLabel,
  validateBtnLabel,
  cancelBtnColor,
  validateBtnColor,
  cancelBtnIcon,
  validateBtnIcon,
  validateBtnDisabled = false,
  cancelBtnDisabled = false,
  onCancel,
  onValidate,
}) => {
  return (
    <IonRow class="ion-no-padding">
      <IonCol size="6" class="ion-no-padding">
        <Button
          color={cancelBtnColor ? cancelBtnColor : "light"}
          onClick={() => onCancel()}
          text={cancelBtnLabel ? cancelBtnLabel : "Retour"}
          svg={cancelBtnIcon ? cancelBtnIcon : IconSvgEnum.back}
          disabled={cancelBtnDisabled}
        />
      </IonCol>
      <IonCol size="6" class="ion-no-padding">
        <Button
          color={validateBtnColor ? validateBtnColor : "success"}
          onClick={() => onValidate()}
          text={validateBtnLabel ? validateBtnLabel : "Sauvegarder"}
          svg={validateBtnIcon ? validateBtnIcon :IconSvgEnum.ok_white}
          disabled={validateBtnDisabled}
        />
      </IonCol>
    </IonRow>
  );
};

export default BottomButtons;
