import React from 'react';

interface IconSvgProps {
  icon:IconSvgEnum;
  /**Wil be place on the first icon.  */
  secondIcon?:IconSvgEnum;
  height?: number;
  width?: number;
  classCss?: string;
};

const IconSvg: React.FC<IconSvgProps> = ({icon, secondIcon, height = 45, width = 45, classCss='' }) => {


  return (
    <div className={`svg-size ${classCss}`}>
      <img src={`/assets/icon/${icon}.svg`} />
      {secondIcon && <img className="icon-hover" src={`/assets/icon/${secondIcon}.svg`} />}
    </div>)
}

export default IconSvg;


export enum IconSvgEnum {
    microphone_white = 'microphone-white',
    microphone_mute_white='microphone-mute-white',

    cancel = 'cancel',
    cancel_white = 'cancel-white',
    ok = 'ok',
    ok_white = 'ok-white',
    comment='comment',
    download_white= 'download-white',
    download= 'download',
    record_message= 'record-message',
    video_camera = 'video-camera',
    home_orange= 'home-orange',
    album= 'album',
    picture = 'picture',
    settings = 'settings',
    cross="cross",
    cross_white="cross-white",
    diagonale_white="diagonale_white",
    user="user",
    group="group",
    send="send",
    info="information",
    stop= "stop",
    back= "back"
  }
