import _ from "lodash";
import firebase from "../FirebaseConfig";
import { SharedFolder } from "../models/SharedFolder";
import { State, UserRelationship,RelationshipState } from "../models/UserRelationship";
import { UserSupervised } from "../models/UserSupervised";
import { UserWithRelationship } from "../models/UserWithRelationship";
import { updateFolderRequest } from "./sharedFolder";
import { deleteSupervisorRequest } from "./supervisorRelationship";

/**
 * Return all relationship for current logged user (accepted or waiting).
 */
export const getAllUserRelationShip = async (userName: string) => {
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    const db = firebase.firestore();

    return (
      await db
        .collection("userRelationship")
        .where("users", "array-contains", userName)
        .get()
    ).docs.map((item) => {
      const relationShipModel = item.data() as UserRelationship;
      relationShipModel.id = item.id; //TODO remove ?
      return relationShipModel;
    });
  }
};

/**
 * Load all relation for the user.
 * @param userId
 * @returns
 */
export const getAnnuaireUsers = async (currentUserName: string) => {
  const db = firebase.firestore();
  let allRelationship = await getAllUserRelationShip(currentUserName);
  if (!allRelationship) return [];

  let friendMailsList: string[] = [];

  // check if there are duplicate date in relation ship
  // ex: 2 users add each other with no internet connexion => 2 identical  relationship is created so remove one for avoid conflicts
  let annuaireDistinct: UserRelationship[] = [];
  let userDuplicate: UserRelationship[] = [];
  _.each(allRelationship, (rs) => {
    if (
      !_.some(
        annuaireDistinct,
        (u) =>
          (u.emailFrom == rs.emailFrom && u.emailTo == rs.emailTo) ||
          (u.emailTo == rs.emailFrom && u.emailFrom == rs.emailTo)
      )
    )
      annuaireDistinct.push(rs);
    else userDuplicate.push(rs);
  });

  if (userDuplicate.length > 0) {
    await Promise.all(_.each(userDuplicate, (rs) => removeRelationShip(rs.id)));
  }
  // find relationship accepted (only friend et not pending invitation)
  annuaireDistinct.forEach((userRelationShip) => {
    userRelationShip.users.forEach((user) => {
      if (!_.some(friendMailsList, user) && user !== currentUserName)
        friendMailsList.push(user);
    });
  });

  let queriesArray: Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  >[] = [];
  friendMailsList.forEach((mail) => {
    // TODO: remove all user queries and replace by an array of friends id in user table.
    // Add firebase functions for update these array list
    queriesArray.push(db.collection("users").doc(mail).get());
  });

  var users: UserWithRelationship[] = [];
  (await Promise.all(queriesArray)).forEach((user) => {
    if (user.exists) {
      var userResult = user.data() as UserWithRelationship;
      userResult.id = user.id; //TODO remove ?

      userResult.relationships = _.filter(
        annuaireDistinct,
        (item) =>
          _.some(item.users, (u) => u == currentUserName) &&
          _.some(item.users, (u) => u == userResult.email)
      );
      users.push(userResult);
    }
  });

  return users;
};

/**
 * Remove a relation ship (doesn't remove additional data like shared folder)
 * @param relationShipId
 */
export const removeRelationShip = async (relationShipId: string) => {
  const db = firebase.firestore();

  await db
    .collection("userRelationship")
    .doc(relationShipId)
    .delete()
    .catch(() => console.log(""));
};

// TODO improve search: https://firebase.google.com/docs/firestore/solutions/search
export const searchAnnuaireUsers = async (
  currentUserName: string,
  searchText: string
) => {
  const db = firebase.firestore();

  let allRelationship = await getAllUserRelationShip(currentUserName);
  let userMatches: UserWithRelationship[] = [];
  userMatches = (await db.collection("users").get()).docs.map((item) => {
    const relationShipModel = item.data() as UserWithRelationship;
    relationShipModel.id = item.id;
    relationShipModel.relationships = _.filter(allRelationship, (relation) =>
      _.some(relation.users, (u) => u == relationShipModel.email)
    );

    if (!_.some(userMatches, { email: relationShipModel.email }))
      userMatches.push(relationShipModel);
    return relationShipModel;
  });
  _.remove(userMatches, (user) => user.email == currentUserName);

  return _.filter(userMatches, (user) => {
    var userConcat = (
      user.firstName +
      " " +
      user.lastName +
      " " +
      user.email
    ).toLocaleLowerCase();
    return userConcat.includes(searchText.toLocaleLowerCase());
  });
};

export const createFriendRequest = async (data: UserRelationship) => {
  const entityId = data.emailFrom + "->" + data.emailTo;
  const db = firebase.firestore();
  await db.collection("userRelationship").doc(entityId).set(data);
};

export const removeFriendRequest = async (
  currentUserName: string,
  userName: string,
  supervisedAccount: UserSupervised | null,
  isRefused: boolean
) => {
  const db = firebase.firestore();
  const entityId1 = currentUserName + "->" + userName;
  const entityId2 = userName + "->" + currentUserName;
  const state = isRefused ? RelationshipState.Refused : RelationshipState.Removed;

  await db
    .collection("userRelationship")
    .doc(entityId1)
    .update({ 
      accepted: false,
      state: {
        date: new Date().toISOString(),
        state: state,
        updatedby: currentUserName,
      } as State  }).catch(() => console.log(""));
  await db
    .collection("userRelationship")
    .doc(entityId2)
    .update({ 
      accepted: false,
      state: {
        date: new Date().toISOString(),
        state: state,
        updatedby: currentUserName,
      } as State  }).catch(() => console.log(""));

  // remove supervisor request
  deleteSupervisorRequest(currentUserName, userName);
  deleteSupervisorRequest(userName, currentUserName);
  // remove user from folder shared with him
  const allFolders = (
    await db
      .collection("sharedFolder")
      .where("users", "array-contains", currentUserName)
      .get()
  ).docs.map((item) => {
    return item.data() as SharedFolder;
  });

  const foldersCurrentUserOwner = _.filter(
    allFolders,
    (folder) =>
      folder.creator == currentUserName &&
      _.some(folder.users, (u) => u == userName)
  );
  const foldersDeletedUserOwner = _.filter(
    allFolders,
    (folder) =>
      folder.creator == userName &&
      _.some(folder.users, (u) => u == currentUserName)
  );

  foldersCurrentUserOwner.forEach((folder) => {
    _.remove(folder.users, (u) => u === userName);
    updateFolderRequest(folder, supervisedAccount);
  });
  foldersDeletedUserOwner.forEach((folder) => {
    _.remove(folder.users, (u) => u === currentUserName);
    updateFolderRequest(folder, supervisedAccount);
  });
};

export const acceptFriendRequest = async (idRelationship: string) => {
  const db = firebase.firestore();
  return db
    .collection("userRelationship")
    .doc(idRelationship)
    .update({ 
      accepted: true,
      state: {
        date: new Date().toISOString(),
        state: RelationshipState.Accepted
      } as State  });
};
export const viewAcceptFriendRequest = async (idRelationship: string) => {
  const db = firebase.firestore();
  return db.collection('userRelationship').doc(idRelationship).update({ notifSender: true });
}
export const viewAcceptSupervisionRequest = async (idRelationship: string) => {
  const db = firebase.firestore();
  return db.collection('supervisorRelationship').doc(idRelationship).update({ notifAdministrator: true });
}