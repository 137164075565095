import React, { Fragment, useEffect } from "react";
import { Comment } from "../models/Comment";
import "./CommentsSection.css";
import FileActions from "./FileActions";
import { SharedFile } from "../models/SharedFile";
import { SharedFolder } from "../models/SharedFolder";
import { convertToTimestamp } from "../util/functions";

interface CommentsSectionProps {
  comments: Comment[];
  currentUserEmail: string;
  currentPicture: SharedFile | null;
  folder: SharedFolder;
  loading: Boolean;
  deletePicture: () => {};
  downloadPicture: () => void;
  showDetails: () => void;
  addAudioComment: () => void;
  setAudioBlobUrl: (url: string | null) => void
}
const CommentsSection: React.FC<CommentsSectionProps> = ({
  comments,
  currentUserEmail,
  currentPicture,
  folder,
  loading,
  deletePicture,
  downloadPicture,
  showDetails,
  addAudioComment,
  setAudioBlobUrl
}) => {
  // for scroll bottom when modal open
  const commentsContent = React.createRef<HTMLIonContentElement>();

  useEffect(() => {
      // hack for scrool bottom on commentaire, if no timeout, nothing to scrol besauce div are not rendered yet
      setTimeout(
        () =>{
          const elt = document.getElementById("comment-section");
          if(elt) elt.scrollTop = elt.scrollHeight;
        },
        1000
      );
  }, []);
  useEffect(() => {
    return () => {
      setAudioBlobUrl(null)
    }
  }, []);

  useEffect(() => {
    const elt = document.getElementById("comment-section");
    if (elt) elt.scrollTop = elt.scrollHeight;
    if (commentsContent.current) {
      commentsContent.current.scrollToBottom();
    }
  }, [comments]);

  return (
    <div className="comments-content">
      <div id="comment-section" className="comment-section">
        {comments.length == 0 && (
          <div className={" no-comment"}>
            À vous d'ajouter le premier commentaire !
          </div>
        )}

        <div className="comments-list">
          {comments.length > 0 &&
            comments.map((comment, index) => {
              const timestamp = convertToTimestamp(comment.date);
              const date = timestamp ? timestamp.toDate(): null;

              return (
                <div
                  key={index}
                  className={
                    "comment-row " +
                    (currentUserEmail == comment.senderEmail ? "me" : "")
                  }
                >
                  <div
                    className={
                      "comment-item " +
                      (currentUserEmail == comment.senderEmail ? "me" : "")
                    }
                  >
                    <div className="comment">{(comment.type === "text" || !comment.type) && <p>{comment.text}</p>}
                      {comment.type === "audio" &&
                        <audio className="comment-audio" controls>
                          <source
                            src={comment.text}
                            type="audio/mpeg"
                          />
                        </audio>}
                    </div>
                    <div className="sender">
                      De{" "}
                      {currentUserEmail == comment.senderEmail
                        ? "Moi"
                        : comment.senderName}{" "}
                      {date && <Fragment>le {date.toLocaleDateString()} à{" "}
                      {date.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}</Fragment>}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {!loading && (
        <FileActions
          folder={folder}
          currentPicture={currentPicture ? currentPicture : null}
          currentUserEmail={currentUserEmail}
          deletePicture={deletePicture}
          downloadPicture={downloadPicture}
          showDetails={showDetails}
          addAudioComment={addAudioComment}
        />
      )}
    </div>
  );
};

export default CommentsSection;
