import React from "react";
import { IonImg } from "@ionic/react";
import { connect } from "../data/connect";
import "./EmergencyCommand.scss";
import _ from "lodash";
import emergencyCallImg from "../icons/call-urgence.png";
import { RouteComponentProps, withRouter } from "react-router";
import { menuController } from "@ionic/core";
import { setShowOffline } from "../data/global/global.actions";

interface DispatchProps {
  setShowOffline: typeof setShowOffline;
}
interface OwnProps {}
interface StateProps {
  appOnline: boolean;
}

interface EmergencyShortcut
  extends RouteComponentProps,
    OwnProps,
    DispatchProps,
    StateProps {}

const EmergencyShortcut: React.FC<EmergencyShortcut> = ({
  history,
  appOnline,
  setShowOffline,
}) => {
  const onClickHandler = () => {
    if (!appOnline) {
      setShowOffline(true);
      return;
    }
    menuController.close();
    history.push("/emergencycall");
  };
  return (
    <IonImg
      className="emergencyCallImg"
      src={emergencyCallImg}
      onClick={onClickHandler}
    />
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    appOnline: state.global.appOnline,
  }),
  mapDispatchToProps: {
    setShowOffline,
  },
  component: withRouter(EmergencyShortcut),
});
