import React, { useEffect, useRef } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonSearchbar,
  IonLabel,
  IonProgressBar,
} from "@ionic/react";
import { closeSharp, searchOutline } from "ionicons/icons";
import UserItem, { userCardType } from "./UserItem";
import Loading from "./Loading";
import { UserWithRelationship } from "../models/UserWithRelationship";
import { connect } from "../data/connect";
import { searchUser } from "../data/user/user.actions";
import * as selectors from "../data/selectors";
import _ from "lodash";
import "./SpeakerList.scss";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { setSearchTerm, setShowOffline } from "../data/global/global.actions";
import { UserSupervised } from "../models/UserSupervised";
import { UserRelationship } from "../models/UserRelationship";
import { isPlatform } from "@ionic/core";

interface OwnProps {
  onSearch: (show: boolean) => void;
}

interface StateProps {
  loadingCall: boolean;
  loadingAnnuaire: boolean;
  loadingSearch: boolean;
  currentUserEmail?: string;
  friendRequestsPending: UserWithRelationship[];
  searchUsersFiltered: UserWithRelationship[];
  isHelpedProfil: boolean;
  searchTerm: string;
  supervisedAccount?: UserSupervised;
  subtitling: boolean;
  appOnline: boolean;
}

interface DispatchProps {
  searchUser: typeof searchUser;
  setSearchTerm: typeof setSearchTerm;
  setShowOffline: typeof setShowOffline;
}
interface AnnuaireSearchProps
  extends RouteComponentProps,
    OwnProps,
    StateProps,
    DispatchProps {
  allUserRelations: UserRelationship[];
}
interface OptionalState {
  searchTerm: string;
}

const AnnuaireSearch: React.FC<AnnuaireSearchProps> = ({
  loadingAnnuaire,
  loadingSearch,
  allUserRelations,
  currentUserEmail,
  friendRequestsPending,
  searchUsersFiltered,
  subtitling,
  appOnline,
  searchUser,
  onSearch,
  isHelpedProfil,
  searchTerm,
  supervisedAccount,
  setSearchTerm,
  setShowOffline
}) => {
  const state = useLocation().state as OptionalState;
  const searchBarRef = useRef<HTMLIonSearchbarElement>(null);

  useEffect(() => {
    // If searchTerm exist, set as default search
    if (state && state.searchTerm) {
     // setSearchTerm(state.searchTerm);
    }
  }, [state]);

  function onTextSearchChanged() {
    onSearch(searchTerm === "");
    if (currentUserEmail && appOnline) {
      searchUser(currentUserEmail, searchTerm);
    }
    if(!appOnline){
      setShowOffline(true)
    }
  }

  let usersFiltered: UserWithRelationship[] = [];
  const pendingRequest = friendRequestsPending;
  const havePendingRequest = pendingRequest.length > 0;

  if (!havePendingRequest && searchTerm) usersFiltered = searchUsersFiltered;
  else if (!havePendingRequest && !searchTerm) usersFiltered = [];
  else usersFiltered = pendingRequest;

  usersFiltered.forEach((user) => {
    const userRelation = _.find(
      allUserRelations,
      (relation) =>
        _.some(relation.users, (u) => u === currentUserEmail) &&
        _.some(relation.users, (u) => u === user.email)
    );

    user.isWaitingActionFromOther = userRelation
      ? userRelation.emailFrom === currentUserEmail && !userRelation.accepted
      : false;
  });

  return (
    <React.Fragment>
      {/* Text Search  */}
      {!havePendingRequest && (
        <div>
          <IonSearchbar
            ref={searchBarRef}
            searchIcon={searchOutline}
            placeholder="Saisir un nom pour ouvrir l'annuaire"
            debounce={500}
            value={searchTerm}
            onIonInput={async (e) => {
              //fix for: https://trello.com/c/meEvfuHz/219-d%C3%A9calage-dans-le-temps-de-saisie-des-caract%C3%A8res-lors-de-frappe-de-texte
              if (searchBarRef.current) {
                const target = await searchBarRef.current.getInputElement();
                setSearchTerm(target.value);
              }
            }}
            onIonChange={async (e: CustomEvent) => {
              if (searchBarRef.current) {
                const target = await searchBarRef.current.getInputElement();
                if(isPlatform("mobile") || isPlatform("mobileweb")){
                  target.blur();
                }
              }
              onTextSearchChanged();
            }}
            showCancelButton="focus"
            cancelButtonIcon={closeSharp}
            cancelButtonText={subtitling ? "ANNULER" : ""}
            mode={"ios"}
          ></IonSearchbar>
          {searchTerm && !loadingSearch && (
            <div style={{ textAlign: "center" }}>
              <IonLabel color="primary">
                {usersFiltered.length +
                  (usersFiltered.length > 1
                    ? " résultats trouvés dans l'annuaire"
                    : " résultat trouvé dans l'annuaire")}
              </IonLabel>
            </div>
          )}
          {false && !searchTerm && usersFiltered.length == 0 && (
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <IonLabel color="primary">
                Renseigner le nom, prénom ou mail du contact que vous recherchez
                dans le champ ci-dessus
              </IonLabel>
            </div>
          )}
        </div>
      )}

      {havePendingRequest && (
        <div>
          <div style={{ textAlign: "center", marginTop: "40px" }}>
            <IonLabel color="primary">Demande de contact</IonLabel>
          </div>
        </div>
      )}

      {/* results */}
      {loadingSearch && searchTerm !== "" ? (
        <div style={{ marginTop: "20px" }}>
          <IonProgressBar type="indeterminate"></IonProgressBar>
        </div>
      ) : (
        <IonGrid fixed>
          <IonRow>
            {usersFiltered
              .filter((user) => user.email != currentUserEmail)
              .sort((a, b) =>
                (a.isWaitingActionFromOther && !b.isWaitingActionFromOther) ||
                (!a.isWaitingActionFromOther &&
                  !b.isWaitingActionFromOther &&
                  b.relationships.length === 0)
                  ? -1
                  : 1
              )
              .map((user) => (
                <IonCol
                  size="12"
                  size-lg="4"
                  size-md="6"
                  size-sm="12"
                  key={user.id}
                >
                  <UserItem
                    key={user.id}
                    user={user}
                    type={
                      havePendingRequest
                        ? userCardType.FriendRequest
                        : userCardType.Search
                    }
                  />
                </IonCol>
              ))}
          </IonRow>
        </IonGrid>
      )}

      <Loading enable={loadingAnnuaire} />
    </React.Fragment>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    searchUser,
    setSearchTerm,
    setShowOffline
  },
  mapStateToProps: (state) => {
    let allUserRelations: UserRelationship[] = [];

    if (state.supervisor.supervisedAccount)
      allUserRelations = state.supervisor.supervisedAccount.allUserRelations;
    else if (state.user.allUserRelations)
      allUserRelations = state.user.allUserRelations;

    return {
      loadingCall: state.visioCall.loading,
      loadingAnnuaire: state.user.loadingAnnuaire,
      loadingSearch: state.user.loadingSearch,
      // if mode supervised, use supervised email and not current user email
      currentUserEmail: state.supervisor.supervisedAccount
        ? state.supervisor.supervisedAccount.user.email
        : state.user.username,
      supervisedAccount: state.supervisor.supervisedAccount,
      allUserRelations: allUserRelations,
      friendRequestsPending: state.supervisor.supervisedAccount
        ? state.supervisor.supervisedAccount.pendingRequests
        : state.user.requestPending
        ? state.user.requestPending
        : [],
      searchUsersFiltered: state.user.searchUsersFiltered
        ? state.user.searchUsersFiltered
        : [],
      isHelpedProfil: selectors.getIsHelpedProfil(state),
      searchTerm: state.global.searchTerm,
      subtitling: selectors.getSubtitlingConfig(state),
      appOnline: state.global.appOnline,
    };
},
  component: React.memo(AnnuaireSearch),
});
