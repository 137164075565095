import {Directory, Filesystem} from "@capacitor/filesystem";
import _ from "lodash";
import {
  convertBlobToBase64,
  defaultPicture,
  isVideoFile,
  _CachedImageFolder,
} from "./commons";

export const readFileFromCache = (
  file: string | undefined,
  setFileSrc: (src: string) => void
) => {
  if (file) {
    let fileName: any;
    let fileType: any;
    if (file === defaultPicture) {
      fileName = "avatar.png";
      fileType = "png";
    } else if (
      file.includes("/assets/img/screensaver")
    ) {
      fileName = file.split("/").pop();
      fileType = fileName.split(".").pop();
    } else {
      let exp = file.split("%").pop();
      fileName = exp ? exp.split("?")[0] : "";
      fileType = fileName.split(".").pop();
    }
    const isVideo = isVideoFile(fileType);
    Filesystem.readFile({
      directory: Directory.Cache,
      path: `${_CachedImageFolder}/${fileName}`,
    })
      .then((readFile) => {

        if(isVideo){
          setFileSrc(`data:video/${fileType};base64,${readFile.data}`);
        }else{
          setFileSrc(`data:image/${fileType};base64,${readFile.data}`);
        }
      })
      .catch(async (e) => {
        if (fileName && file) {
          const response = await storeFile(file, fileName);
          if (response) {
            Filesystem.readFile({
              directory: Directory.Cache,
              path: `${_CachedImageFolder}/${fileName}`,
            }).then((readFile) => {
              if(isVideo){
                setFileSrc(`data:video/${fileType};base64,${readFile.data}`);
              }else{
                setFileSrc(`data:image/${fileType};base64,${readFile.data}`);
              }
            });
          } else {
            setFileSrc(file);
            return file;
          }
        }
      });
  }
  return "";
};
export const storeFile = async (url: string, path: string) => {
  // retrieve the image
  let response: Response | undefined;
  try {
    response = await fetch(url);
  } catch (e) {}
  if (response) {
    // convert to a Blob
    const blob = await response.blob();
    // convert to base64 data, which the Filesystem plugin requires
    const base64Data = (await convertBlobToBase64(blob)) as string;
    const savedFile = await Filesystem.writeFile({
      path: `${_CachedImageFolder}/${path}`,
      data: base64Data,
      directory: Directory.Cache,
      recursive: true,
    });
    return savedFile;
  } else {
    return null;
  }
};

export const createCacheFolder = async () => {
  try {
    await Filesystem.mkdir({
      directory: Directory.Cache,
      path: `CACHED-IMG`,
      recursive: true,
    });
  } catch (e) {}
};
