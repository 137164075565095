import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, withIonLifeCycle, IonButton, IonFooter, IonList, IonItem, IonLabel, IonListHeader, IonText } from '@ionic/react';
import './Notification.scss';
import { connect } from '../data/connect';
import { acceptOrRefuseCall, setUserActionFromPush } from '../data/visioCall/visioCall.actions';
import { setAudioData, updateLastActivity } from '../data/user/user.actions';

import { RouteComponentProps } from 'react-router';
import { registerPlugin } from '@capacitor/core';
import { PushNotifications, PushNotificationSchema, Token, ActionPerformed } from '@capacitor/push-notifications'
import {isPlatform} from "@ionic/core";
import VisioCall, { ParticipantState, UserActionFromPush } from '../models/VisioCall';
import { register } from '../serviceWorker';
import firebase from '../FirebaseConfig';
import IncomingCall from '../components/IncomingCall';
import { stat } from 'fs';
import { UserWithRelationship } from '../models/UserWithRelationship';
import _ from 'lodash';
import { defaultPicture } from '../util/commons';
import * as selectors from '../data/selectors';
import { audioType } from '../components/AudioPlayer';
import { menuController } from '@ionic/core';

const CustomNativePlugin = registerPlugin('CustomNativePlugin') as any;


interface OwnProps extends RouteComponentProps {

}

interface DispatchProps {
  acceptOrRefuseCall: typeof acceptOrRefuseCall;
  setAudioData: typeof setAudioData;
  updateLastActivity: typeof updateLastActivity;
  setUserActionFromPush: typeof setUserActionFromPush;
}
interface NotificationProps extends OwnProps, DispatchProps {
  isAuthenticated: boolean,
  userEmail: string,
  incomingVisioCall: VisioCall,
  annuaire: UserWithRelationship[],
  userActionFromPush?: UserActionFromPush,
  isAppActive: boolean
}

const Notification: React.FC<NotificationProps> = ({ acceptOrRefuseCall, setAudioData, updateLastActivity, setUserActionFromPush, incomingVisioCall, annuaire, location, history, isAppActive, userActionFromPush, userEmail }) => {



  useEffect(() => {
    setAudioData(audioType.IncomingCall, !!incomingVisioCall);
    checkUserAlreadyresponseCallFromPush()

  }, [incomingVisioCall]);

  useEffect(() => {
    checkUserAlreadyresponseCallFromPush()
  }, [userActionFromPush]);
  
  //for mobile debug
  useEffect(() => {
  }, [location.pathname]);
  

  useEffect(() => {
    if(isPlatform("mobile") )
    {
      CustomNativePlugin.addListener("incoming_call", function(data:any){
      const { call_id, accepted } = data;
      setUserActionFromPush({ callId: call_id, accepted:  accepted} as UserActionFromPush);
    });
  }
  }, []);

 
  function checkUserAlreadyresponseCallFromPush(){
    if(incomingVisioCall && userActionFromPush && userActionFromPush.callId == incomingVisioCall.id)
    {
        if(userActionFromPush.accepted) {
          menuController.close();
          acceptCall();
        }
        else if (!userActionFromPush.accepted) cancelCall();

        // remove user response
        setUserActionFromPush(undefined);
    }
  }


  function registerPushEvent() {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();
    PushNotifications.removeAllListeners();

    console.log("AAAA registerPushEvent")
    // TODO => Migrate token save to actions and use a function from dataApi.ts
    /*PushNotifications.addListener('registration',
      async (token: Token) => {
        console.log("AAAA PushNotifications registration", token.value);
        const db = firebase.firestore();
        const userExist = await db.collection("users").doc(userEmail).get()
        if(userExist.data())
        {
          await db.collection("users").doc(userEmail)
          .update({
            pushToken: token.value,
            pushTokenLastUpdate: (new Date()).toISOString()
          })
          .catch((e) => alert('Error on registration save : ' + JSON.stringify(e)));
        }
      });*/


    // Some issue with your setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // newIncomingCall(notification.data);
        //alert("pushNotificationReceived...");
        console.log("AAAA pushNotificationReceived " + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // ACCEPT_ACTION or DECLINE_ACTION
        const actionId = notification.actionId;
        const callId = notification.notification.data.callId;
        console.log("AAAA pushNotificationActionPerformed " + JSON.stringify(notification));
        if(actionId != "tap")
        {
          setUserActionFromPush({ callId: callId, accepted:  actionId == "ACCEPT_ACTION"} as UserActionFromPush);
        }
      }
    );
  }

  function unregisterPushEvent() {
    if (!isPlatform('desktop')) {
      PushNotifications.removeAllListeners();
    }
  }

  useEffect(() => {
    if (!isPlatform('desktop') && userEmail) {
      registerPushEvent();
    }
    return unregisterPushEvent;
  }, [userEmail]);


  async function acceptCall() {
    await acceptOrRefuseCall(incomingVisioCall.id, userEmail, ParticipantState.Accepted);

    // update user last activity
    await updateLastActivity();

    history.push('/tabs/conference/video');
  }

  function cancelCall() {
    acceptOrRefuseCall(incomingVisioCall.id, userEmail, ParticipantState.Refused)
    menuController.open();
  }



  const showIncomingCall = isAppActive && incomingVisioCall && !userActionFromPush && incomingVisioCall.c_active ? true: false;
  const userCreator = incomingVisioCall ? _.find(annuaire, (user) => user.email == incomingVisioCall.creator) : undefined;

  return <IncomingCall
    showModal={showIncomingCall}
    caller={userCreator}
    callbackAccept={() => acceptCall()}
    callbackCancel={() => cancelCall()}
    setAudioData={setAudioData}
    visioCallData={incomingVisioCall}
  />;
    /*if(userActionFromPush)return <div></div>;
    else return (
      <IncomingCall
        showModal={showIncomingCall}
        caller={userCreator}
        callbackAccept={() => acceptCall()}
        callbackCancel={() => cancelCall()}
        setAudioData={setAudioData}
        visioCallData={incomingVisioCall}
      />
    );*/

}

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: !!firebase.auth().currentUser, //state.user.isLoggedin,
    userEmail: state.user.username,
    incomingVisioCall: selectors.getVisioCallWaitingResponse(state),
    annuaire: state.user.annuaire ? state.user.annuaire : [],
    userActionFromPush: state.visioCall.userActionFromPush,
    isAppActive: state.global.appIsActive
  }),
  mapDispatchToProps: {
    acceptOrRefuseCall,
    setAudioData,
    updateLastActivity,
    setUserActionFromPush
  },
  component: Notification
})