import { MediaShareRequestActions } from "./mediaShareRequest.actions";
import { MediaShareRequestState } from "./mediaShareRequest.state";

export function MediaShareRequestReducer(
  state: MediaShareRequestState,
  action: MediaShareRequestActions
): MediaShareRequestState {
  switch (action.type) {
    case "set-media-share-loading":
      return { ...state, loading: action.isLoading };
    case "set-media-share-request-list":
      return { ...state, requests: action.requests };
  }
}
