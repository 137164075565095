import React from 'react';
import { IonButton, IonModal, IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonCol, IonRow, IonText, IonIcon } from '@ionic/react';
import './AddUserModal.scss'
import { UserWithRelationship } from '../../models/UserWithRelationship';
import Button from '../../components/Button';
import UserItem, { userCardType } from '../../components/UserItem';
import { hourglassOutline, personAddOutline, personRemoveOutline, arrowBackCircleOutline } from 'ionicons/icons';
import { sendSupervisorRequest, removeSupervisorRequest } from '../../data/supervisor/supervisor.actions';
import { SupervisorRelationship, SupervisorRelationshipState } from '../../models/SupervisorRelationship';
import _ from 'lodash';
import { setShowOffline,setToastNotification } from '../../data/global/global.actions';
import { connect } from '../../data/connect';
import { checkIsValidPhone } from '../../util/checkIsPhoneFormat';
import { User } from '../../models/User';
import { sendSms } from '../../api/sms';
import { _UrlMLV } from '../../appConfig';
import { getGlobalConfig } from "../../api/globalConfig";
import { ToastNotification } from '../../models/ToastNotification';
import { sendMailRequest } from "../../api/mail";
import { validateEmail } from "../../util/email.validation";
import { MailType } from '../../models/Mail';
interface OwnProps {
  enable: boolean,
  onClose: () => void,
  contacts: UserWithRelationship[]
  relationships: SupervisorRelationship[];
  currentUserEmail: string;
  sendSupervisorRequest: typeof sendSupervisorRequest;
  removeSupervisorRequest: typeof removeSupervisorRequest;
  isHelpedProfil: boolean;
}

interface StateProps {
  appOnline: boolean;
  currentUser?: User;
  supervisorRequests: SupervisorRelationship[];
}

interface DispatchProps {
  setShowOffline: typeof setShowOffline;
  setToastNotification: typeof setToastNotification;
}

interface AddUserModalProps extends OwnProps, StateProps, DispatchProps { }

const AddUserModal: React.FC<AddUserModalProps> = ({ 
  currentUser, 
  appOnline, 
  enable, 
  contacts, 
  currentUserEmail, 
  relationships, 
  supervisorRequests,
  onClose, 
  sendSupervisorRequest, 
  removeSupervisorRequest, 
  setShowOffline, 
  setToastNotification,
  isHelpedProfil }) => {

 
  async function requestAddSupperviseur(targetUser: User) {
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    const supervisorRequestsAccepted = _.filter(supervisorRequests, (f) => f.state == SupervisorRelationshipState.Accepted);
    const config = await getGlobalConfig(isHelpedProfil,currentUserEmail);
    const maxNumberSupPerSupervisor = config.contact?.maxNumberSupPerSupervisor;
    if(maxNumberSupPerSupervisor && +maxNumberSupPerSupervisor <= supervisorRequestsAccepted.length) {
      setToastNotification({
        message:
        "Le nombre maximum de " +
        maxNumberSupPerSupervisor +
        " supervision(s) autorisée(s) a déjà été atteint",
        color: "danger",
      } as ToastNotification);
      return;
    }
    try {
      if (currentUserEmail) {

        await sendSupervisorRequest(targetUser.email, currentUserEmail);
        if (checkIsValidPhone(targetUser.email) && currentUser) {
          const message = `Bonjour ${targetUser.firstName}, ${currentUser.firstName} ${currentUser.lastName}  se propose de superviser votre compte Mon Lien Visio. Pour répondre, veuillez cliquer sur le lien ${_UrlMLV}`;
          sendSms(targetUser.email, message)
        }
        if (validateEmail(targetUser.email)) {
          let message = '';
          sendMailRequest(currentUserEmail, MailType.AddSupervision, message, MailType.AddSupervision, targetUser.email)
        }
      }
    } catch (e) {
      alert("Exception userItems>requestAddSupperviseur(): " + JSON.stringify(e));
    }
  }

  async function removeSupperviseur(administered: string, administrator: string) {
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    try {
      if (currentUserEmail) {
        await removeSupervisorRequest(administered, administrator);
      }
    } catch (e) {
      alert("Exception userItems>removeSupperviseur(): " + JSON.stringify(e));
    }
  }



  const helpedusers = contacts.filter((c) => c.isHelpedProfil);

  return <IonModal isOpen={enable} cssClass='add-user-modal  modal2' onDidDismiss={() => onClose()}>
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle style={{ padding: "20px" }} >Liste des contacts supervisables</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="">
          <IonRow>
            {helpedusers.map((contact) => {

              const alreadyHaveRequest = _.find(relationships, (r) => _.some(r.users, (u) => u == contact.email));

              return <IonCol
                size="12"
                size-lg="4"
                size-md="6"
                size-sm="12"
                key={contact.id}
              >
                <UserItem user={contact} type={userCardType.SupervisorUserList} buttons={
                  <IonCol size="12">
                    {alreadyHaveRequest ?
                      <IonButton
                        className="btn-call"
                        color={alreadyHaveRequest.state == SupervisorRelationshipState.Waiting ? "light" : "danger"}
                        type="button"
                        onClick={() => removeSupperviseur(alreadyHaveRequest.administered, alreadyHaveRequest.administrator)}
                        expand="block"
                      > <IonIcon icon={alreadyHaveRequest.state == SupervisorRelationshipState.Waiting ? hourglassOutline : personRemoveOutline} className="icon-size" /> {alreadyHaveRequest.state == SupervisorRelationshipState.Waiting ? "En attente" : "Retirer"} </IonButton> :
                      <IonButton
                        className="btn-call"
                        color="success"
                        type="button"
                        onClick={() => requestAddSupperviseur(contact)}
                        expand="block"
                      > <IonIcon icon={personAddOutline} className="icon-size" /> Ajouter </IonButton>}

                  </IonCol>} />
              </IonCol>
            })}

          </IonRow>
          {helpedusers.length == 0 && <div className="text-center"><IonText color="secondary" >Aucun contact disponible</IonText></div>}
        </div>
      </IonContent>
      <div slot="fixed" style={{ width: '100%', bottom: "0" }}>
        <Button color="light" onClick={() => onClose()} text="Retour" icon={arrowBackCircleOutline} />
      </div>
    </IonPage>
  </IonModal>;
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    appOnline: state.global.appOnline,
    currentUser: state.user.userFirestore,
    supervisorRequests: state.supervisor.requests,
  }),
  mapDispatchToProps: {
    setShowOffline,
    setToastNotification,
  },
  component: AddUserModal,
});