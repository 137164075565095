import { DeviceInfo } from "@capacitor/device";

export interface User {
  id: string;
  email: string;
  isWaitingActionFromOther: boolean;
  telephone: string;
  firstName: string,
  lastName: string,
  pushToken?: string;
  picture: string;
  pushTokenLastUpdate?: string;
  lastActivity?: Date;
  config?: UserConfiguration;
  
  /**if true, show extra informations for current user interface (ex: add label under header button like 'Menu', 'New call' etc ...) */
  isHelpedProfil?:boolean;
  isSubscriber?:boolean;
  screensaverExcludedFiles?:string[];
  // current user device platform (android, ios or web). Used for PUSH notifications
  deviceInfo:DeviceInfo;
  status?: boolean;
}

export interface UserConfiguration {
  /**Font size of the application */
  fontClass?: FontClassType;
  /**Timeout of idle before show screensaver (milliseconds) */
  timeoutScreensaver?: number;

  timeoutEnableScreenSaverStart?:Time;
  timeoutEnableScreenSaverEnd?:Time;
  /**Contact to start visocall if current user click emergency button */
  emergencyContacts?:string[];
  soundEnabled?: boolean;
  alarmClockRingtone?: string;
  alarmClockVolume?: number;
  subtitling?: boolean;
  recoveryEmail?: string;
}



export enum FontClassType {
  small = 'small-text',
  medium = 'medium-text',
  large = 'large-text'
}

export interface Time{
  hours: number;
  minutes: number
}