import {SharedFile} from './SharedFile';

export enum FolderType {
  shared = "shared",
  unshared = "unshared",
}
export interface SharedFolder {
    id: string;
    name: string;
    creator:string;
    /**
     * Set by firebase functions when sharedFolder is updated
     */
    creatorName:string;
    users: string[];
    files: SharedFile[];
    dateCreate: Date;
    notViewBy: string[];
    type: FolderType;
  }
  