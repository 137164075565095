import React, { Fragment, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonText,
  IonButton,
  IonIcon,
  IonContent,
} from "@ionic/react";
import UserItem, { userCardType } from "./UserItem";
import { RouteComponentProps, withRouter } from "react-router";
import Loading from "./Loading";
import { UserWithRelationship } from "../models/UserWithRelationship";
import { connect } from "../data/connect";
import { setLoading, startCall } from "../data/visioCall/visioCall.actions";
import * as selectors from "../data/selectors";
import "./SpeakerList.scss";
import _ from "lodash";
import { UserSupervised } from "../models/UserSupervised";
import { UserRelationship,RelationshipState} from "../models/UserRelationship";
import { ContactGroup } from "../models/ContactGroup";
import ContactGroupItem, { groupCardType } from "./contacts/ContactGroupItem";
import SelectMultipleUsers from "./SelectMultipleUsers";
import { videocam } from "ionicons/icons";
import { setShowOffline, setToastNotification } from "../data/global/global.actions";
import { ToastNotification } from "../models/ToastNotification";
import { _MaxParticipantsVisioCall } from "../appConfig";
import { updateLastActivity } from "../data/user/user.actions";
import AnnuaireSearch from "./AnnuaireSearch";
import { User } from "../models/User";
import { getGlobalConfig } from "../api/globalConfig";
interface OwnProps { }

interface StateProps {
  users: UserWithRelationship[];
  loadingCall: boolean;
  loadingAnnuaire: boolean;
  currentUserEmail?: string;
  friendRequestsPending: UserWithRelationship[];
  supervisedAccount?: UserSupervised;
  allUserRelations: UserRelationship[];
  contactGroups: ContactGroup[];
  currentUser?: User;
  appOnline: boolean;
  isHelpedProfil: boolean; 
}

interface DispatchProps {
  setLoadingCall: typeof setLoading;
  updateLastActivity: typeof updateLastActivity;
  startCall: typeof startCall;
  setToastNotification: typeof setToastNotification;
  setShowOffline: typeof setShowOffline;
}

interface SpeakerListProps
  extends RouteComponentProps,
  OwnProps,
  StateProps,
  DispatchProps { }

const SpeakerList: React.FC<SpeakerListProps> = ({
  users,
  loadingCall,
  loadingAnnuaire,
  currentUserEmail,
  supervisedAccount,
  friendRequestsPending,
  allUserRelations,
  contactGroups,
  history,
  currentUser,
  appOnline,
  isHelpedProfil,
  setLoadingCall,
  updateLastActivity,
  startCall,
  setToastNotification,
  setShowOffline
}) => {
  const [formUsers, setFormUsers] = useState<string[]>([]);
  const [formGroups, setFormGroups] = useState<ContactGroup[]>([]);

  const updateSelectedUsers = (selectedUsers: string[]) => {
    setFormUsers(selectedUsers);
  };
  const updateSelectedGroups = (selectedGroups: ContactGroup[]) => {
    setFormGroups(selectedGroups);
  };
  const [showSpeakerList, setShowSpeakerList] = useState(true);

  const hideSpeakerList = (show: boolean) => {
    setShowSpeakerList(show);
  };
  async function call() {
    const config = await getGlobalConfig(isHelpedProfil,currentUserEmail);
    const maxNumberContactPerVisio = config.contact?.maxNumberContactPerVisio;
    let usersToCall: string[] = [];
    let groupsIdToCall: string[] = [];
    formGroups.forEach((group) => {
      group.members.map((member) => usersToCall.push(member));
      groupsIdToCall.push(group.id)
    });
    usersToCall = [...usersToCall, ...formUsers];
    usersToCall = usersToCall.filter((c, index) => {
      return usersToCall.indexOf(c) === index && c !== currentUserEmail;
    });
    if (maxNumberContactPerVisio && +maxNumberContactPerVisio < usersToCall.length) {
      setToastNotification({
        message:
          "Le nombre maximum de contacts autorisés, y compris vous-même, est de " +
          maxNumberContactPerVisio +
          " pour un appel vidéo",
        color: "danger",
      } as ToastNotification);
      return;
    }
    if (usersToCall.length === 0) {
      setToastNotification({
        color: "danger",
        message:
          "Vous devez sélectionner au moins un contact pour lancer l'appel",
      } as ToastNotification);
    } else {
      try {
        if (currentUser) {
          await startCall(usersToCall, currentUser, groupsIdToCall);
          await updateLastActivity();
        }
        history.push("/tabs/conference/video");
      } catch (e) {
        alert("Exception userItems>callUser(): " + JSON.stringify(e));
      }
    }
  }

  //usersFiltered : the number of friends
  let usersFiltered = _.filter(
    users,
    (user) =>
      user.email != currentUserEmail &&
      _.some(user.relationships, (relation) =>
        _.some(relation.users, (u) => u == currentUserEmail)
      )
  );
  usersFiltered = _.reverse(
    _.sortBy(usersFiltered, (user) => {
      if (
        user.relationships &&
        user.relationships.length > 0 &&
        user.relationships[0].callsCounter
      )
        return user.relationships[0].callsCounter;
      else return 0;
    })
  );

  let groupsFiltered = _.reverse(
    _.sortBy(contactGroups, (group) => {
      if (group.callsCounter)
        return group.callsCounter;
      else return 0;
    })
  );
  const pendingRequest = friendRequestsPending;
  const havePendingRequest = pendingRequest.length > 0;
  const haveUserChecked = formUsers.length > 0 || formGroups.length > 0;
  if (supervisedAccount) {
    usersFiltered.forEach((user) => {
      const userRelation = _.find(
        allUserRelations,
        (relation) =>
          _.some(relation.users, (u) => u === currentUserEmail) &&
          _.some(relation.users, (u) => u === user.email)
      );
      user.isWaitingActionFromOther = userRelation
        ? userRelation.emailFrom === currentUserEmail && !userRelation.accepted && userRelation.state?.state == RelationshipState.Waiting
        : false;
    });
  }

  return (
    <Fragment>
      <IonContent>
        <AnnuaireSearch onSearch={hideSpeakerList} />
        {showSpeakerList && (
          <Fragment>
            {/** list of friends */}
            {supervisedAccount && (
              <IonGrid fixed>
                <IonRow>
                  {groupsFiltered.map((group) => (
                    <IonCol
                      size="12"
                      size-lg="4"
                      size-md="6"
                      size-sm="12"
                      key={group.id}
                    >
                      <ContactGroupItem
                        key={group.id}
                        group={group}
                        type={groupCardType.Annuaire}
                      />
                    </IonCol>
                  ))}
                  {usersFiltered
                    .sort((a, b) =>
                      a.isWaitingActionFromOther && !b.isWaitingActionFromOther
                        ? -1
                        : 1
                    )
                    .map((user) => (
                      <IonCol
                        size="12"
                        size-lg="4"
                        size-md="6"
                        size-sm="12"
                        key={user.id}
                      >
                        <UserItem
                          key={user.id}
                          user={user}
                          type={userCardType.Search}
                          disableCallOnClick={true}
                        />
                      </IonCol>
                    ))}
                </IonRow>
              </IonGrid>
            )}
            {!supervisedAccount && usersFiltered.length > 0 && (
              <div className="full-height-flex">
                <SelectMultipleUsers
                  users={users}
                  currentUserEmail={currentUserEmail ? currentUserEmail : ""}
                  selectedUsers={formUsers}
                  checkedAll={false}
                  updateSelectedUsers={updateSelectedUsers}
                  groups={contactGroups}
                  selectedGroups={formGroups}
                  updateSelectedGroups={updateSelectedGroups}
                  showContactChip={false}
                />
              </div>
            )}
            {/** if user has no friends */}
            {usersFiltered.length == 0 && !havePendingRequest && (
              <div style={{ textAlign: "center", marginTop: "40px" }}>
                <IonLabel color="primary">
                  <IonText>
                    Veuillez saisir un nom dans l'annuaire pour demander une
                    mise en relation à vos contacts
                  </IonText>{" "}
                </IonLabel>
              </div>
            )}
            {/** loadings components */}
            <Loading
              enable={loadingCall}
              text="Création de l'appel ..."
              callbackCancel={() => setLoadingCall(false)}
              textCancel="Retour"
            />
            <Loading enable={loadingAnnuaire} text="Affichage en cours ..." />
          </Fragment>
        )}
      </IonContent>
      {showSpeakerList &&
        usersFiltered.length > 0 &&
        !loadingCall &&
        !loadingAnnuaire && haveUserChecked && (
          <div slot="fixed" style={{ width: "100%", bottom: "0", marginTop: "20px" }}>
            <IonButton
              style={{ width: "100%" }}
              color=""
              onClick={() => appOnline ? call() : setShowOffline(true)}
            >
              <IonIcon icon={videocam} className="icon-size" /> APPELER
            </IonButton>
          </div>
        )}
    </Fragment>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setLoadingCall: setLoading,
    updateLastActivity,
    startCall,
    setToastNotification,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    users: !state.supervisor.supervisedAccount
      ? selectors.getUsers(state)
      : selectors.getSupervisedUsers(state),
    loadingCall: state.visioCall.loading,
    loadingAnnuaire: state.user.loadingAnnuaire,
    // if we are in mode supervisor so use supervised email and not current email
    currentUserEmail: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user.email
      : state.user.username,
    fontSizeName: selectors.getFontSizeConfig(state),
    supervisedAccount: state.supervisor.supervisedAccount,
    friendRequestsPending: state.user.requestPending
      ? state.user.requestPending
      : [],
    allUserRelations: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.allUserRelations
      : state.user.allUserRelations,
    contactGroups: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.contactGroups
      : state.user.groups || [],
    currentUser: state.user.userFirestore,
    appOnline: state.global.appOnline,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
  }),

  component: withRouter(SpeakerList),
});
