import React, { useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonGrid, IonRow, IonCol, IonLabel, IonProgressBar, IonButton, IonIcon, IonModal, IonItem, IonInput } from '@ionic/react';
import { addOutline,arrowBackCircleOutline } from 'ionicons/icons';
import FolderItem from '../components/FolderItem';
import { connect } from '../data/connect';
import { UserWithRelationship } from '../models/UserWithRelationship';
import { SharedFolder } from '../models/SharedFolder';
import { createFolder, updateAlbumsNotViewByUser } from '../data/sharedFolder/sharedFolder.actions';
import * as selectors from '../data/selectors';
import { setShowOffline, setToastNotification } from '../data/global/global.actions';
import { ToastNotification } from '../models/ToastNotification';
import _ from 'lodash';
import './MediaShared.scss';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import Button from '../components/Button';
import { IconSvgEnum } from '../components/IconSvg';
import Header, { ButtonData } from '../components/Header';
import SelectMultipleUsers from '../components/SelectMultipleUsers';
import {UserSupervised} from "../models/UserSupervised";
import { ContactGroup } from "../models/ContactGroup";
import { loadAndSetUserSupervised } from '../data/supervisor/supervisor.actions';
import { convertToTimestamp } from '../util/functions';
import { getGlobalConfig } from "../api/globalConfig";
interface OwnProps {

};

interface StateProps {
  loading: boolean;
  users: UserWithRelationship[];
  groups: ContactGroup[];
  folders: SharedFolder[];
  currentUserEmail?: string;
  firstName: string;
  lastName: string;
  soundEnabled: boolean;
  isHelpedProfil: boolean;
  supervisedAccount :UserSupervised | null;
  appOnline: boolean;
};

interface DispatchProps {
  createFolder: typeof createFolder;
  setToastNotification: typeof setToastNotification;
  updateAlbumsNotViewByUser: typeof updateAlbumsNotViewByUser;
  loadAndSetUserSupervised: typeof loadAndSetUserSupervised;
  setShowOffline: typeof setShowOffline;
};

interface MediaSharedProps extends RouteComponentProps, OwnProps, StateProps, DispatchProps {
};
interface OptionalState {
  newAlbumName: string;
}

const MediaShared: React.FC<MediaSharedProps> = ({ appOnline, supervisedAccount, currentUserEmail, loading, users,groups, folders,firstName, lastName, soundEnabled, isHelpedProfil,updateAlbumsNotViewByUser, createFolder, setToastNotification, loadAndSetUserSupervised,setShowOffline, history, match }) => {

  const [showCreateFolderForm, setShowCreateFolderForm] = useState(false);
  const [formFolderName, setFormFolderName] = useState("");
  const [formSharedUsers, setFormSharedUsers] = useState<string[]>([]);
  const [formGroups, setFormGroups] = useState<ContactGroup[]>([]);
  const state = useLocation().state as OptionalState;

  useEffect(() => {
    onNewAlbumsViewByUser();
  }, []);

  useEffect(() => {
    // If newAlbumName exist, open create folder modal and set default folder/album name
    // We come from voice command and user ask to create new folder
    if (state && state.newAlbumName) {
      setFormFolderName(state.newAlbumName);
      setShowCreateFolderForm(true);
    }
  }, [state]);

  useEffect(() => {
    folders = folders.sort((f1: SharedFolder, f2: SharedFolder) => {
       const dateCreate1 = convertToTimestamp(f1.dateCreate);
       const dateCreate2 = convertToTimestamp(f2.dateCreate);
       if(dateCreate1 && dateCreate2 && dateCreate1 > dateCreate2){
         return -1
       }else{
         return 1
       }
    })
  }, [folders])

  /**
   * Save user see all comments on the current file in database
  */
  const onNewAlbumsViewByUser = () => {
    if (currentUserEmail) {
      const albumsNotViewByUser = folders.filter(folder => folder.notViewBy && _.some(folder.notViewBy, (user) => user == currentUserEmail) && folder.creator !== currentUserEmail)
      if (albumsNotViewByUser) {
        updateAlbumsNotViewByUser(albumsNotViewByUser, currentUserEmail, supervisedAccount);
      }
    }
  }

  const updateSelectedUsers = (selectedUsers: string[]) => {
    setFormSharedUsers(selectedUsers);
  }
  const updateSelectedGroups = (selectedGroups: ContactGroup[]) => {
    setFormGroups(selectedGroups);
  }
  const myfolders = _.filter(folders,(folder) => folder.creator == currentUserEmail);
  async function createNewFolder() {
    if(!appOnline){
      setShowOffline(true)
      return;
    } 
    const config = await getGlobalConfig(isHelpedProfil,currentUserEmail);
    const maxNumberAlbums = config.album?.maxNumberAlbums;
    if (!currentUserEmail) return;
    let usersToShareFolders: string[] = [];
    formGroups.forEach((group) => {
      group.members.forEach((member) => usersToShareFolders.push(member));
    });
    usersToShareFolders = [...usersToShareFolders, ...formSharedUsers];
    usersToShareFolders = usersToShareFolders.filter((c, index) => {
      return (
        usersToShareFolders.indexOf(c) === index && c !== currentUserEmail
      );
    });
    console.log(myfolders);
    console.log(folders);
    if(maxNumberAlbums && +maxNumberAlbums <= myfolders.length) {
      setToastNotification({
        message:
        "Le nombre maximum de " +
        maxNumberAlbums +
        " album(s) autorisé(s) a déjà été atteint",
        color: "danger",
      } as ToastNotification);
      return;
    }
    if (!formFolderName) {
      setToastNotification({ message: "Le nom de l'album est nécessaire", color: "danger" } as ToastNotification)
      return;
    }

    if (usersToShareFolders.length == 0) {
      setToastNotification({ message: "Vous devez sélectionner au moins un contact", color: "danger" } as ToastNotification)
      return;
    }

    let allUserRelations: string[] = _.cloneDeep(usersToShareFolders);
    allUserRelations.push(currentUserEmail)

    // check folder with same name and users shared doesn't exist
    if (_.some(folders, (folder) => {
      folder.name.toLowerCase() == formFolderName.toLowerCase() && _.isEqual(folder.users.sort(), allUserRelations.sort())
      return
    })) {
      setToastNotification({ message: "Il existe déjà un album " + formFolderName + " partagé avec les mêmes utilisateurs", color: "danger" } as ToastNotification);
      return;
    }

    if (currentUserEmail) {
      createFolder(currentUserEmail, formFolderName, usersToShareFolders, history, supervisedAccount)
      setShowCreateFolderForm(false);
      setFormFolderName('');
      setFormSharedUsers([]);
      setFormGroups([]);
    }
  }
  let leftButtons = [];
  if (folders.length > 0) {
    leftButtons.push({
      title: "Album",
      iconSvg: IconSvgEnum.album,
      onClick: () => setShowCreateFolderForm(true),
      addPlusIcon: true
    } as ButtonData)
  }
  return (
    <IonPage id="shared-folder" >
      <Header
        title={supervisedAccount ? "Les albums partagés" : "Albums"}
        showSendMediaBtn
        leftButtons={leftButtons}
      />

      <IonContent fullscreen={true}>
        <div className={isHelpedProfil ? "padding-bottom" : ""}>


          {loading ?
            <div style={{ marginTop: '20px' }}><IonProgressBar type="indeterminate"></IonProgressBar></div>
            :
            <IonGrid fixed>
              <IonRow>
                {folders.map(folder => (
                  <IonCol size="12" size-lg="4" size-md="6" size-sm="12" key={folder.id}>
                    <FolderItem
                      folder={folder}
                      firstName={firstName}
                      lastName={lastName}
                    />
                  </IonCol>

                ))}
              </IonRow>
            </IonGrid>
          }

          {!loading && folders.length == 0 &&
            <div style={{ textAlign: "center", paddingTop: "20px" }}>
              <IonLabel style={{}}>Veuillez cliquer ci-dessous pour ajouter votre premier Album</IonLabel>
              <div><IonButton style={{ width: '100%', marginTop: "20px" }} onClick={() => setShowCreateFolderForm(true)}>
                <IonIcon icon={addOutline} className="icon-size" />
                créer un album</IonButton></div>
            </div>}


          <IonModal isOpen={showCreateFolderForm} onDidDismiss={() => setShowCreateFolderForm(false)}>

            <IonPage>
              
              <Header title="Créer un nouvel album" />
                <IonContent>
                  <div className="full-height-flex">
                    <IonGrid fixed>
                      <IonRow>
                        <IonCol size="12" style={{marginTop: "20px"}}>
                          <IonItem>
                              <IonInput size={30} value={formFolderName} placeholder="Nom de l'album" onIonChange={e => setFormFolderName(e.detail.value!)}></IonInput>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid> 
                    <SelectMultipleUsers
                      users={users}
                      currentUserEmail={currentUserEmail? currentUserEmail: ""}
                      selectedUsers={formSharedUsers}
                      checkedAll = {false}
                      updateSelectedUsers={updateSelectedUsers}
                      groups={groups}
                      selectedGroups={formGroups}
                      updateSelectedGroups={updateSelectedGroups}
                    />
                  </div>
              </IonContent>
              <div slot="fixed" style={{ width: '100%', bottom: "0", marginTop: "20px" }}><IonRow class="ion-no-padding">
                <IonCol size="6" class="ion-no-padding"><Button color="light" onClick={() => setShowCreateFolderForm(false)} text="Retour" icon={arrowBackCircleOutline} /></IonCol>
                <IonCol size="6" class="ion-no-padding"><Button color="success" onClick={createNewFolder} text="Valider" svg={IconSvgEnum.ok_white} /></IonCol>
              </IonRow></div>

            </IonPage>
          </IonModal>
        </div>
      </IonContent>

    </IonPage>
  );

};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    createFolder,
    setToastNotification,
    updateAlbumsNotViewByUser,
    loadAndSetUserSupervised,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    loading: state.sharedFolder.loading,
    users: selectors.getUsersFilteredByNumberOfCalls(state),
    currentUserEmail: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username,
    folders: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.folders : state.sharedFolder.folders,
    firstName: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.firstName : state.user.userFirestore ? state.user.userFirestore.firstName : "",
    lastName: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.lastName : state.user.userFirestore ? state.user.userFirestore.lastName : "",
    soundEnabled: selectors.getsoundEnabledConfig(state),
    isHelpedProfil: selectors.getIsHelpedProfil(state),
    supervisedAccount: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount: null,
    groups: state.user.groups || [],
    appOnline: state.global.appOnline

  }),
  component: React.memo(MediaShared)
});
