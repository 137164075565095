import React from "react";
import { IonPage, IonRow, IonCol, IonGrid } from "@ionic/react";
import { recordingOutline, videocamOffOutline } from "ionicons/icons";
import { connect } from "../data/connect";
import { Mailbox, MailboxItemType } from "../models/Mailbox";
import _ from "lodash";
import "./MailboxPage.scss";
import Header from "../components/Header";
import MenuItem from "../components/MenuItem";
import { UserSupervised } from "../models/UserSupervised";

interface OwnProps {}
interface StateProps {
  mailbox?: Mailbox;
}
interface DispatchProps {}
interface StateProps {
  supervisedAccount?:UserSupervised;
}
interface MailboxProps extends OwnProps, StateProps, DispatchProps {}

const MailboxPage: React.FC<MailboxProps> = ({ mailbox,supervisedAccount }) => {

  const savedMessages = _.orderBy(
    mailbox
      ? mailbox.items.filter(
          (item) => item.type === MailboxItemType.MessageVideo
        )
      : [],
    ["date"],
    ["desc"]
  );

  const missedCalls = _.orderBy(
    mailbox
      ? mailbox.items.filter(
          (item) => item.type === MailboxItemType.MissingCall
        )
      : [],
    ["date"],
    ["desc"]
  );

  const newSavedMessages = savedMessages
    ? savedMessages.filter((message) => !message.viewed).length
    : 0;

  const newMissedCalls = missedCalls
    ? missedCalls.filter((call) => !call.viewed).length
    : 0;

  const routes = [
    {
      id: 0,
      title: "Enregistrement de message",
      path: "/tabs/contacts/message/create",
      icon: "assets/icon/recording.svg",
      color: "#195EA4"
    },
    {
      id: 1,
      title: "Messages enregistrés",
      path: "/tabs/mailbox/savedmessages",
      icon: recordingOutline,
      color: "#195EA4",
      newMessages: newSavedMessages,
    },
    {
      id: 2,
      title: "Appels manqués",
      path: "/tabs/mailbox/missedcalls",
      icon: videocamOffOutline,
      color: "#195EA4",
      newMessages: newMissedCalls,
    },
    {
      id: 3,
      title: "Rappels",
      path: "",
      icon: "assets/icon/pin.svg",
      color: "#195EA4"
    },
  ];

  return (
    <IonPage>
      <Header title="Messages" showHelp/>
      <div style={{ flexGrow: 1, overflow: "auto" }}>
      <IonGrid fixed style={{height: "90%"}}>
          <IonRow style={{height: "100%"}}>
            {routes
              .map((route) => (
                <IonCol
                  style={{height: "50%"}}
                  key={route.title}
                  size="12"
                  size-lg="6"
                  size-md="6"
                  size-sm="6"
                  size-xs="6"
                  auto-hide="false"
                >
                  <MenuItem route={route} />
                </IonCol>
              ))}
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {},
  mapStateToProps: (state) => ({
    mailbox: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.mailbox : state.user.mailbox,
    supervisedAccount: state.supervisor.supervisedAccount
  }),
  component: React.memo(MailboxPage),
});
