import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonLabel,
  IonItem,
} from "@ionic/react";
import {isPlatform} from "@ionic/core";
import {
  sendOutline,
  videocamOutline,
  arrowBackCircleOutline
} from "ionicons/icons";
import { Capacitor } from "@capacitor/core";
import { connect } from "../data/connect";
import { UserWithRelationship } from "../models/UserWithRelationship";
import { uploadVideoMessage } from "../data/sharedFolder/sharedFolder.actions";
import {
  setUserIsActive,
  setToastNotification,
  setShowOffline,
} from "../data/global/global.actions";
import * as selectors from "../data/selectors";
import _ from "lodash";
import "./MessageRecording.scss";
import { RouteComponentProps, useLocation } from "react-router-dom";
import {
  MediaCapture,
  MediaFile,
  CaptureError,
} from "@ionic-native/media-capture";
import { File, DirectoryEntry } from "@ionic-native/file";
import { ToastNotification } from "../models/ToastNotification";
import Button from "../components/Button";
import Header from "../components/Header";
import SelectMultipleUsers from "../components/SelectMultipleUsers";
import RecordView from "../components/RecordView";
import { menuController } from "@ionic/core";
import { BackgroundTask } from '@robingenz/capacitor-background-task';
import { ContactGroup } from "../models/ContactGroup";

interface OwnProps {}

interface StateProps {
  users: UserWithRelationship[];
  currentUserEmail?: string;
  groups: ContactGroup[];
  appOnline: boolean;
}

interface DispatchProps {
  uploadVideoMessage: typeof uploadVideoMessage;
  setToastNotification: typeof setToastNotification;
  setUserIsActive: typeof setUserIsActive;
  setShowOffline: typeof setShowOffline;
}

interface MessageRecordingProps
  extends RouteComponentProps,
    OwnProps,
    StateProps,
    DispatchProps {}

interface OptionalState {
  users: string[];
  isFromCall?: boolean;
  idVisioCall?: string;
  isFromMissedCallMessage?: boolean;
}

const MessageRecording: React.FC<MessageRecordingProps> = ({
  currentUserEmail,
  users,
  groups,
  uploadVideoMessage,
  setToastNotification,
  setUserIsActive,
  setShowOffline,
  history,
  appOnline
}) => {
  // user selected from drop down list recipient
  const [formUsers, setFormUsers] = useState<string[]>([]);
  const [formGroups, setFormGroups] = useState<ContactGroup[]>([]);
  const [videoBlob, setVideoBlob] = useState<Blob>();
  const state = useLocation().state as OptionalState;
  const [isFromCall, setIsFromCall] = useState<boolean>();
  const [isFromMissedCallMessage, setIsFromMissedCallMessage] = useState<boolean>(state && state.isFromMissedCallMessage ? true: false);
  const [title, setTitle] = useState("Enregistrer un message vidéo");
  /**
   * Indicate if user is currently using camera app for recording message
   */
  const [isRecordingVideo, setIsRecordingVideo] = useState<boolean>(false);

  const [startRecordVideo, setStartRecordVideo] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showRecording, setShowRecording] = useState(false);

  const isMobile = isPlatform("mobile") && !isPlatform("tablet") && !isPlatform("mobileweb");

  const setVideoBlobUrl = async (url: string | null) => {
    if (url) {
      let blob = await fetch(url).then((r) => r.blob());
      setVideoBlob(blob);
    }
  };
  const updateSelectedGroups = (selectedGroups: ContactGroup[]) => {
    setFormGroups(selectedGroups);
  }

  useEffect(() => {
    !isFromCall && setUserIsActive(true);
    return () => {
      setUserIsActive(false);
    };
  }, [isFromCall]);

  useEffect(() => {
    if(showContacts){
      setTitle("Sélectionner vos destinataires");
    }
  }, [showContacts]);

  useEffect(()=>{
    async function startRecord() {
      await startMediaCapture();
    }
    if(isFromCall === false || isFromMissedCallMessage){
      startRecord();
    }
  },[isFromCall]);

  useEffect(() => {
    if (state) {
      setIsFromCall(state.isFromCall);
    } else setIsFromCall(false);
  }, []);

  useEffect(() => {
    if (state && state.users && (isFromCall !== state.isFromCall || isFromMissedCallMessage)) {
      // pre-select user if present in route state
      // case when user start a call but nobody respond => record and send a new message
      setFormUsers(_.filter(state.users, (u) => u !== currentUserEmail));
    }
  }, [useLocation().state, users]);

  const updateSelectedUsers = (selectedUsers: string[]) => {
    setFormUsers(selectedUsers);
  };

  const startMediaCapture = async () => {

    if (!isMobile) {
      setStartRecordVideo(true);
      return;
    }

    setIsRecordingVideo(true);

    let capture = await MediaCapture.captureVideo({ limit: 1 }).catch((e) => {
      setIsRecordingVideo(false);
      if (e.code !== 3) {
        // code 3 = user cancel (click back button) on camera page
        alert("Impossible d'accèder à la caméra: " + JSON.stringify(e));
      }
    });

    if (!capture) {
      //alert("Erreur, aucune vidéo récupérée")
      history.push("/tabs/contacts");
      menuController.open();
      return;
    }

    const captureError = capture as CaptureError;
    const captureSuccess = capture as MediaFile[];

    if (captureError && captureError.code) {
      alert("Erreur lors de la capture vidéo " + JSON.stringify(captureError));
      return;
    }

    if (captureSuccess && captureSuccess.length === 0) {
      alert("Erreur aucune vidéo récupéré");
      return;
    }

    const media = captureSuccess[0] as MediaFile;

    let resolvedPath: DirectoryEntry;
    let path = media.fullPath.substring(0, media.fullPath.lastIndexOf("/"));

    if (Capacitor.getPlatform() === "ios") {
      resolvedPath = await File.resolveDirectoryUrl("file://" + path);
    } else {
      resolvedPath = await File.resolveDirectoryUrl(path);
    }

    return File.readAsArrayBuffer(resolvedPath.nativeURL, media.name)
      .then(
        (buffer: ArrayBuffer) => {
          // get the buffer and make a blob to be saved
          let imgBlob = new Blob([buffer], {
            type: media.type,
          });
          setVideoBlob(imgBlob);
        },
        (error: any) => {
          setIsRecordingVideo(false);
        }
      )
      .catch((e) => {
        alert("resolvedPath catch: " + JSON.stringify(e));
      });
  };

  const resetMediaCapture = async () => {
    setVideoBlob(undefined);
    if (isMobile) {
      await startMediaCapture();
    } else {
      setStartRecordVideo(false);
    }
  };

  const sendMedia = async () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    if (videoBlob) {
      let usersToSendMessage: string[] = [];
      formGroups.forEach((group) => {
        group.members.forEach((member) => usersToSendMessage.push(member));
      });
      usersToSendMessage = [...usersToSendMessage, ...formUsers];
      usersToSendMessage = usersToSendMessage.filter((c, index) => {
        return (
          usersToSendMessage.indexOf(c) === index && c !== currentUserEmail
        );
      });
      if(usersToSendMessage.length === 0){
        setToastNotification({
          message: "Vous devez sélectionner au moins un contact",
          color: "danger",
        } as ToastNotification);
        return;
      }
      if (isMobile) {
        let taskId : any = BackgroundTask.beforeExit(async () => {
          _.forEach(usersToSendMessage, async (user) => {
            uploadVideoMessage(
              user,
              videoBlob,
              "mp4",
              state && state.idVisioCall ? state.idVisioCall : undefined
            );
          });
          BackgroundTask.finish({
            taskId,
          });
        });
      } else {
        _.forEach(usersToSendMessage, async (user) => {
          uploadVideoMessage(
            user,
            videoBlob,
            "mp4",
            state && state.idVisioCall ? state.idVisioCall : undefined
          );
        });
      }

      history.push("/tabs/contacts");
      menuController.open();
      setToastNotification({
        message: "Votre message vidéo a bien été envoyé",
        color: "success",
      } as ToastNotification);
    } else {
      alert("Aucun fichier renseigné");
    }
  };

  const selectUsers = () => {
    if (videoBlob) {
      setShowContacts(true)
    }else{
      alert("Aucun fichier renseigné");
    }
  }

  const cancelHandler = () => {
    history.push("/tabs/contacts");
    menuController.open();
  }

  let allUser = users;
  if (state && state.users.length > 0)
    allUser = _.filter(allUser, (u) =>
      _.some(formUsers, (mail) => mail === u.email)
    );

  const showRecord =
    (videoBlob && isMobile) ||
    (startRecordVideo && !isMobile);

  // if we come from a failed call  (recipient doesn't respond)
  if (isFromCall && !videoBlob) {
    return (
      <IonContent>
        <div>
          <IonItem
            lines={"none"}
            style={{ margin: "20px", textAlign: "center" }}
          >
            <IonLabel className={" ion-text-wrap"}>
              Votre correspondant n'est pas disponible. Voulez vous lui laisser
              un message vidéo ?
            </IonLabel>
          </IonItem>
          <IonRow>
            <IonCol size="6">
              <Button
                color="light"
                onClick={() => cancelHandler()}
                text="Retour"
                icon={arrowBackCircleOutline}
              />
            </IonCol>
            <IonCol size="6">
              <Button
                color="success"
                onClick={async () => {
                  setIsFromCall(false);
                  setShowRecording(true);
                }}
                text="Enregistrer"
                icon={videocamOutline}
              />
            </IonCol>
          </IonRow>
        </div>
      </IonContent>
    );
  }
  // else, classic page where user had to select recipient(s)
  else {
    return (
      <IonPage id="shared-folder">
        <Header title={title} action={cancelHandler} showHelp/>

        {!isFromCall && (
          <IonContent>
            <div>
              {(
                <React.Fragment>
                  {showContacts && (
                    <SelectMultipleUsers
                      users={users}
                      currentUserEmail={
                        currentUserEmail ? currentUserEmail : ""
                      }
                      selectedUsers={formUsers}
                      checkedAll={false}
                      updateSelectedUsers={updateSelectedUsers}
                      groups={groups}
                      selectedGroups={formGroups}
                      updateSelectedGroups={updateSelectedGroups}
                    />
                  )}
                </React.Fragment>
              )}

              {!showContacts && videoBlob && isMobile && (
                <div className="video-manage">
                  <video
                    controls
                    style={{ marginTop: "15px;", height: "80%" }}
                    autoPlay={true}
                    playsInline
                  >
                    <source
                      src={URL.createObjectURL(videoBlob)}
                      type="video/mp4"
                    />
                  </video>
                </div>
              )}
              {!showContacts && startRecordVideo && !isMobile && (
                <div
                  className="video-manage"
                  style={{
                    position: "relative",
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <RecordView setVideoBlobUrl={setVideoBlobUrl} />
                </div>
              )}
            </div>
          </IonContent>
        )}
        {(showContacts || (isFromMissedCallMessage && !showContacts && showRecord) || showRecording) && (
          <div slot="fixed" style={{ width: "100%", bottom: "0", marginTop: "20px" }}>
            <IonRow class="ion-no-padding">
              <IonCol class="ion-no-padding" size="6">
                <Button
                  color="light"
                  onClick={() => cancelHandler()}
                  text="Retour"
                  icon={arrowBackCircleOutline}
                  className="no-margin"
                />
              </IonCol>
              <IonCol class="ion-no-padding" size="6">
                <Button
                  color="success"
                  onClick={() => sendMedia()}
                  text="Envoyer"
                  icon={sendOutline}
                  className="no-margin"
                  disabled={!videoBlob}
                />
              </IonCol>
            </IonRow>
          </div>
        )}

        {!showContacts && showRecord && !isFromMissedCallMessage && !showRecording && (
          <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
            <IonRow class="ion-no-padding">
              <IonCol class="ion-no-padding" size="6">
                <Button
                  color="light"
                  onClick={() => cancelHandler()}
                  text="Retour"
                  icon={arrowBackCircleOutline}
                  className="no-margin"
                />
              </IonCol>
              <IonCol class="ion-no-padding" size="6">
                <Button
                  color="success"
                  onClick={() => selectUsers()}
                  text="Sauvegarder"
                  disabled={!videoBlob}
                  icon={"/assets/icon/ok-white.svg"}
                  className="no-margin"
                />
              </IonCol>
          </IonRow>
          </div>
        )}
      </IonPage>
    );
  }
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    uploadVideoMessage,
    setToastNotification,
    setUserIsActive,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    users: selectors.getUsersFilteredByNumberOfCalls(state),
    currentUserEmail: state.user.username,
    groups: state.user.groups || [],
    appOnline: state.global.appOnline
  }),
  component: React.memo(MessageRecording),
});
