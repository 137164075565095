import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { startCall } from "../data/visioCall/visioCall.actions";
import {
  addFriend,
  removeFriend,
  acceptFriend,
  updateLastActivity,
} from "../data/user/user.actions";
import { connect } from "../data/connect";
import * as selectors from "../data/selectors";
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonIcon,
  IonButton,
  IonCol,
  IonRow,
  IonCheckbox,
} from "@ionic/react";
import {
  personAddOutline,
  personRemoveOutline,
  hourglassOutline,
  medkitOutline,
  peopleOutline,
  heartOutline,
  diceOutline,
} from "ionicons/icons";
import { UserWithRelationship } from "../models/UserWithRelationship";
import { relationshipGroupEnum } from "../util/relationshipGroupEnum";
import _ from "lodash";
import "./UserItem.css";
import { UserRelationship,RelationshipState } from "../models/UserRelationship";
import { setSearchTerm, setShowOffline, setToastNotification } from "../data/global/global.actions";
import { ToastNotification } from "../models/ToastNotification";

import TimeAgo from "react-timeago";
//@ts-ignore
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import Button from "./Button";
import { IconSvgEnum } from "./IconSvg";
import { User } from "../models/User";
import {
  loadAndSetUserSupervised,
} from "../data/supervisor/supervisor.actions";
import { UserSupervised } from "../models/UserSupervised";
import { convertToTimestamp } from "../util/functions";
import { defaultPicture } from "../util/commons";
import { readFileFromCache } from "../util/localStorage";
import { getGlobalConfig } from "../api/globalConfig";
import { checkIsValidPhone } from "../util/checkIsPhoneFormat";
import { sendSms } from "../api/sms";
import { _UrlMLV } from "../appConfig";
import { sendMailRequest } from "../api/mail";
import { MailType } from '../models/Mail';
import { validateEmail } from "../util/email.validation";
export enum userCardType {
  /**
   * Show call button
   */
  Annuaire = 0,
  /**
   * Show Add as friend button
   */
  Search = 1,

  /**
   * Show Accept or refuse frienship button
   */
  FriendRequest = 2,

  /**
   * Show select or unselect contact
   */
  Select = 3,

  /**
   * For manage supervisor demand (accepted or pending)
   */
  SupervisorUserList = 4,
}

interface StateProps {
  currentUserEmail?: string;
  allUserRelations: UserRelationship[];
  isHelpedProfil: boolean;
  supervisedAccount: UserSupervised | null;
  currentUser?: User;
  appOnline: boolean;
}

interface OwnProps {
  user: UserWithRelationship;
  type: userCardType;
  isCheckedAll?: boolean;
  initialChecked?: boolean;
  removeUser?: boolean;
  checkUser?: (user: User, checked: boolean) => void;
  resetUserToRemove?: () => void;
  buttons?: JSX.Element;
  /**If true, disable start call on click */
  disableCallOnClick?: boolean;
}

interface DispatchProps {
  startCall: typeof startCall;
  addFriend: typeof addFriend;
  removeFriend: typeof removeFriend;
  acceptFriend: typeof acceptFriend;
  setToastNotification: typeof setToastNotification;
  updateLastActivity: typeof updateLastActivity;
  setSearchTerm: typeof setSearchTerm;
  loadAndSetUserSupervised: typeof loadAndSetUserSupervised;
  setShowOffline: typeof setShowOffline;
}

interface UserItemProps
  extends RouteComponentProps,
  DispatchProps,
  OwnProps,
  StateProps { }

const UserItem: React.FC<UserItemProps> = ({
  user,
  type,
  isCheckedAll = false,
  initialChecked = false,
  removeUser,
  currentUserEmail,
  allUserRelations,
  buttons,
  isHelpedProfil,
  disableCallOnClick = false,
  supervisedAccount,
  currentUser,
  appOnline,
  history,
  checkUser = () => { },
  resetUserToRemove,
  startCall,
  addFriend,
  removeFriend,
  acceptFriend,
  setToastNotification,
  updateLastActivity,
  loadAndSetUserSupervised,
  setSearchTerm,
  setShowOffline
}) => {
  const [src, setSrc] = useState("");
  const userPicture = user.picture ? user.picture : defaultPicture;

  useEffect(() => {
    readFileFromCache(userPicture, setSrc);
  }, [userPicture]);

  const classes =
    type === userCardType.Select
      ? initialChecked
        ? "speaker-card selected-card"
        : "speaker-card"
      : "speaker-card border-orange";

  const [checked, setChecked] = useState(false);
  const [cardClasses, setCardClasses] = useState(classes);

  const changeListUsersHandler = (e: CustomEvent, user: User) => {
    if (!e.detail.checked) {
      setChecked(false);
      checkUser(user, false);
      setCardClasses("speaker-card");
    } else if (e.detail.checked) {
      setChecked(true);
      checkUser(user, true);
      setCardClasses("speaker-card selected-card");
    }
  };
  useEffect(() => {
    setChecked(isCheckedAll);
  }, [isCheckedAll]);
  useEffect(() => {
    if (initialChecked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [initialChecked]);

  useEffect(() => {
    if (removeUser) {
      setChecked(false);
      checkUser(user, false);
      if (resetUserToRemove) resetUserToRemove();
    }
  }, [removeUser]);

  async function callUser() {
    if (!disableCallOnClick) {
      try {
        if (currentUser) {
          await startCall([user.email], currentUser);
          await updateLastActivity();
        }
        history.push("/tabs/conference/video");
      } catch (e) {
        alert("Exception userItems>callUser(): " + JSON.stringify(e));
      }
    }
  }

  function getIconFromGroup(group: string) {
    switch (group) {
      case relationshipGroupEnum.Family:
        return heartOutline;
      case relationshipGroupEnum.Friends:
        return peopleOutline;
      case relationshipGroupEnum.Medical:
        return medkitOutline;
      case relationshipGroupEnum.Game:
        return diceOutline;
      default:
        break;
    }
  }
  const userRelationAccepted = allUserRelations.filter(rel => rel.accepted == true)

  async function requestAddFriend(group: string) {
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    try {
      if (currentUserEmail) {
        const Config = await getGlobalConfig(isHelpedProfil,currentUserEmail);
        const maxNumberContact = Config.contact?.maxNumberContact;
        if (maxNumberContact && +maxNumberContact <= userRelationAccepted.length) {
          setToastNotification({
            message: "Le nombre de contacts possible a déjà été atteint",
            color: "danger",
          } as ToastNotification);
          return;
        }
        await addFriend(currentUserEmail, user.email, group);
        if (validateEmail(user.email)) {
          let message = '';
          sendMailRequest(currentUserEmail, MailType.AddFriend, message, MailType.AddFriend, user.email)
        }
        if (supervisedAccount) loadAndSetUserSupervised(currentUserEmail);
        setToastNotification({
          message: "Votre demande de contact a été envoyée",
          color: "success",
        } as ToastNotification);
        if (checkIsValidPhone(user.email) && currentUser) {
          const message = `Bonjour ${user.firstName}, ${currentUser.firstName} ${currentUser.lastName} souhaite vous ajouter dans la liste de ses contacts Mon Lien Visio. Pour répondre, veuillez cliquer sur le lien ${_UrlMLV + "tabs/contacts"}`;
          sendSms(user.email, message)
        }

      }
    } catch (e) {
      alert("Exception userItems>requestAddFriend(): " + JSON.stringify(e));
    }
  }

  async function requestRemoveFriend(isWaiting: boolean = false,isRefused: boolean = false) {
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    try {
      if (currentUserEmail) await removeFriend(currentUserEmail, user.email, supervisedAccount,isRefused);
      if (currentUserEmail && supervisedAccount) loadAndSetUserSupervised(currentUserEmail)
      if (isWaiting) {
        setToastNotification({
          message: "Votre demande de contact a été annulée",
          color: "success",
        } as ToastNotification);
      } else if(isRefused) {
        setToastNotification({
          message: `${user.firstName} ${user.lastName} a été refusé(e)`,
          color: "success",
        } as ToastNotification);
      } else {
        setToastNotification({
          message: `${user.firstName} ${user.lastName} a été retiré(e) de vos contacts`,
          color: "success",
        } as ToastNotification);
      }
    } catch (e) {
      alert("Exception userItems>requestRemoveFriend(): " + JSON.stringify(e));
    }
  }

  async function requestAcceptFriend() {
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    try {
      const relationship = _.find(
        allUserRelations,
        (relation) =>
          _.some(relation.users, (u) => u === currentUserEmail) &&
          _.some(relation.users, (u) => u === user.email)
      );
      if (relationship) await acceptFriend(relationship.id);
      if (currentUserEmail && supervisedAccount)
        loadAndSetUserSupervised(currentUserEmail);
        if (validateEmail(user.email) && currentUser) {
          let message = '';
          sendMailRequest(currentUser.email, MailType.AcceptFriend, message, MailType.AcceptFriend, user.email)
        }
      if (checkIsValidPhone(user.email) && currentUser) {
        const message = `Bonjour ${user.firstName}, ${currentUser.firstName} ${currentUser.lastName} a accepté votre demande de contact Mon Lien Visio. Pour accéder à votre nouveau contact, veuillez cliquer sur le lien ${_UrlMLV + "tabs/contacts"}`;
        sendSms(user.email, message)
      }
    } catch (e) {
      alert("Exception userItems>requestRemoveFriend(): " + JSON.stringify(e));
    }
  }

  const userRelation = _.find(
    allUserRelations,
    (relation) =>
      _.some(relation.users, (u) => u === currentUserEmail) &&
      _.some(relation.users, (u) => u === user.email)
  );

  const isWaitingActionFromOther = userRelation
    ? userRelation.emailFrom === currentUserEmail && !userRelation.accepted && userRelation.state?.state == RelationshipState.Waiting
    : false;
  const isFriendWithCurrentUser = userRelation ? userRelation.accepted : false;

  let group = null;
  if (userRelation) group = userRelation.group;
  const formatter = buildFormatter({
    prefixAgo: "il y a",
    prefixFromNow: "d'ici",
    seconds: "moins d'une minute",
    minute: "environ une minute",
    minutes: "environ %d minutes",
    hour: "environ une heure",
    hours: "environ %d heures",
    day: "environ un jour",
    days: "environ %d jours",
    month: "environ un mois",
    months: "environ %d mois",
    year: "un an",
    years: "%d ans",
  });

  const showTimeAgoLabel =
    !isHelpedProfil &&
    user.lastActivity &&
    type !== userCardType.Search &&
    type !== userCardType.FriendRequest;

  if (!user.firstName || !user.lastName) {
    console.log("User not valid", user);
    return <></>;
  }

  let userNameLabel = user.firstName + " " + user.lastName[0];

  /** makeVideoCall : call a friend when user clicks on image or name of a friend  */
  function makeVideoCall() {
    if (type == userCardType.Select) return;
    if (type == userCardType.SupervisorUserList) return;

    setSearchTerm("");
    if (isFriendWithCurrentUser) {
      callUser();
    }
  }

  if (type === userCardType.Search || type === userCardType.FriendRequest)
    userNameLabel = user.firstName + " " + user.lastName;

  const lastActivity = convertToTimestamp(user.lastActivity);
  
  return (
    <>
      <IonCard
        className={cardClasses}
        style={{ marginTop: "30px" }}
        onClick={() => setChecked((current) => !current)}
      >
        <IonCardHeader
          onClick={makeVideoCall}
          style={{
            height: "350px",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundImage: "url(" + src + ")"
          }}
        >
          {/* Disable 14/05/2021 https://trello.com/c/78Ueul1z/279-lors-de-lajout-dune-personne-retirer-l%C3%A9tape-du-groupe-famille-amis-et-donc-le-visuel-dans-la-page-des-contacts
            
            group && <div className="card-header-group">
            <IonIcon  icon={getIconFromGroup(group)}  className="icon-size-mid" />
            </div>*/}

          <div className={"card-header-text"}>
            {userNameLabel}

            {type === userCardType.Select && (
              <IonCheckbox
                style={{ float: "right" }}
                slot="end"
                value={user.id}
                onClick={(e) => e.stopPropagation()}
                onIonChange={(e) => changeListUsersHandler(e, user)}
                checked={checked}
              />
            )}
            {showTimeAgoLabel && (
              <div className="time-ago-label">
                <small>
                  <TimeAgo
                    date={lastActivity ? lastActivity.toDate() : undefined}
                    formatter={formatter}
                  />
                </small>
              </div>
            )}

          </div>
        </IonCardHeader>
        {type === userCardType.Annuaire && (
          <IonCardContent>
            {/*<IonButton className={"btn-call"} type="button" onClick={callUser} expand="block"><IonIcon icon={videocam} className="icon-size" /></IonButton>*/}
          </IonCardContent>
        )}

        {type === userCardType.Search && (
          <IonCardContent>
            {isFriendWithCurrentUser && (
              <IonButton
                className="btn-remove-friend"
                type="button"
                color="danger"
                onClick={() => requestRemoveFriend(false)}
                expand="block"
              >
                <IonIcon icon={personRemoveOutline} className="icon-size" />{" "}
                Retirer
              </IonButton>
            )}
            {isWaitingActionFromOther && (
              <IonButton
                className="btn-remove-friend"
                type="button"
                color="light"
                onClick={() => requestRemoveFriend(true)}
                expand="block"
              >
                <IonIcon icon={hourglassOutline} className="icon-size" />
                En attente
              </IonButton>
            )}
            {!isWaitingActionFromOther && !isFriendWithCurrentUser && (
              <IonButton
                className="btn-call"
                color="success"
                type="button"
                onClick={() => requestAddFriend(relationshipGroupEnum.Family)}
                expand="block"
              >
                <IonIcon icon={personAddOutline} className="icon-size" />
                Ajouter
              </IonButton>
            )}
          </IonCardContent>
        )}

        {type === userCardType.FriendRequest && (
          <IonCardContent>
            <IonRow>
              <IonCol size="6">
                <Button
                  className="btn-call"
                  color="danger"
                  onClick={() => requestRemoveFriend(false,true)}
                  text="Non"
                  svg={IconSvgEnum.cancel_white}
                />
              </IonCol>
              <IonCol size="6">
                <Button
                  className="btn-call"
                  color="success"
                  onClick={requestAcceptFriend}
                  text="Oui"
                  svg={IconSvgEnum.ok_white}
                />
              </IonCol>
            </IonRow>
          </IonCardContent>
        )}

        {type === userCardType.SupervisorUserList && (
          <IonCardContent>{buttons ? buttons : <></>}</IonCardContent>
        )}
      </IonCard>
    </>
  );
};

// export default UserItem;
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    startCall,
    addFriend,
    removeFriend,
    acceptFriend,
    setToastNotification,
    updateLastActivity,
    setSearchTerm,
    loadAndSetUserSupervised,
    setShowOffline
  },
  mapStateToProps: (state) => {
    let allUserRelations: UserRelationship[] = [];

    if (state.supervisor.supervisedAccount)
      allUserRelations = state.supervisor.supervisedAccount.allUserRelations;
    else if (state.user.allUserRelations)
      allUserRelations = state.user.allUserRelations;

    return {
      currentUserEmail: state.supervisor.supervisedAccount
        ? state.supervisor.supervisedAccount.user.email
        : state.user.username,
      allUserRelations: allUserRelations,
      supervisedAccount: state.supervisor.supervisedAccount
        ? state.supervisor.supervisedAccount
        : null,
      supervisorRequests: state.supervisor.requests || [],
      isHelpedProfil: selectors.getIsHelpedProfil(state),
      currentUser: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore,
      appOnline: state.global.appOnline
    };
  },
  component: withRouter(UserItem),
});
