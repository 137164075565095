import React, { Fragment, useEffect, useState } from "react";
import {
  IonContent,
  IonImg,
  IonPage,
  IonToast,
  isPlatform,
} from "@ionic/react";
import Header from "../components/Header";
import { connect } from "../data/connect";
import * as selectors from "../data/selectors";
import Button from "../components/Button";
import {
  Camera,
  CameraDirection,
  CameraResultType,
  CameraSource,
} from "@capacitor/camera";
import { cameraOutline, imagesOutline, pencil, arrowBackCircleOutline } from "ionicons/icons";
import { IconSvgEnum } from "../components/IconSvg";
import {
  isVideoFile,
  toBase64,
  validFormatsForSharedFolderImage,
  validFormatsForSharedFolderVideo,
} from "../util/commons";
import {
  setShowOffline,
  setToastNotification,
  setUserIsActive,
} from "../data/global/global.actions";
import _ from "lodash";
import classes from "./SendMedia.module.scss";
import { RouteComponentProps } from "react-router";
import BottomButtons from "../components/BottomButtons";
import SelectMultipleUsers from "../components/SelectMultipleUsers";
import { UserWithRelationship } from "../models/UserWithRelationship";
import { BackgroundTask } from "@robingenz/capacitor-background-task";
import Loading from "../components/Loading";
import { ToastNotification } from "../models/ToastNotification";
import { _fileSizeLimitPhoto, _fileSizeLimitVideo } from "../appConfig";
import EditImage from "../components/Modals/EditImage";
import { menuController } from "@ionic/core";
import { createMediaShare } from "../data/mediaShareRequest/mediaShareRequest.actions";
import { getGlobalConfig } from "../api/globalConfig";
interface OwnProps { }

interface StateProps {
  users: UserWithRelationship[];
  currentUserEmail: string;
  loading: boolean;
  lastName: string;
  firstName: string;
  appOnline: boolean;
  isHelpedProfil:boolean,
}

interface DispatchProps {
  setUserIsActive: typeof setUserIsActive;
  createMediaShare: typeof createMediaShare;
  setToastNotification: typeof setToastNotification;
  setShowOffline: typeof setShowOffline;
}

interface SendMediaProps
  extends RouteComponentProps,
  OwnProps,
  StateProps,
  DispatchProps { }

const SendMedia: React.FC<SendMediaProps> = ({
  history,
  users,
  currentUserEmail,
  loading,
  firstName,
  lastName,
  appOnline,
  isHelpedProfil,
  setUserIsActive,
  createMediaShare,
  setToastNotification,
  setShowOffline
}) => {
  const [fileFormat, setFileFormat] = useState("");
  const [fileData, setFileData] = useState("");
  const [fileSrc, setFileSrc] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [isVideo, setIsVideo] = useState(false);
  const [showSelectContacts, setShowSelectContacts] = useState(false);
  const [selecetdContacts, setSelecetdContacts] = useState<string[]>([]);
  const [title, setTitle] = useState("Envoyer une photo ou une vidéo");
  const [uploadError, setUploadError] = useState("");
  const [showEditFile, setShowEditFile] = useState(false);

  useEffect(() => {
    if (fileSrc) {
      setTitle("Validation");
    }
    if (showSelectContacts) {
      setTitle("Sélection");
    }
    if (loading) {
      setTitle("Envoi");
    }
  }, [fileSrc, showSelectContacts, loading]);

  const inputFile = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (fileData && fileFormat) {
      setFileSrc(`data:image/${fileFormat};base64,${fileData}`);
      setIsVideo(isVideoFile(fileFormat));
    }
  }, [fileFormat, fileData]);

  const updateSelectedUsers = (selectedUsers: string[]) => {
    setSelecetdContacts(selectedUsers);
  };
  /*
   * take picture : this function open the camera or the gallery and take/choose picture
   */
  async function takePicture(type: CameraSource) {
    setUserIsActive(true);
    if (type === CameraSource.Photos) {
      if (inputFile.current) inputFile.current.click();
    } else {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          direction: CameraDirection.Rear,
          allowEditing: false,
          source: CameraSource.Camera,
          resultType: CameraResultType.Base64,
          promptLabelHeader: "Sélectionner une photo",
          promptLabelCancel: "Annuler",
          promptLabelPhoto: "Depuis la galerie",
          promptLabelPicture: "Depuis l'appareil photo",
        });
        if (image && image.base64String) {
          const size =
            (4 *
              Math.ceil(image.base64String.length / 3) *
              0.5624896334383812) /
            1000;
          if (! await checkIsValidFile(image.format, size)) {
            return;
          }
          setFileFormat(image.format);
          setFileData(image.base64String);
          setFileSize(
            (4 *
              Math.ceil(image.base64String.length / 3) *
              0.5624896334383812) /
            1000
          );
        }
      } catch (error) {
        return null;
      }
    }
  }

  async function onSelectFile(e: any) {
    setUserIsActive(true);
    if (e.target.files[0] && e.target.files[0].type) {
      const file = e.target.files[0];
      const base64 = (await toBase64(file)) as string;
      if (base64) {
        const format = _.last(_.split(file.type, "/"));
        const base64String = base64.split(",")[1];
        const size = file.size / 1000;
        if (! await checkIsValidFile(format as string, size)) {
          return;
        }
        setFileSize(size);
        setFileFormat(format as string);
        setFileData(base64String);
      }
    }
  }

  const checkIsValidFile = async (format: string, size: number) => {
    const config = await getGlobalConfig(isHelpedProfil,currentUserEmail);
    const validFormatsForSharedFolderImageInit = config.album?.pictureExtensionAccepted ? config.album.pictureExtensionAccepted.split(",") : validFormatsForSharedFolderImage;
    const validFormatsForSharedFolderVideoInit = config.album?.videoExtensionAccepted ? config.album.videoExtensionAccepted.split(",") : validFormatsForSharedFolderVideo;
    const fileSizeLimitVideo = config.album?.maxSizeVideo ? config.album?.maxSizeVideo : _fileSizeLimitVideo;
    const fileSizeLimitPhoto = config.album?.maxSizePicture ? config.album?.maxSizePicture : _fileSizeLimitPhoto;
    let isValidFile = false;
    let isValidFormat = false;
    let isValidSize = false;
    const isVideo = isVideoFile(format);
    if (isVideo) {
      isValidFormat = _.some(
        validFormatsForSharedFolderVideoInit,
        (f) => f === format
      );
      isValidSize = size < +fileSizeLimitVideo;
      isValidFile = isValidFormat && size < +fileSizeLimitVideo;
    } else {
      isValidFormat = _.some(
        validFormatsForSharedFolderImageInit,
        (f) => f === format
      );
      isValidSize = size < +fileSizeLimitPhoto;
      isValidFile = isValidFormat && size < +fileSizeLimitPhoto;
    }
    if (!isValidFormat) {
      setUploadError("Le format du fichier n'est pas valide");
    }
    if (!isValidSize) {
      setUploadError(
        isVideo
          ? "Le poids de votre vidéo est trop important pour être envoyée"
          : "Le poids de votre photo est trop important pour être envoyée"
      );
    }
    return isValidFile;
  };

  const sendMedia = async () => {
    if (selecetdContacts.length == 0) {
      setToastNotification({
        message: "Vous devez sélectionner au moins un contact",
        color: "danger",
      } as ToastNotification);
      return;
    }
    const senderName = firstName + " " + lastName;
    if (isPlatform("mobile")) {
      let taskId: any = BackgroundTask.beforeExit(async () => {
        createMediaShare(
          currentUserEmail,
          senderName,
          selecetdContacts,
          fileData,
          fileFormat,
          fileSize,
          history
        );
        BackgroundTask.finish({
          taskId,
        });
      });
    } else {
      createMediaShare(
        currentUserEmail,
        senderName,
        selecetdContacts,
        fileData,
        fileFormat,
        fileSize,
        history
      );
    }
  };
  const onValidateHandler = () => {
    if (!showSelectContacts) {
      setShowSelectContacts(true);
    } else {
      if (!appOnline) {
        setShowOffline(true)
        return;
      }
      sendMedia();
    }
  };
  const onCancelHandler = () => {
    if (!fileSrc) {
      setUserIsActive(false);
      history.push("/tabs/media/shared");
      menuController.open()
    } else if (fileSrc && !showSelectContacts) {
      setFileSrc("");
      setFileData("");
      setFileFormat("");
      setIsVideo(false);
    } else if (showSelectContacts) {
      setShowSelectContacts(false);
    }
  };
  return (
    <IonPage>
      <Header title={title} />
      <IonContent>
        {loading && <Loading enable={true} text={"Envoi en cours ..."} />}
        {!showSelectContacts && (
          <div className={classes.mediaContainer}>
            {fileSrc && !isVideo && (
              <Fragment>
                <IonImg src={fileSrc} className={classes.image} />
                <Button
                  color="primary"
                  onClick={() => setShowEditFile(true)}
                  text="Modifier"
                  icon={pencil}
                />
              </Fragment>
            )}
            {fileSrc && isVideo && (
              <video controls className={classes.video}>
                <source src={fileSrc} type={`video/${fileFormat}`} />
              </video>
            )}
            {!fileSrc && (
              <Fragment>
                <input
                  ref={inputFile}
                  style={{ display: "none" }}
                  type="file"
                  id="file"
                  onChange={onSelectFile}
                />
                <Button
                  color="primary"
                  onClick={() => takePicture(CameraSource.Camera)}
                  text="Depuis l'appareil photo"
                  icon={cameraOutline}
                />
                <Button
                  color="primary"
                  onClick={() => takePicture(CameraSource.Photos)}
                  text="Depuis la galerie"
                  icon={imagesOutline}
                />
              </Fragment>
            )}
          </div>
        )}
        {showSelectContacts && !loading && (
          <SelectMultipleUsers
            users={users}
            currentUserEmail={currentUserEmail ? currentUserEmail : ""}
            selectedUsers={selecetdContacts}
            checkedAll={false}
            updateSelectedUsers={updateSelectedUsers}
          />
        )}

        <IonToast
          isOpen={!!uploadError}
          message={uploadError}
          duration={6000}
          color={"danger"}
          onDidDismiss={() => setUploadError("")}
        />
      </IonContent>
      {!loading && (
        <div slot="fixed" style={{ width: "100%", bottom: 0, marginTop: "20px" }}>
          {fileSrc && (
            <BottomButtons
              cancelBtnColor="light"
              validateBtnColor="success"
              cancelBtnLabel="RETOUR"
              validateBtnLabel={showSelectContacts ? "ENVOYER" : "SUIVANT"}
              cancelBtnIcon={IconSvgEnum.cancel}
              validateBtnIcon={
                showSelectContacts ? IconSvgEnum.send : IconSvgEnum.ok_white
              }
              onCancel={onCancelHandler}
              onValidate={onValidateHandler}
            />
          )}
          {!fileSrc && (
            <Button
              color="light"
              routerLink="/tabs/media/shared"
              text="Retour"
              icon={arrowBackCircleOutline}

            />
          )}
        </div>
      )}
      {showEditFile && (
        <EditImage
          onCancel={() => setShowEditFile(false)}
          src={fileSrc}
          setImageFormat={setFileFormat}
          setImageData={setFileData}
        />
      )}
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    users: selectors.getUsersFilteredByNumberOfCalls(state),
    currentUserEmail: state.user.username || "",
    loading: state.mediaShareRequest.loading,
    lastName: state.user.userFirestore ? state.user.userFirestore.lastName : "",
    firstName: state.user.userFirestore
      ? state.user.userFirestore.firstName
      : "",
    appOnline: state.global.appOnline,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
  }),
  mapDispatchToProps: {
    setUserIsActive,
    createMediaShare,
    setToastNotification,
    setShowOffline
  },
  component: React.memo(SendMedia),
});
