import { ActionType } from "../../util/types";
import VisioCall, {
  ParticipantState,
  UserActionFromPush,
} from "../../models/VisioCall";
import { User } from "../../models/User";
import {
  acceptOrRefuseCallFirestore,
  startVisioCall
} from "../../api/visioCall";

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-visioCall-loading",
    isLoading,
  } as const);

export const setVisioCallData = (visio?: VisioCall) =>
  ({
    type: "set-visioCall-setVisioCallData",
    visio,
  } as const);

export const setUserActionFromPush = (response?: UserActionFromPush) =>
  ({
    type: "set-visioCall-setUserActionFromPush",
    response,
  } as const);

/**
 * Accept or refuse an incoming call
 * @param callId
 * @param curentUserMail
 * @param accept
 * @param forceAllParticipantsQuit if true, set completed state for all participants
 */
export const acceptOrRefuseCall =
  (
    callId: string,
    curentUserMail: string,
    state: ParticipantState,
    forceAllParticipantsQuit: boolean = false
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    const result = await acceptOrRefuseCallFirestore(
      callId,
      curentUserMail,
      state,
      forceAllParticipantsQuit
    );
  };

export const startCall =
  (
    toEmails: string[],
    currentUser: User,
    groupsIdToCall: string[] = [],
    isEmergencyCall: boolean = false
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoading(true));
    const data = await startVisioCall(
      toEmails,
      currentUser,
      groupsIdToCall,
      isEmergencyCall
    );
    dispatch(setLoading(false));
    return data;
  };

export type VisioCallActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setVisioCallData>
  | ActionType<typeof setUserActionFromPush>;
