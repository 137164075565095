import { IonItem, IonModal, IonRadio, IonRadioGroup } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "../../data/connect";
import BottomButtons from "../BottomButtons";
import * as selectors from "../../data/selectors";
import { User, UserConfiguration } from "../../models/User";
import { setShowOffline } from "../../data/global/global.actions";
import { updateUser } from "../../data/user/user.actions";
import _ from "lodash";
import Header from "../Header";
import { UserSupervised } from "../../models/UserSupervised";
interface OwnProps {
  showSubtitlingSettings: (show: boolean) => void;
}

interface StateProps {
  config: UserConfiguration;
  supervisedAccount: UserSupervised | null;
  currentUser?: User;
  appOnline: boolean;
}

interface DispatchProps {
  updateUser: typeof updateUser;
  setShowOffline: typeof setShowOffline;
}
interface SubtitlingSettingsProps extends OwnProps, StateProps, DispatchProps {}

const SubtitlingSettings: React.FC<SubtitlingSettingsProps> = ({
  config,
  supervisedAccount,
  currentUser,
  appOnline,
  showSubtitlingSettings,
  updateUser,
  setShowOffline
}) => {
  const [subtitlingInput, setSubtitlingInput] = useState(config.subtitling);
  useEffect(() => {
    setSubtitlingInput(config.subtitling)
  }, [config.subtitling])
  const cancelHandler = () => {
    showSubtitlingSettings(false);
  };

  const validateHandler = async () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    if(currentUser){
      const userConfig = {... config} as UserConfiguration;
      userConfig.subtitling = subtitlingInput;
      currentUser.config = _.clone(userConfig);
      updateUser(currentUser, supervisedAccount, "Modifications enregistrées");
    }
    showSubtitlingSettings(false);
  };

  return (
    config && (
      <IonModal
        isOpen={true}
        onDidDismiss={() => showSubtitlingSettings(false)}
      >
        <Header action={cancelHandler} title="Sous-titrage des icônes" showHelp/>
        <IonRadioGroup
          style={{ flexGrow: 1, padding: "20px" }}
          value={subtitlingInput}
          onIonChange={(e) => {
            setSubtitlingInput(e.detail.value);
          }}
        >
          <IonItem>
            <p>Visible</p>
            <IonRadio slot="start" value={true} />
          </IonItem>
          <IonItem>
            <p>Absent</p>
            <IonRadio slot="start" value={false} />
          </IonItem>
        </IonRadioGroup>
        <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
          <BottomButtons
            onCancel={cancelHandler}
            onValidate={validateHandler}
          />
        </div>
      </IonModal>
    )
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    updateUser,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    config: selectors.getUserConfig(state),
    supervisedAccount: state.supervisor.supervisedAccount || null,
    currentUser: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore,
    appOnline: state.global.appOnline
  }),
  component: React.memo(SubtitlingSettings),
});
