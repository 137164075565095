import { Attachment, AudioInfos, Mail,MailType } from "../models/Mail";
import { createGuid } from "../util/commons";
import { getUser } from "./user";
import firebase from "../FirebaseConfig";
import { FileData } from "../models/FileData";
import { _UrlMLV } from "../appConfig";
/**
 * Send && save new message
 * @param sender
 * @param subject
 * @param message
 * @returns
 */
export const sendMailRequest = async (
  sender: string,
  subject: string,
  message: string,
  type: string,
  receiver: string,
  attachments?: Attachment[],
  audioObj?:AudioInfos | [],
) => {
  const creatorUser = await getUser(sender);
  const creatorName = creatorUser
    ? creatorUser.firstName + " " + creatorUser.lastName
    : sender;
  const id = createGuid(5);
  const mail = {
    id: id,
    senderEmail: sender,
    senderName: creatorName,
    subject: subject,
    message: message,
    type: type,
    receiver: receiver,
    attachments: attachments ? attachments : [],
    audioInfos:audioObj ? audioObj : [],
    url: type != MailType.Help ? _UrlMLV: "",
    dateCreate: new Date(),
  } as Mail;
  const db = firebase.firestore();
  await db.collection("mail").doc(id).set(mail);
  return mail;
};

/**
 * Upload mail file
 */
 export const uploadMailFileRequest = async (
  file: FileData
) => {
  const userAuth = firebase.auth().currentUser;
  if (userAuth) {
    try {
      const fileId = createGuid(5);
      const storage = firebase.storage();
      const filename = userAuth.uid + "_" + fileId + "." + file.extension;
      const filepath =
        "private/mails/" + filename;
      const docref = storage.ref(filepath);
      return await docref.put(file.file).then(async (snapshot) =>{
        const path = await snapshot.ref.getDownloadURL()
        return {path, filename: file.file.name}
      }
      )
    } catch (e) {
      alert("Erreur storage: " + JSON.stringify(e));
    }
  }
  return false;
};