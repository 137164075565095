import { toast } from "react-toastify";
import firebase from "../FirebaseConfig";
import { AuthorizedUser } from "../models/AuthorizedUser";

/**
 * Get an authorized user by an id (email or tel)
 * @param id user email or tel
 * @returns
 */
export const getAuthorizedUserById = async (id: string) => {
  const db = firebase.firestore();
  var authorizedUser = await db.collection("authorizedUser").doc(id).get().then((res) =>{
    return res.exists
    ? (res.data() as AuthorizedUser)
    : false;
  }).catch((e) => {
    if(e.code === "unavailable"){
      return undefined;
    }
    console.log("Erreur getAuthorizedUserById: " + JSON.stringify(e))
  });
  return authorizedUser;
};
