import React, { useState, useEffect } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonRow, IonCol, IonButton, IonList, IonItem, IonLabel, IonInput, IonText, IonIcon } from '@ionic/react';
import { Link, Redirect } from 'react-router-dom';
import './Login.scss';
import { setIsLoggedIn, setUsername, loadUserData } from '../data/user/user.actions';
import { connect } from '../data/connect';
import { RouteComponentProps } from 'react-router';
import firebase from '../FirebaseConfig';
import Loading from '../components/Loading';
import SmsCodeValidationForm from '../components/SmsCodeValidationForm';
import { checkIsPhoneFormat } from '../util/checkIsPhoneFormat';
import { MessageType, showToast } from '../util/toast.message';
import { validateEmail } from '../util/email.validation';
import { menuController } from '@ionic/core';
import { ToastNotification } from "../models/ToastNotification";
import { setShowOffline, setToastNotification } from "../data/global/global.actions";
import { audioType } from "../components/AudioPlayer";
import { checkPhoneExist, getUser } from '../api/user';
import { removeDiacritics } from '../util/functions';
interface OwnProps extends RouteComponentProps { }

interface StateProps {
  isAuthenticated: boolean;
  appOnline: boolean;
}


interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  loadUserData: typeof loadUserData;
  setToastNotification: typeof setToastNotification;
  setShowOffline: typeof setShowOffline;
}

interface LoginProps extends OwnProps, StateProps, DispatchProps { }

const Login: React.FC<LoginProps> = ({ setIsLoggedIn, appOnline, isAuthenticated, setUsername: setUsernameAction, setShowOffline, loadUserData, setToastNotification }) => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginFailed, setLoginFailed] = useState("");
  const [loading, setLoading] = useState(false);
  const [needRedirection, setNeedRedirection] = useState(false);
  const [recaptchaWrapperRef, setRecaptchaWrapperRef] = useState(React.createRef<HTMLDivElement>());

  const [firebasePhoneCodeValidation, setFirebasePhoneCodeValidation] = useState<firebase.auth.ConfirmationResult>();


  useEffect(() => {
    if (isAuthenticated) {
      setNeedRedirection(true);
    }
  }, [isAuthenticated]);



  /*
  * When user validate received code
  */
  /*const validateCode = async (e: React.FormEvent) => {
    e.preventDefault();
    if(!codeReceived)setCodeReceivedFailed("Veuillez renseigner un code valide");
    else if(firebasePhoneCodeValidation && codeReceived)
    {
      firebasePhoneCodeValidation.confirm(codeReceived).then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log("firebasePhoneCodeValidation success !!!", user);

         setIsLoggedIn(true);
        setUsernameAction(username);
        setLoading(false);
        loadUserData();

      }).catch((error) => {
        setCodeReceivedFailed("Le code renseigner n'est pas valide");
      });
    }

  }*/

  /*
* When user validate received code
*/
  const onCodeValidated = async (user: firebase.User) => {

    setIsLoggedIn(true);
    setUsernameAction(username);
    setLoading(false);
    loadUserData();
  }

  /**
   * User cancel code form validation
   */
  const onCancelCodeVerification = () => {
    setFirebasePhoneCodeValidation(undefined);
    setLoading(false);
  }


  /*
  * Login : this function performs the login action and user access to the application
  */
  const login = async (e: React.FormEvent) => {
    let haveErrors = false;
    e.preventDefault();
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    setLoginFailed("");
    setFormSubmitted(true);
    if (!username) {
      setUsernameError(true);
      haveErrors = true;
    }
    if (!checkIsPhoneFormat(username) && !password) {
      setPasswordError(true);
      haveErrors = true;
    }


    if (!haveErrors) {
      setLoading(true);

      // remove true || for enable phone connexion
      if (!checkIsPhoneFormat(username)) {

        firebase.auth().signInWithEmailAndPassword(username, password)
          .then(async (response) => {
            if (response && response.user) {
              setIsLoggedIn(true);
              setUsernameAction(username);
              loadUserData();
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            if (error.code === "auth/too-many-requests") {
              setToastNotification({
                message: "Votre compte a été bloqué pour raison de sécurité. Veuillez nous contacter à l'adresse bonjour@monlienvisio.fr",
                color: "danger",
                duration: 7000,
                playAudio: audioType.NewMessage,
              } as ToastNotification);

            } else if (error.code === "auth/user-disabled") {
              setToastNotification({
                message: "Votre compte a été désactivé. Veuillez nous contacter à l'adresse bonjour@monlienvisio.fr",
                color: "danger",
                duration: 7000,
                playAudio: audioType.NewMessage,
              } as ToastNotification);

            }
            else {
              showToast("Mail ou mot de passe invalide", 3000, MessageType.error);
            }

          });
      }
      else {
        // remove first '0' and add french indicatif '+33'
        const telephoneFormatted = "+33" + (username.substring(1));

        checkPhoneExist(telephoneFormatted).then((exist) => {
          if (exist) {
            firebase.auth().languageCode = 'fr';
            //firebase.auth().settings.appVerificationDisabledForTesting = true;

            const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('submit-login', {
              'size': 'invisible',
              'callback': (response: any) => {
              }
            });
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            firebase.auth().signInWithPhoneNumber(telephoneFormatted, recaptchaVerifier)
              .then(async (response) => {
                // setting PhoneCodeValidation cause show validation code form component
                setFirebasePhoneCodeValidation(response);
                setLoading(false);

              })
              .catch((error) => {
                console.log("signInWithPhoneNumber error", error);
                setLoading(false);
                //clear the recaptcha to show error message
                if (recaptchaVerifier && recaptchaWrapperRef && recaptchaWrapperRef.current) {
                  recaptchaVerifier.clear()
                  recaptchaWrapperRef.current.innerHTML = `<div id="submit-login"></div>`
                }
                if (error.code === "auth/invalid-phone-number") {
                  showToast("Format du numéro de téléphone incorrect", 3000, MessageType.error)
                }
                if (error.code === "auth/user-disabled") {
                  showToast("Votre compte a été désactivé. Veuillez nous contacter à l'adresse bonjour@monlienvisio.fr", 3000, MessageType.error)
                }
                if (error.code === "auth/too-many-requests") {
                  setToastNotification({
                    message: "Votre compte a été bloqué après plusieurs tentatives de connexion",
                    color: "danger",
                    duration: 7000,
                    playAudio: audioType.NewMessage,
                  } as ToastNotification);
                }

              });
          }
          else {
            // phone number doesn't exist
            setLoading(false);
            setLoginFailed("Votre téléphone ne correspond à aucun compte.");
          }

        })
      }
    }
  };


  const isPhoneFormat = username ? checkIsPhoneFormat(username) : false;
  /**
   * sendResetEmailFirebase : sends reset email via firebase
   * @param   {string} email  user email
   * @return  {NotVoid}
   */
  function sendResetEmailFirebase(email: string) {
    var auth = firebase.auth();
    firebase.auth().languageCode = "fr";
    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        showToast(
          "Un email a été envoyé à votre adresse",
          3000,
          MessageType.success
        );
      })
      .catch(function (err) {
        showToast(
          "Aucun utilisateur ne correspond à cet e-mail",
          3000,
          MessageType.error
        );
      });
  }

  const sendPasswordEmail = (username: string) => {
    if (validateEmail(username)) {
      sendResetEmailFirebase(username);
    } else {
      showToast("Le Format de l'adresse email est invalide", 3000, MessageType.error)
    }
  };

  return (
    <IonPage id="login-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Connexion</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        {/** logo image */}
        <div className="login-logo">
          <img src="assets/img/logo.png" alt="Ionic logo" />
        </div>

        {needRedirection && menuController.open() && <Redirect to="/tabs/contacts" />}



        {!firebasePhoneCodeValidation && <form noValidate onSubmit={login}>
          <IonList>

            {/** if login fails */}
            {loginFailed && <IonText color="danger">
              <p className="ion-padding-start" style={{ textAlign: "center" }}>
                {loginFailed}
              </p>
            </IonText>}


            {/** username */}
            <IonItem>
              <IonLabel position="stacked" color="primary"><IonText>Email ou numéro de téléphone</IonText></IonLabel>
              <IonInput name="username" type="text" value={username ? removeDiacritics(username.toLowerCase()) : username} spellCheck={false} autocapitalize="off" onIonChange={e => setUsername(e.detail.value!)}
                required>
              </IonInput>
            </IonItem>

            {formSubmitted && usernameError && <IonText color="danger">
              <p className="ion-padding-start">
                Un mail ou un téléphone est nécessaire
              </p>
            </IonText>}
            <br /><br />

            {!isPhoneFormat && <div>
              {/** password */}
              <IonItem>
                <IonLabel position="stacked" color="primary"><IonText>Mot de passe</IonText> </IonLabel>
                <IonInput name="password" type="password" value={password} onIonChange={e => setPassword(e.detail.value!)}>
                  {/*<IonIcon slot="end" name="eye"></IonIcon>*/}
                </IonInput>
              </IonItem>

              {formSubmitted && passwordError && <IonText color="danger">
                <p className="ion-padding-start">
                  Le mot de passe est nécessaire
                </p>
              </IonText>}
            </div>}

          </IonList>

          <IonRow>
            <IonCol size="12">
              <IonButton type="submit" expand="block" >
                <div ref={recaptchaWrapperRef} style={{ opacity: 0 }}><div id="submit-login"></div></div>
                <IonLabel>Se connecter</IonLabel>
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonButton routerLink="/signup" color="light" expand="block">Créer un compte</IonButton>
            </IonCol>
          </IonRow>
        </form>}


        {firebasePhoneCodeValidation && <SmsCodeValidationForm
          onValidCode={onCodeValidated}
          onCancel={onCancelCodeVerification}
          onLoading={(enable: boolean) => setLoading(enable)}
          firebasePhoneCodeValidation={firebasePhoneCodeValidation} />}

        <br /><br /><br /><br />
        {!isPhoneFormat && !firebasePhoneCodeValidation && <Link
          className={" lost-password"}
          to={{
            pathname: "/lostpassword",
            state: { userEmail: username }
          }}
        >
          Mot de passe oublié ?
        </Link>}
        <Loading enable={loading} text="Connexion en cours ..." />
      </IonContent>

    </IonPage >
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: !!firebase.auth().currentUser,
    appOnline: state.global.appOnline
  }),
  mapDispatchToProps: {
    setToastNotification,
    setIsLoggedIn,
    setUsername,
    loadUserData,
    setShowOffline
  },
  component: Login
})
