import React, { useState } from "react";
import { IonContent, IonPage, IonButton, IonIcon } from "@ionic/react";
import { videocam } from "ionicons/icons";
import { connect } from "../data/connect";
import { UserWithRelationship } from "../models/UserWithRelationship";
import { setToastNotification } from "../data/global/global.actions";
import * as selectors from "../data/selectors";
import _ from "lodash";
import "./CreateVisioCall.scss";
import { RouteComponentProps } from "react-router-dom";
import { ToastNotification } from "../models/ToastNotification";
import { startCall } from "../data/visioCall/visioCall.actions";
import { _MaxParticipantsVisioCall } from "../appConfig";
import { updateLastActivity } from "../data/user/user.actions";
import Header from "../components/Header";
import SelectMultipleUsers from "../components/SelectMultipleUsers";
import { ContactGroup } from "../models/ContactGroup";
import { User } from "../models/User";
import { getGlobalConfig } from "../api/globalConfig";
interface OwnProps {}

interface StateProps {
  users: UserWithRelationship[];
  currentUserEmail?: string;
  groups: ContactGroup[];
  currentUser?: User;
  isHelpedProfil: boolean;
}

interface DispatchProps {
  setToastNotification: typeof setToastNotification;
  startCall: typeof startCall;
  updateLastActivity: typeof updateLastActivity;
}

interface CreateVisioCallProps
  extends RouteComponentProps,
    OwnProps,
    StateProps,
    DispatchProps {}

const CreateVisioCall: React.FC<CreateVisioCallProps> = ({
  currentUserEmail,
  users,
  groups,
  currentUser,
  isHelpedProfil,
  startCall,
  setToastNotification,
  updateLastActivity,
  history,
}) => {
  const [formUsers, setFormUsers] = useState<string[]>([]);
  const [formGroups, setFormGroups] = useState<ContactGroup[]>([]);

  const updateSelectedUsers = (selectedUsers: string[]) => {
    setFormUsers(selectedUsers);
  };
  const updateSelectedGroups = (selectedGroups: ContactGroup[]) => {
    setFormGroups(selectedGroups);
  };

  async function call() {
    const config = await getGlobalConfig(isHelpedProfil,currentUserEmail); 
    const maxNumberContactPerVisio = config.contact?.maxNumberContactPerVisio;
    let usersToCall: string[] = [];
    let groupsIdToCall: string[] = [];
    formGroups.forEach((group) => {
      group.members.map((member) => usersToCall.push(member));
      groupsIdToCall.push(group.id)
    });
    usersToCall = [...usersToCall, ...formUsers];
    usersToCall = usersToCall.filter((c, index) => {
      return usersToCall.indexOf(c) === index && c !== currentUserEmail;
    });
    if (maxNumberContactPerVisio && +maxNumberContactPerVisio < usersToCall.length) {
      setToastNotification({
        message:
        "Le nombre maximum de contacts autorisés, y compris vous-même, est de " +
        maxNumberContactPerVisio +
        " pour un appel vidéo",
        color: "danger",
      } as ToastNotification);
      return;
    }

    if (usersToCall.length === 0) {
      setToastNotification({
        color: "danger",
        message:
          "Vous devez sélectionner au moins un contact pour lancer l'appel",
      } as ToastNotification);
    } else {
      try {
        if (currentUser) {
          await startCall(usersToCall, currentUser,groupsIdToCall);
          await updateLastActivity();
        }
        history.push("/tabs/conference/video");
      } catch (e) {
        alert("Exception userItems>callUser(): " + JSON.stringify(e));
      }
    }
  }

  return (
    <IonPage id="create-visio-call">
      <Header title={"Lancer un appel vidéo groupé"} />
      <IonContent fullscreen={true}>
        <div className="full-height-flex">
          <SelectMultipleUsers
            users={users}
            currentUserEmail={currentUserEmail ? currentUserEmail : ""}
            selectedUsers={formUsers}
            checkedAll={false}
            updateSelectedUsers={updateSelectedUsers}
            groups={groups}
            selectedGroups={formGroups}
            updateSelectedGroups={updateSelectedGroups}
          />
        </div>
      </IonContent>
      <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
        <IonButton style={{ width: "100%" }} color="" onClick={() => call()}>
          <IonIcon icon={videocam} className="icon-size" /> APPELER
        </IonButton>
      </div>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    startCall,
    setToastNotification,
    updateLastActivity,
  },
  mapStateToProps: (state) => ({
    users: selectors.getUsersFilteredByNumberOfCalls(state),
    currentUserEmail: state.user.username,
    groups: state.user.groups || [],
    currentUser: state.user.userFirestore,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
  }),
  component: React.memo(CreateVisioCall),
});
