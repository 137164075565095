import React, { useState,useEffect, useRef } from 'react';
import './Login.scss';
import { connect } from '../data/connect';
import { RouteComponentProps } from 'react-router';
import { App, AppState } from '@capacitor/app';

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {}

interface AppStateManagerProps extends RouteComponentProps, OwnProps, StateProps, DispatchProps { }

const AppStateManager: React.FC<AppStateManagerProps> = ({ history}) => {

  const isAppActive = useRef<boolean>(false);
  const _history = useRef<any>(history);

  useEffect(() => {
    // Vu sur skype avec Franck le 26/03/2021 on désactive pour le moment car trop de soucis (ex: après un enregistrement vidéo)
    /*App.addListener('appStateChange', (state: AppState) => {
      // Redirect to annuaire if app was foreground and user open it
      if(!isAppActive.current && state.isActive && history.location.pathname != '/login' && history.location.pathname != '/signup' && history.location.pathname  != '/tabs/mailbox/message/create')
      {
        history.push('/tabs/contacts');
      }
      isAppActive.current = state.isActive;
    });*/

  }, []);

  return (<div></div>);
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
  }),
  mapDispatchToProps: {
  },
  component: AppStateManager
})