export interface Mail {
  id: string;
  senderEmail: string;
  senderName: string;
  subject: string;
  message?: string;
  type: string;
  audioInfos?:AudioInfos;
  receiver?: string;
  dateCreate: Date;
  attachments: Attachment[];
  url?: string;
}

export interface Attachment {
  filename: string;
  path: string;
}
export interface AudioInfos {
  urlCall:string;
  phoneNumber: any;
  pin: any;
}
export enum MailType{
  Help = 'Aide',
  Contact = 'Contact',
  Installation = 'Installation',
  Invitation = 'Invitation',
  AddFriend = 'AddFriend',
  AcceptFriend= 'AcceptFriend',
  AddSupervision= 'AddSupervision',
  AcceptSupervision = 'AcceptSupervision'

}

