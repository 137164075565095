import firebase from "../FirebaseConfig";
import { User, UserConfiguration } from "../models/User";
import { UserSupervised } from "../models/UserSupervised";
import { Storage } from "@capacitor/storage";
import { setUserConfig } from "../data/user/user.actions";
import { random } from "../util/functions";

const HAS_LOGGED_IN = "hasLoggedIn";
const HAS_SEEN_TUTORIAL = "hasSeenTutorial";
const USERNAME = "username";

/**
 * Get user from firestore database
 * @param email
 */
export const getUser = async (email: string) => {
  const db = firebase.firestore();
  const user = await db.collection("users").doc(email).get();
  return user.data() as User;
};

export const updateUserRequest = async (
  user: User,
  supervisedAccount: UserSupervised | null = null
) => {
  try {
    if (!supervisedAccount) {
      const db = firebase.firestore();
      await db.collection("users").doc(user.email).update(user);
      return true;
    } else {
      const updateUserFunc = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("User-updateUser");
      return updateUserFunc({
        user: user,
      }).then((result) => result.data.success === true);
    }
  } catch (error) {
    console.error("Erreur updateUser: " + JSON.stringify(error));
  }

};

export const getUserData = async () => {
  const response = await Promise.all([
    Storage.get({ key: HAS_LOGGED_IN }),
    Storage.get({ key: HAS_SEEN_TUTORIAL }),
    Storage.get({ key: USERNAME }),
  ]);
  const isLoggedin = (await response[0].value) === "true";
  const hasSeenTutorial = (await response[1].value) === "true";
  const username = (await response[2].value) || undefined;

  const db = firebase.firestore();
  return await db
    .collection("users")
    .doc(username)
    .get()
    .then((user) => {
      return {
        isLoggedin,
        hasSeenTutorial,
        username,
        userFirestore: user.exists ? (user.data() as User) : undefined,
      };
    })
    .catch((err) => {
      console.error("Erreur firebase: " + JSON.stringify(err));
      return null;
    });
};

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Storage.set({ key: HAS_LOGGED_IN, value: JSON.stringify(isLoggedIn) });
};

export const getIsLoggedInData = async () => {
  return (await Storage.get({ key: HAS_LOGGED_IN })).value === "true";
};

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  await Storage.set({
    key: HAS_SEEN_TUTORIAL,
    value: JSON.stringify(hasSeenTutorial),
  });
};

export const setUsernameData = async (username?: string) => {
  if (!username) {
    await Storage.remove({ key: USERNAME });
  } else {
    await Storage.set({ key: USERNAME, value: username });
  }
};

/**
 * Upload user picture and return url
 * @param userName
 * @param fileBase64
 * @param fileExtension
 */
export const uploadUserPictureRequest = async (
  fileBase64: string,
  fileExtension: string,
  userId: string
) => {
  try {
    const storage = firebase.storage();
    const filepath =
      "public/user/" + userId + "_" + random(5) + "." + fileExtension;
    const docref = storage.ref(filepath);
    return await docref
      .putString(fileBase64, "base64")
      .then(async (snapshot) => {
        return await snapshot.ref.getDownloadURL();
      });
  } catch (e) {
    alert("Erreur storage: " + JSON.stringify(e));
  }
  return false;
};

/**
 * Check a phone number exist in firebase authentification
 * @param phone
 * @returns
 */
export const checkPhoneExist = async (phone: string) => {
  const phoneExistFunc = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("Auth-phoneExist");
  return phoneExistFunc({ phone: phone }).then(
    (result) => result.data.exist == true
  );
};
