import React, { useState, useEffect } from "react";

interface VideoPlayerProps {
  url: string;
  height?: string;
  width?: string;
  type: string;
  play: boolean;
  loop: boolean;
  muted: boolean;
  controls: boolean;
  classname?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  height,
  width,
  type,
  play,
  loop,
  muted,
  controls,
  classname,
}) => {
  const player = React.createRef<HTMLVideoElement>();
  const [isReady, setIsReady] = useState(false);

  function fetchVideoAndPlay() {
    fetch(url)
    .then(response => response.blob())
    .then(blob => {
      if(player.current){
        player.current.src = URL.createObjectURL(blob);
        return player.current.play();
      }
    })
    .then(_ => {
      // Video playback started
    })
    .catch(e => {
      // Video playback failed
    })
  }
  useEffect(() => {
    if (player.current) {
      player.current.oncanplay = () => {
        setIsReady(true);
      };
    }
  });

  useEffect(() => {
    if (player.current) {
      fetchVideoAndPlay()
    }
  }, [play]);

  /**
   * Check if an element is in viewport
   *
   * @param {number} [offset]
   * @returns {boolean}
   */
  /* function isInViewport(offset = 0) {
    if (!player.current) return false;

    const top = player.current.getBoundingClientRect().top;
    const right = player.current.getBoundingClientRect().right;

    return (top + offset) >= 0 && (top - offset) <= window.innerHeight &&
           (right) >= 0 && (right) <= window.innerWidth;
  }*/

  return (
    <video
      className={classname}
      ref={player}
      playsInline
      height={height}
      width={width}
      autoPlay={play}
      loop={loop}
      muted={muted}
      controls={controls}
      poster="assets/img/play-video.png"
    >
    </video>
  );
};

export default VideoPlayer;