import React from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonContent,
  IonText,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { connect } from "../../data/connect";
import "./ContactGroupList.scss";
import _ from "lodash";
import { ContactGroup } from "../../models/ContactGroup";
import ContactGroupItem, {
  groupCardType,
} from "../../components/contacts/ContactGroupItem";
import Header, { ButtonData } from "../../components/Header";
import { IconSvgEnum } from "../../components/IconSvg";
import { addOutline,arrowBackCircleOutline } from "ionicons/icons";
import Button from "../../components/Button";
interface OwnProps {}

interface StateProps {
  contactGroups: ContactGroup[];
}

interface DispatchProps {}

interface ContactGroupListProps
  extends RouteComponentProps,
    OwnProps,
    StateProps,
    DispatchProps {}

const ContactGroupList: React.FC<ContactGroupListProps> = ({
  contactGroups,
  history,
}) => {
  const leftButtons = [
    {
      title: "Groupe",
      iconSvg: IconSvgEnum.group,
      onClick: () => createNewGroup(),
      addPlusIcon: true,
    } as ButtonData,
  ];
  contactGroups = _.reverse(
    _.sortBy(contactGroups, (group) => {
      if (group.callsCounter)
        return group.callsCounter;
      else return 0;
    })
  ); 
  const createNewGroup = () => {
    history.push("/tabs/contacts/group/create");
  };
  return (
    <IonPage>
      <Header title="Groupes de contacts" leftButtons={leftButtons} />
      <IonContent>
        <IonGrid fixed>
          <IonRow>
            {contactGroups.length > 0 &&
              contactGroups.map((group) => (
                <IonCol
                  size="12"
                  size-lg="4"
                  size-md="6"
                  size-sm="12"
                  key={group.id}
                >
                  <ContactGroupItem
                    key={group.id}
                    group={group}
                    type={groupCardType.Details}
                  />
                </IonCol>
              ))}
            {contactGroups.length === 0 && (
              <IonCol size="12">
                <IonText color="primary" style={{ textAlign: "center" }}>
                  <h1>
                    Veuillez cliquer ci-dessous pour ajouter votre premier
                    groupe
                  </h1>
                </IonText>
                <IonButton
                  style={{ width: "100%", marginTop: "20px" }}
                  onClick={() => createNewGroup()}
                >
                  <IonIcon icon={addOutline} className="icon-size" />
                  Ajouter un groupe
                </IonButton>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonRow>
          <IonCol size="12">
            <Button
              routerLink="/settings/contacts"
              color="light"
              text="Retour"
              icon={arrowBackCircleOutline}
            />
          </IonCol>
        </IonRow> 
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {},
  mapStateToProps: (state) => ({
    contactGroups: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.contactGroups
      : state.user.groups || [],
  }),

  component: React.memo(ContactGroupList),
});
