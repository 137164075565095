import { SupervisorActions } from "./supervisor.actions";
import { SupervisorState } from "./supervisor.state";

export function supervisorReducer(
  state: SupervisorState,
  action: SupervisorActions
): SupervisorState {
  switch (action.type) {
    case "set-supervisor-loading":
      return { ...state, loading: action.isLoading };
    case "set-supervisor-requests":
      return { ...state, requests: action.requests };
    case "set-user-supervised":
      return { ...state, supervisedAccount: action.user };
  }
}
