import { User } from "../models/User";
import firebase from "../FirebaseConfig";
import { convertBlobToBase64 } from "./commons";

export function sortAlphanumericUsers(a: User, b: User) {
  const fullNameA = a.firstName + " " + a.lastName;
  const fullNameB = b.firstName + " " + b.lastName;
  if (fullNameA === fullNameB) {
    return 0;
  }
  return fullNameA < fullNameB ? -1 : 1;
}

export function sortAlphanumeric(a: string | null, b: string | null) {
  if (a === b) {
    return 0;
  }
  if (a !== null && b !== null) {
    return a < b ? -1 : 1;
  } else {
    return 0;
  }
}

export const convertToTimestamp = (timeObject: any) => {
  if (timeObject instanceof firebase.firestore.Timestamp) {
    return timeObject;
  } else if (Object.prototype.toString.call(timeObject) === "[object Object]") {
    const seconds = timeObject["_seconds"];
    const nanoseconds = timeObject["_nanoseconds"];
    if (seconds && nanoseconds) {
      const timestamp = new firebase.firestore.Timestamp(seconds, nanoseconds);
      return timestamp;
    }
  }
  return undefined;
};

export const random = (length = 8) => {
  return Math.random().toString(16).substr(2, length);
};

export const getBase64FromUrl = async (url: string) => {
  let response: Response | undefined;
  try {
    response = await fetch(url);
  } catch (e) {}
  if (response) {
    // convert to a Blob
    const blob = await response.blob();
    // convert to base64 data, which the Filesystem plugin requires
    const base64Data = (await convertBlobToBase64(blob)) as string;
    return base64Data;
  } else {
    return "";
  }
};

export const removeDiacritics = (str: string) => {
  var result = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return result;
};
