import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonCheckbox,
  IonRadioGroup,
  IonRadio,
  IonToggle,
  IonIcon,
} from "@ionic/react";
import {
  setIsLoggedIn,
  setUsername,
  loadUserData,
  uploadUserPicture,
} from "../data/user/user.actions";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import firebase from "../FirebaseConfig";
import Loading from "../components/Loading";
import { defaultPicture, toBase64 } from "../util/commons";
import { FontClassType, User, UserConfiguration } from "../models/User";
import _ from "lodash";
import "./Signup.scss";
import { MessageType, showToast } from "../util/toast.message";
import SmsCodeValidationForm from "../components/SmsCodeValidationForm";

import {
  arrowBackOutline,
  cameraOutline,
  closeOutline,
  eye,
  eyeOff,
  imagesOutline,
  pencil,
} from "ionicons/icons";
import {
  Camera,
  CameraDirection,
  CameraResultType,
  CameraSource,
} from "@capacitor/camera";
import EditImage from "../components/Modals/EditImage";
import { validateEmail } from "../util/email.validation";
import { getAuthorizedUserById } from "../api/authorizedUser";
import { checkPhoneExist } from "../api/user";
import { setShowOffline } from "../data/global/global.actions";
import { removeDiacritics } from "../util/functions";

interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  loadUserData: typeof loadUserData;
  uploadUserPicture: typeof uploadUserPicture;
  setShowOffline: typeof setShowOffline;
}
interface StateProps {
  appOnline: boolean;
}

interface SignProps extends OwnProps, StateProps, DispatchProps { }

const Signup: React.FC<SignProps> = ({
  setIsLoggedIn,
  history,
  appOnline,
  setUsername: setUsernameAction,
  loadUserData,
  uploadUserPicture,
  setShowOffline
}) => {
  const [signUpType, setSignUpType] = useState("");

  const [telephone, setTelephone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [cguChecked, setCguChecked] = useState(false);
  const [rgpdChecked, setRGPDChecked] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [fontClass, setFontClass] = useState(FontClassType.medium);
  const [buttonName, setButtonName] = useState("voir plus");
  const [textintro, setTextIntro] = useState(
    "Homelinks, responsable du traitement, doit traiter vos données pour créer et administrer votre compte dans le cadre de"
  );
  const [showIntro, setShowIntro] = useState(false);

  const [firebasePhoneCodeValidation, setFirebasePhoneCodeValidation] =
    useState<firebase.auth.ConfirmationResult>();

  const [passwordLabel, setPasswordLabel] = useState("Cacher le mot de passe");
  const [checked, setChecked] = useState(false);
  let typeField = "password";
  const [icon, setIcon] = useState(eyeOff);
  const [srcImage, setSrcImage] = useState("");
  const [formatImage, setFormatImage] = useState("");
  const [base64String, setBase64String] = useState("");
  const [editImage, setEditImage] = useState(false);
  let inputFile = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (base64String && formatImage) {
      setSrcImage(`data:image/${formatImage};base64,${base64String}`);
    } else {
      setSrcImage("");
    }
  }, [formatImage, base64String]);
  /*
   * When user validate received code
   */
  const onCodeValidated = async (user: firebase.User) => {
    setIsLoggedIn(true);
    setUsernameAction(telephone);

    const userName = telephone;
    const db = firebase.firestore();
    const newUser = {
      email: userName,
      telephone: userName,
      id: user.uid,
      firstName: _.startCase(firstName.toLowerCase()),
      lastName: _.startCase(lastName.toLowerCase()),
      picture: "https://www.gravatar.com/avatar?d=mm&s=400",
      pushTokenLastUpdate: "",
      pushToken: "",
      isHelpedProfil: false,
      status: true,
      config: {
        fontClass: FontClassType.medium,
        recoveryEmail: recoveryEmail ? recoveryEmail : "",
      } as UserConfiguration,
    } as User
    await db
      .collection("users")
      .doc(userName)
      .set(newUser);

    if (base64String && formatImage) {
      uploadUserPicture(newUser, base64String, formatImage);
    }
    loadUserData();
    setLoading(false);
    history.push("/tabs/contacts", { direction: "none" });
  };

  /**
   * User cancel code form validation
   */
  const onCancelCodeVerification = () => {
    setFirebasePhoneCodeValidation(undefined);
    setLoading(false);
  };
  /*
   *signup
   */

  const signup = async (e: React.FormEvent) => {
    e.preventDefault();
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    setFormSubmitted(true);
    const checkEqualPassword = password === rePassword;
    let haveErrors = false;

    if (signUpType == "mail" && !username) {
      showToast("L'email n'est pas valide", 3000, MessageType.error);
      haveErrors = true;
    }
    if (signUpType == "mail" && !password) {
      showToast("Le mot de passe est nécessaire", 3000, MessageType.error);
      haveErrors = true;
    }
    if ((signUpType == "mail" && !rePassword) || !checkEqualPassword) {
      showToast(
        "Les mots de passe doivent être identiques",
        3000,
        MessageType.error
      );
      haveErrors = true;
    }

    if (
      signUpType == "tel" &&
      (!telephone || telephone.length != 10 || !telephone.startsWith("0"))
    ) {
      showToast(
        "Le numéro de téléphone n'est pas valide",
        3000,
        MessageType.error
      );
      haveErrors = true;
    }

    if (!firstName) {
      showToast("Le prénom est nécessaire", 3000, MessageType.error);
      haveErrors = true;
    }
    if (!lastName) {
      showToast("Le nom de famille est nécessaire", 3000, MessageType.error);
      haveErrors = true;
    }
    if (!cguChecked) {
      showToast(
        "Vous devez valider les CGU et la politique des cookies  pour créer un compte",
        3000,
        MessageType.error
      );
      haveErrors = true;
    }
    if (!rgpdChecked) {
      showToast(
        "Vous devez valider la Politique de protection des données personnelles  pour créer un compte",
        3000,
        MessageType.error
      );
      haveErrors = true;
    }
    if (recoveryEmail) {
      const isMail = validateEmail(recoveryEmail!); 
      if (!isMail) {
        showToast(
          "L'email de récupération n'est pas valide",
          3000,
          MessageType.error
        );
        haveErrors = true;
      }  
    }

    let isHelpedProfil = false;

    if(!haveErrors)
    {
      // check user have permission from admin to register
      const authorizedUser = await getAuthorizedUserById(signUpType == "mail" ? username : telephone);
      if(authorizedUser === undefined) {
        setShowOffline(true);
        return;
      };
      if(!authorizedUser || !authorizedUser.enabled){
        showToast(
          "Veuillez-nous contacter à l'adresse bonjour@monlienvisio.fr pour recevoir l'autorisation de créer un compte, merci.",
          8000,
          MessageType.error
        );
        haveErrors = true;
      }else
      {
        isHelpedProfil = authorizedUser.isHelpedProfil ? true : false;
      }
    }



    if (signUpType == "mail" && !haveErrors) {
      const usernameClean = _.trim(username.toLowerCase());
      setLoading(true);

      firebase
        .auth()
        .createUserWithEmailAndPassword(usernameClean, password)
        .then(async (user) => {
          const userFirebase = firebase.auth().currentUser;
          setIsLoggedIn(true);
          setUsernameAction(usernameClean);
          const db = firebase.firestore();
          const newUser = {
            email: usernameClean,
            id: userFirebase ? userFirebase.uid : "",
            firstName: _.startCase(firstName.toLowerCase()),
            lastName: _.startCase(lastName.toLowerCase()),
            isHelpedProfil: isHelpedProfil,
            picture: "https://www.gravatar.com/avatar?d=mm&s=400",
            pushTokenLastUpdate: "",
            pushToken: "",
            status: true,
            config: {
              fontClass: FontClassType.medium,
              recoveryEmail: recoveryEmail ? recoveryEmail : "",
            } as UserConfiguration,
          } as User
          await db
            .collection("users")
            .doc(usernameClean)
            .set(newUser);
          if (base64String && formatImage) {
            uploadUserPicture(newUser, base64String, formatImage);
          }
          setLoading(false);
          loadUserData();
          history.push("/tabs/contacts", { direction: "none" });
        })
        .catch((error) => {
          if (error.code == "auth/email-already-in-use") {
            showToast(
              "Veuillez saisir une autre adresse email car elle n'est pas unique",
              3000,
              MessageType.error
            );
          } else if (error.code == "auth/invalid-email") {
            showToast("L'email n'est pas valide", 3000, MessageType.error);
          } else if (error.code == "auth/weak-password") {
            showToast(
              "Le mot de passe doit comporter au moins 6 caractères.",
              3000,
              MessageType.error
            );
          }

          setLoading(false);
        });
    } else if (signUpType == "tel" && !haveErrors) {
      // remove first '0' and add french indicatif '+33'
      const telephoneFormatted = "+33" + telephone.substring(1);
      setLoading(true);

      checkPhoneExist(telephoneFormatted)
        .then((exist) => {
          if (!exist) {
            firebase.auth().languageCode = "fr";
            //firebase.auth().settings.appVerificationDisabledForTesting = true;
            const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
              "submit-signup",
              {
                size: "invisible",
                callback: (response: any) => { },
              }
            );
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            firebase
              .auth()
              .signInWithPhoneNumber(telephoneFormatted, recaptchaVerifier)
              .then((response) => {
                // setting PhoneCodeValidation cause show validation code form component
                setFirebasePhoneCodeValidation(response);
                setLoading(false);
              })
              .catch((error) => {
                console.error("signInWithPhoneNumber error", error);
                setLoading(false);
                if (error.code === "auth/invalid-phone-number") {
                  showToast(
                    "Format du numéro de téléphone incorrect",
                    3000,
                    MessageType.error
                  );
                }
              });
          } else {
            setLoading(false);
            // phone number exist so show error
            showToast(
              "Veuillez saisir un autre numéro de téléphone mobile car il n'est pas unique",
              3000,
              MessageType.error
            );
          }
        })
        .catch((e) => {
          console.error("Auth-phoneExist error", e);
          setLoading(false);
        });
    }
  };

  /** hide and show the text intro */
  function showHideIntro() {
    if (showIntro) {
      setTextIntro(
        "Homelinks, responsable du traitement, doit traiter vos données pour créer et administrer votre compte dans le cadre de"
      );
      setButtonName("voir plus");
      setShowIntro(false);
    } else {
      setTextIntro(
        "Homelinks, responsable du traitement, doit " +
        "traiter vos données pour créer et administrer votre compte " +
        "dans le cadre de l’usage de cette application, assurer son " +
        "bon fonctionnement, réaliser des enquêtes, et statistiques  " +
        "sur l’utilisation de nos services et traiter vos demandes. " +
        "Tous les champs doivent obligatoirement être remplis afin " +
        " de sécuriser votre compte et permettre l’accès à nos services.  " +
        " Le traitement de vos données dans ce cadre est basé sur votre  " +
        " consentement, ou sur les intérêts légitimes de Homelinks ou " +
        " sur le respect de ses obligations légales.Selon  " +
        " les conditions applicables, vous pouvez modifier et accéder  " +
        "à vos données, demander leur effacement ou leur portabilité, " +
        "exercer votre droit d’opposition ou de limitation du traitement, " +
        "  et définir des directives quant au sort de vos données, directement " +
        "  grâce aux réglages de votre compte ou par courriel  " +
        " à bonjour@monlienvisio.fr.Vous pouvez retirer à tout moment votre  " +
        " consentement au traitement de vos données.Pour plus d’informations, " +
        " nous vous invitons à consulter notre "
      );
      setButtonName("voir moins");
      setShowIntro(true);
    }
  }

  /**
   * showHidePasswords : show and hide password
   * @param   {void}
   * @return  {void}
   */
  const showHidePasswords = () => {
    if (!checked) {
      typeField = "text";
      setPasswordLabel(" Cacher votre mot de passe");

      setIcon(eyeOff);
    } else {
      typeField = "password";
      setPasswordLabel("  Afficher le mot de passe");

      setIcon(eye);
    }
    setChecked(!checked);
    document.getElementsByTagName("input")[3].type = typeField;
    document.getElementsByTagName("input")[4].type = typeField;
    // document.getElementsByTagName("input")[2].type = typeField;
  };

  /*
   * take picture : this function open the camera or the gallery and take/choose picture
   */
  async function takePicture(type: CameraSource) {
    if (type === CameraSource.Photos) {
      if (inputFile.current) inputFile.current.click();
    } else {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          direction: CameraDirection.Rear,
          allowEditing: false,
          source: CameraSource.Camera,
          resultType: CameraResultType.Base64,
          promptLabelHeader: "Sélectionner une photo",
          promptLabelCancel: "Annuler",
          promptLabelPhoto: "Depuis la galerie",
          promptLabelPicture: "Depuis l'appareil photo",
        });
        if (image.base64String) {
          setBase64String(image.base64String);
          setFormatImage(image.format);
        }
      } catch (error) {
        return null;
      }
    }
  }

  async function onSelectFile(e: any) {
    if (e.target.files[0] && e.target.files[0].type) {
      const file = e.target.files[0];
      const base64 = (await toBase64(file)) as string;
      if (base64) {
        const format = _.last(_.split(file.type, "/"));
        setFormatImage(format as string);
        setBase64String(base64.split(",")[1]);
      }
    }
  }
  return (
    <IonPage id="signup-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Création de compte</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id="signup-content">
        <div className="login-logo">
          <img src="assets/img/logo.png" alt="Ionic logo" />
          <div style={{ width: "100%", textAlign: "center" }}>
            <IonLabel position="stacked" color="primary">
              <IonText className={fontClass}>Mon lien visio</IonText>{" "}
            </IonLabel>
          </div>
        </div>

        {firebasePhoneCodeValidation && (
          <SmsCodeValidationForm
            onValidCode={onCodeValidated}
            onCancel={onCancelCodeVerification}
            onLoading={(enable: boolean) => setLoading(enable)}
            firebasePhoneCodeValidation={firebasePhoneCodeValidation}
          />
        )}

        {!firebasePhoneCodeValidation && (
          <form noValidate onSubmit={signup}>
            <IonList>
              {/* Mail or telephone */}
              <div style={{ width: "100%", textAlign: "center" }}>
                <IonLabel position="stacked" color="primary">
                  <IonText color="dark" className={fontClass}>
                    Mode de connexion
                  </IonText>{" "}
                </IonLabel>
              </div>
              <IonItem className="item-form">
                <IonRow style={{ width: "100%" }}>
                  <IonCol size-xs="12" size-md="6" size-lg="6" size="6">
                    <IonButton
                      className={fontClass}
                      type="button"
                      expand="block"
                      fill={signUpType == "mail" ? "solid" : "outline"}
                      onClick={(e) => {
                        setSignUpType("mail");
                        setTelephone("");
                      }}
                    >
                      Email
                    </IonButton>
                  </IonCol>
                  <IonCol size-xs="12" size-md="6" size-lg="6" size="6">
                    <IonButton
                      className={fontClass}
                      type="button"
                      expand="block"
                      fill={signUpType == "tel" ? "solid" : "outline"}
                      onClick={(e) => {
                        setSignUpType("tel");
                        setUsername("");
                      }}
                    >
                      <IonText style={{ whiteSpace: "normal" }}>
                        Téléphone mobile
                      </IonText>
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRadioGroup
                  value={signUpType}
                  onIonChange={(e) => setSignUpType(e.detail.value)}
                  style={{ with: "100%" }}
                ></IonRadioGroup>
              </IonItem>

              {signUpType && (
                <div>
                  {/* First name */}
                  <IonItem className="item-form">
                    <IonLabel position="stacked" color="primary">
                      {" "}
                      <IonText className={fontClass}>Prénom </IonText>
                    </IonLabel>
                    <IonInput
                      name="firstName"
                      type="text"
                      value={firstName}
                      spellCheck={false}
                      autocapitalize="off"
                      onIonChange={(e) => {
                        const firstName = e.detail.value
                          ? e.detail.value.trim()
                          : null;
                        setFirstName(firstName!);
                      }}
                      required
                    ></IonInput>
                  </IonItem>

                  {/* Last name */}
                  <IonItem className="item-form">
                    <IonLabel position="stacked" color="primary">
                      <IonText className={fontClass}>Nom</IonText>{" "}
                    </IonLabel>
                    <IonInput
                      name="lastName"
                      type="text"
                      value={lastName}
                      spellCheck={false}
                      autocapitalize="off"
                      onIonChange={(e) => {
                        const lastName = e.detail.value
                          ? e.detail.value.trim()
                          : null;
                        setLastName(lastName!);
                      }}
                      required
                    ></IonInput>
                  </IonItem>

                  {signUpType == "tel" && (
                    <div>
                      {/* telephone */}
                      <IonItem className="item-form">
                        <IonLabel position="stacked" color="primary">
                          <IonText className={fontClass}>Téléphone</IonText>{" "}
                        </IonLabel>
                        <IonInput
                          name="telephone"
                          type="text"
                          value={telephone}
                          spellCheck={false}
                          autocapitalize="off"
                          onIonChange={(e) => {
                            let telephone = e.detail.value
                              ? e.detail.value.trim()
                              : null;
                            if (telephone) {
                              telephone = telephone.replace(" ", "");
                            }

                            setTelephone(telephone!);
                          }}
                          required
                        ></IonInput>
                      </IonItem>
                    </div>
                  )}

                  {signUpType == "mail" && (
                    <div>
                      {/* Mail */}
                      <IonItem className="item-form">
                        <IonLabel position="stacked" color="primary">
                          <IonText className={fontClass}>Email</IonText>{" "}
                        </IonLabel>
                        <IonInput
                          name="username"
                          type="text"
                          value={username ? removeDiacritics(username.toLowerCase()) : username}
                          spellCheck={false}
                          autocapitalize="off"
                          onIonChange={(e) => {
                            const userName = e.detail.value
                              ? e.detail.value.trim()
                              : null;
                            setUsername(userName!);
                          }}
                          required
                        ></IonInput>
                      </IonItem>

                      {/* Password */}
                      <IonItem className="item-form">
                        <IonLabel position="stacked" color="primary">
                          {" "}
                          <IonText className={fontClass}>
                            Mot de passe
                          </IonText>{" "}
                        </IonLabel>
                        <IonInput
                          name="password"
                          type="password"
                          value={password}
                          onIonChange={(e) => {
                            const password = e.detail.value
                              ? e.detail.value.trim()
                              : null;
                            setPassword(password!);
                          }}
                        ></IonInput>
                      </IonItem>

                      <IonItem className="item-form">
                        <IonLabel position="stacked" color="primary">
                          {" "}
                          <IonText className={fontClass}>
                            Confirmer le mot de passe
                          </IonText>{" "}
                        </IonLabel>
                        <IonInput
                          name="repassword"
                          type="password"
                          value={rePassword}
                          onIonChange={(e) => {
                            const password = e.detail.value
                              ? e.detail.value.trim()
                              : null;
                            setRePassword(password!);
                          }}
                        ></IonInput>
                      </IonItem>
                      {/* show/ hide password */}
                      {signUpType == "mail" && (
                        <IonItem className="item-form">
                          <IonToggle
                            className="toggle-button"
                            checked={checked}
                            onClick={showHidePasswords}
                          />{" "}
                          <IonText color="primary">
                            <IonIcon icon={icon} /> {passwordLabel}
                          </IonText>
                        </IonItem>
                      )}
                      {/*buttons */}
                    </div>
                  )}
                  {/* recovery Email */}
                  <IonItem>
                    <IonLabel position="stacked" color="primary">
                      <IonText className={fontClass}>
                        {" "}
                        Email de récupération
                      </IonText>{" "}
                    </IonLabel>
                    <IonInput
                      name="recoveryEmail"
                      type="text"
                      value={recoveryEmail ? removeDiacritics(recoveryEmail.toLowerCase()) : recoveryEmail}
                      spellCheck={false}
                      autocapitalize="off"
                      onIonChange={(e) => {
                        const recoveryEmail = e.detail.value
                          ? e.detail.value.trim()
                          : null;
                        setRecoveryEmail(recoveryEmail!);
                      }}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked" color="primary">
                      {" "}
                      <IonText className={fontClass}>
                        Ajouter une photo de profil
                      </IonText>{" "}
                    </IonLabel>
                    <div style={{ width: "100%", padding: "20px" }}>
                      <IonRow style={{ width: "100%" }}>
                        <IonCol size="12" className={"image-container"}>
                          <img
                            style={{
                              margin: "0 auto",
                              width: "150px",
                              height: "150px",
                              borderRadius: "5px",
                              objectFit: "cover",
                            }}
                            src={srcImage ? srcImage : defaultPicture}
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow style={{ width: "100%" }}>
                        <IonCol size-xs="12" size-md="6" size-lg="6" size="6">
                          <input
                            ref={inputFile}
                            style={{ display: "none" }}
                            type="file"
                            id="file"
                            multiple={false}
                            onChange={onSelectFile}
                          />
                          <IonButton
                            className={fontClass}
                            type="button"
                            expand="block"
                            fill={"solid"}
                            onClick={
                              srcImage
                                ? () => setEditImage(true)
                                : () => takePicture(CameraSource.Photos)
                            }
                          >
                            <IonIcon
                              icon={srcImage ? pencil : imagesOutline}
                              style={{ marginRight: "10px" }}
                              className="icon-size"
                            />
                            <IonText
                              className="hide-sm"
                              style={{ whiteSpace: "normal" }}
                            >
                              {srcImage
                                ? "Modifier l'image"
                                : "Depuis la galerie"}
                            </IonText>
                          </IonButton>
                        </IonCol>
                        <IonCol size-xs="12" size-md="6" size-lg="6" size="6">
                          <IonButton
                            className={fontClass}
                            type="button"
                            expand="block"
                            fill={"outline"}
                            onClick={
                              srcImage
                                ? () => {
                                    setBase64String("");
                                    setFormatImage("");
                                    setSrcImage("");
                                    if (inputFile.current)
                                      inputFile.current.value = "";
                                  }
                                : () => takePicture(CameraSource.Camera)
                            }
                          >
                            <IonIcon
                              icon={srcImage ? closeOutline : cameraOutline}
                              style={{ marginRight: "10px" }}
                              className="icon-size"
                            />
                            <IonText
                              className="hide-sm"
                              style={{ whiteSpace: "normal" }}
                            >
                              {srcImage ? "Annuler" : "Depuis l'appareil photo"}
                            </IonText>
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </div>
                  </IonItem>

                  {/* intro text */}
                  {
                    <IonText>
                      <p id="intro-text" className="ion-padding-start">
                        {textintro}
                        {showIntro && (
                          <a
                            href="https://www.monlienvisio.fr/politique-de-confidentialite"
                            target="_blank"
                          >
                            Politique de protection des données personnelles
                          </a>
                        )}
                        <a onClick={showHideIntro}> {buttonName}</a>
                      </p>
                    </IonText>
                  }

                  {/* RGPD */}
                  <IonItem type="button" lines="none" className="item-form">
                    <IonCheckbox
                      id="cgu"
                      slot="start"
                      checked={rgpdChecked}
                      onIonChange={(e) => {
                        const rgpdChecked = e.detail.checked ? true : false;
                        setRGPDChecked(rgpdChecked!);
                      }}
                    />
                    <div className="cgu-text new-line">
                      {" "}
                      <IonText>
                        Je déclare avoir pris connaissance de la
                        <a
                          href="https://www.monlienvisio.fr/politique-de-confidentialite"
                          target="_blank"
                        >
                          Politique de protection des données personnelles
                        </a>
                        et accepte que mes données soient collectées et traitées
                        par Homelinks selon ses termes.
                      </IonText>
                    </div>
                  </IonItem>

                  {/* CGU */}
                  <IonItem type="button" lines="none" className="item-form">
                    <IonCheckbox
                      id="cgu"
                      slot="start"
                      checked={cguChecked}
                      onIonChange={(e) => {
                        const cguChecked = e.detail.checked ? true : false;
                        setCguChecked(cguChecked!);
                      }}
                    />
                    <div className="cgu-text new-line">
                      {" "}
                      <IonText>
                        Je déclare avoir pris connaissance et accepter les
                        <a
                          href="https://www.monlienvisio.fr/cgu"
                          target="_blank"
                        >
                          Conditions Générales d’Utilisation
                        </a>{" "}
                        et la
                        <a
                          href="https://www.monlienvisio.fr/politique-dutilisation-des-cookies/"
                          target="_blank"
                        >
                          politique des cookies
                        </a>
                      </IonText>
                    </div>
                  </IonItem>
                </div>
              )}
            </IonList>

            <IonRow>
              {
                <IonCol size="12">
                  <IonButton
                    className={fontClass}
                    type="submit"
                    expand="block"
                    disabled={!signUpType}
                  >
                    <div id="submit-signup" style={{ opacity: 0 }}></div>
                    <IonLabel>Créer un compte</IonLabel>
                  </IonButton>
                </IonCol>
              }
              <IonCol size="12">
                <IonButton
                  className={fontClass}
                  type="button"
                  routerLink="/login"
                  color="light"
                  expand="block"
                >
                  <IonIcon
                    style={{ marginRight: "10px" }}
                    className="icon-size"
                    src={arrowBackOutline}
                  />
                  Retour
                </IonButton>
              </IonCol>
            </IonRow>
          </form>
        )}
        <Loading enable={loading} text="Création de votre compte ..." />
        {editImage && srcImage && (
          <EditImage
            onCancel={() => setEditImage(false)}
            src={srcImage}
            setImageFormat={setFormatImage}
            setImageData={setBase64String}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    appOnline: state.global.appOnline
  }),
  mapDispatchToProps: {
    setIsLoggedIn,
    setUsername,
    loadUserData,
    uploadUserPicture,
    setShowOffline
  },
  component: Signup,
});
