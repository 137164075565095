import React, { useState } from "react";
import { IonContent, IonIcon, IonItem, IonList, IonPage ,IonRow,IonCol} from "@ionic/react";
import Header from "../../components/Header";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { easel, text,arrowBackCircleOutline } from "ionicons/icons";
import classes from "./Settings.module.scss";
import FontSizeSettings from "../../components/Modals/FontSizeSettings";
import ScreenSaverSettings from "../../components/Modals/ScreenSaverSettings";
import { UserSupervised } from "../../models/UserSupervised";
import SubtitlingSettings from "../../components/settings/SubtitlingSettings";
import Button from "../../components/Button";
interface OwnProps {}

interface StateProps {
  isHelpedProfil: boolean;
  supervisedAccount?: UserSupervised;
}

interface DispatchProps {}

interface AppearanceSettingsProps extends OwnProps, StateProps, DispatchProps {}

const AppearanceSettings: React.FC<AppearanceSettingsProps> = ({
  isHelpedProfil,
  supervisedAccount
}) => {
  const [showFontSizeSettings, setShowFontSizeSettings] = useState(false);
  const [showScreenSaveSettings, setShowScreenSaveSettings] = useState(false);
  const [showSubtitlingSettings, setShowSubtitlingSettings] = useState(false);
  const options = [
    {
      id: 0,
      title: "Taille de police",
      icon: text,
      action: () => {
        setShowFontSizeSettings(true);
      },
    },
    {
      id: 1,
      title: "Sous-titrage des icônes",
      icon: "assets/icon/subtitles.svg",
      action: () => {
        setShowSubtitlingSettings(true);
      },
    }
  ];

  if(isHelpedProfil || supervisedAccount){
    options.push({
      id: 2,
      title: "Écran de veille",
      icon: easel,
      action: () => {
        setShowScreenSaveSettings(true);
      },
    },);
  }

  return (
    <IonPage>
      <Header title="Réglages d'accessibilité" showHelp/>
      <IonContent>
        <div
          className={
            classes.container + (isHelpedProfil ? " padding-bottom" : "")
          }
        >
          <IonList className={classes.list}>
            {options.map((option) => (
              <IonItem key={option.id} button onClick={option.action} className={classes.item}>
                <IonIcon
                  slot="start"
                  icon={option.icon}
                  className={classes.icon}
                ></IonIcon>
                <p>{option.title}</p>
              </IonItem>
            ))}
          </IonList>
        </div>
      </IonContent>
      {showFontSizeSettings && (
        <FontSizeSettings
        showFontSizeSettings={setShowFontSizeSettings}
        />
      )}
      {showScreenSaveSettings && (
        <ScreenSaverSettings
        showScreenSaverSettings={setShowScreenSaveSettings}
        />
      )}
      {showSubtitlingSettings && (
        <SubtitlingSettings
        showSubtitlingSettings={setShowSubtitlingSettings}
        />
      )}
      <IonRow>
          <IonCol size="12">
            <Button
              routerLink="/settings"
              color="light"
              text="Retour"
              icon={arrowBackCircleOutline}
            />
          </IonCol>
        </IonRow>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    isHelpedProfil: selectors.getIsHelpedProfil(state),
    supervisedAccount: state.supervisor.supervisedAccount, 
  }),
  mapDispatchToProps: {},
  component: React.memo(AppearanceSettings),
});
