import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { IonIcon, IonModal } from "@ionic/react";
import "./RecordView.scss";
import "./RecordAudio.scss";
import Header from "./Header";
import BottomButtons from "./BottomButtons";
import { connect } from "../data/connect";
import { IconSvgEnum } from "./IconSvg";
import { setShowOffline } from "../data/global/global.actions";

interface OwnProps {
  setAudioBlobUrl: (url: string | null) => void;
  setStartAudioRecord: (start: boolean) => void;
  onClose: () => void;
  onValidate: () => void;
}

interface StateProps {
  appOnline: boolean;
}

interface DispatchProps {
  setShowOffline: typeof setShowOffline;
}

interface RecordViewProps extends OwnProps, StateProps, DispatchProps {}

const RecordAudio: React.FC<RecordViewProps> = ({
  appOnline,
  setAudioBlobUrl,
  setStartAudioRecord,
  onClose,
  onValidate,
  setShowOffline
}) => {
  const [isActive, setIsActive] = useState(false);
  const [showRecord, setShowRecord] = useState(true);
  const [timer, setTimer] = useState(0);
  const countRef = useRef<any>(null);

  useEffect(() => {
    return () => {
      setAudioBlobUrl(null);
      setStartAudioRecord(false);
      setIsActive(false);
      setShowRecord(true);
      setTimer(0);
      stopRecording();
      clearBlobUrl();
    };
  }, []);

  const formatTime = (timer: number) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = Math.floor(timer / 60);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  const {
    startRecording,
    stopRecording,
    pauseRecording,
    clearBlobUrl,
    mediaBlobUrl,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
  });

  setAudioBlobUrl(mediaBlobUrl);

  const cancelHandler = () => {
    setShowRecord(false);
    onClose();
    setAudioBlobUrl(null);
    setStartAudioRecord(false);
  };

  const validateHandler = async () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    onValidate();
  };
  const start = () => {
    setTimer(0);
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
    clearBlobUrl();
    startRecording();
    setIsActive(true);
  };
  const stop = () => {
    clearInterval(countRef.current);
    pauseRecording();
    stopRecording();
    setIsActive(false);
  };
  return (
    <IonModal isOpen={showRecord}>
      <Header title="Enregistrer un commentaire audio" hideHome />
      <div className="audio-record-container">
        <div className="buttons-container">
          <h4 style={{paddingBottom: "20px"}}>
            Cliquer sur COMMENCER pour enregistrer votre voix puis sur TERMINER.
            <br />
            Et si le commentaire audio vous convient, cliquer sur ENVOYER
          </h4>
          <BottomButtons
            cancelBtnColor="light"
            validateBtnColor="light"
            cancelBtnLabel="Commencer"
            validateBtnLabel="Terminer"
            cancelBtnIcon={IconSvgEnum.record_message}
            validateBtnIcon={IconSvgEnum.stop}
            onCancel={() => start()}
            onValidate={() => stop()}
          />
        </div>
        <div className="timer-container">
          <span className="timer">{formatTime(timer)}</span>

          <div className="record-container">
            {isActive && (
              <IonIcon
                src={"assets/icon/record.svg"}
                className={"audio-record-icon record"}
              />
            )}
            {isActive && <h3>Enregistrement en cours ...</h3>}
          </div>

          {mediaBlobUrl && (
            <audio controls className="recorded-audio">
              <source src={mediaBlobUrl} type="audio/mpeg" />
            </audio>
          )}
        </div>
      </div>
      <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
        <BottomButtons
          cancelBtnColor="light"
          validateBtnColor="success"
          cancelBtnLabel="Retour"
          validateBtnLabel={"Envoyer"}
          cancelBtnIcon={IconSvgEnum.cancel}
          validateBtnIcon={IconSvgEnum.send}
          onCancel={() => cancelHandler()}
          onValidate={() => validateHandler()}
        />
      </div>
    </IonModal>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setShowOffline
  },
  mapStateToProps: (state) => ({
    loading: state.sharedFolder.loading,
    appOnline: state.global.appOnline
  }),
  component: React.memo(RecordAudio),
});
