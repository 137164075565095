
/**
* hasSpecialCharacter : checks if password contains a special character 
* @param   {string} str  user password
* @return  {boolean}     
*/
function hasSpecialCharacter(str: string) {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(str)
}

/**
* hasMoreThenNChar : checks if password exeeds n characters
* @param   {string} str  user password
* @param   {number} limit   number of character
* @return  {boolean}          
*/
function hasMoreThenNChar(str: string, limit: number) {
    if (str.length > limit) {
        return true
    }
    return false
}


/**
* getComplexity : get the complexity of the password => weak, medium, strong
* @param   {string} str  user password
* @return  {number}   the category of the password 2=strong, 1= medium , 0=weak
*/
export function getComplexity(str: string) {

    if (hasMoreThenNChar(str, 8) && hasSpecialCharacter(str)) {
        return 2;
    }

    if (hasMoreThenNChar(str, 8)) {
        return 1;
    }

    if (hasMoreThenNChar(str, 6)) {
        return 0;
    }

}

// password display name
export enum PasswordDisplayName {
    weak = 'Faible',
    medium = 'Moyen',
    strong = 'Fort'
}

export enum PasswordColor {
    weak = 'danger',
    medium = 'warning',
    strong = 'success'
}