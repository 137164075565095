import React, { useEffect, useState } from 'react';
import { setAudioData } from '../data/user/user.actions';
import { isPlatform } from '@ionic/core';
import { App } from '@capacitor/app';
import { BackgroundTask } from '@robingenz/capacitor-background-task';

export enum audioType{
    /**
     * When a new call income
     */
    IncomingCall = "IncomingCall",
    /**
     * A new file is shared with the user
     */
    NewSharedFile = "NewSharedFile",
    /**
     * A new message is available
     */
    NewMessage = "NewMessage",
    /**
     * Play when waiting other to responde call
     */
    OutcomingCall = "OutcomingCall"
  }

  
interface AudioPlayerProps { 
    type: audioType;
    play:boolean;
    /** Come from user setting. If false, user have disable notification sound so keep only incoming call sound */
    settingSoundEnable: boolean;
    setAudioData: typeof setAudioData;
}
//const audio = new Audio();
const AudioPlayer: React.FC<AudioPlayerProps> = ({ type, play, settingSoundEnable, setAudioData }) => {
    
    const [audioIncomingCall] = useState(new Audio());
    const [audioNewSharedFile] = useState(new Audio());
    const [audioNewMessage] = useState(new Audio());
    const [audioOutcomingCall] = useState(new Audio());

    const [appIsActive, setAppIsActive] = useState(true);

    useEffect(() => {

        audioIncomingCall.src="assets/rings/incoming-call.mp3";
        audioNewSharedFile.src="assets/rings/new-shared-file.mp3";
        audioNewMessage.src="assets/rings/new-message.mp3";
        audioOutcomingCall.src="assets/rings/outcoming-call.mp3";


        audioIncomingCall.loop=true;
        audioOutcomingCall.loop=true;

        audioNewSharedFile.loop=false;
        audioNewMessage.loop=false;

        // no loop so clear audioData when play is over
        audioNewSharedFile.addEventListener('ended',() => {
            setAudioData(audioType.NewSharedFile, false)
        },false);
        audioNewMessage.addEventListener('ended',() => {
            setAudioData(audioType.NewMessage, false)
        },false);

        audioIncomingCall.load();
        audioNewSharedFile.load();
        audioNewMessage.load();
        audioOutcomingCall.load();

        // cancel ring if user quit app
        App.addListener('appStateChange', (state) => {
            setAppIsActive(state.isActive);
            if (!state.isActive && isPlatform("mobile")) {
            let taskId : any = BackgroundTask.beforeExit(async () => {
                pauseAudio()
                BackgroundTask.finish({ taskId });
            });
            }
        });

    }, []);

    useEffect(() => {
        if(!play)
        {
            pauseAudio();
        }
        else 
        {
            if(settingSoundEnable || type === audioType.IncomingCall || type === audioType.OutcomingCall) playAudio();
        }

        return () => {
            //pauseAll()
        }
    }, [play, type]);


    function playAudio(){
        // if app is background, do not start audio
        if(!appIsActive && isPlatform("mobile")) return;
        
        audioIncomingCall.currentTime = 0;
        audioNewSharedFile.currentTime = 0;
        audioNewMessage.currentTime = 0;
        audioOutcomingCall.currentTime = 0;

        if(type == audioType.IncomingCall)   audioIncomingCall.play().catch((err) => console.error("AAAA error play audio audioIncomingCall " +  JSON.stringify({message:err.message, error:audioIncomingCall.error})));
        if(type == audioType.NewSharedFile) audioNewSharedFile.play().catch((err) => console.error("AAAA error play audio audioNewSharedFile " + JSON.stringify({message:err.message, error:audioNewSharedFile.error})));
        if(type == audioType.NewMessage)   audioNewMessage.play().catch((err) => console.error("AAAA error play audio audioNewMessage " +        JSON.stringify({message:err.message, error:audioNewMessage.error})));
        if(type == audioType.OutcomingCall) audioOutcomingCall.play().catch((err) => console.error("AAAA error play audio audioOutcomingCall " + JSON.stringify({message:err.message, error:audioOutcomingCall.error})));
    }

    function pauseAll()
    {
        audioIncomingCall.pause();
        audioNewSharedFile.pause();
        audioNewMessage.pause();
        audioOutcomingCall.pause();
    }
    
    function pauseAudio(){
        if(type == audioType.IncomingCall)  audioIncomingCall.pause();
        if(type == audioType.NewSharedFile) audioNewSharedFile.pause();
        if(type == audioType.NewMessage)    audioNewMessage.pause();
        if(type == audioType.OutcomingCall) audioOutcomingCall.pause();
    }

    return (<div></div>);
};

export default AudioPlayer