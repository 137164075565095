import isProdEnv from "./util/isProdEnv";

/**
 * Timeout before cancel incoming call if no action provided from user
 */
export const _timeoutBeforeCancelCall = 60000;

/**
 * Current app version show in the menu
 */
export const _appVersion = "v2.25";

export const _fileSizeLimitPhoto = 6000; // 6mo
export const _fileSizeLimitVideo = 100000; // 100mo

/**
 * Number of time to wait before show screensaver with picture gallery
 */
export const _timeoutBeforeScreensaver = 60000;

export const _MaxParticipantsVisioCall = 20;

export const _MaxCharNewComment = 1000;

/**
 * Number max of folder where user is creator
 */
export const _MaxAlbum = 4;
/**
 * pictures in shared album limitation
 */
export const _MaxPictureByAlbum = 20;
/**
 * videos in shared album limitation
 */
export const _MaxVideoByAlbum = 2;

/**
 * Number of second wait before calling emergency contact
 */
export const _TimerEmergencyCall = 10000;

/**
 * Number of second wait before start record video
 */
export const _TimerRecordVideo = 3000;

/**
 * Name of unshared folder (unshared folder is a folder that contains the medias that are sent by contacts)
 */
export const _NameUnsharedFolder = "Mon Album";

/**
 * Url of MLV in play store
 */
export const _UrlMLVAndroid =
  "https://play.google.com/store/apps/details?id=fr.homelinks.monlienvisio";

/**
 * Url of MLV in App store
 */
export const _UrlMLVIos =
  "https://apps.apple.com/fr/app/mon-lien-visio/id1588378947";

/**
 * support email addresses
 */

export const MailSupport = "bonjour@monlienvisio.fr";

export const IdConfig = "MLV-AIzaSyBKsEmaGqc2RTysC2HUwuCAO9WrxKWhUbE";
export const IdConfigNotHelped = "MLVNOTHELPED-AIzaSyAO9WrxKWhUbE";
export const IdConfigHelped = "MLVHELPD-AIzaSyBKsEmaGqcrxKWhUbE";
export const IdConfigSubscriberHelped = "MLV-SubHelped-eBKsEmaGqJlkZe";
export const IdConfigSubscriberNotHelped = "MLV-SubNotHelped-BKsEmaGqJlkZe";
export const _UrlMLV = isProdEnv() ? "https://app.monlienvisio.fr/" : "https://mon-lien-visio-homelinks.web.app/";