export interface SupervisorRelationship {
    id:string;
    /**  User that can take control */
    administrator:string;
    /** User that allow administrator to take controle*/
    administered:string;
    /** Array containing user ids from and to. Used for search query (allow us to use array-contains-any in firestore) */
    users:string[];
    /** State of the demand */
    state: SupervisorRelationshipState;

    notifAdministrator : boolean;
    dateCreate: string;
}
  
/** Administrator demande state */
export enum SupervisorRelationshipState{
    /**
     * Wait answer from administered
     */
    Waiting = "Waiting",
    /**
     * Administered accept the demand for taking control
     */
    Accepted = "Accepted",
  }