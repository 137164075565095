import React, { useState } from "react";
import { IonContent, IonPage, IonText,IonRow,IonCol } from "@ionic/react";
import { connect } from "../data/connect";
import { startCall } from "../data/visioCall/visioCall.actions";
import { Mailbox, MailboxItem, MailboxItemType } from "../models/Mailbox";
import {
  setMessageViewByUser,
  updateLastActivity,
} from "../data/user/user.actions";
import _ from "lodash";
import "./MailboxPage.scss";
import { RouteComponentProps } from "react-router-dom";
import Header from "../components/Header";
import ShowMessageModal from "../components/Modals/ShowMessageModal";
import SelectMultipleMessages from "../components/SelectMultipleMessages";
import * as selectors from "../data/selectors";
import { UserSupervised } from "../models/UserSupervised";
import { User } from "../models/User";
import {arrowBackCircleOutline} from "ionicons/icons";
import Button from "../components/Button";
import { setShowOffline } from "../data/global/global.actions";
interface OwnProps {}

interface StateProps {
  mailbox?: Mailbox;
  currentUserEmail?: string;
  isHelpedProfil: boolean;
  supervisedAccount?: UserSupervised;
  currentUser?: User;
  appOnline: boolean;
}

interface DispatchProps {
  setMessageViewByUser: typeof setMessageViewByUser;
  startCall: typeof startCall;
  updateLastActivity: typeof updateLastActivity;
  setShowOffline: typeof setShowOffline;
}

interface MissedCallsProps
  extends RouteComponentProps,
    OwnProps,
    StateProps,
    DispatchProps {}

const MissedCalls: React.FC<MissedCallsProps> = ({
  mailbox,
  history,
  currentUserEmail,
  isHelpedProfil,
  supervisedAccount,
  currentUser,
  appOnline,
  setMessageViewByUser,
  startCall,
  updateLastActivity,
  setShowOffline
}) => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [currentMessage, setCurrentMessage] = useState<MailboxItem>();

  function showMessage(message: MailboxItem) {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    setCurrentMessage(message);
    setShowMessageModal(true);
  }

  function hideMessage() {
    setCurrentMessage(undefined);
    setShowMessageModal(false);
  }

  async function callUser(toMail: string) {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    try {
      if (currentUser) {
        await startCall([toMail], currentUser);
        await updateLastActivity();
      }
      history.push("/tabs/conference/video");
    } catch (e) {
      alert("Exception userItems>callUser(): " + JSON.stringify(e));
    }
  }

  const missedCalls = _.reverse(mailbox
    ? mailbox.items.filter(
        (item) => item.type === MailboxItemType.MissingCall
      )
    : []);

  return (
    <IonPage>
      <Header title="Appels manqués" showHelp/>
      <IonContent>
        <div className={isHelpedProfil ? "padding-bottom" : ""}>
          {missedCalls && missedCalls.length == 0 && (
            <div style={{ textAlign: "center", marginTop: "50px" }}>
              <IonText>La liste des appels manqués est vide</IonText>
            </div>
          )}
          {missedCalls && missedCalls.length > 0 && (
            <SelectMultipleMessages
              messages={missedCalls}
              showMessage={showMessage}
              type="call"
            />
          )}
          {showMessageModal && (
            <ShowMessageModal
              showMessageModal={showMessageModal}
              currentMessage={currentMessage}
              hideMessage={hideMessage}
              callUser={callUser}
            />
          )}
        </div>
      </IonContent>
      <IonRow>
          <IonCol size="12">
            <Button
              routerLink="/tabs/mailbox"
              color="light"
              text="Retour"
              icon={arrowBackCircleOutline}
            />
          </IonCol>
        </IonRow>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setMessageViewByUser,
    startCall,
    updateLastActivity,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    mailbox: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.mailbox : state.user.mailbox,
    currentUserEmail: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
    supervisedAccount: state.supervisor.supervisedAccount,
    currentUser: state.user.userFirestore,
    appOnline: state.global.appOnline
  }),
  component: React.memo(MissedCalls),
});
