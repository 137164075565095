import { IonContent, IonInput, IonItem, IonModal } from "@ionic/react";
import React, { useState } from "react";
import { connect } from "../../data/connect";
import _ from "lodash";
import Header from "../Header";
import { ContactGroup } from "../../models/ContactGroup";
import BottomButtons from "../BottomButtons";
import { updateContactGroupName } from "../../data/user/user.actions";
import { setShowOffline, setToastNotification } from "../../data/global/global.actions";
import { ToastNotification } from "../../models/ToastNotification";
import { UserSupervised } from "../../models/UserSupervised";

interface OwnProps {
  group: ContactGroup;
  hist: any;
  onCancel: () => void;
}

interface StateProps {
  appOnline: boolean;
  supervisedAccount: UserSupervised | null;
}

interface DispatchProps {
  updateContactGroupName: typeof updateContactGroupName;
  setToastNotification: typeof setToastNotification;
  setShowOffline: typeof setShowOffline;
}
interface UpdateContactGroupNameProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const UpdateContactGroupName: React.FC<UpdateContactGroupNameProps> = ({
  group,
  supervisedAccount,
  onCancel,
  hist,
  appOnline,
  updateContactGroupName,
  setToastNotification,
  setShowOffline
}) => {
  const [contactGroupName, setContactGroupName] = useState(group.name);
  async function onValidate() {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    if (contactGroupName === "") {
      setToastNotification({
        message: "Le nom du groupe est nécessaire",
        color: "danger",
      } as ToastNotification);
      return;
    }
    updateContactGroupName(group, contactGroupName, supervisedAccount);
    hist.push("/tabs/settings/contacts/groups");
    setToastNotification({
      message: "Le nom du groupe a bien été modifié",
      color: "success",
    } as ToastNotification);
  }
  return (
    <IonModal isOpen={true} onDidDismiss={() => onCancel()}>
      <Header hideHome title="Modifier le nom de votre groupe" />
      <IonContent>
        <IonItem>
          <IonInput
            size={30}
            value={contactGroupName}
            placeholder="Nom du groupe"
            onIonChange={(e) => setContactGroupName(e.detail.value!)}
          ></IonInput>
        </IonItem>
      </IonContent>
      <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
        <BottomButtons onCancel={onCancel} onValidate={onValidate} />
      </div>
    </IonModal>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    updateContactGroupName,
    setToastNotification,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    appOnline: state.global.appOnline,
    supervisedAccount: state.supervisor.supervisedAccount || null
  }),
  component: React.memo(UpdateContactGroupName),
});
