export interface Mailbox {
    user: string;
    items: MailboxItem[];
}
  

export interface MailboxItem {
    id:string;
    urlFile: string;
    fromUser: string;
    viewed: boolean;
    date: Date;
    sizeKo: number;
    /**
     * If true, we need to show toast notification on user who received the message. When notification played, this attribut is set to false
     */
    needToastNotification: boolean;
    type: MailboxItemType;
    /**
     * if it's a missing call, content id call bind to it
     */
    idVisoCall?: string
}
  

export enum MailboxItemType{
    /**
     * This message is a vidéo sent by an user
     */
    MessageVideo = 'MessageVideo',
    /**
     * This message is a missing call notification
     */
    MissingCall = 'MissingCall',
  }