import React from "react";
import {
  IonContent,
  IonPage,
  IonButton,
  IonLabel,
  IonRow,
  IonCol,
  IonIcon,
  IonProgressBar,
} from "@ionic/react";
import "./AccountList.scss";
import { RouteComponentProps } from "react-router";
import Header from "../../components/Header";
import { connect } from "../../data/connect";
import { trashOutline,arrowBackCircleOutline } from "ionicons/icons";
import { UserWithRelationship } from "../../models/UserWithRelationship";
import {
  SupervisorRelationship,
  SupervisorRelationshipState,
} from "../../models/SupervisorRelationship";
import UserItem, { userCardType } from "../../components/UserItem";
import _ from "lodash";
import { loadAndSetUserSupervised, removeSupervisorRequest, removeSupervisorRequestAdmin } from "../../data/supervisor/supervisor.actions";
import { UserSupervised } from "../../models/UserSupervised";
import Button from "../../components/Button";
interface OwnProps extends RouteComponentProps {}

interface StateProps {
  allContacts: UserWithRelationship[];
  supervisorRequests: SupervisorRelationship[];
  userEmail: string;
  loading: boolean;
  currentUserEmail: string;
  supervisedAccount: UserSupervised;
}

interface DispatchProps {
  removeSupervisorRequest: typeof removeSupervisorRequest;
  removeSupervisorRequestAdmin: typeof removeSupervisorRequestAdmin;
  loadAndSetUserSupervised: typeof loadAndSetUserSupervised;
}

interface SupervisorListProps extends OwnProps, StateProps, DispatchProps {}

const SupervisorList: React.FC<SupervisorListProps> = ({
  allContacts,
  supervisorRequests,
  userEmail,
  history,
  loading,
  currentUserEmail,
  supervisedAccount,
  removeSupervisorRequest,
  removeSupervisorRequestAdmin,
  loadAndSetUserSupervised
}) => {
  const userAdministrator = supervisorRequests.filter(
    (m) =>
      m.administered == userEmail &&
      m.state == SupervisorRelationshipState.Accepted
  );
  const removeSupervisor = (administered: string, administrator: string) => {
    const userAdministrator = supervisorRequests.filter(
      (m) =>
        m.administered == userEmail &&
        m.state == SupervisorRelationshipState.Accepted
    );
    if(supervisedAccount){
        removeSupervisorRequestAdmin(administered, administrator);
        loadAndSetUserSupervised(userEmail);
    }else{
        removeSupervisorRequest(administered, administrator);
    }
    if (userAdministrator.length === 1) {
      history.push("/settings/account");
    }
  };
  return (
    <IonPage>
      <Header title={userAdministrator.length === 1 ? "Superviseur du compte": "Superviseurs du compte"} />
      <IonContent id="supervisor-account-list">
        {loading && (
          <div style={{ marginTop: "150px" }}>
            <IonProgressBar type="indeterminate"></IonProgressBar>
          </div>
        )}

        {!loading && userAdministrator.length > 0 && (
          <div>
            <IonRow>
              {userAdministrator.map((contact, i) => {
                const user = _.find(allContacts, (u) => u.email == contact.administrator);
                if (user) {
                  return (
                    <IonCol
                      size="12"
                      size-lg="4"
                      size-md="6"
                      size-sm="12"
                      key={i}
                    >
                      <UserItem
                        user={user}
                        type={userCardType.SupervisorUserList}
                        buttons={
                        <IonRow>
                            <IonCol size="12">
                              <IonButton
                                className="btn-call"
                                color="danger"
                                type="button"
                                onClick={() =>
                                  removeSupervisor(
                                    contact.administered,
                                    contact.administrator
                                  )
                                }
                                expand="block"
                                disabled={user.email === currentUserEmail}
                              >
                                <IonIcon
                                  icon={trashOutline}
                                  className="icon-size"
                                />{" "}
                                Retirer
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        }
                      />
                    </IonCol>
                  );
                }
              })}
            </IonRow>
          </div>
        )}
      </IonContent>
      <IonRow>
          <IonCol size="12">
            <Button
              routerLink="/settings/account"
              color="light"
              text="Retour"
              icon={arrowBackCircleOutline}
            />
          </IonCol>
        </IonRow>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    allContacts: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.annuaire : state.user.annuaire || [],
    supervisorRequests: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.supervisorRequests
      : state.supervisor.requests
      ? state.supervisor.requests
      : [],
    userEmail: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username,
    loading: state.supervisor.loading,
    currentUserEmail: state.user.username || "",
    supervisedAccount: state.supervisor.supervisedAccount
  }),
  mapDispatchToProps: {
    removeSupervisorRequest,
    removeSupervisorRequestAdmin,
    loadAndSetUserSupervised
  },
  component: SupervisorList,
});
