import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { connect } from "../data/connect";
import { setScreenSaveEnable } from "../data/global/global.actions";
import * as selectors from "../data/selectors";
import _ from "lodash";
import "./CreateVisioCall.scss";
import { RouteComponentProps } from "react-router-dom";
import { _MaxParticipantsVisioCall } from "../appConfig";
import { updateLastActivity } from "../data/user/user.actions";
import VisioCall from "../models/VisioCall";
import IdleTimer from "react-idle-timer";
import { UserConfiguration } from "../models/User";
import { menuController } from "@ionic/core";
import { UserSupervised } from "../models/UserSupervised";

interface OwnProps {}

interface StateProps {
  callData: VisioCall;
  haveIncomingCall: boolean;
  isAppReady: boolean;
  /**Current user config */
  config: UserConfiguration;
  showScreensave: boolean;
  supervisedAccount: UserSupervised | undefined;
}

interface DispatchProps {
  setScreenSaveEnable: typeof setScreenSaveEnable;
  updateLastActivity: typeof updateLastActivity;
}

interface IdleTimerPageProps
  extends RouteComponentProps,
    OwnProps,
    StateProps,
    DispatchProps {}

interface OptionalState {}
/**
 * This page manage screensaver by showing it when user is inactive
 * @returns
 */
const IdleTimerPage: React.FC<IdleTimerPageProps> = ({
  supervisedAccount,
  history,
  callData,
  haveIncomingCall,
  isAppReady,
  config,
  showScreensave,
  setScreenSaveEnable,
  updateLastActivity,
}) => {
  const [showLoading, setShowLoading] = useState(false);
  const idleTimer = React.createRef<IdleTimer>();

  useEffect(() => {
    // Case when user click navigation back button (from android bar), intercep event for block go back in url
    document.addEventListener("ionBackButton", (ev: any) => {
      ev.detail.register(10, () => {
        if (showScreensave && idleTimer.current) {
          idleTimer.current.reset();
          setScreenSaveEnable(false);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (idleTimer.current) {
      if (!haveIncomingCall) {
        idleTimer.current.reset();
        setScreenSaveEnable(false);
      }
    }
  }, [callData, haveIncomingCall]);

  return (
    <>
      <Loading enable={!isAppReady || showLoading} text="" />

      {!callData && !haveIncomingCall && (
        /**Time for manage screensaver */

        <IdleTimer
          ref={idleTimer}
          timeout={config.timeoutScreensaver}
          onActive={() => {
            // show loading screen 250ms for avoid click on background element after screensaver hide
            setShowLoading(true);
            setScreenSaveEnable(false);
            setTimeout(() => {
              setShowLoading(false);
            }, 250);
            updateLastActivity();
            if (!supervisedAccount) {
              // always redirect user to home before quit sreensaver
              history.push("/");
              menuController.open();
            }
          }}
          onAction={async () => await updateLastActivity()}
          onIdle={() => {
            setScreenSaveEnable(true);
          }}
          debounce={250}
        />
      )}
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setScreenSaveEnable,
    updateLastActivity,
  },
  mapStateToProps: (state) => ({
    callData: selectors.getVisioCall(state),
    haveIncomingCall:
      !!selectors.getVisioCallWaitingResponse(state) ||
      !!selectors.getVisioCall(state),
    isAppReady: state.data.appReady,
    config: selectors.getUserConfig(state),
    showScreensave: state.global.screenSaverEnable,
    supervisedAccount: state.supervisor.supervisedAccount,
  }),
  component: React.memo(IdleTimerPage),
});