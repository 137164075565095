export interface UserRelationship {
    id:string,
    emailFrom: string;
    emailTo: string
    group: string;
    accepted:boolean;
    dateCreate: string;
    callsCounter: number;
    notifSender: boolean;
    /**
     * Array containing user id from and to. Used for search query (allow us to use array-contains-any in firestore)
     */
    users:string[];
    state?: State;
    listCalls?:string[];
  }

  export interface State {
    date: string;
    state: RelationshipState;
    updatedby?: string,
  }
  export enum RelationshipState {
    
    Waiting = "Waiting",
   
    Accepted = "Accepted",
   
    Refused = "Refused",
  
    Removed = "Removed"
  }
