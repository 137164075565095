import { ActionType } from "../../util/types";
import _ from "lodash";
import { MediaShareRequest } from "../../models/MediaShareRequest";
import {
  acceptMediaShareRequest,
  createMediaShareRequest,
} from "../../api/mediaShareRequest";
import { menuController } from "@ionic/core";
import { toast } from "react-toastify";

export const setRequestsReducer = (requests: MediaShareRequest[]) =>
  ({
    type: "set-media-share-request-list",
    requests,
  } as const);

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-media-share-loading",
    isLoading,
  } as const);

export const createMediaShare =
  (
    senderEmail: string,
    senderName: string,
    receivers: string[],
    fileBase64: string,
    fileExtension: string,
    sizeInKb: number,
    history: any
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoading(true));
    const res = await createMediaShareRequest(
      senderEmail,
      senderName,
      receivers,
      fileBase64,
      fileExtension,
      sizeInKb
    );
    dispatch(setLoading(false));
    if (res) {
      history.push(`/tabs/contacts`);
      menuController.open();
      toast.success("Le fichier a été envoyé avec succès");
    }
  };

export const acceptMediaShare =
  (mediaShareRequest: MediaShareRequest, currentUserName: string) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoading(true));
    await acceptMediaShareRequest(mediaShareRequest, currentUserName);
    dispatch(setLoading(false));
    toast.success("Le fichier a été téléchargé avec succès");
  };

export type MediaShareRequestActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setRequestsReducer>;
