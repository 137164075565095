import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonText,
  IonTitle,
  IonToggle,
} from "@ionic/react";
import { trash } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { connect } from "../data/connect";
import { setShowOffline } from "../data/global/global.actions";
import { loadAndSetUserSupervised } from "../data/supervisor/supervisor.actions";
import { deleteMailboxMessages } from "../data/user/user.actions";
import { MailboxItem, MailboxItemType } from "../models/Mailbox";
import { UserSupervised } from "../models/UserSupervised";
import MessageItem from "./MessageItem";

interface OwnProps {
  messages: MailboxItem[];
  type: string;
  showMessage: (message: MailboxItem) => void;
}

interface StateProps {
  currentUserEmail?:string;
  supervisedAccount?: UserSupervised;
  appOnline: boolean;
}

interface DispatchProps {
  deleteMailboxMessages: typeof deleteMailboxMessages;
  loadAndSetUserSupervised: typeof loadAndSetUserSupervised;
  setShowOffline: typeof setShowOffline;
}
interface SelectMultipleMessagesProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const SelectMultipleMessages: React.FC<SelectMultipleMessagesProps> = ({
  messages,
  type,
  currentUserEmail,
  supervisedAccount,
  appOnline,
  showMessage,
  deleteMailboxMessages,
  loadAndSetUserSupervised,
  setShowOffline
}) => {
  const [selectedMessages, setSelectedMessages] = useState<MailboxItem[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkStatus, setCheckStatus] = useState(2);

  useEffect(() => {
    if (isCheckedAll) {
      setSelectedMessages(messages);
    } else if (!isCheckedAll && checkStatus === 0) {
      setSelectedMessages([]);
    }
  }, [isCheckedAll, checkStatus]);

  useEffect(() => {
    if (selectedMessages.length === 0) {
      setIsCheckedAll(false);
      setCheckStatus(0);
    } else if (selectedMessages.length === messages.length) {
      setIsCheckedAll(true);
      setCheckStatus(1);
    }
  }, [selectedMessages, messages]);

  const checkAllMessages = (e: CustomEvent) => {
    const isCheckedAll = e.detail.checked ? true : false;
    setIsCheckedAll(isCheckedAll);
    if (isCheckedAll) {
      setCheckStatus(1);
    } else {
      setCheckStatus(0);
    }
  };
  const updateListMessagesToDelete = (
    message: MailboxItem,
    checked: boolean
  ) => {
    const indexMessage = selectedMessages.indexOf(message);
    let updatedMessages = selectedMessages;
    if (checked && indexMessage === -1) {
      updatedMessages = [...updatedMessages, message];
    } else if (!checked && indexMessage !== -1) {
      updatedMessages = updatedMessages.filter((msg) => msg !== message);
    }
    setSelectedMessages(updatedMessages);
  };

  const deleteMessages = async () => {
    if (!appOnline) {
      setShowOffline(true);
      return;
    }
    if (currentUserEmail) {
      let successMessage =
        selectedMessages &&
        selectedMessages[0].type === MailboxItemType.MissingCall
          ? "Votre appel manqué a été supprimé"
          : "Votre message enregistré a été supprimé";
      if (selectedMessages.length > 1) {
        successMessage =
          selectedMessages &&
          selectedMessages[0].type === MailboxItemType.MissingCall
            ? "Vos appels manqués ont été supprimés"
            : "Vos messages enregistrés ont été supprimés";
      }
      deleteMailboxMessages(
        currentUserEmail,
        selectedMessages,
        successMessage,
        supervisedAccount
      );
    }
  };
  return (
    <IonGrid>
      <IonRow>
        <IonCol size="12" style={{ marginTop: "20px" }}>
          <IonItem>
            <IonText>Aucun / Tous</IonText>
            <IonToggle
              slot="end"
              checked={isCheckedAll}
              onIonChange={(e) => {
                checkAllMessages(e);
              }}
            />
          </IonItem>
        </IonCol>
        {selectedMessages.length > 0 && (
          <IonCol size="12" style={{ marginTop: "20px" }}>
            <IonItem>
              <IonTitle style={{whiteSpace: "normal", width: "100%"}}>SUPPRIMER LA SÉLECTION</IonTitle>
              <IonIcon
                slot="end"
                icon={trash}
                onClick={deleteMessages}
                style={{ width: "40px", height: "40px", color: "var(--ion-color-primary)" }}
              />
            </IonItem>
          </IonCol>
        )}
        {messages.map((message) => {
          return (
            <IonCol
              key={message.id}
              size="12"
              size-lg="4"
              size-md="6"
              size-sm="6"
              size-xs="12"
              auto-hide="false"
            >
              <MessageItem
                message={message}
                onClick={() => {
                  showMessage(message);
                }}
                checked={isCheckedAll}
                updateListMessagesToDelete={updateListMessagesToDelete}
                title={
                  type === "message" ? "MESSAGE ENREGISTRÉ" : "APPEL MANQUÉ"
                }
              />
            </IonCol>
          );
        })}
      </IonRow>
    </IonGrid>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    deleteMailboxMessages,
    loadAndSetUserSupervised,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    currentUserEmail: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username,
    supervisedAccount: state.supervisor.supervisedAccount,
    appOnline: state.global.appOnline
  }),
  component: React.memo(SelectMultipleMessages),
});
