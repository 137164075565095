import { TextToSpeech } from "@ionic-native/text-to-speech";
import { isPlatform } from "@ionic/core";

/**
 * Read a text with TTS and execute onFinish callback when finish
 * @param text Text to read
 */
export const readText = (text: string, onFinish?: () => void) => {
  if (isPlatform("ios") && !isPlatform("mobileweb")) {
    return TextToSpeech.speak({
      text: text,
      locale: "fr-FR",
      rate: 1.6,
    }).then(() => {
      onFinish && onFinish();
    });
  } else if (isPlatform("android") && !isPlatform("mobileweb")) {
    return TextToSpeech.speak({
      text: text,
      locale: "fr-FR",
    }).then(() => {
      onFinish && onFinish();
    });
  } else {
    const voices = speechSynthesis.getVoices();
    const utterance = new SpeechSynthesisUtterance();
    utterance.voice = voices[0];
    utterance.lang = "fr-FR";
    utterance.text = text;
    speechSynthesis.speak(utterance);
    if (text === "") speechSynthesis.cancel();
    utterance.onend = function (event) {
      //console.log('Utterance has finished being spoken after ' + event.elapsedTime + ' seconds.');
      onFinish && onFinish();
    }
  }
};
