import  VisioCall, { ParticipantState }  from '../models/VisioCall';
import { AppState } from './state';
import _ from 'lodash';
import { SharedFolder } from '../models/SharedFolder';
import { FontClassType, Time, UserConfiguration } from '../models/User';
import { _timeoutBeforeScreensaver } from '../appConfig';
import { defaultPicture } from '../util/commons';
import { UserWithRelationship } from '../models/UserWithRelationship';
import { SupervisorRelationshipState } from '../models/SupervisorRelationship';


/**
 * Get all users in annuaire (all user that current user is in friendship)
 * @param state 
 */
export const getUsers = (state: AppState) => {

    const username = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username;
    let annuaire = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.annuaire : state.user.annuaire;

    return annuaire ? annuaire.filter((user) => user.relationships.some((relation) => relation.users.some((u) => u === username) && relation.accepted)) : [];
 };

 /**
 * Get all supervised users in annuaire (all user that current user is in friendship or waiting)
 * @param state 
 */
export const getSupervisedUsers = (state: AppState) => {

  if(state.supervisor.supervisedAccount){
    const username = state.supervisor.supervisedAccount.user.email;
    let annuaire = state.supervisor.supervisedAccount.annuaire;
    return annuaire ? annuaire.filter((user) => user.relationships.some((relation) => relation.users.some((u) => u === username)&& (relation.accepted || !relation.accepted && relation.emailFrom ===  username))) : [];
  }
  return []; 
};

/**
 * Get all users in annuaire filtered by number of calls (all user that current user is in friendship)
 * @param state 
 */
 export const getUsersFilteredByNumberOfCalls = (state: AppState) => {
  const users = getUsers(state);

  const usersFiltered = _.reverse(
    _.sortBy(users, (user) => {
      if (user.relationships && user.relationships.length > 0 && user.relationships[0].callsCounter)
        return user.relationships[0].callsCounter;
      else return 0;
    })
  );
  return usersFiltered;
};


export const getUserConfig = (state: AppState) => {
  let configDefault = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.config : state.user.userFirestore ? state.user.userFirestore.config : { 
    emergencyContacts: []
  } as UserConfiguration;

  return {
    ...configDefault,
    fontClass: getFontSizeConfig(state),
    timeoutScreensaver: getTimeoutScreenSaverConfig(state),
    timeoutEnableScreenSaverStart:  getTimeoutEnableScreenSaverStartConfig(state),
    timeoutEnableScreenSaverEnd: getTimeoutEnableScreenSaverEndConfig(state),
    soundEnabled: getsoundEnabledConfig(state),
    alarmClockRingtone: getAlarmClockRingtoneConfig(state),
    alarmClockVolume: getAlarmClockVolumeConfig(state),
    subtitling: getSubtitlingConfig(state),
  } as UserConfiguration; 
};

 export const getFontSizeConfig = (state: AppState) => {
    const user = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore;
    return user && user.config && user.config.fontClass ? user.config.fontClass : FontClassType.medium;
 };

 export const getFontSizeConfigCurrentUser = (state: AppState) => {
   if(!state.supervisor.supervisedAccount){
    const user = state.user.userFirestore;
    return user && user.config && user.config.fontClass ? user.config.fontClass : FontClassType.medium;
   }
   return "";
};

 export const getsoundEnabledConfig = (state: AppState) => {
  const user = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore;
  return user && user.config && typeof user.config.soundEnabled != 'undefined' ? user.config.soundEnabled : true;
};

export const getAlarmClockRingtoneConfig = (state: AppState) => {
  const user = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore;
  return user && user.config && typeof user.config.alarmClockRingtone != 'undefined' ? user.config.alarmClockRingtone : "";
};

export const getAlarmClockVolumeConfig = (state: AppState) => {
  const user = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore;
  return user && user.config && typeof user.config.alarmClockVolume != 'undefined' ? user.config.alarmClockVolume : 80;
};

 export const getTimeoutScreenSaverConfig = (state: AppState) => {
  const user = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore;
  return user && user.config && user.config.timeoutScreensaver ? user.config.timeoutScreensaver : _timeoutBeforeScreensaver;
};

export const getTimeoutEnableScreenSaverStartConfig = (state: AppState) => {
  const user = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore;
  return user && user.config && user.config.timeoutEnableScreenSaverStart ? user.config.timeoutEnableScreenSaverStart : { hours:8, minutes:0 } as Time as Time;
};

export const getTimeoutEnableScreenSaverEndConfig = (state: AppState) => {
  const user = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore;
  return user && user.config && user.config.timeoutEnableScreenSaverEnd ? user.config.timeoutEnableScreenSaverEnd : { hours:21, minutes:30 } as Time;
};

export const getSubtitlingConfig = (state: AppState) => {
  const user = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore;
  return user && user.config && typeof user.config.subtitling != 'undefined' ? user.config.subtitling : true;
};



export const getIsHelpedProfil = (state: AppState) => {
  return state.user.userFirestore && state.user.userFirestore.isHelpedProfil ? true : false;
};

/*
* Visio call selectors
*/

//export const getVisioCall  = (state: AppState) => state.visioCall as VisioCall;
export const getVisioCall  = function(state: AppState) 
{
  return state.visioCall.roomData as VisioCall
}

/**
 * Return visio call that need a response from current user (accept or refuse call). 
 * @param state 
 */
export const getVisioCallWaitingResponse = function(state: AppState) 
{
  const visioCallFind = state.visioCall.roomData as VisioCall;
  if(visioCallFind && _.some(visioCallFind.participantStates, (call) => call.email == state.user.username && call.state == ParticipantState.Waiting))
    return visioCallFind;
  else
    return null
  
}


/**
 * Count number of shared file that current user not saw
 * @param state 
 */
export const getNewSharedFileCountTotal= function (state: AppState) {
  const currentUserMail = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username;
  const folders = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.folders : state.sharedFolder.folders;
  const countTotal =  folders.map((folder) => {
    const result = folder.files.map((file) =>  {
      return _.some(file.viewBy, (user) => user == currentUserMail) ? 0 : 1 
    });
    
    return _.sum(result);
  })

   return _.sum(countTotal)
}

/**
 * Count number of comments that current user not saw
 * @param state 
 */
 export const getNewCommentsCountTotal = function (state: AppState) {
  const folders = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.folders : state.sharedFolder.folders;
  const countTotal =  folders.map((folder) => {
    return getNewCommentsCountByFolder(state, folder);
  });
  return _.sum(countTotal)
}

/**
 * Count number of comments by folder that current user not saw
 * @param state 
 */
 export const getNewCommentsCountByFolder = function (state: AppState, folder: SharedFolder) {
  const currentUserMail = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username;
  const countTotal =  folder.files.map((file) => {
    if (file.comments) {
      const commentsNotRead = _.filter(
        file.comments,
        (comment) =>
          comment.senderEmail !== currentUserMail &&
          !_.some(comment.viewBy, (viewBy) => viewBy === currentUserMail)
      );
      return commentsNotRead.length;
    }
  })

  return _.sum(countTotal)
}

/**
 * Count number of albums that current user not saw
 * @param state 
 */
 export const getNewAlbumsCountTotal = function (state: AppState) {
  const currentUserMail = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username;
  const folders = state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.folders : state.sharedFolder.folders;
  const countTotal =  folders.map((folder) => {
    if(folder.notViewBy){
      return _.some(folder.notViewBy, (user) => user == currentUserMail) && folder.creator !==  currentUserMail ? 1 : 0
    }else{
      return 0;
    }
  });

   return _.sum(countTotal)
}

export const getNewSharedFileCountByFolder= function (folder: SharedFolder, currentUser: string) {

  
  const countTotal =  folder.files.map((file) =>  {
      return _.some(file.viewBy, (user) => user == currentUser) ? 0 : 1 
    });
    return _.sum(countTotal);
}


const getIdParam = (_state: AppState, props: any) => {
  return props.match.params['id'];
}

/**
 * Get user picture 
 */
export function getPictureUser(annuaire: UserWithRelationship[], mail: string) {
  const userData = _.find(annuaire, (u) => u.email === mail);
  return userData ? userData.picture : defaultPicture;
}

/**
  * Return user name (firstname + lastname) or email if user not exist in annuaire
*/
export function getUserName(annuaire: UserWithRelationship[], mail: string) {
  const userData = _.find(annuaire, (u) => u.email === mail);
  let name = mail;
  if (userData) name = userData.firstName + " " + userData.lastName;
  return name;
}

/**
 * Return all supervisor request waiting current user validation
 * @param state 
 * @returns 
 */
export const getSupervisorRequestWaiting = function(state: AppState) 
{
  return state.supervisor.requests.find(m => m.state == SupervisorRelationshipState.Waiting && m.administered == state.user.username);
}

/**
 * Return all media share request waiting current user validation
 * @param state
 * @returns
 */
 export const getMediaShareRequests = function (state: AppState) {
  return state.mediaShareRequest.requests.find(
    (request) =>
      state.user.username && request.receivers.includes(state.user.username)
  );
};