import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { IonButton, IonCol, IonInput, IonItem, IonLabel, IonList, IonRow, IonText } from '@ionic/react';
import firebase from '../FirebaseConfig';



interface onValidCodeFunction { (user: firebase.User): void; }
interface onLoadingFunction { (enable: boolean): void; }

interface SmsCodeValidationFormProps {
    onValidCode: onValidCodeFunction;
    onLoading: onLoadingFunction;
    onCancel: () =>  void;
    firebasePhoneCodeValidation: firebase.auth.ConfirmationResult;
}

const SmsCodeValidationForm: React.FC<SmsCodeValidationFormProps> = ({ onValidCode, onCancel, onLoading, firebasePhoneCodeValidation}) => {

    const [codeReceived, setCodeReceived] = useState('');
    const [codeReceivedFailed, setCodeReceivedFailed] = useState('');

    useEffect(() => {

    }, []);

    const validateCode = async (e: React.FormEvent) => {
        e.preventDefault();
        if(!codeReceived)setCodeReceivedFailed("Veuillez renseigner un code valide");
        else {
            if(firebasePhoneCodeValidation && codeReceived)
            {
                onLoading(true);
                firebasePhoneCodeValidation.confirm(codeReceived).then(async (result) => {
                    // User signed in successfully.
                    //const user = result.user;

                    const userFirebase = firebase.auth().currentUser;
                    if(userFirebase)
                    {
                        onValidCode(userFirebase);
                    }
                    else
                    {
                        alert("User invalide (null)");
                        onLoading(false);
                    }
                }).catch((error) => {
                    onLoading(false);
                    setCodeReceivedFailed("Le code renseigné n'est pas valide");
                });
            }
        }
      }

    return (<form noValidate onSubmit={validateCode}>
        <IonList>
          <IonText >
            <p className="ion-padding-start" style={{ textAlign: "center" }}>
              Pour vous connecter de façon sécurisée, veuillez saisir le code reçu par SMS
            </p>
          </IonText>

          {codeReceivedFailed && <IonText color="danger">
            <p className="ion-padding-start" style={{ textAlign: "center" }}>
              {codeReceivedFailed}
            </p>
          </IonText>}

          {/** username */}
          <IonItem>
            <IonLabel position="stacked" color="primary"><IonText>Code SMS</IonText></IonLabel>
            <IonInput name="code-receive" type="text" value={codeReceived} spellCheck={false} autocapitalize="off" onIonChange={e => setCodeReceived(e.detail.value!)}
              required>
            </IonInput>
          </IonItem>
          <br /><br />
        </IonList>

        <IonRow>
          <IonCol size="12">
            <IonButton id="" type="submit" expand="block">Valider</IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <IonButton type="button" onClick={() => onCancel()} color="light" expand="block">Retour</IonButton>
          </IonCol>
        </IonRow>
      </form>);
};

export default SmsCodeValidationForm
