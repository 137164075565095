import { GlobalActions } from "./global.actions";
import { GlobalState } from "./global.state";

export function globalReducer(
  state: GlobalState,
  action: GlobalActions
): GlobalState {
  switch (action.type) {
    case "set-toast-notification":
      return { ...state, notification: action.toast };
    case "set-screensaver-enable":
      return { ...state, screenSaverEnable: action.enable };
    case "set-hide-emergency-command":
      return { ...state, hideEmergencyCommand: action.hide };
    case "set-search-term":
      return { ...state, searchTerm: action.searchTerm };
    case "set-userIsActive":
      return { ...state, userIsActive: action.userIsActive };
    case "set-AppIsActive":
      return { ...state, appIsActive: action.active };
    case "set-AppOnline":
      return { ...state, appOnline: action.appOnline };
    case "set-ShowOffline":
      return { ...state, showOffline: action.showOffline };
  }
}
