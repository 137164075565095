import {
  IonDatetime,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "../../data/connect";
import BottomButtons from "../BottomButtons";
import * as selectors from "../../data/selectors";
import { User, UserConfiguration } from "../../models/User";
import { setShowOffline, setToastNotification } from "../../data/global/global.actions";
import { ToastNotification } from "../../models/ToastNotification";
import { updateUser } from "../../data/user/user.actions";
import _ from "lodash";
import Header from "../Header";
import { UserSupervised } from "../../models/UserSupervised";
interface OwnProps {
  showScreenSaverSettings: (show: boolean) => void;
}

interface StateProps {
  config: UserConfiguration;
  supervisedAccount: UserSupervised | null;
  currentUser?: User;
  appOnline: boolean;
}

interface DispatchProps {
  setShowOffline: typeof setShowOffline;
  updateUser: typeof updateUser;
}
interface ScreenSaverSettingsProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ScreenSaverSettings: React.FC<ScreenSaverSettingsProps> = ({
  config,
  supervisedAccount,
  currentUser,
  appOnline,
  showScreenSaverSettings,
  updateUser,
  setShowOffline
}) => {
  const [timeoutScreensaver, setTimeoutScreensaver] = useState(config.timeoutScreensaver);
  const [dateStart, setDateStart] = useState<Date>();
  const [dateEnd, setDateEnd] = useState<Date>();

  useEffect(() => {
    setTimeoutScreensaver(config.timeoutScreensaver)
  }, [config.timeoutScreensaver])

  useEffect(() => {
    let dateStart = new Date();
    dateStart.setHours(
      config.timeoutEnableScreenSaverStart
        ? config.timeoutEnableScreenSaverStart.hours
        : 8
    );
    dateStart.setMinutes(
      config.timeoutEnableScreenSaverStart
        ? config.timeoutEnableScreenSaverStart.minutes
        : 0
    );
    setDateStart(dateStart)
  }, [config.timeoutEnableScreenSaverStart?.hours, config.timeoutEnableScreenSaverStart?.minutes])

  useEffect(() => {
    let dateEnd = new Date();
    dateEnd.setHours(
      config.timeoutEnableScreenSaverEnd
        ? config.timeoutEnableScreenSaverEnd.hours
        : 8
    );
    dateEnd.setMinutes(
      config.timeoutEnableScreenSaverEnd
        ? config.timeoutEnableScreenSaverEnd.minutes
        : 0
    );
    setDateEnd(dateEnd);
  }, [config.timeoutEnableScreenSaverEnd?.hours, config.timeoutEnableScreenSaverEnd?.minutes])

  const cancelHandler = () => {
    showScreenSaverSettings(false);
  };

  const validateHandler = async () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    // check if time start < time end for screensaver
    if (
      dateStart &&
      dateEnd
    ) {

      if (dateStart > dateEnd) {
        setToastNotification({
          message: "l'heure de début doit être antérieure à l'heure de fin",
          onClickMessage: () => {},
          color: "danger",
          duration: 3000,
          buttons: [],
        } as ToastNotification);

        return;
      }
    }
    if(currentUser){
      const userConfig = {... config} as UserConfiguration;
      userConfig.timeoutScreensaver = timeoutScreensaver;
      if(dateStart) userConfig.timeoutEnableScreenSaverStart = {hours: dateStart.getHours(), minutes: dateStart.getMinutes()};
      if(dateEnd) userConfig.timeoutEnableScreenSaverEnd = {hours: dateEnd.getHours(), minutes: dateEnd.getMinutes()};
      currentUser.config = _.clone(userConfig);
      updateUser(currentUser, supervisedAccount, "Modifications enregistrées");
    }
    showScreenSaverSettings(false);
  };



  return (
    config && (
      <IonModal
        cssClass="modal-sreensaver-settings"
        isOpen={true}
        onDidDismiss={() => showScreenSaverSettings(false)}
      >
        <Header action={cancelHandler} title="Écran de veille" showHelp/>
        <div style={{ flexGrow: 1, overflow: "scroll" }}>
          <IonRadioGroup
            value={timeoutScreensaver}
            onIonChange={(e) =>
              setTimeoutScreensaver(e.detail.value as number)
            }
          >
            <IonListHeader className="option-header">
              <IonLabel>
                Délais d'inactivité avant l'affichage de l'écran de veille
              </IonLabel>
            </IonListHeader>
            <IonItem>
              <IonLabel>30 secondes</IonLabel>
              <IonRadio slot="start" value={30000} />
            </IonItem>

            <IonItem>
              <IonLabel>1 minute</IonLabel>
              <IonRadio slot="start" value={60000} />
            </IonItem>

            <IonItem>
              <IonLabel>5 minutes</IonLabel>
              <IonRadio slot="start" value={300000} />
            </IonItem>

            <IonItem>
              <IonLabel>10 minutes</IonLabel>
              <IonRadio slot="start" value={600000} />
            </IonItem>
          </IonRadioGroup>
          <IonList>
            <IonListHeader className="option-header">
              <IonLabel>
                L'écran de veille sera activé dans l'intervalle horaire suivant:
              </IonLabel>
            </IonListHeader>
            <IonItem>
              <IonLabel>Début de journée</IonLabel>
              <IonDatetime
                display-format="HH:mm"
                cancel-text="annuler"
                done-text="valider"
                picker-format="HH:mm"
                value={dateStart ? dateStart.toISOString(): null}
                onIonChange={(e) => {
                  setDateStart(new Date(e.detail.value as string))
                }}
              ></IonDatetime>
            </IonItem>

            <IonItem>
              <IonLabel>Fin de journée</IonLabel>
              <IonDatetime
                cancel-text="annuler"
                done-text="valider"
                display-format="HH:mm"
                picker-format="HH:mm"
                value={dateEnd ? dateEnd.toISOString(): null}
                onIonChange={(e) => {
                  setDateEnd(new Date(e.detail.value as string))
                }}
              ></IonDatetime>
            </IonItem>
          </IonList>
        </div>

        <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
          <BottomButtons
            onCancel={cancelHandler}
            onValidate={validateHandler}
          />
        </div>
      </IonModal>
    )
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    updateUser,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    config: selectors.getUserConfig(state),
    supervisedAccount: state.supervisor.supervisedAccount || null, 
    currentUser: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore,
    appOnline: state.global.appOnline
  }),
  component: React.memo(ScreenSaverSettings),
});
