import React from "react";
import { IonPage } from "@ionic/react";
import Header from "../components/Header";
import SpeakerList from "../components/SpeakerList";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";

interface OwnProps {}

interface StateProps {}

interface ContactsProps extends RouteComponentProps, OwnProps, StateProps {}

const Contacts: React.FC<ContactsProps> = () => {
  return (
    <IonPage>
      <Header title="Contacts" showHelp />
      <SpeakerList />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: React.memo(Contacts),
});
