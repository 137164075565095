import { IonButton, IonIcon, IonModal } from "@ionic/react";
import { cameraOutline, imagesOutline, pencil } from "ionicons/icons";
import React, { useState } from "react";
import { connect } from "../../data/connect";
import { CameraResultType, CameraSource, Camera } from "@capacitor/camera";
import { toBase64 } from "../../util/commons";
import _ from "lodash";
import Header from "../Header";
import EditImage from "./EditImage";

interface OwnProps {
  fileFormat: string;
  fileData: string;
  showChoosePicture: (show: boolean) => void;
  setFileFormat: (format: string) => void;
  setFileData: (data: string) => void;
}
interface StateProps {}
interface DispatchProps {}
interface ChoosePictureModalProps extends OwnProps, StateProps, DispatchProps {}

const ChoosePictureModal: React.FC<ChoosePictureModalProps> = ({
  fileFormat,
  fileData,
  showChoosePicture,
  setFileFormat,
  setFileData,
}) => {
  const [showEditImage, setShowEditImage] = useState(false);
  const inputFile = React.createRef<HTMLInputElement>();
  /*
   * take picture : this function open the camera or the gallery and take/choose picture
   */
  async function takePicture(type: CameraSource) {
    if (type == CameraSource.Photos) {
      if (inputFile.current) inputFile.current.click();
    } else {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        source: CameraSource.Camera,
        resultType: CameraResultType.Base64,
        promptLabelHeader: "Sélectionner une photo",
        promptLabelCancel: "Annuler",
        promptLabelPhoto: "Depuis la galerie",
        promptLabelPicture: "Depuis l'appareil photo",
      });
      showChoosePicture(false);
      if (image.base64String) {
        setFileData(image.base64String);
        setFileFormat(image.format);
      }
    }
  }

  async function onSelectFile(e: any) {
    if (e.target.files[0] && e.target.files[0].type) {
      const file = e.target.files[0];
      const base64 = (await toBase64(file)) as string;
      showChoosePicture(false);
      if (base64) {
        const format = _.last(_.split(file.type, "/"));
        setFileData(base64.split(",")[1]);
        setFileFormat(format || "");
      }
    }
  }

  return (
    <IonModal
      isOpen={true}
      cssClass="add-friend-modal"
      onDidDismiss={() => showChoosePicture(false)}
    >
      <Header hideHome title="Choisir une photo" />
      <input
        ref={inputFile}
        style={{ display: "none" }}
        type="file"
        id="file"
        multiple={false}
        onChange={onSelectFile}
      />
      <IonButton onClick={() => takePicture(CameraSource.Camera)}>
        <IonIcon
          icon={cameraOutline}
          style={{ marginRight: "10px" }}
          className="icon-size"
        />
        <b>Depuis l'appareil photo</b>{" "}
      </IonButton>
      <IonButton onClick={() => takePicture(CameraSource.Photos)}>
        <IonIcon
          icon={imagesOutline}
          style={{ marginRight: "10px" }}
          className="icon-size"
        />
        <b>Depuis la galerie</b>
      </IonButton>
      {fileData && fileFormat && (
        <IonButton onClick={() => setShowEditImage(true)}>
          <IonIcon
            icon={pencil}
            style={{ marginRight: "10px" }}
            className="icon-size"
          />
          <b>Modifier l'image</b>
        </IonButton>
      )}
      <IonButton onClick={() => showChoosePicture(false)} color="light">
        Annuler
      </IonButton>
      {showEditImage && (
        <EditImage
          onCancel={() => {
            setShowEditImage(false);
            showChoosePicture(false);
          }}
          src={`data:image/${fileFormat};base64,${fileData}`}
          setImageFormat={setFileFormat}
          setImageData={setFileData}
        />
      )}
    </IonModal>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {},
  mapStateToProps: (state) => ({}),
  component: React.memo(ChoosePictureModal),
});
