import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "../../data/connect";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCheckbox,
  IonIcon,
} from "@ionic/react";
import _ from "lodash";
import "../UserItem.css";
import { readFileFromCache } from "../../util/localStorage";
import { ContactGroup } from "../../models/ContactGroup";
import {
  deleteContactGroup,
  quitContactGroup,
  updateLastActivity,
} from "../../data/user/user.actions";
import { startCall } from "../../data/visioCall/visioCall.actions";
import { exitOutline, trashOutline } from "ionicons/icons";
import { UserSupervised } from "../../models/UserSupervised";
import * as selectors from "../../data/selectors";
import { UserWithRelationship } from "../../models/UserWithRelationship";
import { User } from "../../models/User";
import "./ContactGroup.scss";
import { setShowOffline } from "../../data/global/global.actions";

export enum groupCardType {
  /**
   * Show call button
   */
  Annuaire = 0,
  /**
   * Show details
   */
  Details = 1,
  /**
   * Show select
   */
  Select = 2,
}

interface StateProps {
  currentUserEmail: string;
  supervisedAccount: UserSupervised | null;
  users: UserWithRelationship[];
  currentUser: User | undefined;
  appOnline: boolean;
}

interface OwnProps {
  group: ContactGroup;
  type: groupCardType;
  isCheckedAll?: boolean;
  initialChecked?: boolean;
  removeGroup?: boolean;
  checkGroup?: (group: ContactGroup, checked: boolean) => void;
  resetGroupToRemove?: () => void;
}

interface DispatchProps {
  updateLastActivity: typeof updateLastActivity;
  startCall: typeof startCall;
  quitContactGroup: typeof quitContactGroup;
  deleteContactGroup: typeof deleteContactGroup;
  setShowOffline: typeof setShowOffline;
}

interface ContactGroupItemProps
  extends RouteComponentProps,
    DispatchProps,
    OwnProps,
    StateProps {}

const ContactGroupItem: React.FC<ContactGroupItemProps> = ({
  group,
  users,
  currentUser,
  type,
  history,
  currentUserEmail,
  removeGroup,
  supervisedAccount,
  isCheckedAll = false,
  initialChecked = false,
  appOnline,
  updateLastActivity,
  startCall,
  checkGroup,
  resetGroupToRemove,
  quitContactGroup,
  deleteContactGroup,
  setShowOffline
}) => {
  const classes =
    type === groupCardType.Select
      ? initialChecked
        ? "speaker-card selected-card"
        : "speaker-card"
      : "speaker-card border-orange";
  const userPicture = group.picture ? group.picture : "";
  const members = users.filter(
    (user) => user && group.members.includes(user.email)
  );

  members.push(currentUser as UserWithRelationship);
  const membersGroup = members.map((m) => {
    return m.firstName + " " + m.lastName[0];
  });
  const [src, setSrc] = useState("");
  const [checked, setChecked] = useState(false);
  const [cardClasses, setCardClasses] = useState(classes);

  const isCreator = group.creator === currentUserEmail;

  useEffect(() => {
    readFileFromCache(userPicture, setSrc);
  }, [userPicture]);

  useEffect(() => {
    setChecked(isCheckedAll);
  }, [isCheckedAll]);

  useEffect(() => {
    if (initialChecked) {
      setChecked(true);
    }
  }, [initialChecked]);

  useEffect(() => {
    if (removeGroup) {
      setChecked(false);
      checkGroup && checkGroup(group, false);
      if (resetGroupToRemove) resetGroupToRemove();
    }
  }, [removeGroup]);

  const changeListGroupsHandler = (e: CustomEvent, group: ContactGroup) => {
    if (!e.detail.checked) {
      setChecked(false);
      checkGroup && checkGroup(group, false);
      setCardClasses("speaker-card");
    } else if (e.detail.checked) {
      setChecked(true);
      checkGroup && checkGroup(group, true);
      setCardClasses("speaker-card selected-card");
    }
  };

  const onDeleteOrQuitContactGroup = () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    if (isCreator) {
      deleteContactGroup(group, currentUserEmail, supervisedAccount);
    } else {
      quitContactGroup(group, currentUserEmail, supervisedAccount);
    }
  };

  const onClickHandler = async () => {
    if (type === groupCardType.Details) {
      history.push("/tabs/settings/contacts/groups/" + group.id);
    } else if (type === groupCardType.Annuaire) {
      if (supervisedAccount) {
        return;
      }
      try {
        if (group && currentUser) {
          let usersToCall = group.members.filter(
            (user) => user !== currentUserEmail
          );
          await startCall(usersToCall, currentUser, [group.id]);
          await updateLastActivity();
        }
        history.push({
          pathname: "/tabs/conference/video",
          state: {
            isGroup: true,
            groupName: group.name,
            groupPicture: group.picture,
          },
        });
      } catch (e) {
        alert("Exception userItems>callUser(): " + JSON.stringify(e));
      }
    } else if (type === groupCardType.Select) {
      setChecked((current) => !current);
    } else {
      return;
    }
  };

  return (
    <IonCard className={cardClasses} style={{ marginTop: "30px" }}>
      <IonCardHeader
        style={{
          height: "350px",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundImage: group.picture
            ? "url(" + src + ")"
            : "url(assets/img/group.png)",
        }}
        onClick={onClickHandler}
      >
        <div className={"card-header-text"}>
          {group.name}

          {type === groupCardType.Select && (
            <IonCheckbox
              style={{ float: "right" }}
              slot="end"
              value={group.id}
              onClick={(e) => e.stopPropagation()}
              onIonChange={(e) => changeListGroupsHandler(e, group)}
              checked={checked}
            />
          )}
          <div className="time-ago-label">
            <p className="list-members">
              <small>{membersGroup.join(", ")}</small>
            </p>
          </div>

        </div>
      </IonCardHeader>
      {supervisedAccount && type !== groupCardType.Details && (
        <IonCardContent>
          <IonButton
            className="btn-remove-friend"
            type="button"
            color="danger"
            onClick={onDeleteOrQuitContactGroup}
            expand="block"
          >
            <IonIcon
              icon={isCreator ? trashOutline : exitOutline}
              className="icon-size"
            />{" "}
            {isCreator ? "Retirer" : "Quitter"}
          </IonButton>
        </IonCardContent>
      )}
    </IonCard>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    updateLastActivity,
    startCall,
    quitContactGroup,
    deleteContactGroup,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    currentUserEmail: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user.email
      : state.user.username
      ? state.user.username
      : "",
    supervisedAccount: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount
      : null,
    users: selectors.getUsers(state) || [],
    appOnline: state.global.appOnline,
    currentUser: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore,
  }),
  component: withRouter(ContactGroupItem),
});
