import {
  IonItem,
  IonModal,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "../../data/connect";
import BottomButtons from "../BottomButtons";
import * as selectors from "../../data/selectors";
import { FontClassType, User, UserConfiguration } from "../../models/User";
import { setShowOffline } from "../../data/global/global.actions";
import { updateUser } from "../../data/user/user.actions";
import _ from "lodash";
import Header from "../Header";
import classes from './FontSizeSettings.module.scss';
import { UserSupervised } from "../../models/UserSupervised";
interface OwnProps {
  showFontSizeSettings: (show: boolean) => void;
}

interface StateProps {
  config: UserConfiguration;
  supervisedAccount: UserSupervised | null;
  appOnline: boolean;
  currentUser?: User;
}

interface DispatchProps {
  setShowOffline: typeof setShowOffline;
  updateUser: typeof updateUser;
}
interface FontSizeSettingsProps extends OwnProps, StateProps, DispatchProps {}

const FontSizeSettings: React.FC<FontSizeSettingsProps> = ({
  config,
  supervisedAccount,
  appOnline,
  currentUser,
  showFontSizeSettings,
  setShowOffline,
  updateUser
}) => {
 

  const cancelHandler = () => {
    showFontSizeSettings(false);
  };

  const [fontClassInput, setFontClassInput] = useState<FontClassType | undefined>(undefined);
  useEffect(() => {
      setFontClassInput(config.fontClass);
  }, [config.fontClass])
  const validateHandler = async () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    if(currentUser){
      const userConfig = {... config} as UserConfiguration;
      userConfig.fontClass = fontClassInput;
      currentUser.config = _.clone(userConfig);
      updateUser(currentUser, supervisedAccount, "Modifications enregistrées");
    }
    showFontSizeSettings(false);
  };

  return (
    config && (
      <IonModal isOpen={true} onDidDismiss={() => showFontSizeSettings(false)} cssClass="modal-font-size-settings">
        <Header action={cancelHandler} title="Taille de la police" showHelp/>
        <IonRadioGroup
          style={{ flexGrow: 1, padding: "20px" }}
          value={fontClassInput}
          onIonChange={(e) =>{
            setFontClassInput(e.detail.value as FontClassType)
          }
          }
        >
          <IonItem>
            <p className={classes.small}>Petite</p>
            <IonRadio slot="start" value={FontClassType.small} id="small" />
          </IonItem>

          <IonItem>
            <p className={classes.medium}>Moyenne</p>
            <IonRadio slot="start" value={FontClassType.medium} id="medium" />
          </IonItem>

          <IonItem>
            <p className={classes.large}>Grande</p>
            <IonRadio slot="start" value={FontClassType.large} id="large" />
          </IonItem>
        </IonRadioGroup>
        <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
          <BottomButtons
            onCancel={cancelHandler}
            onValidate={validateHandler}
          />
        </div>
      </IonModal>
    )
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setShowOffline,
    updateUser
  },
  mapStateToProps: (state) => ({
    config: selectors.getUserConfig(state),
    supervisedAccount: state.supervisor.supervisedAccount || null, 
    appOnline: state.global.appOnline,
    currentUser: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore,
  }),
  component: React.memo(FontSizeSettings),
});
