import { ActionType } from "../../util/types";
import { UserState } from "./user.state";
import { User, UserConfiguration } from "../../models/User";
import { UserWithRelationship } from "../../models/UserWithRelationship";
import { UserRelationship, RelationshipState, State } from "../../models/UserRelationship";
import { setAppReady } from "../sessions/sessions.actions";
import { audioType } from "../../components/AudioPlayer";
import { Mailbox, MailboxItem, MailboxItemType } from "../../models/Mailbox";
import { Device } from "@capacitor/device";
import _ from "lodash";
import firebase from "firebase";
import { ContactGroup } from "../../models/ContactGroup";
import { UserSupervised } from "../../models/UserSupervised";
import { setUserSupervised } from "../supervisor/supervisor.actions";
import { toast } from "react-toastify";
import {
  getUser,
  getUserData,
  setHasSeenTutorialData,
  setIsLoggedInData,
  setUsernameData,
  updateUserRequest,
  uploadUserPictureRequest,
} from "../../api/user";
import {
  acceptFriendRequest,
  createFriendRequest,
  getAnnuaireUsers,
  removeFriendRequest,
  viewAcceptFriendRequest,
  viewAcceptSupervisionRequest,
  searchAnnuaireUsers,
} from "../../api/userRelationShip";
import { deleteFileFromStorageRequest } from "../../util/firebaseStorage";
import {
  getMailboxCurrentUserRequest,
  updateMailboxCurrentUserRequest,
} from "../../api/mailbox";
import {
  createContactGroupRequest,
  deleteContactGroupRequest,
  updateContactGroupRequest,
  uploadGroupPictureRequest,
} from "../../api/contactGroup";
import { getSupervisedUserData } from "../../api/supervisorRelationship";
import { sendMailRequest, uploadMailFileRequest } from "../../api/mail";
import { createGuid } from "../../util/commons";
import { FileData } from "../../models/FileData";
import { Attachment } from "../../models/Mail";

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getUserData();
  if (data) dispatch(setData(data));
  dispatch(setLoading(false));
  dispatch(setAppReady(true));
};

export const loadAnnuaire =
  (currentUserName: string, loading = true) =>
    async (dispatch: React.Dispatch<any>) => {
      if (loading) dispatch(setLoadingAnnuaire(true));
      const data = await getAnnuaireUsers(currentUserName);
      dispatch(setAnnuaireUsers(data));
      if (loading) dispatch(setLoadingAnnuaire(false));
    };

export const searchUser =
  (currentUserName: string, searchText: string) =>
    async (dispatch: React.Dispatch<any>) => {
      dispatch(setLoadingSearch(true));
      dispatch(setSearchUserFiltered([]));
      searchAnnuaireUsers(currentUserName, searchText)
        .then((results) => {
          results = _.reverse(
            _.sortBy(results, (user) => {
              if (
                user.relationships &&
                user.relationships.length > 0 &&
                user.relationships[0].callsCounter
              )
                return user.relationships[0].callsCounter;
              else return 0;
            })
          );
          dispatch(setSearchUserFiltered(results));
        })
        .finally(() => {
          dispatch(setLoadingSearch(false));
        });
    };

export const setSearchUserFiltered = (users: UserWithRelationship[]) =>
({
  type: "set-user-search-filtered",
  users,
} as const);

export const addFriend =
  (emailFrom: string, emailTo: string, group: string) =>
    async (dispatch: React.Dispatch<any>) => {
      const result = await createFriendRequest({
        emailFrom: emailFrom,
        emailTo: emailTo,
        accepted: false,
        group: group,
        users: [emailFrom, emailTo],
        notifSender: false,
        dateCreate: new Date().toISOString(),
        state: {
          date: new Date().toISOString(),
          state: RelationshipState.Waiting
        } as State
      } as UserRelationship);
      return result;
    };

export const removeFriend =
  (
    currentUserName: string,
    userName: string,
    supervisedAccount: UserSupervised | null,
    isRefused: boolean,
  ) =>
    async (dispatch: React.Dispatch<any>) => {
      const result = await removeFriendRequest(
        currentUserName,
        userName,
        supervisedAccount,
        isRefused
      );
      return result;
    };

export const acceptFriend = (idRelationship: string) => async (dispatch: React.Dispatch<any>) => {
  const result = await acceptFriendRequest(idRelationship);
  return result;
}
export const notifAcceptFriend = (idRelationship: string) => async (dispatch: React.Dispatch<any>) => {
  const result = await viewAcceptFriendRequest(idRelationship);
  return result;
}
export const notifAcceptSpervision = (idRelationship: string) => async (dispatch: React.Dispatch<any>) => {
  const result = await viewAcceptSupervisionRequest(idRelationship);
  return result;
}




/**
 * Update last activity date for current user
 */
export const updateLastActivity = () => async () => {
  const currentUser = firebase.auth().currentUser;
  const currentUserName =
    currentUser && currentUser.email
      ? currentUser.email
      : currentUser && currentUser.phoneNumber
        ? currentUser.phoneNumber.replace("+33", "0")
        : null;
  if (currentUser && currentUserName) {
    const lastActivity = new Date();
    const deviceInfo = await Device.getInfo();
    const db = firebase.firestore();
    await db
      .collection("users")
      .doc(currentUserName)
      .update({
        lastActivity: lastActivity,
        deviceInfo: deviceInfo,
      } as User);
  }
};

export const setUserConfig = (config: UserConfiguration) =>
({
  type: "set-user-config",
  config,
} as const);

export const setLoading = (isLoading: boolean) =>
({
  type: "set-user-loading-load-data",
  isLoading,
} as const);

export const setLoadingAnnuaire = (isLoading: boolean) =>
({
  type: "set-user-loading-annuaire",
  isLoading,
} as const);

export const setLoadingSearch = (isLoading: boolean) =>
({
  type: "set-user-loading-search",
  isLoading,
} as const);

export const setCurrentUserData = (user: User) =>
({
  type: "set-current-user-data",
  user,
} as const);

export const setData = (data: Partial<UserState>) =>
({
  type: "set-user-data",
  data,
} as const);

export const setAnnuaireUsers = (data: UserWithRelationship[]) =>
({
  type: "set-annuaire-users-data",
  data,
} as const);

export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  await setIsLoggedInData(false);
  dispatch(setUsername());
};

export const setIsLoggedIn =
  (loggedIn: boolean) => async (dispatch: React.Dispatch<any>) => {
    await setIsLoggedInData(loggedIn);
    return {
      type: "set-is-loggedin",
      loggedIn,
    } as const;
  };

export const setUsername =
  (username?: string) => async (dispatch: React.Dispatch<any>) => {
    await setUsernameData(username);
    return {
      type: "set-username",
      username,
    } as const;
  };

export const setHasSeenTutorial =
  (hasSeenTutorial: boolean) => async (dispatch: React.Dispatch<any>) => {
    await setHasSeenTutorialData(hasSeenTutorial);
    return {
      type: "set-has-seen-tutorial",
      hasSeenTutorial,
    } as const;
  };

export const setDarkMode = (darkMode: boolean) =>
({
  type: "set-dark-mode",
  darkMode,
} as const);

export const setAudioData = (audioType: audioType, audioPlay: boolean) =>
({
  type: "set-audio-data",
  audioType,
  audioPlay,
} as const);

/**
 * All user's relations. Real time update.
 * @param data
 */
export const setUserRelations = (data: UserRelationship[]) =>
({
  type: "set-user-relations-data",
  data,
} as const);

export const processFriendRequest =
  (data: UserRelationship[]) => async (dispatch: React.Dispatch<any>) => {
    let queries = data.map((item) => {
      return getUser(item.emailFrom).then((user) => {
        let userWithRelation = user as UserWithRelationship;
        userWithRelation.id = user.email;
        userWithRelation.relationships = [item];
        return userWithRelation;
      });
    });

    dispatch(setFriendRequestPending(await Promise.all(queries)));
  };

export const setFriendRequestPending = (data: UserWithRelationship[]) =>
({
  type: "set-user-friend-request-pending",
  data,
} as const);

export const uploadUserPicture =
  (
    user: User | undefined,
    fileBase64: string,
    fileExtension: string,
    supervisedAccount: UserSupervised | null = null
  ) =>
    async (dispatch: React.Dispatch<any>) => {
      if (user) {
        if (
          user.picture &&
          user.picture.includes("firebasestorage.googleapis.com")
        ) {
          deleteFileFromStorageRequest(user.picture);
        }
        user.picture = await uploadUserPictureRequest(
          fileBase64,
          fileExtension,
          user.id
        );
        if (!user.picture) alert("Error lors du téléchargement");
        await updateUserRequest(user, supervisedAccount);
        dispatch(setCurrentUserData(user));
        return user;
      }
    };

export const setUserMailbox = (mailbox: Mailbox) =>
({
  type: "set-user-mailbox",
  mailbox,
} as const);

export const setUserGroups = (groups: ContactGroup[]) =>
({
  type: "set-user-groups",
  groups,
} as const);

export const deleteMailboxMessage =
  (userName: string, message: MailboxItem, succesMessage: string, supervisedAccount: UserSupervised | undefined) =>
    async (dispatch: React.Dispatch<any>) => {
      supervisedAccount && dispatch(setLoading(true));
      const mailbox = await getMailboxCurrentUserRequest(userName);

      if (mailbox) {
        if (message.type === MailboxItemType.MessageVideo) {
          try {
            await deleteFileFromStorageRequest(message.urlFile);
          } catch (e) {
            console.error("Cannot delete file from storage");
          }
        }
        _.remove(mailbox.items, (msg) => msg.id === message.id);
        await updateMailboxCurrentUserRequest(userName, mailbox).then(() => {
          if (supervisedAccount) {
            getSupervisedUserData(supervisedAccount.user.email)
              .then((userSupervised) => {
                localStorage.setItem(
                  "userSupervised",
                  JSON.stringify(userSupervised)
                );
                dispatch(setUserSupervised(userSupervised));
              })
              .finally(() => {
                dispatch(setLoading(false));
                toast.success(succesMessage)
              });
          } else {
            dispatch(setLoading(false));
            toast.success(succesMessage)
          }
        }).catch((error) => {
          console.error("deleteMailboxMessage Error: ", error);
        });
      }
    };

/**
 * Set a mailbox message viewed by current user
 * @param messageId
 */
export const setMessageViewByUser =
  (userName: string, message: MailboxItem) =>
    async (dispatch: React.Dispatch<any>) => {
      const mailbox = await getMailboxCurrentUserRequest(userName);

      if (mailbox && mailbox.items) {
        let fromUserViewd: string;
        mailbox.items.map((msg) => {
          if (msg.id === message.id) {
            msg.viewed = true;
            fromUserViewd = msg.fromUser;
          }
          mailbox.items.map((m) => {
            if (m.fromUser === fromUserViewd) {
              m.viewed = true;
            }
          });
        });
        await updateMailboxCurrentUserRequest(userName, mailbox);
        setUserMailbox(mailbox);
      }
    };

/**
 * Called after a notification (toast + audio) is done, update database for not playing notification next time
 */
export const setMessageNotificationShownByUser =
  (userName: string) => async (dispatch: React.Dispatch<any>) => {
    const mailbox = await getMailboxCurrentUserRequest(userName);

    if (mailbox && mailbox.items) {
      mailbox.items = mailbox.items.map((msg) => {
        msg.needToastNotification = false;
        return msg;
      });
      await updateMailboxCurrentUserRequest(userName, mailbox);
      setUserMailbox(mailbox);
    }
  };

export const deleteMailboxMessages =
  (userName: string, messages: MailboxItem[], succesMessage: string, supervisedAccount: UserSupervised | undefined) =>
    async (dispatch: React.Dispatch<any>) => {
      supervisedAccount && dispatch(setLoading(true));
      const mailbox = await getMailboxCurrentUserRequest(userName);
      const messageIds: string[] = [];
      if (mailbox) {
        messages.forEach(async (message) => {
          messageIds.push(message.id);
          if (message.type === MailboxItemType.MessageVideo) {
            try {
              await deleteFileFromStorageRequest(message.urlFile);
            } catch (e) {
              console.error("Cannot delete file from storage");
            }
          }
        });
        _.remove(mailbox.items, (msg) => messageIds.includes(msg.id));
        await updateMailboxCurrentUserRequest(userName, mailbox).then(() => {
          if (supervisedAccount) {
            getSupervisedUserData(supervisedAccount.user.email)
              .then((userSupervised) => {
                localStorage.setItem(
                  "userSupervised",
                  JSON.stringify(userSupervised)
                );
                dispatch(setUserSupervised(userSupervised));
              })
              .finally(() => {
                dispatch(setLoading(false));
                toast.success(succesMessage)
              });
          } else {
            dispatch(setLoading(false));
            toast.success(succesMessage)
          }
        }).catch((error) => {
          console.error("deleteMailboxMessage Error: ", error);
        });
      }
    };

export const createContactGroup =
  (
    creator: string,
    name: string,
    members: string[],
    fileBase64: string,
    fileExtension: string,
    supervisedAccount: UserSupervised | null
  ) =>
    async (dispatch: React.Dispatch<any>) => {
      dispatch(setLoading(true));
      let picture = "";
      const id = createGuid(5);
      if (fileBase64 && fileExtension) {
        picture = await uploadGroupPictureRequest(
          fileBase64,
          fileExtension,
          id
        );
        if (!picture) alert("Error lors du téléchargement");
      }
      const res = await createContactGroupRequest(
        id,
        creator,
        name,
        picture,
        members
      );
      if (supervisedAccount) {
        getSupervisedUserData(supervisedAccount.user.email)
          .then((userSupervised) => {
            localStorage.setItem(
              "userSupervised",
              JSON.stringify(userSupervised)
            );
            dispatch(setUserSupervised(userSupervised));
          })
          .finally(async () => {
            toast.success("Le groupe a été ajouté avec succès");
            dispatch(setLoading(false));
          });
      } else {
        toast.success("Le groupe a été ajouté avec succès");
        dispatch(setLoading(false));
      }
      return res;
    };

export const deleteContactGroup =
  (
    group: ContactGroup,
    currentUsername: string,
    supervisedAccount: UserSupervised | null
  ) =>
    async (dispatch: React.Dispatch<any>) => {
      if (group) {
        if (group.picture) await deleteFileFromStorageRequest(group.picture);
        if (!supervisedAccount) {
          await deleteContactGroupRequest(group.id);
          toast.success("Vous avez supprimé le groupe avec succès");
        } else {
          dispatch(setLoading(true));
          const deleteContactGroupByIdFunc = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable("ContactGroup-deleteContactGroupById");
          return deleteContactGroupByIdFunc({
            id: group.id,
          }).then(() => {
            getSupervisedUserData(currentUsername)
              .then((userSupervised) => {
                localStorage.setItem(
                  "userSupervised",
                  JSON.stringify(userSupervised)
                );
                dispatch(setUserSupervised(userSupervised));
              })
              .finally(() => {
                dispatch(setLoading(false));
                toast.success("Le groupe a été retiré avec succès");
              });
          });
        }
      }
    };

export const updateContactGroupName =
  (
    group: ContactGroup,
    newName: string,
    supervisedAccount: UserSupervised | null
  ) =>
    async (dispatch: React.Dispatch<any>) => {
      if (group) {
        group.name = newName;
        await updateContactGroupRequest(group, supervisedAccount);
      }
    };
export const updateContactGroupMembers =
  (
    group: ContactGroup,
    members: string[],
    supervisedAccount: UserSupervised | null
  ) =>
    async (dispatch: React.Dispatch<any>) => {
      if (group) {
        group.members = members;
        await updateContactGroupRequest(group, supervisedAccount);
      }
    };

export const quitContactGroup =
  (
    group: ContactGroup,
    username: string,
    supervisedAccount: UserSupervised | null
  ) =>
    async (dispatch: React.Dispatch<any>) => {
      if (group) {
        group.members = group.members.filter((email) => email !== username);
        if (!supervisedAccount) {
          await updateContactGroupRequest(group, supervisedAccount);
          toast.success("Vous avez quitté le groupe avec succès");
        } else {
          dispatch(setLoading(true));
          const updateContactGroupFunc = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable("ContactGroup-updateContactGroup");
          return updateContactGroupFunc({
            contactGroup: group,
          }).then(() => {
            getSupervisedUserData(username)
              .then((userSupervised) => {
                localStorage.setItem(
                  "userSupervised",
                  JSON.stringify(userSupervised)
                );
                dispatch(setUserSupervised(userSupervised));
              })
              .finally(() => {
                dispatch(setLoading(false));
                toast.success("Le groupe a été quitté avec succès");
              });
          });
        }
      }
    };
export const uploadGroupPicture =
  (
    group: ContactGroup,
    fileBase64: string,
    fileExtension: string,
    supervisedAccount: UserSupervised | null
  ) =>
    async () => {
      if (group) {
        if (
          group.picture &&
          group.picture.includes("firebasestorage.googleapis.com")
        ) {
          deleteFileFromStorageRequest(group.picture);
        }
        group.picture = await uploadGroupPictureRequest(
          fileBase64,
          fileExtension,
          group.id
        );
        if (!group.picture) alert("Error lors du téléchargement");
        await updateContactGroupRequest(group, supervisedAccount);
        return group;
      }
    };

/**
 * Save push token for current user
 * @param currentUserName
 * @param pushToken
 * @returns
 */
export const updateUserPushToken =
  (currentUserName: string, pushToken: string) =>
    async (dispatch: React.Dispatch<any>) => {
      const currentUser = await getUser(currentUserName);
      if (currentUser) {
        currentUser.pushToken = pushToken;
        currentUser.pushTokenLastUpdate = new Date().toISOString();
        await updateUserRequest(currentUser);
      }
    };

/**
 * send && save help email
 * @param currentUser
 * @param subject
 * @param message
 * @param type
 * @returns
 */
export const sendEmail =
  (
    currentUser: string,
    subject: string,
    message: string,
    type: string,
    receiver: string,
    files: FileData[]
  ) =>
    async (dispatch: React.Dispatch<any>) => {
      dispatch(setLoading(true));
      let attachments: Attachment[] = [];
      for (var i = 0; i < files.length; i++) {
        const file = files[i];
        const attachment = (await uploadMailFileRequest(file)) as Attachment;
        if (attachment) {
          attachments.unshift(attachment);
        }
      }
      const result = await sendMailRequest(
        currentUser,
        subject,
        message,
        type,
        receiver,
        attachments
      );
      dispatch(setLoading(false));
      toast.success("Le message a bien été envoyé");
      return result;
    };
export const updateUser = (currentUser: User, supervisedAccount: UserSupervised | null, successMessage: string = "") =>
  async (dispatch: React.Dispatch<any>) => {
    if (currentUser) {
      dispatch(setLoading(true));
      await updateUserRequest(currentUser, supervisedAccount).then((result) => {
        if (result) {
          if (supervisedAccount) {
            getSupervisedUserData(supervisedAccount.user.email)
              .then((userSupervised) => {
                localStorage.setItem(
                  "userSupervised",
                  JSON.stringify(userSupervised)
                );
                dispatch(setUserSupervised(userSupervised));
              })
              .finally(() => {
                successMessage && toast.success(successMessage);
                dispatch(setLoading(false));
              });
          } else {
            dispatch(setLoading(false));
            successMessage && toast.success(successMessage);
          }
        } else {
          dispatch(setLoading(false));
          toast.error("Une erreur est survenue");
        }
      });
    }
  };

export type UserActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setIsLoggedIn>
  | ActionType<typeof setUsername>
  | ActionType<typeof setHasSeenTutorial>
  | ActionType<typeof setDarkMode>
  | ActionType<typeof setAnnuaireUsers>
  | ActionType<typeof setLoadingAnnuaire>
  | ActionType<typeof setFriendRequestPending>
  | ActionType<typeof setSearchUserFiltered>
  | ActionType<typeof setLoadingSearch>
  | ActionType<typeof setUserRelations>
  | ActionType<typeof setCurrentUserData>
  | ActionType<typeof setAudioData>
  | ActionType<typeof setUserMailbox>
  | ActionType<typeof setUserConfig>
  | ActionType<typeof setUserGroups>;
