import React, {useEffect, useState} from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react';

import {connect} from '../data/connect';
import {RouteComponentProps, useLocation} from 'react-router';
import firebase from '../FirebaseConfig';
import Loading from '../components/Loading';
import {MessageType, showToast} from '../util/toast.message';
import './PasswordLost.scss'
import {validateEmail} from '../util/email.validation';
import { setShowOffline } from '../data/global/global.actions';
import { removeDiacritics } from '../util/functions';

interface OwnProps {}

interface StateProps {
  appOnline: boolean;
}

interface DispatchProps {
  setShowOffline: typeof setShowOffline;
}

interface PasswordLostProps
  extends RouteComponentProps,
    OwnProps,
    StateProps,
    DispatchProps {}

const PasswordLost: React.FC<PasswordLostProps> = ({ history, appOnline, setShowOffline }) => {

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const data = useLocation()

let user : any  = data.state



  useEffect(() => {
setEmail(user.userEmail)

  }, []);
  /**
  * inputValidation : checks the validity of data form
  * @param   {void} firstName  First Name of the User
  * @return  {boolean}            Fullname of the user
  */
  function inputValidation() {
    if (!email) {
      setEmailError(true)
      return false
    }
    return true
  }



  /**
  * sendPasswodEmail : retrieve the data form sended by the user
  * @param   {FormEvent} e event form
  * @return  {void}
  */
  const sendPasswodEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    setFormSubmitted(true);

    setLoading(true)
    if (inputValidation()) {
      if (validateEmail(email)) {
        sendResetEmailFirebase(email)
      } else {
        showToast("Le Format de l'adresse email est invalide", 3000, MessageType.error)
      }
    }
    setLoading(false)

  };



  /**
  * sendResetEmailFirebase : sends reset email via firebase
  * @param   {string} email  user email
  * @return  {void}
  */
  function sendResetEmailFirebase(email: string) {
    var auth = firebase.auth();
    firebase.auth().languageCode = 'fr';
    auth.sendPasswordResetEmail(email).then(function () {
      showToast("Un email a été envoyé à votre adresse", 3000, MessageType.success)
      history.replace("/login")
    }).catch(function (err) {
      showToast("Aucun utilisateur ne correspond à cet e-mail", 3000, MessageType.error)
    });
  }


  return (
    <IonPage id="login-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Mot de passe oublié</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <div className="login-logo">
          <img src="assets/img/logo.png" alt="Ionic logo" />
        </div>

        <form noValidate onSubmit={sendPasswodEmail}>
          <IonList>


            {/*email*/}
            <IonItem>
              <IonLabel position="stacked" color="primary"><IonText >Email</IonText></IonLabel>
              <IonInput name="email" value={email ? removeDiacritics(email.toLowerCase()) : email} spellCheck={false} autocapitalize="off" onIonChange={e => setEmail(e.detail.value!)}
                required>
              </IonInput>
            </IonItem>

            {formSubmitted && emailError && <IonText  color="danger">
              <p className="ion-padding-start">
              L'adresse email est nécessaire
              </p>
            </IonText>}
            <br /><br />
          </IonList>


          {/* buttons */}

          <IonRow>
          <IonCol size="12">
          <IonButton  type="submit" expand="block">Re-initialiser</IonButton>
            </IonCol>
            <IonCol size="12">
              <IonButton routerLink="/" color="light" expand="block">Retour</IonButton>
            </IonCol>
          </IonRow>

        </form>
        <Loading enable={loading} text="Modification en cours ..." />
      </IonContent>

    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setShowOffline
  },
  mapStateToProps: (state) => ({
    appOnline: state.global.appOnline
  }),
  component: React.memo(PasswordLost),
});