/**
 * showToast : show message dialog in bottom of the screen 
 * @param   {string} msg  message body
 * @param   {number} duration  display duration of message in ms
 * @param   {MessageType} type  type of message => success or error
 * @return  {void}
 */
export async function showToast(msg: string, duration: number, type: MessageType) {
    const toast = document.createElement('ion-toast')
    toast.message = msg
    toast.duration = duration
    toast.color = type
    toast.animated = true
    toast.position = 'bottom'
    document.body.appendChild(toast)
    return toast.present()
}

export enum MessageType {
    success = 'success',
    error = 'danger'
}