import {
  IonBadge,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
} from "@ionic/react";
import _ from "lodash";
import React from "react";
import { Mailbox } from "../models/Mailbox";
import { connect } from "../data/connect";
import * as selectors from "../data/selectors";
import { UserWithRelationship } from "../models/UserWithRelationship";
import classes from "./MenuItem.module.scss";
import { RouteComponentProps } from "react-router";
import { setSearchTerm } from "../data/global/global.actions";

interface Page {
  id: number;
  title: string;
  path: string;
  icon: string;
  routerDirection?: string;
  onlyCallingMode?: boolean;
  onlySharedFilesMode?: boolean;
  onlyMailboxMode?: boolean;
  onlyContactsMode?: boolean;
  color?: string;
  newMessages?: number;
  isSetting?: boolean;
}

interface OwnProps {
  route: Page;
}

interface StateProps {
  friendRequestsPending: UserWithRelationship[];
  countNewSharedFile: number;
  mailbox?: Mailbox;
  countNewComments: number;
  countNewAlbums: number;
}

interface DispatchProps {
  setSearchTerm: typeof setSearchTerm;
}

interface MenuItemProps
  extends RouteComponentProps,
    OwnProps,
    StateProps,
    DispatchProps {}

const MenuItem: React.FC<MenuItemProps> = ({
  route,
  mailbox,
  countNewSharedFile,
  friendRequestsPending,
  countNewComments,
  countNewAlbums,
  setSearchTerm,
}) => {
  const countAlbumsNews =
    countNewSharedFile + countNewComments + countNewAlbums;

  const mailboXnewMessage = mailbox
    ? _.filter(mailbox.items, (message) => !message.viewed).length
    : 0;

  let style = {
    border: `5px solid ${route.color}`,
    height: "100%",
    display: "flex",
    margin: 0,
  };

  const changeBackgroundColor = () => {
    for (let i = 0; i < 5; i++) {
      var element = document.getElementById(i.toString());
      if (i !== route.id) {
        if (element) element.style.removeProperty("background-color");
      } else {
        if (element) element.style.backgroundColor = "rgba(56, 128, 255, 0.08)";
      }
    }
  };
  let newMessagesBadge = null;
  if (route.newMessages && route.newMessages > 0) {
    newMessagesBadge = (
      <IonBadge color="danger" className={classes.ionBadge}>
        {route.newMessages > 99 ? "99+" : route.newMessages}
      </IonBadge>
    );
  }

  return (
    <IonCard
      id={route.id.toString()}
      className="speaker-card"
      onClick={() => {
        changeBackgroundColor();
        setSearchTerm("");
      }}
      routerLink={route.path ? route.path: undefined}
      routerDirection="none"
      style={style}
    >
      <div className={classes.card}>
        <IonCardHeader
          className={classes.ionCardHeader}
          style={{height: "55px"}}
        >
          <div className={classes.cardHeaderText + " menu-header-text-size"}>
            {route.title}
          </div>
        </IonCardHeader>
        <IonCardContent
          style={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f4f5f8",
          }}
        >
          <div
            className={
              classes.center +
              " " +
              (route.newMessages || route.isSetting ? classes.margin : "")
            }
          >
            <IonIcon icon={route.icon} className={classes.ionIcon} />
            {route.onlySharedFilesMode && countAlbumsNews > 0 && (
              <IonBadge color="danger" className={classes.ionBadge}>
                {countAlbumsNews > 99 ? "99+" : countAlbumsNews}
              </IonBadge>
            )}
            {route.onlyMailboxMode && mailboXnewMessage > 0 && (
              <IonBadge color="danger" className={classes.ionBadge}>
                {mailboXnewMessage > 99 ? "99+" : mailboXnewMessage}
              </IonBadge>
            )}
            {route.onlyContactsMode && friendRequestsPending.length > 0 && (
              <IonBadge color="danger" className={classes.ionBadge}>
                {friendRequestsPending.length > 99
                  ? "99+"
                  : friendRequestsPending.length}
              </IonBadge>
            )}
            {newMessagesBadge}
          </div>
        </IonCardContent>
      </div>
    </IonCard>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    friendRequestsPending: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.pendingRequests
      : state.user.requestPending
      ? state.user.requestPending
      : [],
    countNewSharedFile: selectors.getNewSharedFileCountTotal(state),
    countNewComments: selectors.getNewCommentsCountTotal(state),
    countNewAlbums: selectors.getNewAlbumsCountTotal(state),
    mailbox: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.mailbox
      : state.user.mailbox,
  }),
  mapDispatchToProps: {
    setSearchTerm,
  },
  component: MenuItem,
});
