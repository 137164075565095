import firebase from "../FirebaseConfig";
import {
  SupervisorRelationship,
  SupervisorRelationshipState,
} from "../models/SupervisorRelationship";
import { UserSupervised } from "../models/UserSupervised";

/**
 * Create a new supervisor request
 * @param administered
 * @param administrator
 */
export const createSupervisorRequest = async (
  administered: string,
  administrator: string
) => {
  const entityId = administrator + "->" + administered;
  const db = firebase.firestore();
  await db
    .collection("supervisorRelationship")
    .doc(entityId)
    .set({
      administered: administered,
      administrator: administrator,
      state: SupervisorRelationshipState.Waiting,
      users: [administered, administrator],
      notifAdministrator:false,
      dateCreate: new Date().toISOString(),
    } as SupervisorRelationship);
};

/**
 * delete a supervisor request
 * @param administered
 * @param administrator
 */
export const deleteSupervisorRequest = async (
  administered: string,
  administrator: string
) => {
  const entityId = administrator + "->" + administered;
  const db = firebase.firestore();
  await db.collection("supervisorRelationship").doc(entityId).delete();
};

export const valdiateSupervisorRequest = async (
  administered: string,
  administrator: string
) => {
  const entityId = administrator + "->" + administered;
  const db = firebase.firestore();
  await db.collection("supervisorRelationship").doc(entityId).update({
    state: SupervisorRelationshipState.Accepted,
  });
};

/**
 * Return a request if exist
 * @param administered
 * @param administrator
 * @returns
 */
export const getSupervisorRequest = async (
  administered: string,
  administrator: string
) => {
  const entityId = administrator + "->" + administered;
  const db = firebase.firestore();
  try {
    const db = firebase.firestore();
    var request = await db
      .collection("supervisorRelationship")
      .doc(entityId)
      .get();
    return request.data() as SupervisorRelationship;
  } catch (e) {
    alert("Erreur getSupervisorRequest: " + JSON.stringify(e));
  }
  return null;
};

/**
 * Return supervised usr data when current user use supervisor mode
 * @param userName
 * @returns
 */
export const getSupervisedUserData = async (userName: string) => {
  const getSupervisedUserDataFunc = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("Surpervisor-getSupervisedUserData");
  return getSupervisedUserDataFunc({
    userName: userName,
  }).then((result) => result.data.user as UserSupervised);
};

/**
 * delete a supervisor request
 * @param administered
 * @param administrator
 */
export const deleteSupervisorRequestAdmin = async (
  administered: string,
  administrator: string
) => {
  const deleteSupervisorRequestFunc = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("Surpervisor-deleteSupervisorRequest");
  return deleteSupervisorRequestFunc({
    administered: administered,
    administrator: administrator,
  });
};
