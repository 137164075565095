import React, { useState } from "react";
import {
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonCol,
} from "@ionic/react";
import Header from "../../components/Header";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import classes from "./Settings.module.scss";
import EmergencyContactsSettings from "../../components/Modals/EmergencyContactsSettings";
import { isBoolean } from "lodash";
import { UserSupervised } from "../../models/UserSupervised";
import { RouteComponentProps } from "react-router";
import { arrowBackCircleOutline, peopleCircle } from "ionicons/icons";
import Button from "../../components/Button";
import {
  SupervisorRelationship,
  SupervisorRelationshipState,
} from "../../models/SupervisorRelationship";
interface OwnProps {}

interface StateProps {
  isHelpedProfil: boolean;
  currentUserEmail: string | undefined;
  supervisedAccount?: UserSupervised;
  supervisorRequests: SupervisorRelationship[];
  supervisedRequests: SupervisorRelationship[];
}

interface DispatchProps {}

interface ContactsSettingsProps
  extends RouteComponentProps,
    OwnProps,
    StateProps,
    DispatchProps {}

const ContactsSettings: React.FC<ContactsSettingsProps> = ({
  isHelpedProfil,
  supervisedAccount,
  history,
  supervisorRequests,
  supervisedRequests,
  currentUserEmail,
}) => {
  const [showEmergencyContactsModal, setShowEmergencyContactsModal] =
    useState(false);
  const showEmergencyContacts =
    isHelpedProfil || supervisedAccount !== undefined;
  const userAdministrator = supervisorRequests.filter(
    (m) =>
      m.administered == currentUserEmail &&
      m.state == SupervisorRelationshipState.Accepted
  );
  const supervisedUserAdministrator = supervisedRequests.filter(
    (m) =>
      m.administered == currentUserEmail &&
      m.state == SupervisorRelationshipState.Accepted
  );
  const showSupervision =
    (userAdministrator.length > 0 && isHelpedProfil && !supervisedAccount) ||
    (!isHelpedProfil && !supervisedAccount) ||
    (!isHelpedProfil && supervisedUserAdministrator.length > 0);

  const options = [
    showSupervision && {
      id: 0,
      title: "Supervision",
      icon: peopleCircle,
      action: () => {
        if (!isHelpedProfil && supervisedUserAdministrator.length > 0) {
          history.push("/supervisorlist");
        } else {
          history.push("/supervisor/list");
        }
      },
    },
    {
      id: 1,
      title: "Groupe de contacts",
      icon: "assets/icon/group-users.svg",
      action: () => {
        history.push("/tabs/settings/contacts/groups");
      },
    },
    showEmergencyContacts && {
      id: 2,
      title: "Contacts prioritaires",
      icon: "assets/icon/priority-users.svg",
      action: () => {
        setShowEmergencyContactsModal(true);
      },
    },
    {
      id: 3,
      title: "Invitation",
      icon: "assets/icon/plus.svg",
      action: () => {
        history.push("/tabs/settings/contacts/invitation");
      },
    },
  ];

  return (
    <IonPage>
      <Header title="Réglages des contacts" showHelp />
      <IonContent>
        <div
          className={
            classes.container + (isHelpedProfil ? " padding-bottom" : "")
          }
        >
          <IonList className={classes.list}>
            {options.map((option) => {
              if (!isBoolean(option)) {
                return (
                  <IonItem
                    key={option.id}
                    button
                    onClick={option.action}
                    className={classes.item}
                  >
                    <IonIcon
                      slot="start"
                      icon={option.icon}
                      className={classes.icon}
                    ></IonIcon>
                    <p>{option.title}</p>
                  </IonItem>
                );
              }
            })}
          </IonList>
        </div>
      </IonContent>
      {showEmergencyContactsModal && (
        <EmergencyContactsSettings
          showEmergencyContacts={setShowEmergencyContactsModal}
        />
      )}
      <IonRow>
        <IonCol size="12">
          <Button
            routerLink="/settings"
            color="light"
            text="Retour"
            icon={arrowBackCircleOutline}
          />
        </IonCol>
      </IonRow>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    isHelpedProfil: selectors.getIsHelpedProfil(state),
    supervisedAccount: state.supervisor.supervisedAccount,
    supervisorRequests: state.supervisor.requests || [],
    supervisedRequests: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.supervisorRequests
      : [],
    currentUserEmail: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user.email
      : state.user.username,
  }),
  mapDispatchToProps: {},
  component: React.memo(ContactsSettings),
});
