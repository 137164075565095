import { IonContent, IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "../../data/connect";
import _ from "lodash";
import Header from "../Header";
import { ContactGroup } from "../../models/ContactGroup";
import { updateContactGroupMembers } from "../../data/user/user.actions";
import SelectMultipleUsers from "../SelectMultipleUsers";
import BottomButtons from "../BottomButtons";
import * as selectors from "../../data/selectors";
import { UserWithRelationship } from "../../models/UserWithRelationship";
import { setShowOffline, setToastNotification } from "../../data/global/global.actions";
import { ToastNotification } from "../../models/ToastNotification";
import { UserSupervised } from "../../models/UserSupervised";
import { getGlobalConfig } from "../../api/globalConfig";
interface OwnProps {
  group: ContactGroup;
  hist: any;
  onCancel: () => void;
}

interface StateProps {
  users: UserWithRelationship[];
  currentUserEmail: string;
  appOnline: boolean;
  supervisedAccount: UserSupervised | null;
  isHelpedProfil: boolean;
}

interface DispatchProps {
  updateContactGroupMembers: typeof updateContactGroupMembers;
  setToastNotification: typeof setToastNotification;
  setShowOffline: typeof setShowOffline;
}
interface UpdateContactGroupMembersProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const UpdateContactGroupMembers: React.FC<UpdateContactGroupMembersProps> = ({
  group,
  users,
  hist,
  currentUserEmail,
  appOnline,
  supervisedAccount,
  isHelpedProfil,
  onCancel,
  updateContactGroupMembers,
  setToastNotification,
  setShowOffline
}) => {
  const [formUsers, setFormUsers] = useState<string[]>([]);
  useEffect(() => {
    if (group) {
      setFormUsers(group.members);
    }
  }, [group]);
  const updateSelectedUsers = (selectedUsers: string[]) => {
    setFormUsers(selectedUsers);
  };
  async function onValidate() {
    const Config = await getGlobalConfig(isHelpedProfil,currentUserEmail); 
    const maxNumberMembersPerGroup = Config.contact?.maxNumberMembersPerGroup;
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    if (formUsers.length == 0) {
      setToastNotification({
        message: "Vous devez sélectionner au moins un contact",
        color: "danger",
      } as ToastNotification);
      return;
    }
    if (maxNumberMembersPerGroup && +maxNumberMembersPerGroup < formUsers.length) {
      setToastNotification({
        message: "Le nombre de contacts possible par groupe a déjà été atteint",
        color: "danger",
      } as ToastNotification);
      return;
    }
    formUsers.push(currentUserEmail);
    updateContactGroupMembers(group, formUsers, supervisedAccount);
    hist.push("/tabs/settings/contacts/groups");
    setToastNotification({
      message: "Les membres du groupe ont bien été modifiés",
      color: "success",
    } as ToastNotification);
  }
  return (
    <IonModal isOpen={true} onDidDismiss={() => onCancel()}>
      <Header hideHome title="Modifier les membres du groupe" />
      <IonContent>
        <div className="full-height-flex">
          <SelectMultipleUsers
            users={users}
            currentUserEmail={currentUserEmail ? currentUserEmail : ""}
            selectedUsers={formUsers.filter(
              (email) => currentUserEmail !== email
            )}
            checkedAll={
              users.length ===
              formUsers.filter((email) => currentUserEmail !== email).length
            }
            updateSelectedUsers={updateSelectedUsers}
          />
        </div>
      </IonContent>
      <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
        <BottomButtons onCancel={onCancel} onValidate={onValidate} />
      </div>
    </IonModal>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    updateContactGroupMembers,
    setToastNotification,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    users: selectors.getUsersFilteredByNumberOfCalls(state),
    appOnline: state.global.appOnline,
    currentUserEmail: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user.email
      : state.user.username
      ? state.user.username
      : "",   
    supervisedAccount: state.supervisor.supervisedAccount || null,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
  }),
  component: React.memo(UpdateContactGroupMembers),
});
