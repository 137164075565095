import { ActionType } from "../../util/types";

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-conf-loading",
    isLoading,
  } as const);

export const setAppReady = (isReady: boolean) =>
  ({
    type: "set-app-ready",
    isReady,
  } as const);

export const setMenuEnabled = (menuEnabled: boolean) =>
  ({
    type: "set-menu-enabled",
    menuEnabled,
  } as const);

export type SessionsActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setMenuEnabled>
  | ActionType<typeof setAppReady>;
