import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonButton,
  IonLabel,
  IonRow,
  IonCol,
  IonIcon,
  IonProgressBar,
} from "@ionic/react";
import "./AccountList.scss";
import { RouteComponentProps, useLocation } from "react-router";
import Header, { ButtonData } from "../../components/Header";
import { connect } from "../../data/connect";
import { laptopOutline, personAddOutline, trashOutline,arrowBackCircleOutline } from "ionicons/icons";
import { IconSvgEnum } from "../../components/IconSvg";
import AddUserModal from "./AddUserModal";
import { UserWithRelationship } from "../../models/UserWithRelationship";
import { SupervisorRelationship, SupervisorRelationshipState } from "../../models/SupervisorRelationship";
import UserItem, { userCardType } from "../../components/UserItem";
import * as selectors from "../../data/selectors";
import _ from "lodash";
import { loadAndSetUserSupervised, removeSupervisorRequest, sendSupervisorRequest, setUserSupervised } from "../../data/supervisor/supervisor.actions";
import { UserSupervised } from "../../models/UserSupervised";
import { menuController } from "@ionic/core";
import SupervisedAccountMenu from "../../components/supervision/SupervisedAccountMenu";
import { loadUserData } from "../../data/user/user.actions";
import { setShowOffline, setUserIsActive } from "../../data/global/global.actions";
import Button from "../../components/Button";
interface OwnProps extends RouteComponentProps { }

interface StateProps {
  allContacts: UserWithRelationship[];
  supervisorRequests: SupervisorRelationship[];
  userEmail: string;
  supervisedAccount?: UserSupervised;
  loading: boolean;
  isHelpedProfil: boolean;
  appOnline: boolean;
}

interface DispatchProps {
  sendSupervisorRequest: typeof sendSupervisorRequest;
  removeSupervisorRequest: typeof removeSupervisorRequest;
  loadAndSetUserSupervised: typeof loadAndSetUserSupervised;
  setUserSupervised: typeof setUserSupervised;
  loadUserData: typeof loadUserData;
  setUserIsActive: typeof setUserIsActive;
  setShowOffline: typeof setShowOffline;
}
interface OptionalState {
  isFromSupervisorShortcut?: boolean;
}

interface AccountListProps extends OwnProps, StateProps, DispatchProps { }

const AccountList: React.FC<AccountListProps> = ({ appOnline, allContacts, supervisorRequests, userEmail, history, supervisedAccount, loading, isHelpedProfil,
  loadUserData, sendSupervisorRequest, removeSupervisorRequest, loadAndSetUserSupervised, setUserSupervised, setUserIsActive, setShowOffline }) => {

  const [title, setTitle] = useState("");
  const state = useLocation().state as OptionalState;
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [isFromSupervisorShortcut, setIsFromSupervisorShortcut] = useState(state ? state.isFromSupervisorShortcut : false);

  useEffect(() => {
    if (isFromSupervisorShortcut && supervisorRequests.length === 1) {
      takeUserControl(supervisorRequests[0].administered);
    }
  }, [supervisorRequests, isFromSupervisorShortcut]);

  useEffect(() => {
    setUserIsActive(true);
    return () => {
      setUserIsActive(false);
    }
  }, []);

  const headerButton = [{
    title: "Modifier",
    iconSvg: IconSvgEnum.user,
    addPlusIcon: true,
    onClick: () => setShowAddUserModal(true),
  } as ButtonData]


  function takeUserControl(userName: string) {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    const userFound = _.find(allContacts, (u) => u.email == userName);
    if (userFound) {
      loadAndSetUserSupervised(userFound.email);
    }
  }

  const cancelUserControl = () => {
    setUserSupervised(undefined);
    localStorage.removeItem('userSupervised');
    loadUserData();
    if (isFromSupervisorShortcut) {
      setIsFromSupervisorShortcut(false);
      history.push("/tabs/contacts");
      menuController.open();
    }
  }


  // user that can take control to current user
  const userAdministrator = supervisorRequests.filter(m => m.administered == userEmail && m.state == SupervisorRelationshipState.Accepted);
  const userAdministered = supervisorRequests.filter(m => m.administrator == userEmail && m.state == SupervisorRelationshipState.Accepted);
  const removeSupervisor = (administered: string, administrator: string) => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    const userAdministrator = supervisorRequests.filter(m => m.administered == userEmail && m.state == SupervisorRelationshipState.Accepted);
    removeSupervisorRequest(administered, administrator);
    if (userAdministrator.length === 1) {
      history.push("/settings/account");
    }
  }
  useEffect(() => {
    const text = !isHelpedProfil ? userAdministered.length === 1 ? "Votre contact supervisable": "Vos contacts supervisables":
    userAdministrator.length === 1 ? "Superviseur de votre compte": "Superviseurs de votre compte";
    setTitle(text);
  }, [userAdministrator, userAdministered])

  return (
    <IonPage>
      <Header title={!loading ? title: " "} leftButtons={!loading && !isHelpedProfil ? headerButton : undefined} showHelp={isHelpedProfil}/>
      <IonContent id="supervisor-account-list">

        {loading && <div style={{ marginTop: '150px' }}><IonProgressBar type="indeterminate"></IonProgressBar></div>}

        {!loading && <div>
          {(userAdministrator.length === 0 && userAdministered.length === 0) && <div className="help">
            <div style={{ textAlign: "center", paddingTop: "20px" }}>
              <IonLabel color="secondary" >
                {isHelpedProfil ? "La supervision de votre compte par un de vos contacts autorise l'accès à votre compte en votre nom" :
                  "La supervision du compte d'un de vos contacts autorise l'accès à son compte en son nom"}
              </IonLabel>
            </div>

            {!isHelpedProfil && <div>
              <IonButton style={{ width: '100%', marginTop: "20px" }} onClick={() => setShowAddUserModal(true)}>
                <span>
                  <IonIcon icon={personAddOutline} className="icon-size" />
                </span>
              </IonButton>
            </div>}
          </div>}
          {userAdministrator.length > 0 && <div>
            <IonRow>
              {userAdministrator.map((contact, i) => {
                const otherMail = (userEmail == contact.administered ? contact.administrator : contact.administered)
                const user = _.find(allContacts, (u) => u.email == otherMail);

                if (user) {
                  return <IonCol
                    size="12"
                    size-lg="4"
                    size-md="6"
                    size-sm="12"
                    key={i}>
                    <UserItem user={user} type={userCardType.SupervisorUserList} buttons={
                      <IonRow>
                        <IonCol size="12">
                          <IonButton
                            className="btn-call"
                            color="danger"
                            type="button"
                            onClick={() => removeSupervisor(contact.administered, contact.administrator)}
                            expand="block"
                          >
                            <IonIcon icon={trashOutline} className="icon-size" /> Retirer</IonButton>
                        </IonCol>
                      </IonRow>
                    } />
                  </IonCol>
                }
              })}
            </IonRow>
          </div>}

          {userAdministered.length > 0 && <div>
            <IonRow>
              {userAdministered.map((contact, i) => {
                const otherMail = (userEmail == contact.administered ? contact.administrator : contact.administered)
                const user = _.find(allContacts, (u) => u.email == otherMail);

                if (user) {
                  return <IonCol
                    size="12"
                    size-lg="4"
                    size-md="6"
                    size-sm="12"
                    key={i}>
                    <UserItem user={user} type={userCardType.SupervisorUserList} buttons={
                      <IonRow>
                        <IonCol size="12">
                          <IonButton
                            className="btn-call"
                            color="success"
                            type="button"
                            onClick={() => takeUserControl(contact.administered)}
                            expand="block"
                          >
                            <IonIcon icon={laptopOutline} className="icon-size" /> Superviser </IonButton>
                        </IonCol>
                      </IonRow>
                    } />
                  </IonCol>
                }
              })}
            </IonRow>
          </div>}

          <AddUserModal contacts={allContacts}
            enable={showAddUserModal}
            currentUserEmail={userEmail}
            onClose={() => setShowAddUserModal(false)}
            sendSupervisorRequest={sendSupervisorRequest}
            removeSupervisorRequest={removeSupervisorRequest}
            relationships={supervisorRequests}
            isHelpedProfil={isHelpedProfil}
          />
        </div>}

        {(supervisedAccount ||
          (supervisedAccount &&
            isFromSupervisorShortcut &&
            userAdministered.length == 1)) && (
            <SupervisedAccountMenu
              supervisedAccount={supervisedAccount}
              cancelUserControl={cancelUserControl}
            />
          )}
        
      </IonContent>
      {!loading && <IonRow>
          <IonCol size="12">
            <Button
              routerLink="/settings/account"
              color="light"
              text="Retour"
              icon={arrowBackCircleOutline}
            />
          </IonCol>
        </IonRow> }
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    allContacts: state.user.annuaire || [],
    supervisorRequests: state.supervisor.requests || [],
    userEmail: state.user.username,
    supervisedAccount: state.supervisor.supervisedAccount,
    loading: state.supervisor.loading,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
    appOnline: state.global.appOnline
  }),
  mapDispatchToProps: {
    sendSupervisorRequest,
    removeSupervisorRequest,
    loadAndSetUserSupervised,
    setUserSupervised,
    loadUserData,
    setUserIsActive,
    setShowOffline
  },
  component: AccountList,
});
