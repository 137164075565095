import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import IconSvg, { IconSvgEnum } from './IconSvg';

interface ButtonProps {
  onClick?: Function;
  icon?:string;
  svg?: IconSvgEnum;
  /**Second svg place on top of the first cvg */
  secondSvg?: IconSvgEnum;
  text:string;
  color?:string;
  disabled?:boolean;
  className?: string;
  routerLink?: string;
  typeSubmit?:boolean;
  textSize?: string;
  iconSize?: string;
  fullWidth?: boolean;
};

const Button: React.FC<ButtonProps> = ({fullWidth=true, iconSize, textSize, onClick, icon, svg, secondSvg, text, color, disabled, routerLink, className, typeSubmit = false}) => {


  return (
    <IonButton
    routerLink={routerLink}
    type={typeSubmit ? 'submit' : 'button'}
    className={fullWidth ? className + " ion-text-wrap btn-full-width": className + " ion-text-wrap"}
    onClick={() => onClick ? onClick() : null}
    disabled={disabled}
    color={color ? color: "light"} >
        {icon && <IonIcon
            icon={icon}
            style={{ marginRight: "10px" }}
            className={iconSize ? iconSize: "icon-size"} /> }

            {svg && <IconSvg icon={svg} secondIcon={secondSvg} />}

        <div className={icon || svg ? "hide-sm "+textSize : textSize}>{text}</div>
    </IonButton>
  )
}

export default Button;
