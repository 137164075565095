import { IonContent, IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "../../data/connect";
import BottomButtons from "../BottomButtons";
import SelectMultipleUsers from "../SelectMultipleUsers";
import * as selectors from "../../data/selectors";
import { User, UserConfiguration } from "../../models/User";
import { UserWithRelationship } from "../../models/UserWithRelationship";
import { setShowOffline,setToastNotification } from "../../data/global/global.actions";
import { updateUser } from "../../data/user/user.actions";
import _ from "lodash";
import Header from "../Header";
import { UserSupervised } from "../../models/UserSupervised";
import { ContactGroup } from "../../models/ContactGroup";
import { getGlobalConfig } from "../../api/globalConfig";
import { ToastNotification } from "../../models/ToastNotification";
interface OwnProps {
  showEmergencyContacts: (show: boolean) => void;
}

interface StateProps {
  config: UserConfiguration;
  users: UserWithRelationship[];
  groups: ContactGroup[];
  currentUserEmail?: string;
  supervisedAccount: UserSupervised | null;
  appOnline: boolean;
  currentUser?: User;
  isHelpedProfil: boolean;
}

interface DispatchProps {
  setShowOffline: typeof setShowOffline;
  updateUser: typeof updateUser;
  setToastNotification: typeof setToastNotification;
}
interface EmergencyContactsSettingsProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const EmergencyContactsSettings: React.FC<EmergencyContactsSettingsProps> = ({
  currentUserEmail,
  config,
  users,
  groups,
  supervisedAccount,
  appOnline,
  currentUser,
  isHelpedProfil,
  showEmergencyContacts,
  setShowOffline,
  updateUser,
  setToastNotification
}) => {
  const [emergencyContacts, setEmergencyContacts] = useState<string[]>([]);
  const [formGroups, setFormGroups] = useState<ContactGroup[]>([]);
  useEffect(() => {
    setEmergencyContacts(
      config.emergencyContacts ? config.emergencyContacts : []
    );
  }, [config.emergencyContacts ? config.emergencyContacts.toLocaleString(): ""]);

  const updateSelectedUsers = (selectedUsers: string[]) => {
    setEmergencyContacts(selectedUsers);
  };
  const updateSelectedGroups = (selectedGroups: ContactGroup[]) => {
     setFormGroups(selectedGroups);
  }

  const cancelHandler = () => {
    showEmergencyContacts(false);
  };

  const validateHandler = async () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    let SelectedEmergencyContacts: string[] = [];
    formGroups.forEach((group) => {
      SelectedEmergencyContacts = [...SelectedEmergencyContacts, ...group.members];
    });
    SelectedEmergencyContacts = [...SelectedEmergencyContacts, ...emergencyContacts];
    SelectedEmergencyContacts = SelectedEmergencyContacts.filter((c, index) => {
      return (
        SelectedEmergencyContacts.indexOf(c) === index && c !== currentUserEmail
      );
    });
    const Config = await getGlobalConfig(isHelpedProfil,currentUserEmail); 
    const maxNumberEmergencyContacts = Config.contact?.maxNumberEmergencyContacts;
    if (maxNumberEmergencyContacts && +maxNumberEmergencyContacts < SelectedEmergencyContacts.length) {
      setToastNotification({
        message: "Le nombre de contacts prioritaires possible a déjà été atteint",
        color: "danger",
      } as ToastNotification);
      return;
    }
    if(currentUser){
      const userConfig = {... config} as UserConfiguration;
      userConfig.emergencyContacts = SelectedEmergencyContacts;
      currentUser.config = _.clone(userConfig);
      updateUser(currentUser, supervisedAccount, "Modifications enregistrées");
    }
    showEmergencyContacts(false);
    setFormGroups([]);
  };
  return (
    config && (
      <IonModal isOpen={true} onDidDismiss={() => showEmergencyContacts(false)} cssClass="modal-emergency-contact">
        <Header
          action={cancelHandler}
          title="Sélectionner les contacts à appeler en priorité"
          showHelp
        />
        <IonContent>
          <div className="full-height-flex">
            <SelectMultipleUsers
              users={users}
              currentUserEmail={currentUserEmail ? currentUserEmail : ""}
              selectedUsers={emergencyContacts ? emergencyContacts : []}
              checkedAll={false}
              updateSelectedUsers={updateSelectedUsers}
              groups={groups}
              selectedGroups={formGroups}
              updateSelectedGroups={updateSelectedGroups}
            />
          </div>
        </IonContent>
        <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
          <BottomButtons
            onCancel={cancelHandler}
            onValidate={validateHandler}
          />
        </div>
      </IonModal>
    )
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setShowOffline,
    updateUser,
    setToastNotification

  },
  mapStateToProps: (state) => ({
    currentUserEmail: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username,
    config: selectors.getUserConfig(state),
    users: selectors.getUsersFilteredByNumberOfCalls(state),
    supervisedAccount: state.supervisor.supervisedAccount || null, 
    groups: state.user.groups || [],
    appOnline: state.global.appOnline,
    currentUser: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
  }),
  component: React.memo(EmergencyContactsSettings),
});
