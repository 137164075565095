import { FolderType, SharedFolder } from "../models/SharedFolder";
import { createGuid } from "../util/commons";
import { getUser } from "./user";
import firebase from "../FirebaseConfig";
import { UserSupervised } from "../models/UserSupervised";
import { FileData } from "../models/FileData";
import { SharedFile } from "../models/SharedFile";
import { Comment } from "../models/Comment";

export const createFolderRequest = async (
  currentUserName: string,
  name: string,
  users: string[]
) => {
  const creatorUser = await getUser(currentUserName);
  const creatorName = creatorUser
    ? creatorUser.firstName + " " + creatorUser.lastName[0]
    : currentUserName;
  users.push(currentUserName);

  try {
    const id = createGuid(5);
    const notViewBy = users.filter((user) => user !== currentUserName);
    const folder = {
      creator: currentUserName,
      creatorName: creatorName,
      id: id,
      files: [],
      users: users,
      name: name,
      dateCreate: new Date(),
      type: FolderType.shared,
      notViewBy: notViewBy,
    } as SharedFolder;

    const db = firebase.firestore();
    const res = await db.collection("sharedFolder").doc(id).set(folder);

    return folder;
  } catch (e) {
    alert("Erreur createFolderRequest: " + JSON.stringify(e));
  }
  return false;
};

export const deleteFolderRequest = async (
  id: string,
  supervisedAccound: UserSupervised | null
) => {
  try {
    if (!supervisedAccound) {
      const db = firebase.firestore();
      return await db.collection("sharedFolder").doc(id).delete();
    } else {
      const deleteFolderFunc = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("Folder-deleteFolder");
      return deleteFolderFunc({
        folderId: id,
      }).then((result) => result.data.success === true);
    }
  } catch (e) {
    alert("Erreur deleteFolderRequest: " + JSON.stringify(e));
  }
  return false;
};

export const getFolderRequest = async (
  id: string,
  supervisedAccound: UserSupervised | null
) => {
  try {
    if (!supervisedAccound) {
      const db = firebase.firestore();
      var folder = await db.collection("sharedFolder").doc(id).get();
      return folder.data() as SharedFolder;
    } else {
      const getFolderByIdFunc = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("Folder-getFolderById");
      return getFolderByIdFunc({
        folderId: id,
      }).then((result) => result.data as SharedFolder);
    }
  } catch (e) {
    console.log("Erreur getFolderRequest: " + JSON.stringify(e));
  }
  return null;
};

export const updateFolderRequest = async (
  folder: SharedFolder,
  supervisedAccount: UserSupervised | null,
  fileId: string | null = null,
  comment: Comment | null = null
) => {
  try {
    if (!supervisedAccount) {
      const db = firebase.firestore();
      return await db.collection("sharedFolder").doc(folder.id).update(folder);
    } else {
      const updateFolderFunc = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("Folder-updateFolder");
      return updateFolderFunc({
        folder: folder,
        fileId: fileId,
        comment: comment,
      }).then((result) => result.data as SharedFolder);
    }
  } catch (e) {
    alert("Erreur updateFolderRequest: " + JSON.stringify(e));
  }
  return Promise.resolve(false);
};

/**
 * Get unshared folder
 * @param username
 */
export const getUnSharedFolder = async (username: string) => {
  const db = firebase.firestore();
  return (
    await db
      .collection("sharedFolder")
      .where("users", "array-contains", username)
      .where("creator", "==", username)
      .where("type", "==", FolderType.unshared)
      .get()
  ).docs.map((item) => {
    const relationShipModel = item.data() as SharedFolder;
    relationShipModel.id = item.id;
    return relationShipModel;
  });
};

export const uploadPrivateUserFileRequest = async (
  file: FileData,
  folderId: string,
  currentUser: string,
  usersFolder: string[]
) => {
  const userAuth = firebase.auth().currentUser;
  if (userAuth) {
    try {
      const fileId = createGuid(5);
      const fileName = fileId + "." + file.extension;
      const storage = firebase.storage();
      const filepath = "private/folder/" + folderId + "/" + fileName;
      const docref = storage.ref(filepath);

      if (file.data) {
        return await docref
          .putString(file.data, "base64")
          .then(async (snapshot) => {
            return {
              dateCreate: new Date(),
              id: fileId,
              name: fileName,
              uploadBy: currentUser,
              extension: file.extension,
              sizeKo: snapshot.bytesTransferred / 1000,
              viewBy: [currentUser],
              url: await snapshot.ref.getDownloadURL(),
              comments: [],
              needToastNotification: usersFolder,
            } as SharedFile;
          });
      } else if (file.file) {
        return await docref.put(file.file).then(async (snapshot) => {
          return {
            dateCreate: new Date(),
            id: fileId,
            name: fileName,
            uploadBy: currentUser,
            extension: file.extension,
            sizeKo: snapshot.bytesTransferred / 1000,
            viewBy: [currentUser],
            url: await snapshot.ref.getDownloadURL(),
            comments: [],
            needToastNotification: usersFolder,
          } as SharedFile;
        });
      }
    } catch (e) {
      alert("Erreur storage: " + JSON.stringify(e));
    }
    return null;
  }
};

export const uploadPrivateUserFileRequestBase64 = async (
  fileBase64: string,
  fileExtension: string,
  folderId: string,
  currentUser: string,
  sizeInKb: number
) => {
  const userAuth = firebase.auth().currentUser;
  if (userAuth) {
    try {
      const fileId = createGuid(5);
      const fileName = fileId + "." + fileExtension;
      const storage = firebase.storage();
      const filepath = "private/folder/" + folderId + "/" + fileName;
      const docref = storage.ref(filepath);
      return await docref
        .putString(fileBase64, "base64")
        .then(async (snapshot) => {
          return {
            dateCreate: new Date(),
            id: fileId,
            name: fileName,
            uploadBy: currentUser,
            extension: fileExtension,
            sizeKo: sizeInKb,
            viewBy: [currentUser],
            url: await snapshot.ref.getDownloadURL(),
            comments: [],
            needToastNotification: [],
          } as SharedFile;
        });
    } catch (e) {
      alert("Erreur storage: " + JSON.stringify(e));
    }
    return null;
  }
};

export const uploadAudioCommentRequest = async (
  folder: SharedFolder,
  fileId: string,
  senderEmail: string,
  senderName: string,
  fileData: Blob,
  fileExtension: string,
  supervisedAccount: UserSupervised | null
) => {
  const currentUser = firebase.auth().currentUser;

  if (currentUser) {
    const audioId = createGuid(5);
    const filepath =
      "private/comments/" + fileId + "/" + audioId + "." + fileExtension;
    var storageRef = firebase.storage().ref(filepath);
    return await storageRef
      .put(fileData)
      .then(async (snapshot) => {
        if (folder) {
          var newComment = {
            text: await snapshot.ref.getDownloadURL(),
            senderEmail: senderEmail,
            viewBy: [senderEmail],
            date: new Date(),
            senderName: senderName,
            type: "audio",
          } as Comment;

          folder.files.forEach((file) => {
            if (file.id === fileId) {
              if (!file.comments) file.comments = [];
              file.comments.push(newComment);
            }
          });
          await updateFolderRequest(folder, supervisedAccount);
        }
      })
      .catch((err) =>
        alert("uploadAudioCommentRequest error" + JSON.stringify(err))
      );
  } else {
    return false;
  }
};

export const uploadAlbumFileRequest = async (
  fileData: string,
  fileFormat: string,
  folderId: string
) => {
  const userAuth = firebase.auth().currentUser;
  if (userAuth) {
    try {
      const fileId = createGuid(5);
      const fileName = fileId + "." + fileFormat;
      const storage = firebase.storage();
      const filepath = "private/folder/" + folderId + "/" + fileName;
      const docref = storage.ref(filepath);

      if (fileData) {
        return await docref
          .putString(fileData, "base64")
          .then(async (snapshot) => {
            const url = await snapshot.ref.getDownloadURL();
            return url;
          });
      }
    } catch (e) {
      alert("Erreur storage: " + JSON.stringify(e));
    }
    return null;
  }
};
