import React, { useState, useEffect, useRef } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { IonPage, IonToast } from '@ionic/react';
import './VideoConference.scss';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import VisioCall, { ParticipantState } from '../models/VisioCall';
import { setLoading, setVisioCallData, acceptOrRefuseCall } from '../data/visioCall/visioCall.actions';
import VisioComponent from './VisioComponent';
import Loading from '../components/Loading';
import _ from 'lodash';
import { logErrorOnCall } from '../api/visioCall';
//import Jitsi from 'react-jitsi'
import { Insomnia } from '@awesome-cordova-plugins/insomnia';

declare global {
  interface Window {
    JitsiMeetExternalAPI: any;
  }
}
interface OwnProps extends RouteComponentProps { };

interface StateProps {
  callData: VisioCall;
  username?: string;
};

interface DispatchProps {
  setLoading: typeof setLoading;
  setVisioCallData: typeof setVisioCallData;
  acceptOrRefuseCall: typeof acceptOrRefuseCall;
};
interface VideoConferenceProps extends OwnProps, StateProps, DispatchProps { }


const VideoConference: React.FC<VideoConferenceProps> = ({ callData, history, username, setLoading, setVisioCallData, acceptOrRefuseCall }) => {
  const prevCallDataRef = useRef<VisioCall>();
  useEffect(() => {
    if (prevCallDataRef.current && !callData) {
      // si firestore retourne aucun call mais qu'on en a un en cours
      // et si il n'y a que 2 participants
      // et si l'utilisateur courant à un state = Accepted 
      // et que l'autre participant est à waiting
      // alors ça veut dire que l'autre participant à cliqué sur refuser le call
      if (prevCallDataRef.current.participantEmails.length == 2
        && _.some(prevCallDataRef.current.participantStates, (state) => state.email == username && state.state == ParticipantState.Accepted)
        && _.some(prevCallDataRef.current.participantStates, (state) => state.email != username && state.state == ParticipantState.Waiting)) {
        cancelCall(true);
      }
      else {
        cancelCall(false);
      }
    }

    prevCallDataRef.current = callData;
  }, [callData]);

  useEffect(() => {
    Insomnia.keepAwake();
    return () => {
      Insomnia.allowSleepAgain();
    };
  }, []);

  function cancelCall(redirectMessageVideo: boolean = false) {
    if (callData && username) {

      logErrorOnCall(callData.id, {
        name: "User refuser call",
        type: "RefuseBtnClicked",
        isFatal: false
      }, username);

      acceptOrRefuseCall(callData.id, username, ParticipantState.Completed)
    }
    // force delete current call from state (don't wait firestore refresh snapshop)
    setVisioCallData(undefined);
    setLoading(false);
    if (redirectMessageVideo && prevCallDataRef.current) {
      history.push({
        pathname: '/tabs/contacts/message/create',
        state: {
          isFromCall: true,
          users: prevCallDataRef.current.participantEmails,
          idVisioCall: prevCallDataRef.current.id
        }
      });
    }
    else {
      history.push('/tabs/contacts', { direction: 'none' });
    }
  }



  return (
    <IonPage id="">
      {callData ? <Route component={VisioComponent} /> : <div><Loading enable={true} text="Vous allez être connecté avec " textCancel="Raccrocher" callbackCancel={cancelCall} /></div>}
    </IonPage>
  );
};

//export default React.memo(VideoConference);


export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setLoading,
    setVisioCallData,
    acceptOrRefuseCall,
  },
  mapStateToProps: (state) => {
    return {
      callData: selectors.getVisioCall(state),
      username: state.user.username
    }
  },

  component: React.memo(VideoConference)
});
