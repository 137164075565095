import React from "react";
import {
  IonModal,
  IonPage,
  IonHeader,
  IonToolbar,
  IonCol,
  IonRow,
  IonGrid,
  IonButtons,
  IonButton,
} from "@ionic/react";
import _ from "lodash";
import IconSvg, { IconSvgEnum } from "../IconSvg";
import {
  optionsOutline,
  peopleOutline,
  recordingOutline
} from "ionicons/icons";
import Button from "../Button";
import { UserSupervised } from "../../models/UserSupervised";
import { connect } from "../../data/connect";
import MenuItem from "../MenuItem";
import { defaultPicture } from "../../util/commons";
import "./SupervisedAccountMenu.scss";
import { RouteComponentProps, withRouter } from "react-router";
import { menuController } from "@ionic/core";
import * as selectors from "../../data/selectors";
import classes from "../header.module.scss";

interface OwnProps {
  supervisedAccount: UserSupervised;
  cancelUserControl: () => void;
}
interface StateProps {
  subtitling: boolean;
}
interface DispatchProps {}
interface SupervisedAccountMenuProps
  extends RouteComponentProps, 
    OwnProps,
    StateProps,
    DispatchProps {}

const SupervisedAccountMenu: React.FC<SupervisedAccountMenuProps> = ({
  supervisedAccount,
  history,
  subtitling,
  cancelUserControl,
}) => {
  const routes = [
    {
      id: 1,
      title: `Contacts`,
      path: "/tabs/contacts",
      icon: peopleOutline,
      onlyContactsMode: true,
      onlyCallingMode: false,
      color: "#F1933D",
    },
    {
      id: 2,
      title: `Messages`,
      path: "/tabs/mailbox",
      icon: recordingOutline,
      onlyMailboxMode: true,
      onlyCallingMode: false,
      color: "#195EA4",
    },
    {
      id: 3,
      title: `Albums`,
      path: "/tabs/media/shared",
      icon: "/assets/icon/album.svg",
      onlySharedFilesMode: true,
      onlyCallingMode: false,
      color: "#439423",
    },
    {
      id: 4,
      title: `Réglages`,
      path: "/settings",
      icon: optionsOutline,
      onlyCallingMode: false,
      color: "#D43974",
    },
  ];

  return (
    <IonModal isOpen={true}>
      <IonPage>
        <IonHeader translucent={true}>
          <IonToolbar>
            <div className="header-content">
              <img
                className="supervised-img"
                src={
                  supervisedAccount.user.picture
                    ? supervisedAccount.user.picture
                    : defaultPicture
                }
              />
              <p className="header-title header-title-size">
                Supervisions autorisées pour
                <br />
                <b style={{ color: "red" }}>
                  {supervisedAccount.user.firstName +
                    " " +
                    supervisedAccount.user.lastName}
                </b>
              </p>
            </div>
            <IonButtons slot="end">
          <IonButton type="button" onClick={() => {history.push("/tabs/help"); menuController.close();} }>
              <div>
                <IconSvg icon={IconSvgEnum.info} />{" "}
                  {subtitling && <span className={classes.iconTitle}>Aide</span>}
                </div>
            </IonButton>
          </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div style={{ flexGrow: 1, overflow: "auto" }}>
          <IonGrid fixed className={"grid-height"}>
            <IonRow style={{ height: "100%" }}>
              {routes.map((route) => (
                <IonCol
                  key={route.title}
                  size="12"
                  size-lg="6"
                  size-md="6"
                  size-sm="6"
                  size-xs="6"
                  auto-hide="false"
                >
                  <MenuItem route={route} />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </div>
        <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
          <IonRow>
            <IonCol>
              <Button
                color="light"
                onClick={() => cancelUserControl()}
                text={
                  "Annuler la supervision de " +
                  supervisedAccount.user.firstName
                }
                svg={IconSvgEnum.cancel}
              />
            </IonCol>
          </IonRow>
        </div>
      </IonPage>
    </IonModal>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    subtitling: selectors.getSubtitlingConfig(state),
  }),
  mapDispatchToProps: {},
  component: withRouter(SupervisedAccountMenu),
});
