import { toast } from "react-toastify";
import {
  createSupervisorRequest,
  deleteSupervisorRequest,
  deleteSupervisorRequestAdmin,
  getSupervisedUserData,
  valdiateSupervisorRequest,
} from "../../api/supervisorRelationship";
import { SupervisorRelationship } from "../../models/SupervisorRelationship";
import { UserSupervised } from "../../models/UserSupervised";
import { ActionType } from "../../util/types";

/**
 * Create new supervisor request
 * @param administered
 * @param administrator
 * @returns
 */
export const sendSupervisorRequest =
  (administered: string, administrator: string) =>
  async (dispatch: React.Dispatch<any>) => {
    await createSupervisorRequest(administered, administrator);
    toast.success("Votre demande de supervision est bien envoyée");
  };

export const removeSupervisorRequest =
  (administered: string, administrator: string) =>
  async (dispatch: React.Dispatch<any>) => {
    await deleteSupervisorRequest(administered, administrator);
    toast.success("Votre demande de supervision a bien bien été annulée");
  };

export const processSupervisorRequest =
  (administered: string, administrator: string, accepted: boolean) =>
  async (dispatch: React.Dispatch<any>) => {
    console.log("processSupervisorRequest", accepted);
    if (accepted) {
      await valdiateSupervisorRequest(administered, administrator);
      toast.success("La demande de supervision a bien été validée");
    } else {
      await deleteSupervisorRequest(administered, administrator);
      toast.success("La demande de supervision a bien été refusée");
    }
  };

export const loadAndSetUserSupervised =
  (userName: string) => async (dispatch: React.Dispatch<any>) => {
    if (userName) {
      dispatch(setLoading(true));
      getSupervisedUserData(userName)
        .then((userSupervised) => {
          localStorage.setItem(
            "userSupervised",
            JSON.stringify(userSupervised)
          );
          dispatch(setUserSupervised(userSupervised));
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    } else {
      dispatch(setUserSupervised(undefined));
    }
  };

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-supervisor-loading",
    isLoading,
  } as const);

export const setSupervisorRequests = (requests: SupervisorRelationship[]) =>
  ({
    type: "set-supervisor-requests",
    requests,
  } as const);

export const setUserSupervised = (user?: UserSupervised) =>
  ({
    type: "set-user-supervised",
    user,
  } as const);

export const removeSupervisorRequestAdmin =
  (administered: string, administrator: string) =>
  async (dispatch: React.Dispatch<any>) => {
    await deleteSupervisorRequestAdmin(administered, administrator);
    toast.success("Votre demande de supervision a bien bien été annulée");
  };

export type SupervisorActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setSupervisorRequests>
  | ActionType<typeof setUserSupervised>;
