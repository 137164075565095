import React, { useState } from "react";
import { useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { IonRow, IonCol, IonLabel } from "@ionic/react";
import Button from "../components/Button";
import { stopCircle } from "ionicons/icons";
import "./RecordView.scss";
import { isPlatform } from "@ionic/core";
import Countdown from "react-countdown";
import { _TimerRecordVideo } from "../appConfig";
const VideoPreview = ({
  stream,
  videoRef,
}: {
  stream: MediaStream | null;
  videoRef: any;
}) => {
  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          if (videoRef && videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
          }
        });
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.srcObject = null;
        videoRef.current && videoRef.current.pause();
      }
    };
  }, []);

  return (
    <video
      ref={videoRef}
      autoPlay
      controls
      className={"video-record"}
      poster={"assets/img/video-poster.jpg"}
    />
  );
};
interface RecordViewProps {
  setVideoBlobUrl: (url: string | null) => void;
}
const RecordView: React.FC<RecordViewProps> = ({ setVideoBlobUrl }) => {
  let videoRef = React.useRef<HTMLVideoElement>(null);
  const [startRecord, setStartRecord] = useState(-1);
  const [startCount, setStartCount] = useState(-1);
  const {
    startRecording,
    stopRecording,
    clearBlobUrl,
    previewStream,
    mediaBlobUrl,
  } = useReactMediaRecorder({
    video: true,
  });
  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.srcObject = null;
        videoRef.current.currentTime = 0;
        videoRef.current && videoRef.current.pause();
      }
      stopRecording();
      clearBlobUrl();
      setStartRecord(-1);
      setVideoBlobUrl(null);
    };
  }, []);
 
  const isDesktop = isPlatform("desktop");
  setVideoBlobUrl(mediaBlobUrl);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <IonRow className="call-toolbar">
        <IonCol size="6">
          <Button
            className={" btn-option-call"}
            onClick={() => {
              if(videoRef.current) videoRef.current.currentTime = 0;
              if (startRecord === -1) {
                setStartCount(1)

              } else if (startRecord === 0) {
                clearBlobUrl();
                setStartCount(1)
              }
            }}
            text="Commencer"
            icon={"assets/icon/record.svg"}
          />
        </IonCol>
        <IonCol size="6">
          <Button
            className={" btn-option-call"}
            onClick={() => {
              if(videoRef.current) videoRef.current.currentTime = 0;
              setStartRecord(0);
              stopRecording();
             setStartCount(-1)
            }}
            text="Terminer"
            disabled={startCount === 1 || startRecord !== 1}
            icon={stopCircle}
          />
        </IonCol>
      </IonRow>
      <div style={{ width: "100%", position: "relative" }}>
        {startCount === 1 && <Countdown
          date={Date.now() + _TimerRecordVideo}
          onComplete={() => {
            setStartRecord(1);
            startRecording();
            setStartCount(-1)
          }}
          renderer={({ seconds, completed }) => {
            if (!completed)
              return (
                <div className="counter-record">
                  <span >{seconds}</span>
                </div>
              );
            else return <span>Votre enregistrement va commencer ...</span>;
          }}
        />}
        {startRecord === 1 && (
          <div style={{ position: "absolute", zIndex: 10, right: 0 }}>
            {isDesktop && <IonLabel className="text-record" color="danger">Enregistrement en cours</IonLabel>}<button className={"button-record Rec"}></button>
          </div>
        )}
        {startRecord === 1 && (<VideoPreview stream={previewStream} videoRef={videoRef} />)}
        {mediaBlobUrl && startRecord != 1 && (
          <video
            ref={videoRef}
            src={mediaBlobUrl}
            controls
            className={"video-record"}
          />
        )}
        {(startRecord == -1 || startCount === 1) && (
          <video
            ref={videoRef}
            className={"video-record"}
            poster={"assets/img/video-poster.jpg"}
          />
        )}
      </div>
    </div>
  );
};

export default RecordView;
