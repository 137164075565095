import { menuController } from "@ionic/core";
import { toast } from "react-toastify";
import { _NameUnsharedFolder } from "../appConfig";
import { setLoading } from "../data/mediaShareRequest/mediaShareRequest.actions";
import firebase from "../FirebaseConfig";
import { MediaShareRequest } from "../models/MediaShareRequest";
import { SharedFile } from "../models/SharedFile";
import { FolderType, SharedFolder } from "../models/SharedFolder";
import { createGuid } from "../util/commons";
import { deleteFileFromStorageRequest } from "../util/firebaseStorage";
import { getBase64FromUrl } from "../util/functions";
import { getUnSharedFolder } from "./sharedFolder";
import { getUser } from "./user";

/**
 * Create a new media share request
 */
export const createMediaShareRequest = async (
  senderEmail: string,
  senderName: string,
  receivers: string[],
  fileBase64: string,
  fileExtension: string,
  sizeInKb: number
) => {
  const userAuth = firebase.auth().currentUser;
  if (userAuth) {
    try {
      const fileId = createGuid(5);
      const fileName = fileId + "." + fileExtension;
      const storage = firebase.storage();
      const filepath = "private/media/" + userAuth.uid + "/" + fileName;
      const docref = storage.ref(filepath);
      await docref.putString(fileBase64, "base64").then(async (snapshot) => {
        const fileUrl = await snapshot.ref.getDownloadURL();
        const entityId = createGuid(5);
        const db = firebase.firestore();
        await db
          .collection("mediaShareRequest")
          .doc(entityId)
          .set({
            id: entityId,
            senderEmail: senderEmail,
            senderName: senderName,
            receivers: receivers,
            fileUrl: fileUrl,
            fileName: fileName,
            fileExtension: fileExtension,
            fileSizeKo: sizeInKb,
            fileId: fileId,
          } as MediaShareRequest);
      });
      return true;
    } catch (e) {
      alert("Erreur createMediaShareRequest: " + JSON.stringify(e));
    }
    return null;
  }
};

/**
 * update or delete media share request
 */
export const updateOrDeleteMediaShareRequest = async (
  mediaShareRequest: MediaShareRequest,
  currentUserEmail: string
) => {
  try {
    const db = firebase.firestore();
    if (mediaShareRequest.receivers.length > 1) {
      const users = mediaShareRequest.receivers.filter(
        (email) => email !== currentUserEmail
      );
      await db
        .collection("mediaShareRequest")
        .doc(mediaShareRequest.id)
        .update({
          receivers: users,
        } as MediaShareRequest);
      return true;
    } else {
      await deleteFileFromStorageRequest(mediaShareRequest.fileUrl);
      await db
        .collection("mediaShareRequest")
        .doc(mediaShareRequest.id)
        .delete();
    }
  } catch (e) {
    alert("Erreur refuseMediaShareRequest: " + JSON.stringify(e));
  }
};

export const acceptMediaShareRequest = async (
  mediaShareRequest: MediaShareRequest,
  currentUserName: string
) => {
  try {
    const base64Data = await getBase64FromUrl(mediaShareRequest.fileUrl);
    if (base64Data) {
      const unsharedFolder = await getUnSharedFolder(currentUserName);
      const id =
        unsharedFolder && unsharedFolder[0]
          ? unsharedFolder[0].id
          : createGuid(5);
      const fileId = createGuid(5);
      const fileName = fileId + "." + mediaShareRequest.fileExtension;
      const storage = firebase.storage();
      const filepath = "private/folder/" + id + "/" + fileName;
      const docref = storage.ref(filepath);
      await docref
        .putString(base64Data.split(",")[1], "base64")
        .then(async (snapshot) => {
          const url = await snapshot.ref.getDownloadURL();
          const file = {
            dateCreate: new Date(),
            id: fileId,
            name: fileName,
            uploadBy: mediaShareRequest.senderEmail,
            extension: mediaShareRequest.fileExtension,
            sizeKo: snapshot.bytesTransferred / 1000,
            viewBy: [],
            url: url,
            comments: [],
            needToastNotification: [],
          } as SharedFile;
          if (unsharedFolder.length === 0) {
            const creatorUser = await getUser(currentUserName);
            const creatorName = creatorUser
              ? creatorUser.firstName + " " + creatorUser.lastName[0]
              : currentUserName;

            const folder = {
              creator: currentUserName,
              creatorName: creatorName,
              id: id,
              files: [file],
              users: [currentUserName],
              name: _NameUnsharedFolder,
              dateCreate: new Date(),
              notViewBy: [currentUserName],
              type: FolderType.unshared,
            } as SharedFolder;
            const db = firebase.firestore();
            await db.collection("sharedFolder").doc(id).set(folder);
            await updateOrDeleteMediaShareRequest(
              mediaShareRequest,
              currentUserName
            );
          } else {
            if (unsharedFolder && unsharedFolder[0]) {
              const db = firebase.firestore();
              const recentFiles = unsharedFolder[0].files;
              recentFiles.unshift(file);
              await db
                .collection("sharedFolder")
                .doc(unsharedFolder[0].id)
                .update({ files: recentFiles } as SharedFolder);
              await updateOrDeleteMediaShareRequest(
                mediaShareRequest,
                currentUserName
              );
            }
          }
        });
    }
  } catch (e) {
    alert("Erreur acceptMediaShare: " + JSON.stringify(e));
  }
};
