import { IonCol, IonContent, IonLabel, IonModal, IonRow, IonSkeletonText } from "@ionic/react";
import { trashOutline, videocamOutline,arrowBackCircleOutline} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { deleteMailboxMessage, setMessageViewByUser } from "../../data/user/user.actions";
import { MailboxItem, MailboxItemType } from "../../models/Mailbox";
import Button from "../Button";
import VideoPlayer from "../VideoPlayer";
import * as selectors from "../../data/selectors";
import { UserWithRelationship } from "../../models/UserWithRelationship";
import { connect } from "../../data/connect";
import { getDateString } from "../../util/commons";
import { RouteComponentProps, withRouter } from "react-router";
import { readFileFromCache } from "../../util/localStorage";
import { setShowOffline, setUserIsActive } from "../../data/global/global.actions";
import { UserSupervised } from "../../models/UserSupervised";
import Header from "../Header";

interface OwnProps {
  showMessageModal: boolean;
  currentMessage: MailboxItem | undefined;
  hideMessage: () => void;
  callUser?: (email: string) => void;
}

interface StateProps {
  annuaire: UserWithRelationship[];
  currentUserEmail?: string;
  supervisedAccount?: UserSupervised;
  appOnline: boolean;
}

interface DispatchProps {
  deleteMailboxMessage: typeof deleteMailboxMessage;
  setUserIsActive: typeof setUserIsActive;
  setShowOffline: typeof setShowOffline;
  setMessageViewByUser: typeof setMessageViewByUser;
}
interface ShowMessageModalProps extends RouteComponentProps, OwnProps, StateProps, DispatchProps { }

const ShowMessageModal: React.FC<ShowMessageModalProps> = ({
  showMessageModal,
  currentMessage,
  annuaire,
  currentUserEmail,
  history,
  supervisedAccount,
  appOnline,
  deleteMailboxMessage,
  hideMessage,
  callUser,
  setUserIsActive,
  setShowOffline,
  setMessageViewByUser
}) => {
  const userPicture = selectors.getPictureUser(
    annuaire,
    currentMessage ? currentMessage.fromUser : ""
  );
  const [srcPhoto, setSrcPhoto] = useState("");
  const [srcVideo, setSrcVideo] = useState("");

  useEffect(() => {
    if (
      currentMessage &&
      !currentMessage.viewed &&
      currentUserEmail &&
      !supervisedAccount
    )
      setMessageViewByUser(currentUserEmail, currentMessage);
  }, []);
  useEffect(() => {
    readFileFromCache(userPicture, setSrcPhoto);
  }, [userPicture]);

  useEffect(() => {
    if (currentMessage) {
      readFileFromCache(currentMessage.urlFile, setSrcVideo);
    }
  }, [currentMessage]);

  const userName = selectors.getUserName(annuaire, currentMessage ? currentMessage.fromUser : "");
  const date = getDateString(currentMessage ? currentMessage.date : null);

  async function deleteMessage(message: MailboxItem) {
    if (!appOnline) {
      setShowOffline(true);
      return;
    }
    if (currentUserEmail) {
      const successMessage =
        message.type === MailboxItemType.MissingCall
          ? "Votre appel manqué a été supprimé"
          : "Votre message enregistré a été supprimé";
      deleteMailboxMessage(
        currentUserEmail,
        message,
        successMessage,
        supervisedAccount
      );
      hideMessage();
    }
  }
  const recordVideoMsg = () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    if(currentMessage){
      setUserIsActive(true);
      history.push({
        pathname: '/tabs/contacts/message/create',
        state: {
          isFromMissedCallMessage: true,
          users: [currentMessage.fromUser]
        }
      })
    }
  }
  const isSavedMessage = currentMessage && currentMessage.type === MailboxItemType.MessageVideo;
  const isMissedCall = currentMessage && (!currentMessage.type || currentMessage.type === MailboxItemType.MissingCall);

  return (
    <IonModal
      isOpen={showMessageModal}
      onDidDismiss={() => hideMessage()}
      cssClass="mailbox-message"
    >
      <Header hideHome title={isSavedMessage ? "Message enregistré" : "Appel manqué"} />
      <IonContent>
        {isSavedMessage && (
          <div className="mailbox-message-video-container">
            {srcVideo && <VideoPlayer
              classname="image-gallery-image"
              url={srcVideo}
              type={"video/mp4"}
              play={true}
              loop={false}
              muted={false}
              controls={true}
            />}
            {srcVideo === "" && <IonSkeletonText animated />}
          </div>
        )}
        {isMissedCall && (
          <div className="mailbox-message-video-container">
            <div className="user-picture">
              <img src={srcPhoto} />
            </div>
            <IonLabel className={" ion-text-wrap"}>
              <b>{userName}</b> a tenté de vous joindre le {date}
            </IonLabel>
            {!supervisedAccount && <IonRow style={{ width: "100%", marginTop: "20px" }}>
              <IonCol size="12">
                <Button
                  color="primary"
                  onClick={() => callUser ? callUser(currentMessage.fromUser) : {}}
                  text="Rappeler"
                  icon={videocamOutline}
                />
              </IonCol>
              <IonCol size="12">
                <Button
                  color="primary"
                  onClick={recordVideoMsg}
                  text="Enregistrer"
                  icon={"assets/icon/record-message-white.svg"}
                />
              </IonCol>
            </IonRow>}
          </div>
        )}
        <IonRow style={{ width: "100%" }}>
        <IonCol size="6">
            <Button
              color="light"
              onClick={() => hideMessage()}
              text="Retour"
              icon={arrowBackCircleOutline}
            />
          </IonCol>
          <IonCol size="6">
            <Button
              color="danger"
              onClick={() =>
                currentMessage ? deleteMessage(currentMessage) : null
              }
              text="Supprimer"
              icon={trashOutline}
            />
          </IonCol>
        
        </IonRow>
      </IonContent>
    </IonModal>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    deleteMailboxMessage,
    setUserIsActive,
    setShowOffline,
    setMessageViewByUser
  },
  mapStateToProps: (state) => ({
    annuaire: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.annuaire: state.user.annuaire ? state.user.annuaire : [],
    currentUserEmail: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username,
    supervisedAccount: state.supervisor.supervisedAccount,
    appOnline: state.global.appOnline
  }),
  component: withRouter(ShowMessageModal),
});
