import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
} from "@ionic/react";
import React, { Fragment } from "react";
import { connect } from "../../data/connect";
import _ from "lodash";
import Header from "../Header";
import { ContactGroup } from "../../models/ContactGroup";
import { IconSvgEnum } from "../IconSvg";
import Button from "../Button";
import "./ContactGroup.scss";
import firebase from "../../FirebaseConfig";
import ContactChip from "../ContactChip";
import * as selectors from "../../data/selectors";
import { UserWithRelationship } from "../../models/UserWithRelationship";
import { User } from "../../models/User";
import { arrowBackCircleOutline } from "ionicons/icons";
import { convertToTimestamp } from "../../util/functions";
interface OwnProps {
  group: ContactGroup;
  hist: any;
  onCancel: () => void;
}

interface StateProps {
  users: UserWithRelationship[];
  currentUser: User | undefined;
}

interface DispatchProps {}
interface ShowContactGroupDetailsProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ShowContactGroupDetails: React.FC<ShowContactGroupDetailsProps> = ({
  group,
  users,
  currentUser,
  onCancel,
}) => {
  const members = users.map((user) => {
    if (user && group.members.includes(user.email)) {
      return user;
    }
  });
  members.push(currentUser as UserWithRelationship);
  const dateCreate = convertToTimestamp(group.dateCreate);

  return (
    <IonModal isOpen={true} onDidDismiss={() => onCancel()}>
      <Header hideHome title="Détails du groupe" />
      <IonContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol
              size="12"
              className="center-flex"
              style={{ flexDirection: "column" }}
            >
              <img
                style={!group.picture ? {padding: "10px"}: {}}
                className={"groupUserPicture"}
                src={group.picture ? group.picture : "assets/icon/group-white.svg"}
                alt="avatar"
              />
              <IonLabel style={{ color: "var(--ion-color-primary)" }}>
                {group.name.toUpperCase()}
              </IonLabel>
            </IonCol>
            <IonCol>
              <IonList inset={true} lines={"none"}>
                <IonItemDivider>
                  <IonLabel>Créateur</IonLabel>
                </IonItemDivider>
                <IonItem>
                  <IonLabel>
                    <b>{group.creatorName}</b>
                  </IonLabel>
                </IonItem>
                {dateCreate && (
                  <Fragment>
                    <IonItemDivider>
                      <IonLabel>Date de création</IonLabel>
                    </IonItemDivider>
                    <IonItem>
                      <IonLabel>
                        <b>{dateCreate.toDate().toLocaleDateString()}</b>
                      </IonLabel>
                    </IonItem>
                  </Fragment>
                )}
                <IonItemDivider>
                  <IonLabel>Membres</IonLabel>
                </IonItemDivider>
                <IonItem>
                  <IonCol size="12" style={{ marginTop: "20px" }}>
                    {members.map(
                      (member) =>
                        member && (
                          <ContactChip
                            key={member.id}
                            image={member.picture}
                            label={member.firstName + " " + member.lastName}
                            contact={member}
                          />
                        )
                    )}
                  </IonCol>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
        <IonRow class="ion-no-padding">
          <IonCol size="12" class="ion-no-padding">
            <Button
              color="light"
              onClick={() => onCancel()}
              text="Retour"
              icon={arrowBackCircleOutline}
            />
          </IonCol>
        </IonRow>
      </div>
    </IonModal>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {},
  mapStateToProps: (state) => ({
    users: selectors.getUsers(state) || [],
    currentUser: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user
      : state.user.userFirestore,
  }),
  component: React.memo(ShowContactGroupDetails),
});
