import { IonCol, IonContent, IonModal, IonRow, IonText } from "@ionic/react";
import React from "react";
import { connect } from "../../data/connect";
import _ from "lodash";
import Header from "../Header";
import { ContactGroup } from "../../models/ContactGroup";
import Button from "../Button";
import { IconSvgEnum } from "../IconSvg";
import { trashOutline } from "ionicons/icons";
import { deleteContactGroup } from "../../data/user/user.actions";
import { setShowOffline, setToastNotification } from "../../data/global/global.actions";
import { ToastNotification } from "../../models/ToastNotification";
import { UserSupervised } from "../../models/UserSupervised";
import { arrowBackCircleOutline } from "ionicons/icons";

interface OwnProps {
  group: ContactGroup;
  hist: any;
  onCancel: () => void;
}

interface StateProps {
  currentUsername: string;
  supervisedAccount: UserSupervised | null;
  appOnline: boolean;
}

interface DispatchProps {
  deleteContactGroup: typeof deleteContactGroup;
  setToastNotification: typeof setToastNotification;
  setShowOffline: typeof setShowOffline;
}
interface DeleteContactGroupProps extends OwnProps, StateProps, DispatchProps {}

const DeleteContactGroup: React.FC<DeleteContactGroupProps> = ({
  group,
  supervisedAccount,
  currentUsername,
  appOnline,
  onCancel,
  hist,
  deleteContactGroup,
  setToastNotification,
  setShowOffline
}) => {
  async function onDeleteContactGroup(group: ContactGroup) {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    deleteContactGroup(group, currentUsername, supervisedAccount);
    hist.push("/tabs/settings/contacts/groups");
  }
  return (
    <IonModal isOpen={true} onDidDismiss={() => onCancel()}>
      <Header hideHome title="Suppression du groupe" />
      <IonContent>
        <IonText color="danger" style={{ textAlign: "center" }}>
          <h1>
            Êtes-vous sûr de vouloir supprimer le groupe: <b>{group.name}</b> ?
          </h1>
        </IonText>
      </IonContent>
      <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
        <IonRow style={{ width: "100%" }}>
          <IonCol size={"6"}>
            <Button
              color="light"
              onClick={() => onCancel()}
              text="Retour"
              icon={arrowBackCircleOutline}
            />
          </IonCol>
          <IonCol size="6">
            <Button
              color="danger"
              onClick={() => onDeleteContactGroup(group)}
              text="Supprimer"
              icon={trashOutline}
            />
          </IonCol>
        </IonRow>
      </div>
    </IonModal>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    deleteContactGroup,
    setToastNotification,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    currentUsername: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user.email
      : state.user.username
      ? state.user.username
      : "",
    supervisedAccount: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount
      : null,
    appOnline: state.global.appOnline
  }),
  component: React.memo(DeleteContactGroup),
});
