import React, { Fragment, useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonLabel,
  IonButton,
  IonIcon,
  IonModal,
  IonToast,
  IonItem,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonButtons

} from "@ionic/react";
import { isPlatform } from "@ionic/core";
import {
  trashOutline,
  imageOutline,
  addOutline,
  cameraOutline,
  imagesOutline,
  personAddOutline,
  createOutline,
  sendOutline,
  closeSharp,
  arrowBack,
  arrowBackCircleOutline
} from "ionicons/icons";
import Loading from "../components/Loading";
import { connect } from "../data/connect";
import { UserWithRelationship } from "../models/UserWithRelationship";
import { FolderType, SharedFolder } from "../models/SharedFolder";
import { SharedFile } from "../models/SharedFile";
import {
  uploadPictureToFolder,
  deleteFile,
  deleteFolder,
  updateViewByFileForFolder,
  updateFolderUsers,
  updateFolderName,
  addComment,
  updateViewByFolderComment,
  uploadAudioComment,
} from "../data/sharedFolder/sharedFolder.actions";
import * as selectors from "../data/selectors";
import _ from "lodash";
import "./SharedFolderDetails.scss";
import { RouteComponentProps } from "react-router-dom";
import { Camera, CameraResultType, CameraSource, CameraDirection } from '@capacitor/camera'
import { Directory, Filesystem } from "@capacitor/filesystem";

import ImageGallery from "react-image-gallery";
import {
  _fileSizeLimitPhoto,
  _fileSizeLimitVideo,
  _MaxCharNewComment,
} from "../appConfig";
import firebase from "../FirebaseConfig";
import {
  validFormatsForSharedFolder,
  validFormatsForSharedFolderVideo,
  validFormatsForSharedFolderImage,
} from "../util/commons";
import {
  setShowOffline,
  setToastNotification,
  setUserIsActive,
} from "../data/global/global.actions";
import { ToastNotification } from "../models/ToastNotification";
import CommentsSection from "../components/CommentsSection";
import { User } from "../models/User";
import { Comment } from "../models/Comment";
import { FileData } from "../models/FileData";
import { FileUploadProgess } from "../models/FileUploadProgess";
import {
  FileTransfer,
  FileTransferError,
  FileTransferObject,
} from "@ionic-native/file-transfer";
import { DirectoryEntry, File } from "@ionic-native/file";
import Button from "../components/Button";
import { IconSvgEnum } from "../components/IconSvg";
import Header, { ButtonData } from "../components/Header";
import SelectMultipleUsers from "../components/SelectMultipleUsers";
import Gallery from "react-photo-gallery";
import PictureItem from "../components/PictureItem";
import ShowPicture from "../components/Modals/ShowPicture";
import axios from "axios";
import {
  CaptureError,
  MediaCapture,
  MediaFile,
} from "@ionic-native/media-capture";
import RecordAudio from "../components/RecordAudio";
import { BackgroundTask } from '@robingenz/capacitor-background-task';
import FileDetails from "../components/albums/FileDetails";
import { UserSupervised } from "../models/UserSupervised";
import { loadAndSetUserSupervised } from "../data/supervisor/supervisor.actions";
import { convertToTimestamp } from "../util/functions";
import { ContactGroup } from "../models/ContactGroup";
import { getGlobalConfig } from "../api/globalConfig";
interface OwnProps { }

interface StateProps {
  folders: SharedFolder[];
  currentUserEmail?: string;
  users: UserWithRelationship[];
  groups: ContactGroup[];
  currentUser?: User;
  fileUploadProgress?: FileUploadProgess;
  isHelpedProfil: boolean;
  soundEnabled: boolean;
  loading: boolean;
  supervisedAccount: UserSupervised | null;
  appOnline: boolean;
}

interface DispatchProps {
  uploadPictureToFolder: typeof uploadPictureToFolder;
  deleteFile: typeof deleteFile;
  deleteFolder: typeof deleteFolder;
  updateViewByFileForFolder: typeof updateViewByFileForFolder;
  updateFolderUsers: typeof updateFolderUsers;
  setToastNotification: typeof setToastNotification;
  updateFolderName: typeof updateFolderName;
  addComment: typeof addComment;
  updateViewByFolderComment: typeof updateViewByFolderComment;
  setUserIsActive: typeof setUserIsActive;
  uploadAudioComment: typeof uploadAudioComment;
  loadAndSetUserSupervised: typeof loadAndSetUserSupervised;
  setShowOffline: typeof setShowOffline;
}

interface SharedFolderDetailsProps
  extends RouteComponentProps<{ id: string }>,
  OwnProps,
  StateProps,
  DispatchProps { }

const SharedFolderDetails: React.FC<SharedFolderDetailsProps> = ({
  history,
  currentUserEmail,
  match,
  folders,
  users,
  groups,
  currentUser,
  fileUploadProgress,
  isHelpedProfil,
  soundEnabled,
  loading,
  supervisedAccount,
  appOnline,
  updateViewByFolderComment,
  uploadPictureToFolder,
  deleteFile,
  deleteFolder,
  updateViewByFileForFolder,
  updateFolderUsers,
  setToastNotification,
  updateFolderName,
  addComment,
  setUserIsActive,
  uploadAudioComment,
  loadAndSetUserSupervised,
  setShowOffline
}) => {
  const folder = _.find(folders, (folder) => folder.id == match.params.id);
  const [uploadError, setUploadError] = useState("");
  const [currentPicture, setCurrentPicture] = useState<SharedFile>();

  const [showPictureDetails, setShowPictureDetails] = useState(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [showAddPersonModal, setShowAddPersonModal] = useState(false);
  const [showFolderSettingModal, setShowFolderSettingModal] = useState(false);
  const [showEditFolderNameModal, setShowEditFolderNameModal] = useState(false);
  const [showPictureComments, setShowPictureComments] = useState(false);

  const [loadingDownloadFile, setLoadingDownloadFile] = useState(false);

  const [formUsers, setFormUsers] = useState<string[]>([]);
  const [formGroups, setFormGroups] = useState<ContactGroup[]>([]);
  const [folderName, setFolderName] = useState(folder ? folder.name : "");

  const [showChoosePictureSource, setShowChoosePictureSource] = useState(false);

  const gallery = React.createRef<ImageGallery>();
  const inputFile = React.createRef<HTMLInputElement>();

  const [newComment, setNewComment] = useState("");
  const [showPicture, setShowPicture] = useState(false);
  const [albumDownload, setAlbumDownload] = useState(false);
  const [currentComments, setCurrentComments] = useState<Comment[]>([]);
  const [indexCurrentPicture, setIndexCurrentPicture] = useState(0);

  const [newCommentType, setNewCommentType] = useState("text");
  const [startAudioRecord, setStartAudioRecord] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>();

  const fileDateCreation = currentPicture ? convertToTimestamp(currentPicture.dateCreate) : undefined;

  useEffect(() => {
    setUserIsActive(false);
  }, []);
  useEffect(() => {
    if (currentPicture) {
      setCurrentComments(currentPicture.comments);
    }
  }, [currentPicture]);

  useEffect(() => {
    // when there are changes in the folder, refresh current picture
    if (currentPicture && folder && folder.files.length > 0) {
      setCurrentComments(folder.files[indexCurrentPicture].comments);
    }
  }, [folders]);

  useEffect(() => {
    if (currentUserEmail) {
      updateViewByFileForFolder(currentUserEmail, match.params.id, supervisedAccount);
    }

    const folder = _.find(folders, (folder) => folder.id == match.params.id);
    if (folder) {
      setFormUsers(folder.users);
    }
  }, [match.params.id, currentUserEmail]);

  useEffect(() => {
    if (currentPicture && showPictureComments) {
      onCommentViewByUser();
    }
  }, [currentPicture, showPictureComments]);

  if (!folder) {
    history.push("/tabs/media/shared");
    return null;
  }

  const updateSelectedUsers = (selectedUsers: string[]) => {
    setFormUsers(selectedUsers);
  };
  const updateSelectedGroups = (selectedGroups: ContactGroup[]) => {
    setFormGroups(selectedGroups);
  }


  async function uploadPicture(files: FileData[]) {
    const config = await getGlobalConfig(isHelpedProfil,currentUserEmail);
    const maxNumberPicPerAlbum = config.album?.maxNumberPicPerAlbum;
    const maxNumberVideoPerAlbum = config.album?.maxNumberVideoPerAlbum;
    const validFormatsForSharedFolderImageInit = config.album?.pictureExtensionAccepted ? config.album.pictureExtensionAccepted.split(",") : validFormatsForSharedFolderImage;
    const validFormatsForSharedFolderVideoInit = config.album?.videoExtensionAccepted ? config.album.videoExtensionAccepted.split(",") : validFormatsForSharedFolderVideo;
    const validFormatsForSharedFolderInit= [
      ...validFormatsForSharedFolderImageInit,
      ...validFormatsForSharedFolderVideoInit
   ]
    const fileSizeLimitVideo = config.album?.maxSizeVideo;
    const fileSizeLimitPhoto = config.album?.maxSizePicture;
    // if true, some files have an invalid extension
    let countFormatError = 0;
    let countSizeError = 0;

    const countPictureToUpload = _.filter(files, (file) =>
      _.some(validFormatsForSharedFolderImageInit, (f) => f === file.extension)
    ).length;
    const countVideoToUpload = _.filter(files, (file) =>
      _.some(validFormatsForSharedFolderVideoInit, (f) => f === file.extension)
    ).length;
    const countPictureAlreadyExist = folder
      ? _.filter(folder.files, (file) =>
        _.some(validFormatsForSharedFolderImageInit, (f) => f === file.extension)
      ).length
      : 0;
    const countVideoAlreadyExist = folder
      ? _.filter(folder.files, (file) =>
        _.some(validFormatsForSharedFolderVideoInit, (f) => f === file.extension)
      ).length
      : 0;
    const countPicInFolder = countPictureAlreadyExist + countPictureToUpload;
    const countVideoInFolder = countVideoAlreadyExist + countVideoToUpload;
    if (maxNumberPicPerAlbum && +maxNumberPicPerAlbum < countPicInFolder) {
      setToastNotification({
        message:
          "Le nombre maximum de " +
          maxNumberPicPerAlbum +
          " photo(s) autorisée(s) a déjà été atteint",
        color: "danger",
      } as ToastNotification);
      return;
    }
    if (maxNumberVideoPerAlbum && +maxNumberVideoPerAlbum < countVideoInFolder) {
      setToastNotification({
        message:
          "Le nombre maximum de " +
          maxNumberVideoPerAlbum +
          " vidéo(s) autorisée(s) a déjà été atteint",
        color: "danger",
      } as ToastNotification);
      return;
    }
    //var folderLimitsExceeded = checkFolderLimitsExceeded(_.some(validFormatsForSharedFolderVideo, (f) => f === file.extension), _.some(validFormatsForSharedFolderImage, (f) => f === file.extension));

    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      if (!_.some(validFormatsForSharedFolderInit, (f) => f === file.extension)) {
        countFormatError++;
        break;
      } else if (
        _.some(validFormatsForSharedFolderVideoInit, (f) => f === file.extension) && fileSizeLimitVideo &&
        file.sizeInKb > (+fileSizeLimitVideo)
      ) {
        countSizeError++;
        break;
      } else if (
        _.some(validFormatsForSharedFolderImageInit, (f) => f === file.extension) && fileSizeLimitPhoto &&
        file.sizeInKb > (+fileSizeLimitPhoto)
      ) {
        countSizeError++;
        break;
      }
    }

    if (countFormatError > 0) {
      if (countFormatError > 1)
        setUploadError("Un des fichiers possède un format non valide");
      else setUploadError("Le format du fichier n'est pas valide");
    } else if (countSizeError > 0 && fileSizeLimitPhoto && fileSizeLimitVideo) {
      setUploadError(
        "Les photos dont la taille dépasse " +
        (+fileSizeLimitPhoto) / 1000 +
        " Mo et les vidéos dépassant " +
        (+fileSizeLimitVideo) / 1000 +
        " Mo ne peuvent pas être importées dans l'album"
      );
      setUploadError(
        "Votre fichier dépasse la taille limite pour être importé dans l'album"
      );
    } else {
      if (folder && currentUserEmail) {
        if (isPlatform("mobile")) {
          let taskId: any = BackgroundTask.beforeExit(async () => {
            uploadPictureToFolder(currentUserEmail, folder.id, files, supervisedAccount);
            BackgroundTask.finish({
              taskId,
            });
          });
        } else {
          uploadPictureToFolder(currentUserEmail, folder.id, files, supervisedAccount);
        }
      }
      setShowChoosePictureSource(false);
      return true;
    }

    return false;
  }

  async function onSelectFile(e: any) {
    setUserIsActive(true);
    if ((e.target.files as FileList).length > 0 && currentUserEmail && folder) {
      let files: File[] = [];

      for (var i = 0; i < (e.target.files as FileList).length; i++) {
        let f = (e.target.files as FileList).item(i);
        if (f) files.push(f);
      }

      const filesData = Array.from<File>(e.target.files).map((file: File) => {
        return {
          file: file,
          extension: _.last(_.split(file.type, "/")),
          sizeInKb: file.size / 1000,
        } as FileData;
      });

      await uploadPicture(filesData as FileData[]);
      setShowChoosePictureSource(false);
    }
  }

  async function selectPictureFromGallery(type: CameraSource) {
    setUserIsActive(true);
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    if (type == CameraSource.Photos) {
      if (inputFile.current) inputFile.current.click();

      /*MultipleDocumentsPicker.pick(2).then(async  (files:any) => {
        files= JSON.parse(files);
        const filesData = Promise.all(files.map(async (file:any) =>
        {
          const {uri, type, name, size} = file;

          try{
            const extension = _.last(_.split(type, '/'));
            const sizeInKb = size/1000
            let data = await Filesystem.readFile({
              path: uri
            });
            const dataReturn = {
              data:data.data,
              extension: extension,
              sizeInKb:sizeInKb
            } as FileData;
            return Promise.resolve(dataReturn);
          }
          catch(e)
          {
            return Promise.reject(e)
          }
        })).then(async (filesData: any) => {
          await uploadPicture(filesData as FileData[]);
          setShowChoosePictureSource(false);
        });
      })*/
    } else {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          direction: CameraDirection.Rear,
          allowEditing: false,
          source: type,
          resultType: CameraResultType.Base64,
          promptLabelHeader: "Sélectionner une photo",
          promptLabelCancel: "Retour",
          promptLabelPhoto: "Depuis mes photos",
          promptLabelPicture: "Prende une photo",
        });

        if (image.base64String) {
          const fileData = {
            data: image.base64String,
            extension: image.format,
            sizeInKb:
              (4 *
                Math.ceil(image.base64String.length / 3) *
                0.5624896334383812) /
              1000,
          } as FileData;

          await uploadPicture([fileData]);
          setShowChoosePictureSource(false);
        }
      } catch (error) {
        return null;
      }
    }
  }

  async function onDeletePicture(idFolder: string, idFile: string) {
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    await deleteFile(idFolder, idFile, supervisedAccount);
    setShowPictureDetails(false);
    setCurrentPicture(undefined);
  }
  async function onDeleteFolder(folder: SharedFolder) {
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    await deleteFolder(folder, currentUserEmail ? currentUserEmail : "", history, supervisedAccount);
    history.push("/tabs/media/shared");
    setShowDeleteFolderModal(false);
  }
  async function addUsersToFolder(idFolder: string) {
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    if (formUsers.length === 0 && formGroups.length == 0) {
      setToastNotification({
        color: "danger",
        message: "Vous devez sélectionner au moins un contact pour sauvegarder",
      } as ToastNotification);
      return;
    }

    if (currentUserEmail) {
      let usersFolder = [...formUsers];
      if (formUsers.indexOf(currentUserEmail) === -1) {
        usersFolder = [...formUsers, currentUserEmail];
      }
      formGroups.forEach((group) => {
        group.members.forEach((member) => usersFolder.push(member));
      });
      usersFolder = usersFolder.filter((c, index) => {
        return (
          usersFolder.indexOf(c) === index && c !== currentUserEmail
        );
      });
      let allUserRelations: string[] = _.cloneDeep(usersFolder);
      allUserRelations.push(currentUserEmail)
      await updateFolderUsers(idFolder, allUserRelations, supervisedAccount);
      setShowAddPersonModal(false);
      setToastNotification({
        color: "success",
        message:
          "La liste des contacts ayant accès à l'album a bien été modifiée",
      } as ToastNotification);
    }
  }

  async function updateFolderNameRequest() {
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    if (!folderName || folderName.length == 0) {
      setToastNotification({
        color: "danger",
        message: "Le nom de l'album n'est pas valide",
      } as ToastNotification);
      return;
    }

    if (currentUserEmail && folder) {
      await updateFolderName(folder.id, folderName, supervisedAccount);
      setShowEditFolderNameModal(false);
      setToastNotification({
        color: "success",
        message: "Le nom de l'album a bien été modifié",
      } as ToastNotification);
    }
  }

  async function onCreateComment() {
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    if (!newComment && !audioBlob) {
      setToastNotification({
        color: "danger",
        message: "Renseigner un commentaire",
      } as ToastNotification);
    } else if (
      newComment.length > _MaxCharNewComment &&
      newCommentType === "text"
    ) {
      setToastNotification({
        color: "danger",
        message:
          "Votre commentaire ne doit pas dépasser " +
          _MaxCharNewComment +
          " caractères. Actuellement il en contient " +
          newComment.length,
      } as ToastNotification);
    } else if (
      currentUserEmail &&
      folder &&
      currentUser &&
      currentPicture &&
      newComment
    ) {
      await addComment(
        folder.id,
        currentPicture.id,
        currentUserEmail,
        currentUser.firstName + " " + currentUser.lastName,
        newComment,
        supervisedAccount
      );
      if (supervisedAccount && currentUserEmail) loadAndSetUserSupervised(currentUserEmail)
      setNewComment("");
    } else if (
      currentUserEmail &&
      folder &&
      currentUser &&
      currentPicture &&
      audioBlob
    ) {
      setStartAudioRecord(false);
      uploadAudioComment(
        folder,
        currentPicture.id,
        currentUserEmail,
        currentUser.firstName + " " + currentUser.lastName,
        audioBlob,
        "mpeg",
        supervisedAccount
      );
      if (supervisedAccount && currentUserEmail) loadAndSetUserSupervised(currentUserEmail)
      setAudioBlob(null);
    }
  }

  /**
   * Save user see all comments on the current file in database
   */
  const onCommentViewByUser = () => {
    if (currentPicture && currentUserEmail) {
      const commentsNotRead = _.filter(
        currentPicture.comments,
        (comment: Comment) =>
          comment.senderEmail !== currentUserEmail &&
          !_.some(comment.viewBy, (viewBy) => viewBy === currentUserEmail)
      );

      if (commentsNotRead.length > 0) {
        updateViewByFolderComment(
          folder.id,
          currentPicture.id,
          currentUserEmail,
          supervisedAccount
        );
      }
    }
  };

  /*const renderPreview = (item: ReactImageGalleryItem) => {
    const itemAny = item as any;
    const extension = itemAny.extension;
    const id = itemAny.id;
    const picture = folder.files.find((pic) => pic.id === id);
    const comments = (picture && picture.comments ? picture.comments : []) as Comment[];

    let currentIndexGalery = gallery.current ? gallery.current.getCurrentIndex() : 0;
    const isCurrentPicture = folder.files[currentIndexGalery].id === id;

    if (_.some(validFormatsForSharedFolderVideo, (f) => f === extension)) {
      return <div className="preview-video image-gallery-thumbnail-inner">
        {currentUserEmail && <CommentCounter comments={comments} currentUserEmail={currentUserEmail} onlyNewBadge={true} />}

        <VideoPlayer url={item.original} height={"100px"} type={"video/" + extension} play={!isCurrentPicture} loop={true} muted={true} controls={false} />
      </div>
    }
    else {
      return <div className="image-gallery-thumbnail-inner">
        {currentUserEmail && <CommentCounter comments={comments} currentUserEmail={currentUserEmail} onlyNewBadge={true} />}
        <img className="image-gallery-thumbnail-image" src={item.original} />
      </div>
    }
  }*/

  /*const renderImage = (item: ReactImageGalleryItem) => {
    const itemAny = item as any;
    const extension = itemAny.extension;
    const id = itemAny.id;

    const picture = folder.files.find((pic) => pic.id === id);
    const comments = (picture && picture.comments ? picture.comments : []) as Comment[];

    let mediaRender;
    if (_.some(validFormatsForSharedFolderVideo, (f) => f === extension) && currentPicture) {
      const isCurrentPicture = currentPicture.id === id;

      const play = isCurrentPicture &&
      !showPictureComments &&
      !showPictureDetails;

      mediaRender = <VideoPlayer classname="image-gallery-image" url={item.original} width={"100%"} type={"video/" + extension} play={play} loop={true} muted={!isCurrentPicture} controls={false} />
    }
    else {
      mediaRender = <img className="image-gallery-image" src={item.original} />
    }


    return <div >
      {currentUserEmail && <CommentCounter comments={comments} currentUserEmail={currentUserEmail} isHelpedProfil={isHelpedProfil} />}
      {mediaRender}
    </div>
  }*/

  function getCommentsCurrentPicture() {
    if (currentPicture && folder) {
      const picture = folder.files.find((file) => file.id == currentPicture.id);
      if (picture && picture.comments) return picture.comments;
    }
    return [];
  }


  function downloadPicture(picture = currentPicture, showFileUploadSuccessToast = true, showAlbumUploadSuccessToast = false) {
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    if (picture) {
      if (isPlatform("mobile")) {
        let localFilepath =
          File.externalDataDirectory + "/Download/" + picture.name;
        if (isPlatform("ios"))
          localFilepath =
            File.syncedDataDirectory + "/Download/" + picture.name;

        // voir pour le faire fonctionner : https://stackoverflow.com/questions/29413869/cordova-file-transfer-download-http-status-401-only-android
        const fileTransfer: FileTransferObject = FileTransfer.create();
        setLoadingDownloadFile(true);
        fileTransfer
          .download(picture.url, localFilepath, true)
          .then(
            async (entry: any) => {
              const photoInTempStorage = await Filesystem.readFile({
                path: entry.toURL(),
              });

              await Filesystem.writeFile({
                data: photoInTempStorage.data,
                path: picture.name,
                directory: Directory.Documents,
              });
              setLoadingDownloadFile(false);
              setShowPictureComments(false);
              setShowPictureDetails(false);
              if (showFileUploadSuccessToast) {
                setToastNotification({
                  color: "success",
                  message: "Fichier téléchargé",
                } as ToastNotification)
              }
              if (showAlbumUploadSuccessToast) {
                setAlbumDownload(false)
                setToastNotification({
                  color: "success",
                  message: "Album téléchargé",
                } as ToastNotification)
              }
            },
            (error: FileTransferError) => {
              console.error("downloadPicture Error : " + JSON.stringify(error));
              setAlbumDownload(false)
              setLoadingDownloadFile(false);
            }
          )
          .catch((e: any) => {
            console.error("downloadPicture catch: " + JSON.stringify(e));
            setAlbumDownload(false)
            setLoadingDownloadFile(false);
          });
      } else {
        setLoadingDownloadFile(true);
        var fileDownload = require("js-file-download");
        axios
          .get(picture.url, {
            responseType: "blob",
          })
          .then((res: any) => {
            fileDownload(res.data, picture.name);
          })
          .then(() => {
            setLoadingDownloadFile(false);
            setShowPictureComments(false);
            setShowPictureDetails(false);
            if (showFileUploadSuccessToast) {
              setToastNotification({
                color: "success",
                message: "Fichier téléchargé",
              } as ToastNotification)
            }
            if (showAlbumUploadSuccessToast) {
              setAlbumDownload(false)
              setToastNotification({
                color: "success",
                message: "Album téléchargé",
              } as ToastNotification)
            }

          }).catch((e: any) => {
            console.error('downloadPicture catch: ' + JSON.stringify(e))
            setAlbumDownload(false)
            setLoadingDownloadFile(false);
          });
      }
    }
  }

  /*const pictures = folder.files.map(file => {
    return {
      original: file.url,
      thumbnail: file.url,
      extension: file.extension,
      id: file.id
    } as ReactImageGalleryItem
  })*/

  const photos = folder.files.map((file) => {
    return {
      src: file.url,
      width: 2,
      height: 2,
    };
  });

  const currentUserIsFolderCreator = currentUserEmail == folder.creator;
  const currentPictureCreatorMail = currentPicture
    ? currentPicture.uploadBy
    : null;
  const currentPictureCreatorUser = currentPictureCreatorMail
    ? _.find(users, (u) => u.email == currentPictureCreatorMail)
    : null;

  let leftButtons = [
    {
      title: "Modifier",
      iconSvg: IconSvgEnum.album,
      onClick: () => setShowFolderSettingModal(true),
      addPlusIcon: false,
    } as ButtonData,
    {
      title: "Telecharger",
      iconSvg: IconSvgEnum.download,
      onClick: () => downloadAlbum(),
      addPlusIcon: false,
    } as ButtonData,
    {
      title: "Ajouter",
      iconSvg: IconSvgEnum.picture,
      onClick: () => setShowChoosePictureSource(true),
      addPlusIcon: true,
    } as ButtonData,

  ];
  if (folder.creator !== currentUserEmail) {
    leftButtons = [
      {
        title: "Télécharger",
        iconSvg: IconSvgEnum.download,
        onClick: () => downloadAlbum(),
        addPlusIcon: false,
      } as ButtonData,
      {
        title: "Ajouter",
        iconSvg: IconSvgEnum.picture,
        onClick: () => setShowChoosePictureSource(true),
        addPlusIcon: true,
      } as ButtonData,
    ];
  }

  const downloadAlbum = () => {
    if (!appOnline) {
      setShowOffline(true)
      return;
    }
    setAlbumDownload(true)
    folder.files.forEach((file, index) => {
      if (index === folder.files.length - 1) {
        downloadPicture(file, false, true)
      } else {
        downloadPicture(file, false, false);
      }
    })
  }

  const openPicture = (open: boolean, index: number = -1) => {
    setShowPicture(open);
    if (index !== -1) {
      setCurrentPicture(folder.files[index]);
    }
  };

  const setAudioBlobUrl = async (url: string | null) => {
    if (url) {
      let blob = await fetch(url).then((r) => r.blob());
      setAudioBlob(blob);
    } else {
      setAudioBlob(null);
    }
  };

  const addAudioComment = async () => {
    setStartAudioRecord((current) => !current);
    /*if (isPlatform("mobileweb") || !isPlatform("mobile")) {
      setStartAudioRecord((current) => !current);
      return;
    } else {
      let capture = await MediaCapture.captureAudio({ limit: 1 }).catch((e) => {
        if (e.code !== 3) {
          alert("Impossible d'accèder à la micro: " + JSON.stringify(e));
        }
      });
      if (!capture) {
        alert("Erreur, aucun audio récupéré");
        return;
      }
      const captureError = capture as CaptureError;
      const captureSuccess = capture as MediaFile[];
      if (captureError && captureError.code) {
        alert(
          "Erreur lors de la capture audio " + JSON.stringify(captureError)
        );
        return;
      }
      if (captureSuccess && captureSuccess.length === 0) {
        alert("Erreur aucun audio récupéré");
        return;
      }
      const media = captureSuccess[0] as MediaFile;
      let resolvedPath: DirectoryEntry;
      let path = media.fullPath.substring(0, media.fullPath.lastIndexOf("/"));
      if (Capacitor.getPlatform() === "ios") {
        resolvedPath = await File.resolveDirectoryUrl("file://" + path);
      } else {
        resolvedPath = await File.resolveDirectoryUrl(path);
      }
      return File.readAsArrayBuffer(resolvedPath.nativeURL, media.name)
        .then(
          (buffer: ArrayBuffer) => {
            // get the buffer and make a blob to be saved
            let imgBlob = new Blob([buffer], {
              type: media.type,
            });
            setAudioBlob(imgBlob);
          },
          (error: any) => { }
        )
        .catch((e) => {
          alert("resolvedPath catch: " + JSON.stringify(e));
        });
    }*/
  };

  const annulateRecord = () => {
    setStartAudioRecord(false);
    setAudioBlobUrl(null);
  };
  const cancelHandler = () => {
    setShowPictureComments(false);
    setStartAudioRecord(false);
    history.push("/tabs/media/shared");
  };
  return (
    <IonPage id="shared-folder">
      <Header title={folderName} leftButtons={leftButtons} />

      <IonContent fullscreen={true}>
        <div className={isHelpedProfil ? "padding-bottom" : ""}>

          {fileUploadProgress && (
            <Loading
              enable={true}
              text={
                "Téléchargement en cours (" +
                fileUploadProgress.current +
                "/" +
                fileUploadProgress.totalFiles +
                ")"
              }
            />
          )}
          {albumDownload && (
            <Loading
              enable={true}
              text={
                "Album en cours de téléchargement"
              }
            />
          )}

          {
            !fileUploadProgress && folder && folder.files.length > 0 && (
              <Fragment>
                <Gallery
                  photos={photos}
                  onClick={(event, { photo, index }) => { }}
                  renderImage={({ photo, index }) => (
                    <PictureItem
                      key={index}
                      extension={folder.files[index].extension}
                      src={photo.src}
                      showDetails={() => {
                        setCurrentPicture(folder.files[index]);
                        setIndexCurrentPicture(index);
                        setShowPictureComments(true);
                      }}
                      picture={folder.files[index]}
                      openPicture={() => openPicture(true, index)}
                      comments={folder.files[index].comments}
                      currentUserEmail={
                        currentUserEmail ? currentUserEmail : ""
                      }
                    />
                  )}
                />
                {showPicture && (
                  <ShowPicture
                    extension={currentPicture ? currentPicture.extension : ""}
                    openPicture={() => openPicture(false)}
                    currentPicture={currentPicture}
                  />
                )}
              </Fragment>
            )
            /*<ImageGallery ref={gallery}
              thumbnailPosition={"bottom"}
              showBullets={false}
              renderItem={renderImage}
              renderThumbInner={renderPreview}
              onBeforeSlide={(nextIndex) => {
                if (gallery.current) {
                  setCurrentPicture(folder.files[nextIndex]);
                }
              }}
              onClick={(event) => {
                if (gallery.current) {
                  //const currentIndex = gallery.current.getCurrentIndex();
                  //setCurrentPicture(folder.files[currentIndex]);
                  //setShowPictureDetails(true);
                  setShowPictureComments(true);
                }
              }}
              items={pictures}
            />*/
          }

          {folder && folder.files.length == 0 && (
            <div>
              <div style={{ textAlign: "center", paddingTop: "20px" }}>
                <IonLabel>
                  Veuillez cliquer ci-dessous pour ajouter vos photos ou vidéos
                  dans l'album
                </IonLabel>
                <div>
                  <IonButton
                    style={{ width: "100%", marginTop: "20px" }}
                    onClick={() => setShowChoosePictureSource(true)}
                  >
                    <span>
                      <IonIcon icon={addOutline} className="icon-size" />
                      <IonIcon icon={imageOutline} className="icon-size" />
                    </span>
                  </IonButton>
                </div>
              </div>
            </div>
          )}

          <IonToast
            isOpen={!!uploadError}
            message={uploadError}
            duration={6000}
            color={"danger"}
            onDidDismiss={() => setUploadError("")}
          />

          {/* MODAL details picture (onclick picture) */}
          <IonModal
            isOpen={showPictureDetails && !!currentPicture}
            cssClass="add-friend-modal"
            onDidDismiss={() => setShowPictureDetails(false)}
          >
            <Header hideHome title="Détails" />
            {currentPicture && (
              <div>
                <div>
                  <IonLabel>
                    Auteur:{" "}
                    {currentPictureCreatorUser
                      ? currentPictureCreatorUser.firstName +
                      " " +
                      currentPictureCreatorUser.lastName
                      : currentPicture.uploadBy == currentUserEmail
                        ? "Moi"
                        : "N/A"}
                  </IonLabel>
                </div>
                <div>
                  <IonLabel>
                    Date:{" "}
                    {fileDateCreation ? fileDateCreation.toDate().toLocaleDateString() : ""}
                  </IonLabel>
                </div>
                <div>
                  <IonLabel>Format: {currentPicture.extension}</IonLabel>
                </div>
                <div>
                  <IonLabel>
                    Poids:{" "}
                    {currentPicture.sizeKo < 1000
                      ? currentPicture.sizeKo.toFixed(2)
                      : (currentPicture.sizeKo / 1000).toFixed(2)}{" "}
                    {currentPicture.sizeKo < 1000 ? "Ko" : "Mo"}
                  </IonLabel>
                </div>
              </div>
            )}
            <div>
              <IonRow style={{ width: "100%" }}>
                <IonCol
                  size={
                    currentPicture &&
                      currentPicture.uploadBy == currentUserEmail
                      ? "6"
                      : "12"
                  }
                >
                  <Button
                    color="light"
                    onClick={() => {
                      setShowPictureComments(true);
                      setShowPictureDetails(false);
                    }}
                    text="Retour"
                    icon={arrowBackCircleOutline}
                  />
                </IonCol>
                {currentPicture && currentPicture.uploadBy == currentUserEmail && (
                  <IonCol size="6">
                    <Button
                      color="danger"
                      onClick={() =>
                        onDeletePicture(folder.id, currentPicture.id)
                      }
                      text="Supprimer"
                      icon={trashOutline}
                    />
                  </IonCol>
                )}
              </IonRow>
            </div>
          </IonModal>
          {/* onWillPresent={scrollBottomCommentaires} */}
          {showPictureComments &&
            !!currentPicture &&
            folder.type &&
            folder.type === FolderType.unshared && (
              <FileDetails
                loadingDownloadFile={loadingDownloadFile}
                currentPicture={currentPicture}
                folder={folder}
                onDelete={() =>
                  currentPicture
                    ? onDeletePicture(folder.id, currentPicture.id)
                    : {}
                }
                showDetails={() => {
                  setShowPictureComments(false);
                  setShowPictureDetails(true);
                }}
                onDownload={downloadPicture}
                onCancel={() => setShowPictureComments(false)}
              />
            )}
          <IonModal
            isOpen={
              showPictureComments &&
              !!currentPicture &&
              folder.type !== FolderType.unshared
            }
            onDidDismiss={() => setShowPictureComments(false)}
          >
            <Header title="MENU" action={cancelHandler} />


            <CommentsSection
              comments={currentComments}
              currentUserEmail={currentUserEmail ? currentUserEmail : ""}
              folder={folder}
              loading={loadingDownloadFile || loading}
              currentPicture={currentPicture ? currentPicture : null}
              deletePicture={() =>
                currentPicture
                  ? onDeletePicture(folder.id, currentPicture.id)
                  : {}
              }
              downloadPicture={downloadPicture}
              showDetails={() => {
                setShowPictureComments(false);
                setShowPictureDetails(true);
              }}
              addAudioComment={addAudioComment}
              setAudioBlobUrl={setAudioBlobUrl}
            />
            <div className="new-comment">
              <IonTextarea
                placeholder="Ajoutez ici votre commentaire"
                value={newComment}
                onIonChange={(e) => setNewComment(e.detail.value!)}
                autoGrow={false}
                rows={2}
              ></IonTextarea>
              {startAudioRecord && (
                <RecordAudio
                  setStartAudioRecord={setStartAudioRecord}
                  setAudioBlobUrl={setAudioBlobUrl}
                  onClose={annulateRecord}
                  onValidate={onCreateComment}
                />
              )}
              {/*audioBlob && isPlatform("mobile") && !isPlatform("mobileweb") && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    padding: "20px",
                    alignItems: "center",
                  }}
                >
                  <audio controls style={{ flexGrow: 1 }}>
                    <source
                      id="source"
                      src={URL.createObjectURL(audioBlob)}
                      type="audio/mpeg"
                    />
                  </audio>
                  <IonIcon
                    src={closeSharp}
                    onClick={() => {
                      setStartAudioRecord(false);
                      setAudioBlob(undefined);
                    }}
                    className="audio-icon"
                  />
                </div>
                  )*/}
            </div>
            <IonRow class="ion-no-padding" style={{ width: "100%" }}>
              <IonCol class="ion-no-padding" size="6">
                <Button
                  color="light"
                  onClick={() => {
                    setShowPictureComments(false);
                    setStartAudioRecord(false);
                  }}
                  text="Retour"
                  icon={arrowBackCircleOutline}
                />
              </IonCol>
              <IonCol class="ion-no-padding" size="6">
                <Button
                  color="success"
                  onClick={() => onCreateComment()}
                  disabled={!newComment && !audioBlob}
                  text="Envoyer"
                  icon={sendOutline}
                />
              </IonCol>
            </IonRow>
            {loading && (
              <Loading
                enable={true}
                text=""
              />
            )}
            {loadingDownloadFile && (
              <Loading
                enable={true}
                text={"Fichier en cours de téléchargement"}
              />
            )}
          </IonModal>

          {/* MODAL Folder setting menu*/}
          <IonModal
            isOpen={showFolderSettingModal}
            cssClass="add-friend-modal"
            onDidDismiss={() => setShowFolderSettingModal(false)}
          >
            <Header hideHome title="Modifier votre album" />
            {folder.type !== FolderType.unshared && (
              <Button
                color="primary"
                onClick={() => {
                  setShowFolderSettingModal(false);
                  setShowAddPersonModal(true);
                }}
                text="Modifier les contacts"
                icon={personAddOutline}
              />
            )}
            <Button
              color="primary"
              onClick={() => {
                setShowFolderSettingModal(false);
                setShowEditFolderNameModal(true);
              }}
              text="Modifier le nom"
              icon={createOutline}
            />
            <Button
              color="danger"
              onClick={() => {
                setShowFolderSettingModal(false);
                setShowDeleteFolderModal(true);
              }}
              text="Supprimer l'album"
              icon={trashOutline}
            />
            <Button
              color="light"
              onClick={() => setShowFolderSettingModal(false)}
              text="Retour"
              icon={arrowBackCircleOutline}
            />
          </IonModal>

          {/* MODAL Manage users */}
          {showAddPersonModal === true && <IonModal
            isOpen={showAddPersonModal}
            cssClass="modal-shared-folder-details2"
            onDidDismiss={() => setShowAddPersonModal(false)}
          >
            <Header hideHome title="Partager cet album avec vos contacts" />
            <IonContent>
              <div className="full-height-flex">
                <SelectMultipleUsers
                  users={users}
                  currentUserEmail={currentUserEmail ? currentUserEmail : ""}
                  selectedUsers={formUsers.filter(
                    (email) => currentUserEmail !== email
                  )}
                  checkedAll={false}
                  updateSelectedUsers={updateSelectedUsers}
                  groups={groups}
                  selectedGroups={formGroups}
                  updateSelectedGroups={updateSelectedGroups}
                />
              </div>
            </IonContent>
            <div slot="fixed" style={{ width: "100%", bottom: "0", marginTop: "20px" }}>
              <IonRow class="ion-no-padding">
                <IonCol size="6" class="ion-no-padding">
                  <Button
                    color="light"
                    onClick={() => {
                      setShowFolderSettingModal(true);
                      setShowAddPersonModal(false);
                    }}
                    text="Retour"
                    icon={arrowBackCircleOutline}
                  />
                </IonCol>
                <IonCol size="6" class="ion-no-padding">
                  <Button
                    color="success"
                    onClick={() => addUsersToFolder(folder.id)}
                    text="Sauvegarder"
                    svg={IconSvgEnum.ok_white}
                  />
                </IonCol>
              </IonRow>
            </div>
          </IonModal>}

          {/* MODAL edit folder name */}
          <IonModal
            isOpen={showEditFolderNameModal}
            cssClass="add-friend-modal"
            onDidDismiss={() => setShowEditFolderNameModal(false)}
          >
            <Header hideHome title="Modifier le nom de votre album" />
            <IonItem>
              <IonInput
                size={30}
                value={folderName}
                placeholder="Nom de l'album"
                onIonChange={(e) => setFolderName(e.detail.value!)}
              ></IonInput>
            </IonItem>
            <IonRow>
              <IonCol size="6">
                <Button
                  color="light"
                  onClick={() => {
                    setShowFolderSettingModal(true);
                    setShowEditFolderNameModal(false);
                    setFolderName(folder.name);
                  }}
                  text="Retour"
                  icon={arrowBackCircleOutline}
                />
              </IonCol>
              <IonCol size="6">
                <Button
                  color="success"
                  onClick={() => updateFolderNameRequest()}
                  text="Sauvegarder"
                  svg={IconSvgEnum.ok_white}
                />
              </IonCol>
            </IonRow>
          </IonModal>

          {/* MODAL delete folder */}
          <IonModal
            isOpen={showDeleteFolderModal}
            cssClass="add-friend-modal"
            onDidDismiss={() => setShowDeleteFolderModal(false)}
          >
            <Header hideHome title="Supprimer votre album" />
            {currentUserIsFolderCreator && (
              <IonLabel style={{ textAlign: "center" }}>
                Confirmer la demande de suppression de l'album{" "}
                <b>{folder.name}</b>, supprimera définitivement toutes les
                photos et/ou vidéos pour vous et pour l'ensemble des contacts
                associés à cet album
              </IonLabel>
            )}
            {!currentUserIsFolderCreator && (
              <IonLabel style={{ textAlign: "center" }}>
                Seul le créateur de l'album peut le supprimer. Créateur actuel:{" "}
                {folder.creator}
              </IonLabel>
            )}
            <div>
              <IonRow style={{ width: "100%" }}>
                <IonCol size={currentUserIsFolderCreator ? "6" : "12"}>
                  <Button
                    color="light"
                    onClick={() => {
                      setShowFolderSettingModal(true);
                      setShowDeleteFolderModal(false);
                    }}
                    text="Retour"
                    icon={arrowBackCircleOutline}
                  />
                </IonCol>
                {currentUserIsFolderCreator && (
                  <IonCol size="6">
                    <Button
                      color="danger"
                      onClick={() => onDeleteFolder(folder)}
                      text="Supprimer"
                      icon={trashOutline}
                    />
                  </IonCol>
                )}
              </IonRow>
            </div>
          </IonModal>

          {/* MODAL choose picture source (gallery or camera) */}
          <IonModal isOpen={showChoosePictureSource} cssClass='add-friend-modal' onDidDismiss={() => setShowChoosePictureSource(false)}>
            <Header hideHome title="Ajouter une photo ou une vidéo" />
            <input ref={inputFile} style={{ display: "none" }} type="file" id="file" multiple onChange={onSelectFile} />
            <Button color="primary" onClick={() => selectPictureFromGallery(CameraSource.Camera)} text="Depuis l'appareil photo" icon={cameraOutline} />
            <Button color="primary" onClick={() => selectPictureFromGallery(CameraSource.Photos)} text="Depuis la galerie" icon={imagesOutline} />
            <Button color="light" onClick={() => { setShowChoosePictureSource(false); setUserIsActive(false); }} text="Retour" icon={arrowBackCircleOutline} />
          </IonModal>

          {/*Hide footer if files are uploading*/
          /* !fileUploadProgress && <IonFooter>
              <IonToolbar>
                <VoiceCommand />
              </IonToolbar>
          </IonFooter>*/}
        </div>
      </IonContent>
      <IonRow>
        <IonCol size="12">
          <Button
            routerLink="/tabs/media/shared"
            color="light"
            text="Retour"
            icon={arrowBackCircleOutline}
          />
        </IonCol>
      </IonRow>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    uploadPictureToFolder,
    deleteFile,
    deleteFolder,
    updateViewByFileForFolder,
    updateFolderUsers,
    setToastNotification,
    updateFolderName,
    addComment,
    updateViewByFolderComment,
    setUserIsActive,
    uploadAudioComment,
    loadAndSetUserSupervised,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    folders: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.folders : state.sharedFolder.folders,
    currentUserEmail: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username,
    currentUser: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore,
    users: selectors.getUsersFilteredByNumberOfCalls(state),
    fileUploadProgress: state.sharedFolder.uploadProgess,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
    soundEnabled: selectors.getsoundEnabledConfig(state),
    loading: state.sharedFolder.loading,
    supervisedAccount: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount : null,
    appOnline: state.global.appOnline,
    groups: state.user.groups || []
  }),
  component: React.memo(SharedFolderDetails)
});
