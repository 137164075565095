import React, { useState, useEffect } from "react";
import { connect } from "../data/connect";
import { ToastNotification } from "../models/ToastNotification";
import { setToastNotification } from "../data/global/global.actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ToastManager.scss";
import { setAudioData } from "../data/user/user.actions";
interface OwnProps {}

interface DispatchProps {
  setToastNotification: typeof setToastNotification;
  setAudioData: typeof setAudioData;
}

interface StateProps {
  notification?: ToastNotification;
}

interface ToastManagerProps extends OwnProps, StateProps, DispatchProps {}

const ToastManager: React.FC<ToastManagerProps> = ({
  notification,
  setToastNotification,
  setAudioData,
}) => {
  const [toastData, setToastData] = useState<ToastNotification>();

  useEffect(() => {
    if (notification) {
      setToastData(notification);
      let toastFunction = toast.success;
      if (notification.color === "danger") toastFunction = toast.error;
      else if (notification.color === "warning") toastFunction = toast.warning;
      else if (notification.color === "info") toastFunction = toast.info;
      const toastId = `TOAST-${Date.now()}`
      toastFunction(
        
        <div style={{ textAlign: "center" }}>{notification.message}</div>,
        {
          position: "bottom-center",
          autoClose: notification.duration,
          hideProgressBar: false,
          closeOnClick: false,
          toastId: toastId,
          onClick: (event: any) => {
            if (notification && notification.onClickMessage) {
              notification.onClickMessage();
              resetToast();
              toast.dismiss(toastId);
              if (notification.playAudio)
                setAudioData(notification.playAudio, false);
            }
          },
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          progressStyle: { right: 0, transformOrigin: "right" },
        }
      );

      if (notification.playAudio) setAudioData(notification.playAudio, true);
    }
  }, [notification]);

  const resetToast = async () => {
    setToastData(undefined);
    setToastNotification(undefined);
    if (notification && notification.playAudio)
      setAudioData(notification.playAudio, false);
  };

  const buttons =
    toastData && toastData.buttons ? toastData.buttons : undefined;

  return (
    <div>
      <ToastContainer
        className="toast-global"
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={true}
        rtl={false}
        draggable
        pauseOnHover={false}
        progressStyle={{ right: 0, transformOrigin: "right" }}
      />
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setToastNotification,
    setAudioData,
  },
  mapStateToProps: (state) => {
    return {
      notification: state.global.notification,
    };
  },
  component: ToastManager,
});