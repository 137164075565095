import { ActionType } from "../../util/types";
import { SharedFolder } from "../../models/SharedFolder";
import { SharedFile } from "../../models/SharedFile";
import { Comment } from "../../models/Comment";
import { MailboxItem } from "../../models/Mailbox";
import _ from "lodash";
import { FileData } from "../../models/FileData";
import { FileUploadProgess } from "../../models/FileUploadProgess";
import { setUserSupervised } from "../supervisor/supervisor.actions";
import { UserSupervised } from "../../models/UserSupervised";
import {
  createFolderRequest,
  deleteFolderRequest,
  getFolderRequest,
  updateFolderRequest,
  uploadAlbumFileRequest,
  uploadAudioCommentRequest,
  uploadPrivateUserFileRequest,
} from "../../api/sharedFolder";
import { getSupervisedUserData } from "../../api/supervisorRelationship";
import { uploadVideoMessageRequest } from "../../api/mailbox";
import { deleteFileFromStorageRequest } from "../../util/firebaseStorage";
import { toast } from "react-toastify";

export const createFolder =
  (
    currentUserName: string,
    name: string,
    users: string[],
    history: any,
    supervisedAccount: UserSupervised | null
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoading(true));
    const res = await createFolderRequest(currentUserName, name, users);
    if (supervisedAccount && res) {
      getSupervisedUserData(currentUserName)
        .then((userSupervised) => {
          localStorage.setItem(
            "userSupervised",
            JSON.stringify(userSupervised)
          );
          dispatch(setUserSupervised(userSupervised));
        })
        .finally(() => {
          dispatch(setLoading(false));
          history.push(`/tabs/media/shared/${res.id}`);
        });
    } else {
      dispatch(setLoading(false));
      if (res) {
        history.push(`/tabs/media/shared/${res.id}`);
      }
    }
  };

export const addComment =
  (
    folderId: string,
    fileId: string,
    senderEmail: string,
    senderName: string,
    comment: string,
    supervisedAccount: UserSupervised | null
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    let folder = await getFolderRequest(folderId, supervisedAccount);
    if (folder) {
      var newComment = {
        text: comment,
        senderEmail: senderEmail,
        viewBy: [senderEmail],
        date: new Date(),
        senderName: senderName,
        type: "text",
      } as Comment;
      if (!supervisedAccount) {
        folder.files.forEach((file) => {
          if (file.id === fileId) {
            if (!file.comments) file.comments = [];
            file.comments.push(newComment);
          }
        });
        await updateFolderRequest(folder, supervisedAccount);
      } else {
        await updateFolderRequest(
          folder,
          supervisedAccount,
          fileId,
          newComment
        );
        setLoading(true);
        getSupervisedUserData(supervisedAccount.user.email)
          .then((userSupervised) => {
            localStorage.setItem(
              "userSupervised",
              JSON.stringify(userSupervised)
            );
            dispatch(setUserSupervised(userSupervised));
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      }
    }
  };

export const setFolders =
  (folders: SharedFolder[]) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setFoldersReducer(folders));
  };

export const uploadPictureToFolder =
  (
    currentUserEmail: string,
    folderId: string,
    files: FileData[],
    supervisedAccount: UserSupervised | null
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    let folder = await getFolderRequest(folderId, supervisedAccount);

    if (folder) {
      const usersFolder = folder.users.filter(
        (user) => user !== currentUserEmail
      );
      try {
        for (var i = 0; i < files.length; i++) {
          dispatch(
            setUploadProgress({
              totalFiles: files.length,
              current: i + 1,
            } as FileUploadProgess)
          );

          const file = files[i];

          var newFile = (await uploadPrivateUserFileRequest(
            file,
            folderId,
            currentUserEmail,
            usersFolder
          )) as SharedFile;
          if (newFile) {
            folder.files.unshift(newFile);
            await updateFolderRequest(folder, supervisedAccount, newFile.id);
          }
        }

        if (supervisedAccount) {
          getSupervisedUserData(currentUserEmail)
            .then((userSupervised) => {
              localStorage.setItem(
                "userSupervised",
                JSON.stringify(userSupervised)
              );
              dispatch(setUserSupervised(userSupervised));
            })
            .finally(() => {
              dispatch(setUploadProgress(undefined));
            });
        } else {
          dispatch(setUploadProgress(undefined));
        }
      } catch (e) {
        dispatch(setUploadProgress(undefined));
      }
    }
    return Promise.resolve(true);
  };

export const uploadVideoMessage =
  (
    toUser: string,
    fileData: Blob,
    fileExtension: string,
    idVisioCall?: string
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    if (fileData) {
      dispatch(setSendMessageLoading(true));
      var newFile = (await uploadVideoMessageRequest(
        toUser,
        fileData,
        fileExtension,
        idVisioCall
      )) as MailboxItem;
      dispatch(setSendMessageLoading(false));

      return newFile;
    } else {
      return null;
    }
  };

export const deleteFile =
  (
    idFolder: string,
    idFile: string,
    supervisedAccount: UserSupervised | null
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    let folder = await getFolderRequest(idFolder, supervisedAccount);
    if (folder) {
      const file = _.find(folder.files, (f) => f.id === idFile);
      if (file) {
        await deleteFileFromStorageRequest(file.url);
        folder.files = _.filter(folder.files, (f) => f.id !== idFile);
        await updateFolderRequest(folder, supervisedAccount);
        if (supervisedAccount) {
          setLoading(true);
          getSupervisedUserData(supervisedAccount.user.email)
            .then((userSupervised) => {
              localStorage.setItem(
                "userSupervised",
                JSON.stringify(userSupervised)
              );
              dispatch(setUserSupervised(userSupervised));
            })
            .finally(() => {
              dispatch(setLoading(false));
            });
        }
      }
    }
  };
export const deleteFolder =
  (
    folder: SharedFolder,
    currentUserEmail: string,
    history: any,
    supervisedAccount: UserSupervised | null
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    if (folder) {
      await Promise.all(
        folder.files.map((file) => deleteFileFromStorageRequest(file.url))
      );
      await deleteFolderRequest(folder.id, supervisedAccount);
      if (supervisedAccount) {
        getSupervisedUserData(currentUserEmail)
          .then((userSupervised) => {
            localStorage.setItem(
              "userSupervised",
              JSON.stringify(userSupervised)
            );
            dispatch(setUserSupervised(userSupervised));
          })
          .finally(() => {});
      }
    }
  };

/**
 * Update the users list for a folder
 * @param idFolder
 */
export const updateFolderUsers =
  (
    idFolder: string,
    users: string[],
    supervisedAccount: UserSupervised | null
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    let folder = await getFolderRequest(idFolder, supervisedAccount);
    if (folder) {
      folder.users = users;
      await updateFolderRequest(folder, supervisedAccount);
      if (supervisedAccount) {
        setLoading(true);
        getSupervisedUserData(supervisedAccount.user.email)
          .then((userSupervised) => {
            localStorage.setItem(
              "userSupervised",
              JSON.stringify(userSupervised)
            );
            dispatch(setUserSupervised(userSupervised));
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      }
    }
  };

/**
 * Update the folder name
 * @param idFolder
 */
export const updateFolderName =
  (
    idFolder: string,
    newName: string,
    supervisedAccount: UserSupervised | null
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    let folder = await getFolderRequest(idFolder, supervisedAccount);
    if (folder) {
      folder.name = newName;
      await updateFolderRequest(folder, supervisedAccount);
      if (supervisedAccount) {
        setLoading(true);
        getSupervisedUserData(supervisedAccount.user.email)
          .then((userSupervised) => {
            localStorage.setItem(
              "userSupervised",
              JSON.stringify(userSupervised)
            );
            dispatch(setUserSupervised(userSupervised));
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      }
    }
  };

/**
 * Update the folder comments view by (remove new comment notification increment)
 * @param idFolder
 */
export const updateViewByFolderComment =
  (
    idFolder: string,
    idFile: string,
    currentUserEmail: string,
    supervisedAccount: UserSupervised | null
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    let folder = await getFolderRequest(idFolder, supervisedAccount);
    if (folder) {
      folder.files.forEach((file) => {
        if (file.id === idFile && file.comments) {
          file.comments.forEach((comment) => {
            if (
              !_.some(
                comment.viewBy,
                (userMail) => userMail == currentUserEmail
              )
            ) {
              comment.viewBy.push(currentUserEmail);
            }
          });
        }
      });
      await updateFolderRequest(folder, supervisedAccount);
      if (supervisedAccount) {
        setLoading(true);
        getSupervisedUserData(supervisedAccount.user.email)
          .then((userSupervised) => {
            localStorage.setItem(
              "userSupervised",
              JSON.stringify(userSupervised)
            );
            dispatch(setUserSupervised(userSupervised));
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      }
    }
  };

/**
 * Add current user mail into "viewBy" attribut for remove new shared files notification
 * @param currentUserEmail
 * @param folderId
 */
export const updateViewByFileForFolder =
  (
    currentUserEmail: string,
    folderId: string,
    supervisedAccount: UserSupervised | null
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    let folder = await getFolderRequest(folderId, supervisedAccount);
    if (folder) {
      let needSave = false;
      _.forEach(folder.files, (file) => {
        if (!_.some(file.viewBy, (u) => u == currentUserEmail)) {
          file.viewBy.push(currentUserEmail);
          needSave = true;
        }
      });

      if (needSave) {
        await updateFolderRequest(folder, supervisedAccount);
        if (supervisedAccount) {
          setLoading(true);
          getSupervisedUserData(supervisedAccount.user.email)
            .then((userSupervised) => {
              localStorage.setItem(
                "userSupervised",
                JSON.stringify(userSupervised)
              );
              dispatch(setUserSupervised(userSupervised));
            })
            .finally(() => {
              dispatch(setLoading(false));
            });
        }
      }
    }
  };

export const setFoldersReducer = (folders: SharedFolder[]) =>
  ({
    type: "set-shared-folder-list",
    folders,
  } as const);

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-shared-folder-loading",
    isLoading,
  } as const);

export const setSendMessageLoading = (isLoading: boolean) =>
  ({
    type: "set-send-message-media-loading",
    isLoading,
  } as const);

export const setUploadProgress = (progress?: FileUploadProgess) =>
  ({
    type: "set-upload-progess",
    progress,
  } as const);

/**
 * Called after a notification (toast + audio) is done, update database for not playing notification next time
 */
export const setFolderNotificationShownByUser =
  (
    folder: SharedFolder,
    currentUserMail: string,
    supervisedAccount: UserSupervised | null
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    if (folder && folder.files) {
      folder.files = folder.files.map((file) => {
        if (file.needToastNotification) {
          file.needToastNotification = file.needToastNotification.filter(
            (user) => user !== currentUserMail
          );
        }
        return file;
      });
      await updateFolderRequest(folder, supervisedAccount);
      if (supervisedAccount) {
        setLoading(true);
        getSupervisedUserData(supervisedAccount.user.email)
          .then((userSupervised) => {
            localStorage.setItem(
              "userSupervised",
              JSON.stringify(userSupervised)
            );
            dispatch(setUserSupervised(userSupervised));
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      }
    }
  };

/**
 * Update albums view by (remove new albums notification increment)
 */
export const updateAlbumsNotViewByUser =
  (
    folders: SharedFolder[],
    currentUserEmail: string,
    supervisedAccount: UserSupervised | null
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    folders.forEach(async (folder) => {
      if (folder.notViewBy) {
        const notViewBy = folder.notViewBy.filter(
          (user) => user !== currentUserEmail
        );
        folder.notViewBy = notViewBy;
        await updateFolderRequest(folder, supervisedAccount);
        if (supervisedAccount) {
          setLoading(true);
          getSupervisedUserData(supervisedAccount.user.email)
            .then((userSupervised) => {
              localStorage.setItem(
                "userSupervised",
                JSON.stringify(userSupervised)
              );
              dispatch(setUserSupervised(userSupervised));
            })
            .finally(() => {
              dispatch(setLoading(false));
            });
        }
      }
    });
  };
export const updateAlbumFile =
  (
    folder: SharedFolder,
    file: SharedFile,
    fileBase64: string,
    fileExtension: string,
    supervisedAccount: UserSupervised | null
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    if (folder) {
      dispatch(setLoading(true));
      if (
        file &&
        file.url &&
        file.url.includes("firebasestorage.googleapis.com")
      ) {
        deleteFileFromStorageRequest(file.url);
      }
      const url = await uploadAlbumFileRequest(
        fileBase64,
        fileExtension,
        folder.id
      );
      if (url) {
        folder.files.forEach((f) => {
          if (f.id === file.id) {
            file.url = url;
          }
        });
      }
      if (!url) alert("Error lors du téléchargement");
      await updateFolderRequest(folder, supervisedAccount, file.id);
      if (supervisedAccount) {
        getSupervisedUserData(supervisedAccount.user.email)
          .then((userSupervised) => {
            localStorage.setItem(
              "userSupervised",
              JSON.stringify(userSupervised)
            );
            dispatch(setUserSupervised(userSupervised));
          })
          .finally(() => {
            dispatch(setLoading(false));
            toast.success("La photo a bien été modifiée");
          });
      }else{
        dispatch(setLoading(false));
        toast.success("La photo a bien été modifiée");
      }
    }
  };

export const uploadAudioComment =
  (
    folder: SharedFolder,
    fileId: string,
    senderEmail: string,
    senderName: string,
    fileData: Blob,
    fileExtension: string,
    supervisedAccount: UserSupervised | null
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    {
      dispatch(setLoading(true));
      await uploadAudioCommentRequest(
        folder,
        fileId,
        senderEmail,
        senderName,
        fileData,
        fileExtension,
        supervisedAccount
      );
      if (supervisedAccount) {
        setLoading(true);
        getSupervisedUserData(supervisedAccount.user.email)
          .then((userSupervised) => {
            localStorage.setItem(
              "userSupervised",
              JSON.stringify(userSupervised)
            );
            dispatch(setUserSupervised(userSupervised));
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      } else {
        dispatch(setLoading(false));
      }
    }
  };

export type SharedFolderActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setFoldersReducer>
  | ActionType<typeof setSendMessageLoading>
  | ActionType<typeof setUploadProgress>;
