import { ToastNotification } from "../../models/ToastNotification";
import { ActionType } from "../../util/types";

export const setToastNotification = (toast?: ToastNotification) =>
  ({
    type: "set-toast-notification",
    toast,
  } as const);

export const setScreenSaveEnable = (enable: boolean) =>
  ({
    type: "set-screensaver-enable",
    enable,
  } as const);

export const setHideEmergencyCommand = (hide: boolean) =>
  ({
    type: "set-hide-emergency-command",
    hide,
  } as const);

export const setSearchTerm = (searchTerm: string) =>
  ({
    type: "set-search-term",
    searchTerm,
  } as const);

export const setUserIsActive = (userIsActive: boolean) =>
  ({
    type: "set-userIsActive",
    userIsActive,
  } as const);

export const setAppIsActive = (active: boolean) =>
  ({
    type: "set-AppIsActive",
    active,
  } as const);

export const setAppOnline = (appOnline: boolean) =>
  ({
    type: "set-AppOnline",
    appOnline,
  } as const);
  
export const setShowOffline = (showOffline: boolean) =>
  ({
    type: "set-ShowOffline",
    showOffline,
  } as const);

export type GlobalActions =
  | ActionType<typeof setToastNotification>
  | ActionType<typeof setScreenSaveEnable>
  | ActionType<typeof setHideEmergencyCommand>
  | ActionType<typeof setSearchTerm>
  | ActionType<typeof setUserIsActive>
  | ActionType<typeof setAppIsActive>
  | ActionType<typeof setAppOnline>
  | ActionType<typeof setShowOffline>;
