import _ from "lodash";
import firebase from "../FirebaseConfig";
import { Mailbox, MailboxItem, MailboxItemType } from "../models/Mailbox";
import { createGuid } from "../util/commons";
import { getUser } from "./user";

export const getMailboxCurrentUserRequest = async (currentUser: string) => {
  try {
    if (currentUser) {
      const db = firebase.firestore();
      let mailbox = await db.collection("mailbox").doc(currentUser).get();
      return mailbox.data() as Mailbox;
    }
  } catch (e) {
    alert("Erreur getMailboxRequest: " + JSON.stringify(e));
  }
  return false;
};

export const updateMailboxCurrentUserRequest = async (
  currentUser: string,
  mailbox: Mailbox
) => {
  try {
    if (currentUser) {
      const db = firebase.firestore();
      return await db.collection("mailbox").doc(mailbox.user).update(mailbox);
    }
  } catch (e) {
    alert("Erreur updateMailboxCurrentUserRequest: " + JSON.stringify(e));
  }
  return false;
};

/**
 * Send new video message to an user
 * @param toUser
 * @param fileData
 * @param fileExtension
 * @param idVisioCall id of the call if video message is from anot answered call
 * @returns
 */
export const uploadVideoMessageRequest = async (
  toUser: string,
  fileData: Blob,
  fileExtension: string,
  idVisioCall?: string
) => {
  const currentUser = firebase.auth().currentUser;
  const recipientUser = await getUser(toUser);

  if (currentUser) {
    const currentUserName = currentUser.email
      ? currentUser.email
      : currentUser.phoneNumber
      ? currentUser.phoneNumber.replace("+33", "0")
      : "";
    const fileId = createGuid(5);
    const filepath =
      "private/mailbox/" +
      recipientUser.id +
      "/" +
      fileId +
      "." +
      fileExtension;
    var storageRef = firebase.storage().ref(filepath);
    return await storageRef
      .put(fileData)
      .then(async (snapshot) => {
        const db = firebase.firestore();

        let mailbox = await db.collection("mailbox").doc(toUser).get();

        // if don't have mailbox, create it
        if (!mailbox.exists) {
          const data = {
            user: toUser,
            items: [],
          } as Mailbox;
          await db.collection("mailbox").doc(toUser).set(data);
          mailbox = await db.collection("mailbox").doc(toUser).get();
        }

        let mailboxData = mailbox.data() as Mailbox;

        let messageFromVisioCallFound = false;
        // fix https://trello.com/c/RTPxNfG2/219-un-message-manqu%C3%A9-ne-doit-pas-%C3%AAtre-envoy%C3%A9-a-son-correspondant-qui-ne-repond-pas-avant-de-cliquer-sur-annuler-car-si-on-appuie-su
        // if id call exist, remove "MissingCall"
        if (idVisioCall) {
          const index = _.findIndex(
            mailboxData.items,
            (item) =>
              item.idVisoCall == idVisioCall &&
              item.type === MailboxItemType.MissingCall
          );
          if (index >= 0) {
            messageFromVisioCallFound = true;
            mailboxData.items[index].type = MailboxItemType.MessageVideo;
            mailboxData.items[index].sizeKo = fileData.size / 1000;
            mailboxData.items[index].urlFile =
              await snapshot.ref.getDownloadURL();
            mailboxData.items[index].id = fileId;
          }
        }

        if (!idVisioCall || !messageFromVisioCallFound) {
          mailboxData.items.push({
            date: new Date(),
            fromUser: currentUserName,
            urlFile: await snapshot.ref.getDownloadURL(),
            viewed: false,
            id: fileId,
            sizeKo: fileData.size / 1000,
            needToastNotification: true,
            type: MailboxItemType.MessageVideo,
          } as MailboxItem);
        }

        await db.collection("mailbox").doc(toUser).update(mailboxData);
        return mailboxData;
      })
      .catch((err) =>
        alert("uploadVideoMessageRequest error" + JSON.stringify(err))
      );
  } else {
    return false;
  }
};

/**
 * Send new message for an user mailbox
 * @param userTo
 * @param message
 * @returns
 */
export const sendMessageToMailbox = async (
  userTo: string,
  message: MailboxItem
) => {
  const sendMessageToMailboxFunc = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("Mailbox-sendMessageToMailbox");
  return sendMessageToMailboxFunc({
    userTo: userTo,
    message: message,
  }).then((result) => result.data.success === true);
};
