import React from "react";
import {
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonCol,
} from "@ionic/react";
import Header from "../../components/Header";
import { connect } from "../../data/connect";
import {
  image,
  lockClosed,
  logOut,
  personCircle,
  arrowBackCircleOutline,
} from "ionicons/icons";
import classes from "./Settings.module.scss";
import firebase from "firebase";
import { setIsLoggedIn, setUsername } from "../../data/user/user.actions";
import { checkIsPhoneFormat } from "../../util/checkIsPhoneFormat";
import { isBoolean } from "lodash";
import { UserSupervised } from "../../models/UserSupervised";
import Button from "../../components/Button";
interface OwnProps {
  history: any;
}

interface StateProps {
  currentUserEmail: string | undefined;
  supervisedAccount?: UserSupervised;
}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
}

interface AccountSettingsProps extends OwnProps, StateProps, DispatchProps {}

const AccountSettings: React.FC<AccountSettingsProps> = ({
  history,
  currentUserEmail,
  supervisedAccount,
  setIsLoggedIn,
  setUsername,
}) => {
  const showResetPwd =
    !supervisedAccount && !checkIsPhoneFormat(currentUserEmail);
  const options = [
    {
      id: 0,
      title: "Photo de profil",
      icon: image,
      action: () => history.push("/updatephoto"),
    },
    {
      id: 1,
      title: "Identification",
      icon: personCircle,
      action: () => {
        history.push("/updateusername");
      },
    },
    showResetPwd && {
      id: 2,
      title: "Mot de passe",
      icon: lockClosed,
      action: () => {
        history.push("/resetpassword");
      },
    },
    !supervisedAccount && {
      id: 4,
      title: "Déconnexion",
      icon: logOut,
      action: signOut,
    },
  ];
  /**
   * signOut : log out  and exit from the application
   **/
  async function signOut() {
    await firebase.auth().signOut();
    setIsLoggedIn(false);
    setUsername(undefined);
    history.replace("/login");
  }

  return (
    <IonPage>
      <Header title="Réglages du compte et profil" showHelp />
      <IonContent>
        <div className={classes.container}>
          <IonList className={classes.list}>
            {options.map((option) => {
              if (!isBoolean(option)) {
                return (
                  <IonItem
                    key={option.id}
                    button
                    onClick={option.action}
                    className={classes.item}
                  >
                    <IonIcon
                      slot="start"
                      icon={option.icon}
                      className={classes.icon}
                    ></IonIcon>
                    <p>{option.title}</p>
                  </IonItem>
                );
              }
            })}
          </IonList>
        </div>
      </IonContent>
      <IonRow>
        <IonCol size="12">
          <Button
            routerLink="/settings"
            color="light"
            text="Retour"
            icon={arrowBackCircleOutline}
          />
        </IonCol>
      </IonRow>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    currentUserEmail: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user.email
      : state.user.username,
    supervisedAccount: state.supervisor.supervisedAccount,
  }),
  mapDispatchToProps: {
    setIsLoggedIn,
    setUsername,
  },
  component: React.memo(AccountSettings),
});
