import _ from "lodash";

/**
 * Check if string is a phone
 * @param val
 * @returns
 */
export const checkIsPhoneFormat = (val: string | undefined) => {
  // For now, just testing if it's a number
  // TODO test all phone format
  const regExp = /[a-zA-Z]/g;
  return val ? !regExp.test(val) : false;
};

export const checkIsValidPhone = (phoneNumber: string) => {
  return (
    checkIsPhoneFormat(phoneNumber) &&
    phoneNumber.length == 10 &&
    (_.startsWith(phoneNumber, "06") || _.startsWith(phoneNumber, "07"))
  );
};
