import { SessionsActions } from "./sessions.actions";
import { ConfState } from "./conf.state";

export const sessionsReducer = (
  state: ConfState,
  action: SessionsActions
): ConfState => {
  switch (action.type) {
    case "set-app-ready": {
      return { ...state, appReady: action.isReady };
    }
    case "set-menu-enabled": {
      return { ...state, menuEnabled: action.menuEnabled };
    }
    default:
      return state;
  }
};
