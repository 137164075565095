import _ from "lodash";
import firebase from "../FirebaseConfig";

export const sendSms = async (tel: string, message: string) => {
  return new Promise(async (resolve) => {
    const sendSmsFunc = firebase
      .app()
      .functions("europe-west1")
      .httpsCallable("VisioCall-inviteByTelephone");
    // remove 0 first case
    const telFormated = _.drop(tel.split("")).join("");
    return sendSmsFunc({
      tel: telFormated,
      message: message,
    }).then((result) => resolve(true));
  });
};
