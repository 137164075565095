import {
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRange,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "../../data/connect";
import BottomButtons from "../BottomButtons";
import * as selectors from "../../data/selectors";
import { User, UserConfiguration } from "../../models/User";
import { setShowOffline } from "../../data/global/global.actions";
import { updateUser } from "../../data/user/user.actions";
import _ from "lodash";
import Header from "../Header";
import { UserSupervised } from "../../models/UserSupervised";
interface OwnProps {
  showAlarmClock: (show: boolean) => void;
}

interface StateProps {
  config: UserConfiguration;
  supervisedAccount: UserSupervised | null;
  appOnline: boolean;
  currentUser?: User;
}

interface DispatchProps {
  setShowOffline: typeof setShowOffline;
  updateUser: typeof updateUser;
}
interface AlarmClockSettingsProps
  extends OwnProps,
  StateProps,
  DispatchProps { }

const AlarmClockSettings: React.FC<AlarmClockSettingsProps> = ({
  config,
  supervisedAccount,
  appOnline,
  currentUser,
  showAlarmClock,
  setShowOffline,
  updateUser
}) => {
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement>();
  const [alarmClockVolumeInput, setAlarmClockVolumeInput] = useState(config.alarmClockVolume);
  const [alarmClockRingtoneInput, setAlarmClockRingtoneInput] = useState(config.alarmClockRingtone);

  useEffect(() => {
    setAlarmClockVolumeInput(config.alarmClockVolume)
  }, [config.alarmClockVolume])
  useEffect(() => {
    setAlarmClockRingtoneInput(config.alarmClockRingtone)
  }, [config.alarmClockRingtone])

  const cancelHandler = () => {
    if (currentAudio) currentAudio.pause();
    showAlarmClock(false);
  };

  const validateHandler = async () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    if (currentAudio) currentAudio.pause();
    if(currentUser){
      const userConfig = {... config} as UserConfiguration;
      userConfig.alarmClockRingtone = alarmClockRingtoneInput;
      userConfig.alarmClockVolume = alarmClockVolumeInput;
      currentUser.config = _.clone(userConfig);
      updateUser(currentUser, supervisedAccount, "Modifications enregistrées");
    }
    showAlarmClock(false);
  };
  const playRingtone = (fileName: string) => {
    if (currentAudio) currentAudio.pause();
    const audio = new Audio("assets/rings/alarmClock/" + fileName);
    audio.volume = alarmClockVolumeInput !== undefined ? alarmClockVolumeInput / 100 : 0.8;
    audio.play();
    setCurrentAudio(audio);
  }
  const rings = [
    {
      id: 0,
      title: "Aucune",
      value: "",
      action: () => { if (currentAudio) currentAudio.pause(); },
    },
    {
      id: 1,
      title: "Matin",
      value: "morning.mp3",
      action: () => playRingtone("morning.mp3"),
    },
    {
      id: 2,
      title: "Quartz",
      value: "quartz.mp3",
      action: () => playRingtone("quartz.mp3"),
    },
    {
      id: 3,
      title: "Cloche",
      value: "incendie.mp3",
      action: () => playRingtone("incendie.mp3"),
    },
    {
      id: 4,
      title: "Samurai",
      value: "samurai.mp3",
      action: () => playRingtone("samurai.mp3"),
    },
    {
      id: 5,
      title: "Coucou",
      value: "cuckoo.mp3",
      action: () => playRingtone("cuckoo.mp3"),
    },
  ];
  return (
    config && (
      <IonModal
        isOpen={true}
        cssClass="modal-alarm-clock-settings"
        onDidDismiss={() => showAlarmClock(false)}
      >
        <Header  action={cancelHandler} title="Sonnerie de réveil" showHelp/>
        <IonItemDivider>Volume</IonItemDivider>
        <IonItem>
          <IonRange value={alarmClockVolumeInput} onIonChange={(e) => {
            setAlarmClockVolumeInput(e.detail.value as number)
            if(currentAudio){
              currentAudio.volume = e.detail.value as number / 100;
            }
          }}>
            <IonIcon slot="start" src={"assets/icon/volume-mute.svg"}></IonIcon>
            <IonIcon slot="end" src={"assets/icon/volume-up.svg"}></IonIcon>
          </IonRange>
        </IonItem>
        <IonItemDivider>Sonnerie</IonItemDivider>
        <IonRadioGroup
          style={{ flexGrow: 1, padding: "20px" }}
          value={alarmClockRingtoneInput}
          onIonChange={(e) => {
            setAlarmClockRingtoneInput(e.detail.value)
          }}
        >
          {
            rings.map((ring) =>
              <IonItem key={ring.id} onClick={ring.action}>
                <IonLabel>{ring.title}</IonLabel>
                <IonRadio slot="start" value={ring.value} />
              </IonItem>
            )
          }
        </IonRadioGroup>
        <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
          <BottomButtons
            onCancel={cancelHandler}
            onValidate={validateHandler}
          />
        </div>
      </IonModal>
    )
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setShowOffline,
    updateUser
  },
  mapStateToProps: (state) => ({
    config: selectors.getUserConfig(state),
    supervisedAccount: state.supervisor.supervisedAccount || null, 
    appOnline: state.global.appOnline,
    currentUser: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore,
  }),
  component: React.memo(AlarmClockSettings),
});
