import { IonRange } from "@ionic/react";
import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../util/cropImage";
import "./Cropper.scss";

interface CropperProps {
  src: string;
  setCroppedImage: (croppedImage: string) => void;
}

const CropperImage: React.FC<CropperProps> = ({ src, setCroppedImage }) => {
  const initialCroppedAreaPixels = { width: NaN, height: NaN, x: NaN, y: NaN };
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [aspect, setAspect] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(
    initialCroppedAreaPixels
  );

  useEffect(() => {
    //temporary solution to solve the bug caused by an image selected by the user (from gallery or camera)
    setTimeout(() => {
      setRotation(1);
      setRotation(0);
    }, 500);
    return () => {
      setRotation(0);
      setZoom(0);
      setCrop({ x: 0, y: 0 });
      setAspect(1);
      setCroppedAreaPixels(initialCroppedAreaPixels);
    };
  }, []);
  useEffect(() => {
    if (croppedAreaPixels) {
      
      const isValidCroppedArea =
        croppedAreaPixels.width &&
        croppedAreaPixels.width !== NaN &&
        croppedAreaPixels.height &&
        croppedAreaPixels.height !== NaN &&
        croppedAreaPixels.x !== NaN &&
        croppedAreaPixels.y !== NaN;

      if (isValidCroppedArea) {
        getCroppedImage().then((res) => {
          setCroppedImage(res);
        });
      }
    }
  }, [croppedAreaPixels, rotation, src]);

  const getCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        src,
        croppedAreaPixels,
        rotation
      );
      return croppedImage;
    } catch (e) {
      alert("Error getCroppedImage : " + e);
    }
    return "";
  };

  const onCropChange = (crop: any) => {
    setCrop(crop);
  };

  const onCropComplete = (
    croppedArea: any,
    croppedAreaPixels: { width: number; height: number; x: number; y: number }
  ) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom: number) => {
    setZoom(zoom);
  };
  const onRotationChange = (rotation: number) => {
    setRotation(rotation);
  };

  return (
    <div className="cropper">
      <div className="crop-container">
        <Cropper
          image={src}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={aspect}
          cropShape="rect"
          showGrid={false}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
        />
      </div>
      <div className="controls">
        <div className="controls-item">
          <p className="label">AGRANDISSEMENT</p>
          <IonRange
            className="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onIonChange={(e: any) => onZoomChange(e.detail.value as number)}
          ></IonRange>
        </div>
        <div className="controls-item">
          <p className="label">ROTATION</p>
          <IonRange
            className="range"
            value={rotation}
            min={0}
            max={360}
            step={1}
            onIonChange={(e: any) => onRotationChange(e.detail.value as number)}
          ></IonRange>
        </div>
      </div>
    </div>
  );
};

export default CropperImage;
