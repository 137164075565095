import React from "react";
import { IonModal, IonPage, IonContent, IonText, IonImg } from "@ionic/react";
import classes from "./MediaShareRequestModal.module.scss";
import { IconSvgEnum } from "./IconSvg";
import _ from "lodash";
import { MediaShareRequest } from "../models/MediaShareRequest";
import { isVideoFile } from "../util/commons";
import Header from "./Header";
import BottomButtons from "./BottomButtons";
import { connect } from "../data/connect";
import { updateOrDeleteMediaShareRequest } from "../api/mediaShareRequest";
import Loading from "./Loading";
import { _NameUnsharedFolder } from "../appConfig";
import { acceptMediaShare } from "../data/mediaShareRequest/mediaShareRequest.actions";
import { setShowOffline } from "../data/global/global.actions";

interface OwnProps {
  mediaShareRequest: MediaShareRequest;
}

interface StateProps {
  currentUserEmail: string;
  loading: boolean;
  appOnline: boolean;
}

interface DispatchProps {
  updateOrDeleteMediaShareRequest: typeof updateOrDeleteMediaShareRequest;
  acceptMediaShare: typeof acceptMediaShare;
  setShowOffline: typeof setShowOffline;
}
interface MediaShareRequestModalProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const MediaShareRequestModal: React.FC<MediaShareRequestModalProps> = ({
  mediaShareRequest,
  currentUserEmail,
  loading,
  appOnline,
  updateOrDeleteMediaShareRequest,
  acceptMediaShare,
  setShowOffline
}) => {
  async function validateRequest() {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    acceptMediaShare(mediaShareRequest, currentUserEmail);
  }
  async function refuseRequest() {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    updateOrDeleteMediaShareRequest(mediaShareRequest, currentUserEmail);
  }
  const isVideo = isVideoFile(mediaShareRequest.fileExtension);
  return (
    <IonModal isOpen={true}>
      <IonPage>
        <Header title="Partage de souvenirs" hideHome />
        {loading && (
          <Loading enable={true} text={"Téléchargement en cours ..."} />
        )}
        <IonText className={classes.text}>
          <b>{`Voici une nouvelle ${!isVideo ? "photo" : "vidéo"} envoyée par ${
            mediaShareRequest.senderName
          }`}</b>
          <br />
          {`Cliquer ci-dessous pour la supprimer ou bien télécharger la dans "${_NameUnsharedFolder}"`}
        </IonText>
        <IonContent scrollY={false}>
          <div className={classes.mediaContainer}>
            {!isVideo && (
              <IonImg
                src={mediaShareRequest.fileUrl}
                className={classes.media}
              />
            )}
            {isVideo && (
              <video controls className={classes.media}>
                <source
                  src={mediaShareRequest.fileUrl}
                  type={`video/${mediaShareRequest.fileExtension}`}
                />
              </video>
            )}
          </div>
        </IonContent>
        <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
          <BottomButtons
            cancelBtnColor="danger"
            validateBtnColor="success"
            cancelBtnLabel="SUPPRIMER"
            validateBtnLabel="TÉLÉCHARGER"
            cancelBtnIcon={IconSvgEnum.cancel_white}
            validateBtnIcon={IconSvgEnum.download_white}
            onCancel={() => refuseRequest()}
            onValidate={() => validateRequest()}
          />
        </div>
      </IonPage>
    </IonModal>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    updateOrDeleteMediaShareRequest,
    acceptMediaShare,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    currentUserEmail: state.user.username || "",
    loading: state.mediaShareRequest.loading,
    appOnline: state.global.appOnline
  }),
  component: React.memo(MediaShareRequestModal),
});
