import firebase from 'firebase';


// Prod MLV
export const config = {
  // Prod MLV
  apiKey: "AIzaSyAdzv2Hk6c0_MMXMzMLnlwRq-MyXIQmBGY",
  authDomain: "monlienvisio.fr",
  projectId: "mon-lien-visio-homelinks-prod",
  storageBucket: "mon-lien-visio-homelinks-prod.appspot.com",
  messagingSenderId: "240074724510",
  appId: "1:240074724510:web:47958a95392ac37439178f",
  measurementId: "G-72N87FXE0D"
  /*apiKey: "AIzaSyBKsEmaGqc2RTysC2HUwuCAO9WrxKWhUbE",
  authDomain: "mon-lien-visio-homelinks.firebaseapp.com",
  projectId: "mon-lien-visio-homelinks",
  storageBucket: "mon-lien-visio-homelinks.appspot.com",
  messagingSenderId: "267417320836",
  appId: "1:267417320836:web:264e5016f64b2fff71b1d1",
  measurementId: "G-QMVN56CBRG"*/
};


// Initialize Firebase
if (firebase.apps.length === 0) {
  firebase.initializeApp(config);
  firebase.analytics();
}

export default firebase;