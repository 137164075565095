import React, { Fragment, useEffect, useState } from "react";
import { SharedFile } from "../models/SharedFile";
import { readFileFromCache } from "../util/localStorage";
import VideoPlayer from "./VideoPlayer";
import firebase from "../FirebaseConfig";
import "../pages/Screensaver.scss";
import { convertToTimestamp } from "../util/functions";

interface ScreensaverItemProps {
  isVideo?: boolean;
  file?: SharedFile;
  creatorName?: string | null;
  albumName?: string;
  isDefault: boolean;
  srcFile?: string;
}

const ScreensaverItem: React.FC<ScreensaverItemProps> = ({
  isVideo,
  file,
  creatorName,
  albumName,
  isDefault,
  srcFile,
}) => {
  const [src, setSrc] = useState("");
  const urlFile = srcFile ? srcFile : file ? file.url : "";
  const extension = file ? file.extension : "";
  const timestampCreate = file ? convertToTimestamp(file.dateCreate) : null;
  const dateCreate = timestampCreate ? timestampCreate.toDate().toLocaleDateString() : undefined;

  useEffect(() => {
    readFileFromCache(urlFile, setSrc);
  }, [urlFile]);

  return (
    <Fragment>
      {!isDefault && (
        <Fragment>
          {!isVideo && <img src={src} />}
          <div style={{ width: "100%" }}>
            {isVideo && (
              <VideoPlayer
                url={src}
                height={"%100"}
                width={"100%"}
                type={"video/" + extension}
                play={true}
                loop={true}
                muted={true}
                controls={false}
              />
            )}
            <div
              className="blur-background"
              style={{ backgroundImage: "url(" + src + ")" }}
            ></div>
            <div className="caption authorAlbum">
              <p>
                {creatorName && `De ${creatorName}`} Album {albumName} 
                {dateCreate && ` le ${dateCreate}`}
              </p>
            </div>
          </div>
        </Fragment>
      )}
      {isDefault && <img src={src} />}
    </Fragment>
  );
};

export default ScreensaverItem;
