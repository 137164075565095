export default interface VisioCall {
  id: string,
  roomName: string;
  roomPassword: string;
  creator: string;
  participantEmails: string[];
  participantStates: Participant[];
  dateCreate: Date;
  dateEnd?: Date;
  totalSecondDuration?: number;
  isEmergencyCall?: boolean;
  c_active: boolean;
  idGroupsContact: string[];
  errors?: VisioCallError[];
  invitedByEmail?: string[];
  invitedBySms?: string[];
}

export interface VisioCallError {
  email: string;
  details?: any; // additional error details
  message?: string; // the error message
  name: string; // the coded name of the error
  type: string; // error type/source, one of : 'CONFIG', 'CONNECTION', 'CONFERENCE'
  isFatal: boolean; // whether this is a fatal error which triggered a reconnect overlay or not,
  date: Date
}

export interface Participant {
  email: string;
  state: ParticipantState;
}

/** If user accept or refuse an incoming call on the native push notification, save response here (native code send response to IONIC) */
export interface UserActionFromPush {
  callId: string;
  accepted: boolean;
}

export enum ParticipantState {
  /**
   * Wait answer from user
   */
  Waiting = "Waiting",
  /**
   * User accept the call
   */
  Accepted = "Accepted",
  /**
   * User didn't accept the call
   */
  Refused = "Refused",

  /**
   * User leave the call after accepted
   */
  Completed = "Completed"
}