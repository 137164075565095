import { isPlatform } from "@ionic/react";
import firebase from "../FirebaseConfig";
import { MailboxItem } from "../models/Mailbox";
import { User } from "../models/User";
import VisioCall, { Participant, ParticipantState, VisioCallError } from "../models/VisioCall";
import { createGuid } from "../util/commons";
import { getUser } from "./user";
import { registerPlugin } from "@capacitor/core";
import axios from "axios";
import _ from "lodash";
import { sendMessageToMailbox } from "./mailbox";

const CustomNativePlugin = registerPlugin("CustomNativePlugin") as any;

export const acceptOrRefuseCallFirestore = async (
  callId: string,
  curentUserMail: string,
  state: ParticipantState,
  forceAllParticipantsQuit: boolean = false
) => {
  const db = firebase.firestore();
  const currentUser = firebase.auth().currentUser;
  const usersToSendMessage = [];
  let call = (
    await db.collection("visioCalls").doc(callId).get()
  ).data() as VisioCall;

  if (call && currentUser) {
    for (var i = 0; i < call.participantStates.length; i++) {
      let user = call.participantStates[i];
      if (user.email == curentUserMail) {
        user.state = state;
      } else if (forceAllParticipantsQuit) {
        // si l'utilisateur n'a pas répondu alors il faut lui afficher une notification d'appel manqué
        /*if (user.state == ParticipantState.Waiting) {
          usersToSendMessage.push(user.email);
        }*/
        user.state = ParticipantState.Completed;
      }
    }

    if (forceAllParticipantsQuit) call.c_active = false;

    db.collection("visioCalls")
      .doc(callId)
      .update(call)
      .catch((err) => console.error("acceptOrRefuseCallFirestore error", err));

    /*usersToSendMessage.forEach(async (user) => {
      const result = await sendMessageToMailbox(user, {
        date: new Date(),
        fromUser: call.creator,
        urlFile: "",
        viewed: false,
        id: createGuid(5),
        sizeKo: 0,
        needToastNotification: true,
        type: "MissingCall",
      } as MailboxItem);
    });*/
  }
};

export const startVisioCall = async (
  toEmails: string[],
  currentUser: User,
  groupsIdToCall: string[],
  isEmergencyCall: boolean = false
) => {
  const callId = createGuid(5);

  if (!currentUser) {
    console.error("AAAA current user not found", currentUser);
    return;
  }
  let toUsers: User[] = [];
  let participants: Participant[] = [
    { email: currentUser.email, state: ParticipantState.Accepted },
  ];
  // for creating room name. all firstName for using join
  let participantFirstNames: string[] = [currentUser.firstName];
  let allEmails: string[] = [currentUser.email];

  for (const mail of toEmails) {
    let userFind = await getUser(mail);
    if (userFind) {
      if (userFind.pushToken !== "") {
        let data = {
          data: {
            name: currentUser.firstName + " " + currentUser.lastName,
            image: currentUser.picture,
            callId: callId,
          },
          to: userFind.pushToken,
          priority: "high",
        } as any;

        if (
          !userFind.deviceInfo ||
          (userFind.deviceInfo && userFind.deviceInfo.platform == "ios")
        ) {
          data.notification = {
            title: "Mon lien visio",
            body:
              "Appel entrant de " +
              currentUser.firstName +
              " " +
              currentUser.lastName,
            sound: "incoming.aiff",
            click_action: "CALL_INVITATION",
          };
        }

        const header = {
          "Content-Type": "application/json",
          Authorization: `key=AAAAN-WRlJ4:APA91bFgKUWvWpJ9jUTw9dBGUBunXuYZ5NqH58hS-aY4r-Ndi6U5QIzKYDWqkTpDzqyl0KgA_4tDdjDXOBdAOpsYzSbuEF5lGq1yGGSsxUFK3tjQPPJX3Rj7d_d5sY4uSAcotzDYZMu3`,
        };

        if (isPlatform("ios")) {
          // Hack for ios. An error occurs when send push notifciation on ios from IONIC but work from Native code
          // so send request data to native code.
          CustomNativePlugin.sendHttpRequest({
            url: "https://fcm.googleapis.com/fcm/send",
            type: "POST",
            header: JSON.stringify(header),
            body: JSON.stringify(data),
          });
        } else {
          axios
            .post("https://fcm.googleapis.com/fcm/send", data, {
              headers: header,
            })
            .catch((err) => console.error("startVisioCall > push", err));
        }
      }

      toUsers.push(userFind);
      participants.push({
        email: userFind.email,
        state: ParticipantState.Waiting,
      });
      participantFirstNames.push(userFind.firstName);
      allEmails.push(userFind.email);
    }
  }

  const now = new Date();
  const roomName = _.snakeCase(
    `${_.join(
      participantFirstNames,
      " "
    )} le ${now.toLocaleDateString()} a ${now.toLocaleTimeString()}`
  );

  const call = {
    c_active: true,
    creator: currentUser.email,
    dateCreate: new Date(),
    roomName: roomName,
    roomPassword: "", //createGuid(5),  <- disable since we use watcha server: https://trello.com/c/fgij0SkX/296-jigasi-appel-audio-entrant-n%C3%A9cessite-un-code
    participantEmails: allEmails,
    participantStates: participants,
    isEmergencyCall: isEmergencyCall,
    idGroupsContact: groupsIdToCall,

  } as VisioCall;

  const db = firebase.firestore();
  await db
    .collection("visioCalls")
    .doc(callId)
    .set(call)
    .catch((err) => console.error("startVisioCall error", err));
};

export const getVisioCallFromArchive = async (visioId: string) => {
  const db = firebase.firestore();
  await db
    .collection("visioCallsArchives")
    .doc(visioId)
    .get()
    .then((visio) => {
      return visio.exists ? (visio.data() as VisioCall) : undefined;
    });
};

export const inviteByTelephone = async (tel: string, message: string) => {
  return new Promise((resolve) => {
    const sendMessageToMailboxFunc = firebase
      .app()
      .functions("europe-west1")
      .httpsCallable("VisioCall-inviteByTelephone");
    // remove 0 first case
    const telFormated = _.drop(tel.split("")).join("");

    return sendMessageToMailboxFunc({
      tel: telFormated,
      message: message,
    }).then((result) => resolve(true));
  });
};

export const logErrorOnCall = async (callId: string, error: any, userEmail: string) => {

  const db = firebase.firestore();

  // Get call
  let call = (
    await db.collection("visioCalls").doc(callId).get()
  ).data() as VisioCall;

  // Create new error for this user
  let newError = {
    email: userEmail,
    details: (error.details) ? error.details : null,
    message: (error.message) ? error.message : null,
    name: error.name,
    type: error.type,
    isFatal: error.isFatal,
    date: new Date()
  } as VisioCallError;
  if (!call.errors) { call.errors = []; }
  call.errors.push(newError);

  // Save call
  await db
    .collection("visioCalls")
    .doc(callId)
    .set(call)
    .catch((err) => console.error("startVisioCall error", err));
};

export const saveInvited = async (
  callId: string,
  invited: string,
  type: string,
) => {
  const db = firebase.firestore();
  let call = (
    await db.collection("visioCalls").doc(callId).get()
  ).data() as VisioCall;
  if (type == "email") {
    let invitedEmails = call.invitedByEmail ? call.invitedByEmail : [];
    invitedEmails.push(invited);
    call.invitedByEmail = invitedEmails;
  } else {
    let invitedSms = call.invitedBySms ? call.invitedBySms : [];
    invitedSms.push(invited);
    call.invitedBySms = invitedSms;
  }

  await db.collection("visioCalls").doc(callId).set(call)
}
