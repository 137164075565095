import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonSkeletonText,
} from "@ionic/react";
import { cameraOutline, imagesOutline, pencil } from "ionicons/icons";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "../data/connect";
import {
  CameraResultType,
  CameraSource,
  Camera,
  CameraDirection,
} from "@capacitor/camera";
import {
  setShowOffline,
  setToastNotification,
  setUserIsActive,
} from "../data/global/global.actions";
import { ToastNotification } from "../models/ToastNotification";
import { defaultPicture, toBase64 } from "../util/commons";
import _ from "lodash";
import classes from "./UpdateUserPicture.module.scss";
import Header from "../components/Header";
import { uploadUserPicture } from "../data/user/user.actions";
import { readFileFromCache } from "../util/localStorage";
import EditImage from "../components/Modals/EditImage";
import BottomButtons from "../components/BottomButtons";
import { RouteComponentProps } from "react-router";
import Button from "../components/Button";
import { User } from "../models/User";
import { UserSupervised } from "../models/UserSupervised";

interface OwnProps {}
interface StateProps {
  picture: string;
  username?: string;
  appOnline: boolean;
  currentUser: User | undefined;
  supervisedAccount: UserSupervised | null;
}
interface DispatchProps {
  uploadUserPicture: typeof uploadUserPicture;
  setToastNotification: typeof setToastNotification;
  setUserIsActive: typeof setUserIsActive;
  setShowOffline: typeof setShowOffline;
}
interface UpdateUserPictureProps
  extends RouteComponentProps,
    OwnProps,
    StateProps,
    DispatchProps {}

const UpdateUserPicture: React.FC<UpdateUserPictureProps> = ({
  picture,
  username,
  history,
  appOnline,
  currentUser,
  supervisedAccount,
  uploadUserPicture,
  setToastNotification,
  setUserIsActive,
  setShowOffline
}) => {
  const [src, setSrc] = useState("");
  const [showEditImage, setShowEditImage] = useState(false);
  const [imageFormat, setImageFormat] = useState("");
  const [imageData, setImageData] = useState("");

  useEffect(() => {
    setUserIsActive(false);
  }, []);

  useEffect(() => {
    readFileFromCache(picture, setSrc);
  }, [picture]);

  const inputFile = React.createRef<HTMLInputElement>();
  /*
   * take picture : this function open the camera or the gallery and take/choose picture
   */
  async function takePicture(type: CameraSource) {
    setUserIsActive(true);
    if (type === CameraSource.Photos) {
      if (inputFile.current) inputFile.current.click();
    } else {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          direction: CameraDirection.Rear,
          allowEditing: false,
          source: CameraSource.Camera,
          resultType: CameraResultType.Base64,
          promptLabelHeader: "Sélectionner une photo",
          promptLabelCancel: "Annuler",
          promptLabelPhoto: "Depuis la galerie",
          promptLabelPicture: "Depuis l'appareil photo",
        });
        if (username && image && image.base64String) {
          setImageFormat(image.format);
          setImageData(image.base64String);
        }
      } catch (error) {
        return null;
      }
    }
  }

  async function onSelectFile(e: any) {
    setUserIsActive(true);
    if (e.target.files[0] && e.target.files[0].type) {
      const file = e.target.files[0];
      const base64 = (await toBase64(file)) as string;
      if (username && base64) {
        const format = _.last(_.split(file.type, "/"));
        setImageFormat(format as string);
        setImageData(base64.split(",")[1]);
      }
    }
  }

  const validateHandler = () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    if (imageData && imageFormat && username) {
      uploadUserPicture(currentUser, imageData, imageFormat, supervisedAccount);
      setToastNotification({
        message: "Votre photo a été modifiée avec succès",
        onClickMessage: () => {},
        color: "success",
        duration: 6000,
        buttons: [],
      } as ToastNotification);
    }
  };

  const cancelHandler = () => {
    setUserIsActive(false);
    history.push("/settings/account");
  };

  return (
    <IonPage>
      <Header title="Photo de profil" showHelp />
      <IonContent>
        <div className={classes.container}>
          {picture && (
            <Fragment>
              {src && (
                <img
                  src={
                    imageData
                      ? `data:image/${imageFormat};base64,${imageData}`
                      : src
                  }
                  alt="avatar"
                  className={classes.picture}
                />
              )}
              {!src && <IonSkeletonText animated className={classes.picture} />}
            </Fragment>
          )}
          <div className={classes.buttonsContainer}>
            <input
              ref={inputFile}
              style={{ display: "none" }}
              type="file"
              id="file"
              multiple={false}
              onChange={onSelectFile}
            />
            <Button
              color="primary"
              onClick={() => takePicture(CameraSource.Camera)}
              text="Depuis l'appareil photo"
              icon={cameraOutline}
            />
            <Button
              color="primary"
              onClick={() => takePicture(CameraSource.Photos)}
              text="Depuis la galerie"
              icon={imagesOutline}
            />
            <Button
              color="primary"
              onClick={() => setShowEditImage(true)}
              text="Modifier l'image"
              icon={pencil}
            />
          </div>
        </div>
      </IonContent>
      <div slot="fixed" style={{ width: "100%", bottom: 0 }}>
        <BottomButtons onCancel={cancelHandler} onValidate={validateHandler} />
      </div>
      {showEditImage && (
        <EditImage
          successMessage="La photo du profil a été modifiée"
          onCancel={() => setShowEditImage(false)}
          src={
            imageData
              ? `data:image/${imageFormat};base64,${imageData}`
              : picture
          }
          setImageFormat={setImageFormat}
          setImageData={setImageData}
        />
      )}
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    uploadUserPicture,
    setToastNotification,
    setUserIsActive,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    picture: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user.picture
        ? state.supervisor.supervisedAccount.user.picture
        : defaultPicture
      : state.user.userFirestore
      ? state.user.userFirestore.picture
      : defaultPicture,
    appOnline: state.global.appOnline,
    username: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user.email
      : state.user.username,
    currentUser: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user
      : state.user.userFirestore,
    supervisedAccount: state.supervisor.supervisedAccount || null,
  }),
  component: React.memo(UpdateUserPicture),
});
