import {
  IonCard,
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
  IonText,
  IonTitle,
  IonToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { ContactGroup } from "../models/ContactGroup";
import { User } from "../models/User";
import { UserWithRelationship } from "../models/UserWithRelationship";
import ContactGroupItem, { groupCardType } from "./contacts/ContactGroupItem";
import ContactChip from "./ContactChip";
import UserItem, { userCardType } from "./UserItem";
import _ from "lodash";
import { UserSupervised } from "../models/UserSupervised";
import { connect } from "../data/connect";

interface OwnProps {
  users: UserWithRelationship[];
  currentUserEmail: string;
  selectedUsers: string[];
  checkedAll: boolean;
  updateSelectedUsers: Function;
  updateSelectedGroups?: Function;
  canRemoveUser?: boolean;
  groups?: ContactGroup[];
  selectedGroups?: ContactGroup[];
  showContactChip?: boolean;
}

interface StateProps {
  supervisedAccount: UserSupervised | undefined;
}

interface DispatchProps {
}
interface SelectMultipleUsersProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const SelectMultipleUsers: React.FC<SelectMultipleUsersProps> = ({
  users,
  groups,
  currentUserEmail,
  selectedUsers,
  selectedGroups,
  checkedAll,
  updateSelectedUsers,
  updateSelectedGroups,
  supervisedAccount,
  canRemoveUser = true,
  showContactChip = true,
}) => {
  let userToRemove: { [key: string]: boolean } = {};
  let groupToRemove: { [key: string]: boolean } = {};

  const [isCheckedAll, setIsCheckedAll] = useState(checkedAll);
  const [checkStatus, setCheckStatus] = useState(2);
  const [selectedUserToRemove, setSelectedUserToRemove] =
    useState(userToRemove);
  const [selectedGroupToRemove, setSelectedGroupToRemove] =
    useState(groupToRemove);
    
  useEffect(() => {
    users = users
    .filter((user) => user.email != currentUserEmail);
  }, [users, currentUserEmail]);
  useEffect(() => {
    if (isCheckedAll) {
      let selectedUsers = [];
      selectedUsers = users
        .map((user) => user.email);
      updateSelectedUsers(selectedUsers);
    } else if (!isCheckedAll && checkStatus === 0) {
      updateSelectedUsers([]);
    }
  }, [isCheckedAll, checkStatus]);

  const checkUser = (user: User, checked: boolean) => {
    const indexUser = selectedUsers.indexOf(user.email);
    if (checked && indexUser === -1) {
      selectedUsers = [...selectedUsers, user.email];
    } else if (!checked && indexUser !== -1) {
      selectedUsers = selectedUsers.filter((email) => email !== user.email);
    }
    updateSelectedUsers(selectedUsers);
  };
  groups = _.reverse(
    _.sortBy(groups, (group) => {
      if (group.callsCounter)
        return group.callsCounter;
      else return 0;
    })
  ); 
  const checkGroup = (group: ContactGroup, checked: boolean) => {
    if (selectedGroups) {
      const indexGroup = selectedGroups.indexOf(group);
      if (checked && indexGroup === -1) {
        selectedGroups = [...selectedGroups, group];
      } else if (!checked && indexGroup !== -1) {
        selectedGroups = selectedGroups.filter((gp) => gp.id !== group.id);
      }
      updateSelectedGroups && updateSelectedGroups(selectedGroups);
    }
  };

  const removeUserHandler = (user: User | ContactGroup) => {
    userToRemove[user.id] = true;
    setSelectedUserToRemove(userToRemove);
  };
  const removeGroupHandler = (group: User | ContactGroup) => {
    groupToRemove[group.id] = true;
    setSelectedGroupToRemove(groupToRemove);
  };

  const checkAllUser = (e: CustomEvent) => {
    const isCheckedAll = e.detail.checked ? true : false;
    setIsCheckedAll(isCheckedAll);
    if (isCheckedAll) {
      setCheckStatus(1);
    } else {
      setCheckStatus(0);
    }
  };

  const resetUserToRemove = () => {
    setSelectedUserToRemove({});
  };
  const resetGroupToRemove = () => {
    setSelectedGroupToRemove({});
  };

  return (
    <IonGrid fixed>
      <IonRow style={{ marginBottom: "70px" }}>
        {/*Disable for now <IonCol size="12">
           <IonItem>
            <IonText style={{ padding: 0 }}>Aucun / Tous</IonText>
            <IonToggle
              slot="end"
              checked={isCheckedAll}
              onIonChange={(e) => checkAllUser(e)}
            />
          </IonItem>
        </IonCol>*/}
        {(selectedUsers.length > 0 ||
          (selectedGroups && selectedGroups.length > 0)) && showContactChip && (
          <IonCol size="12" style={{ marginTop: "20px" }}>
            <IonCard>
              {users
                .filter((user) => selectedUsers.indexOf(user.email) !== -1)
                .map((user) => (
                  <ContactChip
                    key={user.id}
                    image={
                      user.picture
                        ? user.picture
                        : "https://www.gravatar.com/avatar?d=mm&s=200"
                    }
                    label={user.firstName + " " + user.lastName}
                    contact={user}
                    removeContact={canRemoveUser ? removeUserHandler : null}
                  />
                ))}
              {groups &&
                groups
                  .filter(
                    (group) =>
                      selectedGroups && selectedGroups.indexOf(group) !== -1
                  )
                  .map((group) => (
                    <ContactChip
                      key={group.id}
                      image={
                        group.picture ? group.picture : "assets/img/group.png"
                      }
                      label={group.name}
                      contact={group}
                      removeContact={canRemoveUser ? removeGroupHandler : null}
                    />
                  ))}
            </IonCard>
          </IonCol>
        )}
        {users
          .map((user) => (
            <IonCol
              size="12"
              size-lg="4"
              size-md="6"
              size-sm="12"
              key={user.id}
            >
              <UserItem
                key={user.id}
                user={user}
                initialChecked={selectedUsers.indexOf(user.email) !== -1}
                isCheckedAll={isCheckedAll}
                type={userCardType.Select}
                checkUser={checkUser}
                removeUser={selectedUserToRemove[user.id]}
                resetUserToRemove={resetUserToRemove}
              />
            </IonCol>
          ))}
        {groups &&
          groups.map((group) => (
            <IonCol
              size="12"
              size-lg="4"
              size-md="6"
              size-sm="12"
              key={group.id}
            >
              <ContactGroupItem
                key={group.id}
                group={group}
                initialChecked={
                  selectedGroups && selectedGroups.indexOf(group) !== -1
                }
                isCheckedAll={isCheckedAll}
                type={groupCardType.Select}
                checkGroup={checkGroup}
                removeGroup={selectedGroupToRemove[group.id]}
                resetGroupToRemove={resetGroupToRemove}
              />
            </IonCol>
          ))}
      </IonRow>
    </IonGrid>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
  },
  mapStateToProps: (state) => ({
    supervisedAccount: state.supervisor.supervisedAccount, 
  }),
  component: React.memo(SelectMultipleUsers),
});
