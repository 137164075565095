import firebase from "../FirebaseConfig";
import { IdConfigNotHelped, IdConfigHelped, IdConfigSubscriberHelped, IdConfigSubscriberNotHelped } from "../appConfig";
import { UserGlobalConfig } from "../models/UserGlobalConfig";
import { getUser } from "./user";
/**
 * Get global config from firestore database
 * @param id 
 */
export const getGlobalConfig = async (isHelped: boolean, username: string = "") => {
  let docId: string;
  docId = isHelped ? IdConfigHelped : IdConfigNotHelped;
  if (username) {
    const currentUser = await getUser(username);
    if (currentUser.isSubscriber) {
      docId = isHelped ? IdConfigSubscriberHelped : IdConfigSubscriberNotHelped;
    }
  }
  const db = firebase.firestore();
  const config = await db.collection("userGlobalConfig").doc(docId).get();
  return config.data() as UserGlobalConfig;
};
