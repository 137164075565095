import { SharedFolderActions } from "./sharedFolder.actions";
import { SharedFolderState } from "./sharedFolder.state";

export function sharedFolderReducer(
  state: SharedFolderState,
  action: SharedFolderActions
): SharedFolderState {
  switch (action.type) {
    case "set-shared-folder-loading":
      return { ...state, loading: action.isLoading };
    case "set-shared-folder-list":
      return { ...state, folders: action.folders };
    case "set-send-message-media-loading":
      return { ...state, sendMessageLoading: action.isLoading };
    case "set-upload-progess":
      return { ...state, uploadProgess: action.progress };
  }
}
