import React from 'react';
import {IonProgressBar, IonButton } from '@ionic/react';
import './Loading.css'

interface LoadingProps { 
    enable: boolean,
    text?: any,
    callbackCancel?():any,
    textCancel?: string
}

const Loading: React.FC<LoadingProps> = ({ enable, text, callbackCancel, textCancel }) => {

  return enable ? (<div className="loading">
      <div>{text}</div>
      <IonProgressBar type="indeterminate"></IonProgressBar>
      <div>
        {callbackCancel ? <IonButton size="large" color="danger" expand="block" style={{marginTop:'20px'}} onClick={callbackCancel}>{textCancel}</IonButton> : null}
      </div>
    </div>) 
    : <div></div>;
};

export default Loading