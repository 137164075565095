import firebase from '../FirebaseConfig';

/**
 * Remove file from storage
 * @param url 
 */
 export const deleteFileFromStorageRequest = async (url: string) => {

    const storage = firebase.storage();
    const docref = storage.refFromURL(url);
    return await docref.delete()
  }