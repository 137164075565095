import _ from 'lodash';
import firebase from "../FirebaseConfig";
import { convertToTimestamp } from './functions';

/**
 * Default picture name for fake profil avatar
 */
export const defaultUserPictures =[
    "Alain.png",
    "Albane.png",
    "Alex.png",
    "Alice.png",
    "André.png",
    "Béatrice.png",
    "Chantal.png",
    "Daniel.png",
    "Denis.png",
    "Élisa.png",
    "Fabien.png",
    "Fanny.png",
    "Hortense.png",
    "Hugo.png",
    "Laura.png",
    "Liliane.png",
    "Louise.png",
    "Luc.png",
    "Marcel.png",
    "Martine.png",
    "Mauricette.png",
    "Raphaëlle.png",
    "Sam.png",
    "Sara.png",
    "Valentine.png",
    "Victoire.png"
];


export const validFormatsForSharedFolderImage= [
   "jpeg",
   "png",
   "jpg"
]

export const validFormatsForSharedFolderVideo= [
   "ogg",
   "webm",
   "mp4",
]

export const validFormatsForSharedFolder= [
   ...validFormatsForSharedFolderImage,
   ...validFormatsForSharedFolderVideo
]


export function createGuid(nbCar:any)  
{  
   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {  
      var r = Math.random()*nbCar|0, v = c === 'x' ? r : (r&0x3|0x8);  
      return v.toString(nbCar);  
   });  
}

export const _CachedImageFolder = "CACHED-IMG";

export const defaultPicture = "https://www.gravatar.com/avatar?d=mm&s=400";

/**
 * Convert an english date (Wed 7 April) to french translation (ex: Mer 7 avril)
 * @param date 
 */
export function translateDateToFrench(date: string)
{
   date = _.toLower(date);
   // Day
   date =  _.replace(date, 'mon', 'lun');
   date =  _.replace(date, 'tue', 'mar');
   date =  _.replace(date, 'wed', 'mer');
   date =  _.replace(date, 'thu', 'jeu');
   date =  _.replace(date, 'fri', 'ven');
   date =  _.replace(date, 'sat', 'sam');
   date =  _.replace(date, 'sun', 'dim');

   // month
   date =  _.replace(date, 'january', 'Janvier');
   date =  _.replace(date, 'february', 'Février');
   date =  _.replace(date, 'march', 'Mars');
   date =  _.replace(date, 'april', 'Avril');
   date =  _.replace(date, 'may', 'Mai');
   date =  _.replace(date, 'june', 'Juin');
   date =  _.replace(date, 'july', 'Juillet');
   date =  _.replace(date, 'august', 'Août');
   date =  _.replace(date, 'september', 'Septembre');
   date =  _.replace(date, 'october', 'Octobre');
   date =  _.replace(date, 'november', 'Novembre');
   date =  _.replace(date, 'december', 'Décembre');


   return _.startCase(date);
}

/**
 * Format a firebase date to string
 * @param date
 * @returns
 */
export function getDateString(date: Date | null) {
   if (date) {
      let result = convertToTimestamp(date);
      if(result){
         date = result.toDate();
         return (
            date.toLocaleDateString() +
            " à " +
            date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
         );
      }else{
         return "";
      }
      
   } else {
      return "";
   }

}

export const toBase64 = (file : File) => new Promise((resolve, reject) => {
   const reader = new FileReader();
   reader.readAsDataURL(file);
   reader.onload = () => resolve(reader.result);
   reader.onerror = error => reject(error);
 });

export const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
   const reader = new FileReader;
   reader.onerror = reject;
   reader.onload = () => {
     resolve(reader.result);
   };
   reader.readAsDataURL(blob);
});

export const isVideoFile = (type: string) => {
   const isVideo = _.some(
      validFormatsForSharedFolderVideo,
      (f) => f === type
   );
   return isVideo;
}