import React, { useState, useEffect, useRef } from "react";
import {
  IonIcon,
  IonRow,
  IonCol,
  isPlatform,
  getConfig,
} from "@ionic/react";
import {
  setVisioCallData,
  acceptOrRefuseCall,
} from "../data/visioCall/visioCall.actions";
import { connect } from "../data/connect";
import * as selectors from "../data/selectors";
import VisioCall, { ParticipantState } from "../models/VisioCall";
import {
  videocamOffOutline,
  videocamOutline,
  closeCircleOutline,
  personAddOutline,
} from "ionicons/icons";
import { RouteComponentProps, useLocation } from "react-router";
import "./VisioComponent.scss";
import Loading from "../components/Loading";
import { _timeoutBeforeCancelCall } from "../appConfig";
import { setAudioData } from "../data/user/user.actions";
import { audioType } from "../components/AudioPlayer";
import { UserWithRelationship } from "../models/UserWithRelationship";
import { defaultPicture } from "../util/commons";
import _ from "lodash";
import Button from "../components/Button";
import { IconSvgEnum } from "../components/IconSvg";
import { menuController } from "@ionic/core";
import CallInvitation from "../components/Modals/CallInvitation";
import { App } from '@capacitor/app';
import { BackgroundTask } from '@robingenz/capacitor-background-task';
import { UserState } from "../data/user/user.state";
import { logErrorOnCall } from "../api/visioCall";
import { getGlobalConfig } from "../api/globalConfig";
import { UserGlobalConfig } from "../models/UserGlobalConfig";
//import Jitsi from 'react-jitsi'
interface OptionalState {
  isGroup: boolean;
  groupName: string;
  groupPicture: string;
}
declare global {
  interface Window {
    JitsiMeetExternalAPI: any;
  }
}
interface OwnProps extends RouteComponentProps { }

interface StateProps {
  callData: VisioCall;
  user?: UserState;
  users: UserWithRelationship[];
  isHelpedProfil: boolean;
  subtitling: boolean;
}
interface DispatchProps {
  setVisioCallData: typeof setVisioCallData;
  acceptOrRefuseCall: typeof acceptOrRefuseCall;
  setAudioData: typeof setAudioData;
}
interface VisioComponentProps extends OwnProps, StateProps, DispatchProps { }

const VisioComponent: React.FC<VisioComponentProps> = ({
  callData,
  user,
  users,
  isHelpedProfil,
  subtitling,
  setVisioCallData,
  acceptOrRefuseCall,
  setAudioData,
  history,
}) => {
  const state = useLocation().state as OptionalState;
  const groupName = state && state.isGroup && state.groupName ? state.groupName : "";
  const grouPicture = state && state.isGroup && state.groupPicture ? state.groupPicture : "assets/img/group.png";
  const [jitsiMeetAPI, setJitsiMeetAPI] = useState<any>(null);


  const timeoutCancelCall = useRef<NodeJS.Timeout>();

  const [screenHeight, setScreenHeight] = useState(window.screen.height);
  const [_callData, setCallData] = useState<VisioCall>();

  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);

  const [jitsiLoaded, setJitsiLoaded] = useState(false);
  const [nbParticipantsAccepted, setNbParticipantsAccepted] = useState(_.sumBy(callData.participantStates, (p) => p.state == ParticipantState.Accepted ? 1 : 0));
  const [showCallInvitation, setShowCallInvitation] = useState(false);
  const [audioJitsiMeetInfos, setAudioJitsiMeetInfos] = useState({});
  const [invitation, setInvitation] = useState(false);

  //const domain = 'homelinks.watcha.fr'; //"meet.jit.si";
  const domain = 'meet.jit.si';

  useEffect(() => {
    // close menu when new call active
    menuController.close();
    //initJitsi();
    if (callData && user && callData.creator == user.username) setAudioData(audioType.OutcomingCall, true);
    window.addEventListener("resize", functionUpdateScreenDimension);
    return () => {
      setAudioData(audioType.OutcomingCall, false);
      window.removeEventListener("resize", functionUpdateScreenDimension);
      clearIntervalTimeout();
    };
  }, []);

  useEffect(() => {
    if (isPlatform("mobile")) {
      App.addListener("appStateChange", (state) => {
        if (!state.isActive) {
          let taskId: any = BackgroundTask.beforeExit(async () => {
            if (callData) {

              if (user && user.username) {
                logErrorOnCall(callData.id, {
                  name: "Before go in background",
                  type: "appStateChange",
                  isFatal: false
                }, user.username);
              }

              acceptOrRefuseCall(
                callData.id,
                (user && user.username) ? user.username : "",
                ParticipantState.Completed
              );
              history.push("/tabs/contacts");
              menuController.open();
            }
            BackgroundTask.finish({ taskId });
          });
        }
      });
    } else {
      window.addEventListener("beforeunload", function (e) {
        if (callData) {

          if (user && user.username) {
            logErrorOnCall(callData.id, {
              name: "Before navigator unload this page",
              type: "beforeUnload",
              isFatal: false
            }, user.username);
          }

          acceptOrRefuseCall(
            callData.id,
            (user && user.username) ? user.username : "",
            ParticipantState.Completed
          );
          history.push("/tabs/contacts");
          menuController.open();
        }
      });
    }

    return () => {
      if (callData) {

        if (user && user.username) {
          logErrorOnCall(callData.id, {
            name: "User leave visio component",
            type: "pageExit",
            isFatal: false
          }, user.username);
        }

        acceptOrRefuseCall(
          callData.id,
          (user && user.username) ? user.username : "",
          ParticipantState.Completed
        );
        history.push("/tabs/contacts");
      }
    };
  }, []);

  useEffect(() => {
    if (!callData) {
      quitCall(true);
    }

    setCallData(callData);

    setNbParticipantsAccepted(_.sumBy(callData.participantStates, (p) => p.state == ParticipantState.Accepted ? 1 : 0));

    const username = (user) ? user.username : null;
    const currentUserState = _.find(callData.participantStates, (state) => state.email == username);
    if (currentUserState && currentUserState.state == ParticipantState.Accepted && !jitsiMeetAPI) {
      initJitsi();
    }
    getAudioJitsiMeetInfos();
    getConfig();

  }, [callData]);

  useEffect(() => {

    // if current user is creator of the call, enable timeout for quit call if recipient(s) not respond
    const username = (user) ? user.username : null;
    if (callData.creator == username) {
      // If recipient user doesn't take call, quit it and show "send message ?" page to caller
      timeoutCancelCall.current = setTimeout(() => {
        quitCall(false);
      }, _timeoutBeforeCancelCall);

      return () => {
        clearIntervalTimeout();
      };
    } else {
      clearIntervalTimeout();
    }

    return () => clearIntervalTimeout();

  }, []);


  useEffect(() => {
    if (canShowCall()) {
      setAudioData(audioType.OutcomingCall, false);
    }
  }, [canShowCall()]);

  const getAudioJitsiMeetInfos = async () => {
    const response1 = await fetch("https://jitsi-api.watcha.fr/tenants/watcha/phone-numbers");
    const result1 = await response1.json();
    const numbers = result1.numbers;
    const phoneNumber = numbers.FR && numbers.FR[0] ? numbers.FR[0] : "";
    const response2 = await fetch(`https://jitsi-api.watcha.fr/tenants/homelinks/conferences?conference=${callData.roomName}@muc.meet.jitsi`);
    const result2 = await response2.json();
    const pin = result2.id ? result2.id : "";
    setAudioJitsiMeetInfos({ phoneNumber, pin });
  }
  const getConfig = async () => {
    const CONFIG = await getGlobalConfig(isHelpedProfil, user?.username);
    const invi = CONFIG.contact?.invitation != undefined ? CONFIG.contact?.invitation : true;
    setInvitation(invi);
  }
  function canShowCall() {
    // can show call (hide loading) if jitsi loaded AND at least 2 participants accepted the call
    return jitsiLoaded && nbParticipantsAccepted > 1;
  }

  function functionUpdateScreenDimension() {
    setScreenHeight(window.screen.height);
  }

  /**
   *
   * @param redirectHome If true, redirect user to annuaire page
   * @param forceAllParticipantsQuit If true, force quit call for all participants
   */
  function quitCall(
    redirectHome: boolean,
    forceAllParticipantsQuit: boolean = false
  ) {
    if (jitsiMeetAPI) {

      jitsiMeetAPI.removeAllListeners();
      jitsiMeetAPI.executeCommand("hangup");
      jitsiMeetAPI.dispose();
    }

    setVisioCallData(undefined);
    clearIntervalTimeout();
    if (user && user.username) {

      if (user && user.username) {
        logErrorOnCall(callData.id, {
          name: "User click exit call",
          type: "ExitBtnClicked",
          isFatal: false
        }, user.username);
      }

      acceptOrRefuseCall(
        callData.id,
        user.username,
        ParticipantState.Completed,
        forceAllParticipantsQuit
      );
    }
    if (redirectHome) {
      history.push("/tabs/contacts");
      menuController.open();
    }
  }

  function toggleMic() {
    if (jitsiMeetAPI) {
      jitsiMeetAPI.executeCommand("toggleAudio");
    }
  }

  function toggleVideo() {
    if (jitsiMeetAPI) {
      jitsiMeetAPI.executeCommand("toggleVideo");
    }
  }

  function toggleCamera() {
    if (jitsiMeetAPI) {
      jitsiMeetAPI.executeCommand("toggleCamera");
    }
  }

  /**
   * Hide loading and show jitsi
   */
  function enableCall() {
    setAudioData(audioType.OutcomingCall, false);
    clearIntervalTimeout()
  }


  function initJitsi() {

    if (jitsiMeetAPI) return;

    try {
      //jitsi doc : https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe#integration
      const options = {
        roomName: callData.roomName,
        parentNode: document.getElementById("jitsi-container"),
        facingMode: "user",  //  todo test
        userInfo: {
          email: (user) ? user.username : 'Unknown',
          displayName: (user && user.userFirestore) ? user.userFirestore.firstName : 'Unknown',
        },

        //all options here: https://github.com/jitsi/jitsi-meet/blob/master/interface_config.js
        interfaceConfigOverwrite: {
          filmStripOnly: false,
          APP_NAME: "Mon lien visio",
          SHOW_JITSI_WATERMARK: false,
          HIDE_INVITE_MORE_HEADER: true,
          HIDE_DEEP_LINKING_LOGO: true,
          CONNECTION_INDICATOR_DISABLED: true,
          DEFAULT_LOGO_URL: "assets/img/logo.png",
          DEFAULT_WELCOME_PAGE_LOGO_URL: "assets/img/logo.png",
          DISPLAY_WELCOME_FOOTER: false,
          /*INITIAL_TOOLBAR_TIMEOUT: 0,
          TOOLBAR_TIMEOUT:0,*/
          TOOLBAR_ALWAYS_VISIBLE: false,
          MOBILE_APP_PROMO: false,
          JITSI_WATERMARK_LINK: "",
          NATIVE_APP_NAME: "Mon lien visio",
          PROVIDER_NAME: "Homelinks",
          toolbarButtons: [
            /*"fullscreen", "microphone", "camera", "hangup"*/
          ],
        },
        // all options here https://github.com/jitsi/jitsi-meet/blob/master/config.js
        configOverwrite: {
          disableSimulcast: false,
          disableDeepLinking: true,
          prejoinPageEnabled: false,
          defaultLanguage: "fr",
          //is helped profil, show minimal toolbar else show native toolbar
          toolbarButtons: isHelpedProfil ? [] : undefined,
        },
      };

      const api = new window.JitsiMeetExternalAPI(domain, options);
      setJitsiMeetAPI(api);

      api.addEventListener("audioMuteStatusChanged", (data: any) => {
        setIsAudioMuted(data.muted);
      });
      api.addEventListener("videoMuteStatusChanged", (data: any) => {
        setIsVideoMuted(data.muted);
      });

      api.addEventListener("errorOccurred", (data: any) => {
        if (user && user.username) {
          logErrorOnCall(callData.id, data, user.username);
        }
      });

      // Provides event notifications that fire when the local user has joined the video conference.
      api.addEventListener('videoConferenceJoined', (data: any) => {
        setJitsiLoaded(true);
        const username = (user) ? user.username : null;
        if (callData && callData.creator != username) {
          enableCall();
        }

        /*
         * Active tile view for call with Helped profil
        */
        /*
         if(isHelpedProfil){
           api.executeCommand('setTileView',true);
         }
         */
      });

      api.addEventListener("participantLeft", () => {
        const nbParticipants = api.getParticipantsInfo().length;
        if (nbParticipants == 1) {
          quitCall(true, true);
        }
      });

      api.addEventListener("videoConferenceLeft", (data: any) => {
        quitCall(true);
        history.push("/tabs/contacts");
        menuController.open();
      });

      api.addEventListener("participantRoleChanged", function (event: any) {
        const nbParticipants = api.getParticipantsInfo().length;
        if (event.role === "moderator" && callData) {
          api.executeCommand('password', callData.roomPassword);
          if (nbParticipants > 1) {
            enableCall();
          }
        }
      });

      // join a protected channel
      api.addEventListener('passwordRequired', function () {
        if (callData) api.executeCommand('password', callData.roomPassword);
      });

      api.addEventListener("participantJoined", function (event: any) {
        // for room creator, wait at least 2 participants for remove loading
        const nbParticipants = api.getParticipantsInfo().length;
        if (nbParticipants > 1) {
          enableCall();
        }
      });
    } catch (error) {
      console.error('... Failed to load Jitsi API', error);
      clearIntervalTimeout()
    }
  }

  function clearIntervalTimeout() {
    if (timeoutCancelCall && timeoutCancelCall.current)
      clearTimeout(timeoutCancelCall.current);
  }

  const otherParticipant = _.filter(users, (user) =>
    _.some(
      _callData ? _callData.participantEmails : [],
      (mail) => mail == user.email
    )
  );

  let otherParticipantlabel =
    otherParticipant.length > 0 ? otherParticipant[0].firstName : "";
  if (otherParticipant.length > 2) {
    otherParticipantlabel =
      otherParticipantlabel +
      ` et ${otherParticipant.length - 1} autre(s) correspondant(s)`;
    //{otherParticipant[0].firstName} {otherParticipant.length -1 > 1 ? " et " + (otherParticipant.length - 1) + " autres participant" + (otherParticipant.length - 1 > 1 ? "s" : "") : ""}
  } else if (otherParticipant.length > 1) {
    otherParticipantlabel =
      otherParticipantlabel +
      ` et ${otherParticipant.length - 1} autre correspondant`;
  }
  return (
    <div id="visio-call-container">
      <div className="visio-call-title">
        {/*<IonTitle style={{ paddingLeft: "5px" }}>Appel vidéo</IonTitle>*/}
        {invitation && <div className="invitation">
          <IonIcon
            src={personAddOutline}
            onClick={() => setShowCallInvitation(true)}
            className="invitation-icon"
          />
          {subtitling && <span>INVITER</span>}
        </div>}
      </div>
      {showCallInvitation && (
        <CallInvitation
          show={setShowCallInvitation}
          callUrl={"http://" + domain + "/" + callData.roomName}
          audioInfos={audioJitsiMeetInfos}
          callId={callData.id}
        />
      )}
      <div id="jitsi-container"></div>

      <Loading
        enable={!canShowCall()}
        text={
          <div className="preview-user">
            {otherParticipant && otherParticipant.length > 0 && (
              <div style={{ marginBottom: "5px", fontSize: "30px" }}>
                {!groupName ? "Vous allez être connecté avec " : "Vous allez être connecté au groupe "}
              </div>
            )}
            <div style={{ fontSize: "30px" }}>{groupName ? groupName : otherParticipantlabel}</div>
            {!groupName && otherParticipant && otherParticipant.length > 0 && (
              <div>
                <img
                  src={
                    otherParticipant[0].picture
                      ? otherParticipant[0].picture
                      : defaultPicture
                  }
                  alt="avatar"
                />
              </div>
            )}
            {groupName && (
              <div>
                <img
                  src={grouPicture}
                  alt="avatar"
                />
              </div>
            )}
          </div>
        }
        callbackCancel={() => quitCall(true, true)}
        textCancel="Raccrocher"
      />
      {canShowCall() && isHelpedProfil && (
        <IonRow className="call-toolbar">
          <IonCol size="4" sizeXs="4">
            <Button
              className={" btn-option-call"}
              color={!isVideoMuted ? "success" : "dark"}
              onClick={() => {
                toggleVideo();
              }}
              text="Caméra"
              icon={!isVideoMuted ? videocamOutline : videocamOffOutline}
            />
          </IonCol>
          <IonCol size="4" sizeXs="4">
            <Button
              className={" btn-option-call"}
              color={!isAudioMuted ? "success" : "dark"}
              onClick={() => {
                toggleMic();
              }}
              text="Micro"
              svg={IconSvgEnum.microphone_white}
              secondSvg={isAudioMuted ? IconSvgEnum.diagonale_white : undefined}
            />
          </IonCol>
          <IonCol size="4" sizeXs="4">
            <Button
              className={" btn-option-call btn-hangout-call"}
              color={"danger"}
              onClick={() => {
                quitCall(true);
              }}
              text="Quitter"
              icon={closeCircleOutline}
            />
          </IonCol>
        </IonRow>
      )}
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setVisioCallData,
    acceptOrRefuseCall,
    setAudioData,
  },
  mapStateToProps: (state) => {
    return {
      callData: state.visioCall.roomData as VisioCall, //selectors.getVisioCall(state),
      user: state.user,
      users: selectors.getUsers(state),
      isHelpedProfil: selectors.getIsHelpedProfil(state),
      subtitling: selectors.getSubtitlingConfig(state),
    };
  },
  component: VisioComponent,
});