import { combineReducers } from './combineReducers';
import { sessionsReducer } from './sessions/sessions.reducer';
import { userReducer } from './user/user.reducer';
import { visioCallReducer } from './visioCall/visioCall.reducer';
import { sharedFolderReducer } from './sharedFolder/sharedFolder.reducer';
import { globalReducer } from './global/global.reducer';
import { AudioData } from '../models/AudioData';
import { audioType } from '../components/AudioPlayer';
import { supervisorReducer } from './supervisor/supervisor.reducer';
import { MediaShareRequestReducer } from './mediaShareRequest/mediaShareRequest.reducer';

const supervisedAccountSession = localStorage.getItem("userSupervised");
const initialSupervisedAccount = supervisedAccountSession ? JSON.parse(supervisedAccountSession) : undefined;
export const initialState: AppState = {
  data: {
    users: [],
    menuEnabled: true,
    appReady: false,
    
  },
  user: {
    hasSeenTutorial: false,
    darkMode: false,
    isLoggedin: false,
    loadingData: false,
    loadingAnnuaire: supervisedAccountSession ? false : true, // first app start, we need to load user's annuaire,
    loadingSearch: false,
    requestPending: [],
    searchUsersFiltered: [],
    allUserRelations: [],
    audioData: { type:audioType.IncomingCall, play:false } as AudioData,
    mailbox: undefined,
    groups: []
  },
  visioCall:{
    roomData: undefined,
    loading: false,
    userActionFromPush:undefined
  },
  sharedFolder:{
    loading: false,
    sendMessageLoading:false,
    folders:[]
  },
  global:{
    notification: undefined,
    screenSaverEnable: false,
    hideEmergencyCommand: false,
    searchTerm: "",
    userIsActive: false,
    appIsActive: true,
    appOnline: true,
    showOffline: false
  },
  supervisor:{
    loading: false,
    requests: [],
    supervisedAccount: initialSupervisedAccount
  },
  mediaShareRequest:{
    loading: false,
    requests: [],
  }
};

export const reducers = combineReducers({
  data: sessionsReducer,
  user: userReducer,
  visioCall: visioCallReducer,
  sharedFolder: sharedFolderReducer,
  global: globalReducer,
  supervisor: supervisorReducer,
  mediaShareRequest: MediaShareRequestReducer
});

export type AppState = ReturnType<typeof reducers>;