import React, { useState } from "react";
import { IonContent, IonPage, IonText,IonCol,IonRow } from "@ionic/react";
import { connect } from "../data/connect";
import { Mailbox, MailboxItem, MailboxItemType } from "../models/Mailbox";
import { setMessageViewByUser } from "../data/user/user.actions";
import _ from "lodash";
import "./MailboxPage.scss";
import { RouteComponentProps } from "react-router-dom";
import Header from "../components/Header";
import ShowMessageModal from "../components/Modals/ShowMessageModal";
import SelectMultipleMessages from "../components/SelectMultipleMessages";
import * as selectors from "../data/selectors";
import { UserSupervised } from "../models/UserSupervised";
import {arrowBackCircleOutline} from "ionicons/icons";
import Button from "../components/Button";
import { setShowOffline } from "../data/global/global.actions";
interface OwnProps {}

interface StateProps {
  mailbox?: Mailbox;
  currentUserEmail?: string;
  isHelpedProfil: boolean;
  supervisedAccount?: UserSupervised;
  appOnline: boolean;
}

interface DispatchProps {
  setMessageViewByUser: typeof setMessageViewByUser;
  setShowOffline: typeof setShowOffline;
}

interface SavedMessagesProps
  extends RouteComponentProps,
    OwnProps,
    StateProps,
    DispatchProps {}

const SavedMessages: React.FC<SavedMessagesProps> = ({
  mailbox,
  isHelpedProfil,
  currentUserEmail,
  supervisedAccount,
  appOnline,
  setMessageViewByUser,
  setShowOffline
}) => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [currentMessage, setCurrentMessage] = useState<MailboxItem>();

  function showMessage(message: MailboxItem) {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    setCurrentMessage(message);
    setShowMessageModal(true);
  }

  function hideMessage() {
    setCurrentMessage(undefined);
    setShowMessageModal(false);
  }

  const savedMessages = _.reverse(mailbox
    ? mailbox.items.filter(
        (item) => item.type === MailboxItemType.MessageVideo
      )
    : []);
    
  return (
    <IonPage>
      <Header title="Messages enregistrés" showHelp/>
      <IonContent fullscreen={true}>
        <div className={isHelpedProfil ? "padding-bottom" : ""}>
          {savedMessages && savedMessages.length == 0 && (
            <div style={{ textAlign: "center", marginTop: "50px" }}>
              <IonText>La liste des messages enregistrés est vide</IonText>
            </div>
          )}
          {savedMessages && savedMessages.length > 0 && (
            <SelectMultipleMessages
              messages={savedMessages}
              showMessage={showMessage}
              type="message"
            />
          )}
          {showMessageModal && (
            <ShowMessageModal
              showMessageModal={showMessageModal}
              currentMessage={currentMessage}
              hideMessage={hideMessage}
            />
          )}
        </div>
        
      </IonContent>
      <IonRow>
          <IonCol size="12">
            <Button
              routerLink="/tabs/mailbox"
              color="light"
              text="Retour"
              icon={arrowBackCircleOutline}
            />
          </IonCol>
        </IonRow>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setMessageViewByUser,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    mailbox: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.mailbox : state.user.mailbox,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
    currentUserEmail: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username,
    supervisedAccount: state.supervisor.supervisedAccount,
    appOnline: state.global.appOnline
  }),
  component: React.memo(SavedMessages),
});
