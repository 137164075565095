import React, { Fragment, useEffect, useState } from "react";
import {
  IonCol,
  IonRow,
  IonLabel,
  IonModal,
  IonImg,
} from "@ionic/react";
import {isPlatform} from "@ionic/core";
import { connect } from "../data/connect";

import { TextToSpeech } from "@ionic-native/text-to-speech";
import "../components/EmergencyCommand.scss";
import * as selectors from "../data/selectors";
import { startCall } from "../data/visioCall/visioCall.actions";

import Countdown from "react-countdown";

import _ from "lodash";
import { updateLastActivity } from "../data/user/user.actions";
import { User, UserConfiguration } from "../models/User";
import { UserWithRelationship } from "../models/UserWithRelationship";
import { _TimerEmergencyCall } from "../appConfig";
import Button from "../components/Button";
import { IconSvgEnum } from "../components/IconSvg";

import emergencyCallImg from "../icons/call-urgence.png";
import { RouteComponentProps } from "react-router";
import { menuController } from "@ionic/core";
import { arrowBackCircleOutline } from 'ionicons/icons';
interface DispatchProps {
  startCall: typeof startCall;
  updateLastActivity: typeof updateLastActivity;
}
interface OwnProps {
}
interface StateProps {
  currentUserEmail?: string;
  config: UserConfiguration;
  users: UserWithRelationship[];
  currentUser?: User;
}

interface EmergencyCommandProps extends RouteComponentProps, OwnProps, DispatchProps, StateProps {}

const EmergencyCommandRequest: React.FC<EmergencyCommandProps> = ({
  history,
  currentUserEmail,
  config,
  users,
  currentUser,
  startCall,
  updateLastActivity,
}) => {
  const [showModal, setShowModal] = useState(true);
  const [usersToCall, setUsersToCall] = useState<UserWithRelationship[]>([]);
  const [readTextToSpeech, setReadTextToSpeech] = useState(false);

  useEffect(() => {
    // clean user that are not in contact (relationship removed by other user and not update for current user)
    const cleanUser = _.filter(config.emergencyContacts, (userE) => {
      return _.find(
        users,
        (userC: UserWithRelationship) => userC.email == userE
      );
    });

    // get contacts data because "config.emergencyContacts" contains only contact email
    let toCall = _.map(cleanUser, (userE) => {
      const userFound = _.find(
        users,
        (userC: UserWithRelationship) => userC.email == userE
      );
      if (userFound) return userFound;
      // else never reach, here juste for typescript hack (getting type 'UserWithRelationship[]' and not 'UserWithRelationship[] | undefined')
      else return {} as UserWithRelationship;
    });

    //if no emergency contacts set, take contacts most called
    if (toCall.length === 0) {
      toCall =_.take(_.reverse(
        _.sortBy(users, (user) => {
          if (user.relationships && user.relationships.length > 0 && user.relationships[0].callsCounter)
            return user.relationships[0].callsCounter;
          else return 0;
        })
      ), 3);
    }

    setUsersToCall(toCall);
    if(!readTextToSpeech){
        readText(
          toCall.length > 1
            ? "un appel vidéo va être lancé à vos contacts prioritaires"
            :  toCall.length === 1 ? "un appel vidéo va être lancé à votre contact prioritaire": "Il est nécessaire d'avoir un contact ou plus pour pouvoir bénéficier de ce service"
        );
        setReadTextToSpeech(true);
    }
    
  }, [config, users]);


  function close() {
    readText("");
    setShowModal(false);
    history.push("/tabs/contacts");
    menuController.open();
  }

  /**
   * Read a text with TTS and execute onFinish callback when finish
   * @param text Text to read
   */
  function readText(text: string) {
    //resetAudio();
    if (isPlatform("ios")) {
      TextToSpeech.speak({
        text: text,
        locale: "fr-FR",
        rate: 1.5
      }).then(() => {
        //onFinish();
      });
    }else if(isPlatform("android")){
      TextToSpeech.speak({
        text: text,
        locale: "fr-FR",
      }).then(() => {
        //onFinish();
      });
    } else {
      if (text) {
        const voices = speechSynthesis.getVoices();
        const utterance = new SpeechSynthesisUtterance();
        utterance.voice = voices[0];
        utterance.lang = "fr-FR";
        utterance.text = text;
        speechSynthesis.speak(utterance);
      }
      //onFinish();
    }
  }

  async function callUser() {
    try {
      if (currentUser) {
        await startCall(
          _.map(usersToCall, (u) => u.email),
          currentUser,[],
          true
        );
        await updateLastActivity();
        setShowModal(false);
        history.push("/tabs/conference/video");
      }
    } catch (e) {
      alert("Exception userItems>callUser(): " + JSON.stringify(e));
    }
  }

  return (
    <div className={"emergency-call-container"}>
      {users.length > 0 && (
        <IonModal isOpen={showModal} cssClass="speech-modal">
          <IonLabel style={{ textAlign: "center" }}>
            <span className="countdown-title-large">
              {usersToCall.length > 1
                ? "un appel vidéo va être lancé à vos contacts prioritaires"
                : "Un appel vidéo va être lancé à votre contact prioritaire"}
              :
            </span>
          </IonLabel>
          <IonLabel style={{ textAlign: "center" }}>
            <span className="countdown-title-large">
              {_.join(
                _.map(usersToCall, (u) => u.firstName + " " + u.lastName[0]),
                ", "
              )}
            </span>
          </IonLabel>
          <IonLabel style={{ textAlign: "center" }}>
            {showModal && (
              <Countdown
                date={Date.now() + _TimerEmergencyCall}
                onComplete={() => callUser()}
                renderer={({ seconds, completed }) => {
                  if (!completed)
                    return (
                      <span className="countdown-timer-large">{seconds}</span>
                    );
                  else return <span>Votre appel va commencer ...</span>;
                }}
              />
            )}
          </IonLabel>

          <IonRow style={{ width: "100%" }}>
            <IonCol size="12">
              <div>
                <Button
                  color="light"
                  onClick={() => close()}
                  text="Raccrocher"
                  icon={arrowBackCircleOutline}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonModal>
      )}
      {users.length === 0 && (
        <IonModal isOpen={showModal} cssClass="speech-modal">
          <IonLabel style={{ textAlign: "center" }}>
            <span className="countdown-title-large">
            Il est nécessaire d'avoir un contact ou plus pour pouvoir bénéficier de ce service
            </span>
          </IonLabel>
          <IonRow style={{ width: "100%" }}>
            <IonCol size="12">
              <div>
                <Button
                  color="light"
                  onClick={() => close()}
                  text="Retour"
                  icon={arrowBackCircleOutline}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonModal>
      )}
      </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    currentUserEmail: state.user.username,
    users: selectors.getUsers(state),
    config: selectors.getUserConfig(state),
    currentUser: state.user.userFirestore
  }),
  mapDispatchToProps: {
    startCall,
    updateLastActivity,
  },
  component: EmergencyCommandRequest,
});
