import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonSkeletonText,
} from "@ionic/react";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import { User } from "../models/User";
import _ from "lodash";
import { defaultPicture } from "../util/commons";
import "./Login.scss";
import {
  setShowOffline,
} from "../data/global/global.actions";
import { IconSvgEnum } from "../components/IconSvg";
import Header from "../components/Header";
import Button from "../components/Button";
import classes from "./UpdateUsername.module.scss";
import { readFileFromCache } from "../util/localStorage";
import { UserSupervised } from "../models/UserSupervised";
import { UserConfiguration } from "../models/User";
import * as selectors from "../data/selectors";
import { validateEmail } from "../util/email.validation";
import { setConfiguration } from "../util/setConfiguration";
import { arrowBackCircleOutline } from "ionicons/icons";
import { removeDiacritics } from "../util/functions";
import { updateUser } from "../data/user/user.actions";

interface OwnProps extends RouteComponentProps { }

interface StateProps {
  username?: string;
  firstName?: string;
  lastName?: string;
  userPicture?: string;
  supervisedAccount: UserSupervised | null;
  config: UserConfiguration;
  appOnline: boolean;
  currentUser?: User;
}
interface DispatchProps {
  setShowOffline: typeof setShowOffline;
  updateUser: typeof updateUser;
}

interface UpdateUsernameProps extends OwnProps, DispatchProps, StateProps { }

const UpdateUsername: React.FC<UpdateUsernameProps> = ({
  config,
  history,
  firstName,
  lastName,
  userPicture,
  username,
  supervisedAccount,
  appOnline,
  currentUser,
  setShowOffline,
  updateUser
}) => {
  const [firstNameInput, setFirstNameInput] = useState(firstName);
  const [lastNameInput, setLastNameInput] = useState(lastName);
  const [recoveryEmailInput, setRecoveryEmailInput] = useState(config.recoveryEmail);
  const [recoveryEmailError, setRecoveryEmailError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [src, setSrc] = useState("");

  useEffect(() => {
    setRecoveryEmailInput(config.recoveryEmail)
  }, [config.recoveryEmail])

  useEffect(() => {
    setLastNameInput(lastName)
    setFirstNameInput(firstName)
  }, [lastName, firstName])

  useEffect(() => {
    readFileFromCache(userPicture, setSrc);
  }, [userPicture]);

  /**
   * inputValidation : check if there is no error in form data
   * @param   {void}
   * @return  {boolean} true if input are valid
   */
  const inputValidation = (): boolean => {
    var isMail = true;
    if (!firstNameInput) setFirstNameError(true);
    if (!lastNameInput) setLastNameError(true);

    if (recoveryEmailInput != "" && recoveryEmailInput != undefined) {
      isMail = validateEmail(recoveryEmailInput!);
    }
    setRecoveryEmailError(!isMail);
    const validInputs =
      firstNameInput && lastNameInput && isMail ? true : false;
    return validInputs;
  };

  /**
   * updateProfile : retrieve form data and update user profile
   */
  const updateProfile = async () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    setFormSubmitted(true);
    if (inputValidation() && currentUser) {
      currentUser.firstName = _.startCase(
        firstNameInput ? firstNameInput : "".toLowerCase());
      currentUser.lastName =  _.startCase(
        lastNameInput ? lastNameInput : "".toLowerCase()
      );
      const userConfig = {... config} as UserConfiguration;
      userConfig.recoveryEmail = recoveryEmailInput;
      currentUser.config = _.clone(userConfig);
      updateUser(currentUser, supervisedAccount, "Le profil a été modifié avec succès");
      history.push("/settings/account", { direction: "none" });
    }
  };

  return (
    <IonPage id="login-page">
      <Header title="Identification" showHelp />
      <IonContent>
        <div className="login-logo">
          {src && (
            <img
              src={src}
              alt="avatar"
              className={classes.picture}
              onClick={() => !supervisedAccount ? history.push("/updatephoto") : null}
            />
          )}
          {!src && <IonSkeletonText animated className={classes.picture} />}
        </div>

        <form noValidate>
          <IonList>
            {/* First name */}
            <IonItem>
              <IonLabel position="stacked" color="primary">
                <IonText>Prénom</IonText>
              </IonLabel>
              <IonInput
                name="firstName"
                type="text"
                value={firstNameInput}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => {
                  setFirstNameInput(e.detail.value!);
                  setFirstNameError(false);
                }}
                required
              ></IonInput>
            </IonItem>

            {formSubmitted && firstNameError && (
              <IonText color="danger">
                <p className="ion-padding-start">Le prénom est nécessaire</p>
              </IonText>
            )}
            <br />
            <br />

            {/* Last name */}
            <IonItem>
              <IonLabel position="stacked" color="primary">
                <IonText>Nom</IonText>
              </IonLabel>
              <IonInput
                name="lastName"
                type="text"
                value={lastNameInput}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => {
                  setLastNameInput(e.detail.value!);
                  setLastNameError(false);
                }}
                required
              ></IonInput>
            </IonItem>
            {formSubmitted && lastNameError && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  Le nom de famille est nécessaire
                </p>
              </IonText>
            )}

            <br />
            <br />
            {/* Username */}
            <IonItem>
              <IonLabel
                style={{ opacity: 1 }}
                position="stacked"
                color="primary"
              >
                <IonText>Identifiant</IonText>
              </IonLabel>
              <IonInput
                type="text"
                value={username}
                autocapitalize="off"
                disabled={true}
              ></IonInput>
            </IonItem>
            <br />
            <br />
            {/* recovery Email */}
            <IonItem>
              <IonLabel position="stacked" color="primary">
                <IonText>Email de récupération</IonText>
              </IonLabel>
              <IonInput
                name="recoveryEmail"
                type="text"
                value={
                  recoveryEmailInput
                    ? removeDiacritics(recoveryEmailInput.toLowerCase())
                    : recoveryEmailInput
                }
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => {
                  setRecoveryEmailInput(e.detail.value ? e.detail.value : "");
                }}
              ></IonInput>
            </IonItem>
            {formSubmitted && recoveryEmailError && (
              <IonText color="danger">
                <p className="ion-padding-start">L'email n'est pas valide</p>
              </IonText>
            )}
          </IonList>
        </form>
      </IonContent>
      <div slot="fixed" style={{ width: "100%", bottom: 0 }}>
        <IonRow class="ion-no-padding">
          <IonCol size="6" class="ion-no-padding">
            <Button
              color="light"
              routerLink="/settings/account"
              text="Retour"
              icon={arrowBackCircleOutline}
            />
          </IonCol>
          <IonCol size="6" class="ion-no-padding">
            <Button
              onClick={updateProfile}
              color="success"
              text="Sauvegarder"
              svg={IconSvgEnum.ok_white}
            />
          </IonCol>
        </IonRow>
      </div>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    username: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user.email
      : state.user.username
        ? state.user.username
        : "",
    firstName: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user.firstName
      : state.user.userFirestore
        ? state.user.userFirestore.firstName
        : "",
    lastName: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user.lastName
      : state.user.userFirestore
        ? state.user.userFirestore.lastName
        : "",
    userPicture: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user.picture
        ? state.supervisor.supervisedAccount.user.picture
        : defaultPicture
      : state.user.userFirestore
        ? state.user.userFirestore.picture
        : defaultPicture,
    supervisedAccount: state.supervisor.supervisedAccount || null,
    config: selectors.getUserConfig(state),
    appOnline: state.global.appOnline,
    currentUser: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore
  }),
  mapDispatchToProps: {
    setShowOffline,
    updateUser
  },
  component: UpdateUsername,
});
