import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from '../data/connect';
import {Location} from '../models/Location';
import { IonCard, IonCardHeader, IonCardContent,IonBadge } from '@ionic/react';
import _ from 'lodash';
import './WeatherWidget.css'

interface WeatherModel{
    current:CurrentWeatherModel;
}

interface CurrentWeatherModel{
    temp:number;
    weather: WeatherIconModel[];
}

interface WeatherIconModel{
    description: string;
    icon:string;
}


interface WeatherWidgetProps{
  location:Location;
  city: string;
}

const WeatherWidget: React.FC<WeatherWidgetProps> = ({ location, city }) => {

    const [currentWeather, setCurrentWeather] = useState<WeatherModel>();

    useEffect(() => {
        refreshMeteoData();
      }, [location])


      function refreshMeteoData()
      {
        fetch(`https://api.openweathermap.org/data/2.5/onecall?lat=${location.lat}&lon=${location.lng}&exclude=minutely,hourly,daily,alerts&units=metric&lang=fr&appid=c859355fb8a00a6617c0bcf8c11a3e0a`)
        .then(res => res.json())
        .then((result: WeatherModel) => {
            setCurrentWeather(result);
        })
      }


      const iconWeather = currentWeather ? _.head(currentWeather.current.weather) : undefined
      let icon = null;
      if(iconWeather)
      {
        switch(iconWeather.icon){
            case '01d': icon="sun.svg"; break;
            case '02d': icon="sun-cloudy.svg"; break;
            case '03d': icon="cloud.svg"; break;
            case '04d': icon="broken-clouds.svg"; break;
            case '09d': icon="rain.svg"; break;
            case '10d': icon="shower-rain.svg"; break;
            case '11d': icon="storm.svg"; break;
            case '13d': icon="snow.svg"; break;
            case '50d': icon="mist.svg"; break;

            case '01n': icon="sun.svg"; break;
            case '02n': icon="sun-cloudy.svg"; break;
            case '03n': icon="cloud.svg"; break;
            case '04n': icon="broken-clouds.svg"; break;
            case '09n': icon="rain.svg"; break;
            case '10n': icon="shower-rain.svg"; break;
            case '11n': icon="storm.svg"; break;
            case '13n': icon="snow.svg"; break;
            case '50n': icon="mist.svg"; break;


        }
      }


  return (<> {currentWeather && icon && iconWeather && <div className="weatherContainer">
            <div>{city}</div>
            <div className="weatherContent">
                <div className="weatherTemp">
                    <div>{currentWeather.current.temp.toFixed()}°</div>
                    <div className="weatherDesc">{iconWeather.description}</div>
                </div>
                <div className="weatherIcon"><img src={"assets/img/weather/" + icon} alt="Kiwi standing on oval"/></div>
            </div>
            
            </div>}
            </>);
};

export default WeatherWidget