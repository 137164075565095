import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonToggle,
  IonProgressBar,
  IonIcon,
  IonSkeletonText,
} from "@ionic/react";
import "./ResetPassword.scss";
import "./Options.scss";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import firebase from "../FirebaseConfig";
import Loading from "../components/Loading";
import {
  getComplexity,
  PasswordColor,
  PasswordDisplayName,
} from "../util/password.complexity";
import { setShowOffline, setToastNotification } from "../data/global/global.actions";
import { ToastNotification } from "../models/ToastNotification";
import { eye, eyeOff } from "ionicons/icons";
import _ from "lodash";
import { IconSvgEnum } from "../components/IconSvg";
import Button from "../components/Button";
import Header from "../components/Header";
import { defaultPicture } from "../util/commons";
import classes from "./ResetPassword.module.scss";
import { readFileFromCache } from "../util/localStorage";
import { arrowBackCircleOutline } from "ionicons/icons";
interface DispatchProps {
  setToastNotification: typeof setToastNotification;
  setShowOffline: typeof setShowOffline;
}
interface StateProps {
  isAuthenticated: boolean;
  username?: string;
  firstName?: string;
  lastName?: string;
  userPicture?: string;
  appOnline: boolean;
}
interface OwnProps extends RouteComponentProps {}
interface ResetPasswordProps extends OwnProps, DispatchProps, StateProps {}

const ResetPassword: React.FC<ResetPasswordProps> = ({
  history,
  username,
  setToastNotification,
  userPicture,
  appOnline,
  setShowOffline
}) => {
  var passwordHideShowLabel = "Cacher le mot de passe";
  let typeField = "password";

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [actualPassword, setActualPassword] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [actualPasswordError, setActualPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setpasswordConfirmError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [complexity, setComplexity] = useState(0.05);
  const [complexityColor, setComplexityColor] = useState(PasswordColor.weak);
  const [complexityName, setComplexityName] = useState("");
  const [passwordLabel, setPasswordLabel] = useState(passwordHideShowLabel);
  const [icon, setIcon] = useState(eyeOff);
  const [src, setSrc] = useState("");

  useEffect(() => {
    readFileFromCache(userPicture, setSrc);
  }, [userPicture]);

  /**
   * inputsValidation : checks the validity of data form
   * @param   {void}
   * @return  {boolean}
   */
  function inputsValidation() {
    setPasswordError(false);
    setpasswordConfirmError(false);
    setActualPasswordError(false);

    if (!password) {
      setPasswordError(true);
      return false;
    } else if (!passwordConfirm) {
      setpasswordConfirmError(true);
      return false;
    } else if (!actualPassword) {
      setActualPasswordError(true);
      return false;
    } else if (password != passwordConfirm) {
      setToastNotification({
        message: "Les deux mots de passe ne sont pas identiques.",
        onClickMessage: () => {},
        color: "danger",
        duration: 6000,
        buttons: [],
      } as ToastNotification);
      return false;
    } else {
      return true;
    }
  }

  /**
   * changePassword : retrieve data form sended by user
   * @param   {FormEvent} e  form event
   * @return  {void}
   */
  const changePassword = async () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    setFormSubmitted(true);

    if (inputsValidation() && username) {
      var credential = firebase.auth.EmailAuthProvider.credential(
        username,
        actualPassword
      );

      // Prompt the user to re-provide their sign-in credentials

      firebase
        .auth()
        .currentUser!.reauthenticateWithCredential(credential)
        .then(function () {
          setLoading(true);
          updatePasswordDB(password);
        })
        .catch(function (error) {
          setToastNotification({
            message: "Le mot de passe actuel n'est pas correct",
            onClickMessage: () => {},
            color: "danger",
            duration: 6000,
            buttons: [],
          } as ToastNotification);
        });

      await firebase.auth().currentUser!.updatePassword(password);
    }
    setLoading(false);
  };

  /**
   * updatePasswordDB : update the password in the DB
   * @param   {string} password  user password
   * @return  {void}
   */
  async function updatePasswordDB(password: string) {
    try {
      await firebase.auth().currentUser!.updatePassword(password);
      setToastNotification({
        message: "Votre mot de passe a été modifié avec succès",
        onClickMessage: () => {},
        color: "success",
        duration: 6000,
        buttons: [],
      } as ToastNotification);

      history.push("/settings/account");
    } catch (err) {
      setToastNotification({
        message: "Une erreur s'est produite.",
        onClickMessage: () => {},
        color: "danger",
        duration: 6000,
        buttons: [],
      } as ToastNotification);
      console.log(err);
    }
  }

  /**
   * showHidePasswords : show and hide password
   * @param   {void}
   * @return  {void}
   */
  const showHidePasswords = () => {
    if (!checked) {
      typeField = "text";
      setPasswordLabel(" Cacher votre mot de passe");

      setIcon(eyeOff);
    } else {
      typeField = "password";
      setPasswordLabel("  Afficher le mot de passe");

      setIcon(eye);
    }
    setChecked(!checked);
    document.getElementsByTagName("input")[0].type = typeField;
    document.getElementsByTagName("input")[1].type = typeField;
    document.getElementsByTagName("input")[2].type = typeField;
  };

  /**
   * checkPassword : checks the complexity of the password => weak, medium, strong
   * @param   {string} password  First Name of the User
   * @param   {void}
   */
  const checkPassword = (e: any) => {
    setPassword(e.detail.value!);
    switch (getComplexity(e.detail.value)) {
      case 0:
        setPasswordComplexity(
          PasswordDisplayName.weak,
          PasswordColor.weak,
          0.1
        );
        break;
      case 1:
        setPasswordComplexity(
          PasswordDisplayName.medium,
          PasswordColor.medium,
          0.6
        );
        break;
      case 2:
        setPasswordComplexity(
          PasswordDisplayName.strong,
          PasswordColor.strong,
          1
        );
        break;
      default:
        setPasswordComplexity(
          PasswordDisplayName.weak,
          PasswordColor.weak,
          0.05
        );
        break;
    }
  };

  /**
   * setPasswordComplexity : defines the complexity of the user password
   * @param   {string} pwDisplayName  the category name of the password
   * @param   {string} pwColor  the category color of the password
   * @param   {number} complexityValue  percentage of the password depending on complexity
   * @param   {void}
   */
  function setPasswordComplexity(
    pwDisplayName: PasswordDisplayName,
    pwColor: PasswordColor,
    complexityValue: number
  ) {
    setComplexityName(pwDisplayName);
    setComplexityColor(pwColor);
    setComplexity(complexityValue);
  }

  return (
    <IonPage id="login-page">
      <Header title="Changement de mot de passe" showHelp />
      <IonContent className="page-content">
        <div className="login-logo">
          {src && (
            <img
              src={src}
              alt="avatar"
              className={classes.picture}
              onClick={() => history.push("/updatephoto")}
            />
          )}
          {!src && <IonSkeletonText animated className={classes.picture} />}
        </div>

        <form noValidate>
          <IonList>
            <IonItem>
              <IonLabel
                position="stacked"
                className="passwordComplexityName"
                color={complexityColor}
              >
                {complexityName}
              </IonLabel>
              <br />
              <br />
              <IonProgressBar
                color={complexityColor}
                value={complexity}
              ></IonProgressBar>
            </IonItem>
            <br />
            <br />
            <br />
            {/* actual password */}
            <IonItem>
              <IonLabel position="stacked" color="primary">
                {" "}
                <IonText>Mot de passe actuel</IonText>{" "}
              </IonLabel>

              <IonInput
                name="actual_password"
                type="text"
                value={actualPassword}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => setActualPassword(e.detail.value!)}
                required
              ></IonInput>
            </IonItem>
            {formSubmitted && actualPasswordError && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  Le mot de passe actuel est obligatoire
                </p>
              </IonText>
            )}
            <br />
            <br />
            {/* new password */}
            <IonItem>
              <IonLabel position="stacked" color="primary">
                <IonText> Nouveau mot de passe</IonText>{" "}
              </IonLabel>

              <IonInput
                name="password"
                type="text"
                value={password}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => checkPassword(e)}
                required
              ></IonInput>
            </IonItem>
            {formSubmitted && passwordError && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  Le nouveau mot de passe est obligatoire
                </p>
              </IonText>
            )}
            <br />
            <br />
            {/* confirm password*/}
            <IonItem>
              <IonLabel position="stacked" color="primary">
                <IonText>Confirmer le mot de passe</IonText>{" "}
              </IonLabel>
              <IonInput
                name="passwordconfirm"
                type="text"
                value={passwordConfirm}
                onIonChange={(e) => setPasswordConfirm(e.detail.value!)}
              ></IonInput>
            </IonItem>
            {formSubmitted && passwordConfirmError && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  La confirmation de mot de passe est obligatoire
                </p>
              </IonText>
            )}
            <br />
            <br /> <br />
            <br />
            {/* show/ hide password */}
            <IonItem>
              <IonToggle
                className="toggle-button"
                checked={checked}
                onClick={showHidePasswords}
              />{" "}
              <IonText color="primary">
                <IonIcon icon={icon} /> {passwordLabel}
              </IonText>
            </IonItem>
          </IonList>
          <br />
        </form>
        <Loading enable={loading} text="Connexion en cours ..." />
      </IonContent>
      <div slot="fixed" style={{ width: "100%", bottom: 0 }}>
        <IonRow class="ion-no-padding">
          <IonCol size="6" class="ion-no-padding">
            <Button
              routerLink="/settings/account"
              color="light"
              text="Retour"
              icon={arrowBackCircleOutline}
            />
          </IonCol>
          <IonCol size="6" class="ion-no-padding">
            <Button
              onClick={changePassword}
              color="success"
              text="Sauvegarder"
              svg={IconSvgEnum.ok_white}
            />
          </IonCol>
        </IonRow>
      </div>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    config: state.user.userFirestore ? state.user.userFirestore.config : "",
    username: state.user.username,
    userPicture: state.user.userFirestore
      ? state.user.userFirestore.picture
      : defaultPicture,
    appOnline: state.global.appOnline
  }),
  mapDispatchToProps: {
    setToastNotification,
    setShowOffline
  },
  component: ResetPassword,
});
