import React, { useState } from "react";
import { connect } from "../../data/connect";
import _ from "lodash";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./CallInvitation.scss";
import Button from "../Button";
import { IonCol, IonRow } from "@ionic/react";
import { toast } from "react-toastify";
import { checkIsPhoneFormat } from "../../util/checkIsPhoneFormat";
import {
  arrowBackCircleOutline,
  atOutline,
  chatboxEllipsesOutline,
  closeCircleOutline,
  sendOutline,
} from "ionicons/icons";

import {validateEmail} from "../../util/email.validation";
import {AudioInfos, MailType} from '../../models/Mail';
import { inviteByTelephone,saveInvited } from "../../api/visioCall";
import { sendMailRequest } from "../../api/mail";
interface OwnProps {
  show: (show: boolean) => void;
  callUrl: string;
  audioInfos: any;
  callId:string
}
interface StateProps {
  currentUserFirstName?: string;
  currentUserLastName?:string;
  currentUserEmail?: string;
}
interface DispatchProps {}
interface CallInvitationProps extends OwnProps, StateProps, DispatchProps {}

const CallInvitation: React.FC<CallInvitationProps> = ({
  show,
  callUrl,
  audioInfos,
  callId,
  currentUserFirstName,
  currentUserLastName,
  currentUserEmail,
  
}) => {
  const [showSendSmsForm, setShowSendSmsForm] = useState(false);
  const [showSendEmailForm, setShowSendEmailForm] = useState(false);
  const [telephone, setTelephone] = useState("");
  const [sendSmsFormLoading, setSendSmsFormLoading] = useState(false);
  const [sendEmailFormLoading, setSendEmailFormLoading] = useState(false);
  const [email, setEmail] = useState("");

  const cancelHandler = () => {
    show(false);
  };
 console.log("currentUserFirstName",currentUserFirstName)
  const sendSmsForm = () => {
    return (
      <div style={{ marginTop: "20px" }}>
        <div className="label-tel">
          {showSendSmsForm && (
            <label>Numéro de téléphone de votre invité:</label>
          )}
          {showSendEmailForm && <label>Email de votre invité:</label>}
        </div>
        <div className="input-tel">
          {showSendSmsForm && (
            <input
              title="téléphone"
              type="tel"
              placeholder="ex: 0605060408"
              value={telephone}
              onChange={(event) => setTelephone(event.currentTarget.value)}
            />
          )}
          {showSendEmailForm && (
            <input
              title="Email"
              type="email"
              placeholder="ex: monprenom@monlienvisio.fr"
              value={email}
              onChange={(event) => setEmail(event.currentTarget.value)}
            />
          )}
        </div>
        <IonRow>
          <IonCol>
            <Button
              className={"btn-small"}
              color={"light"}
              onClick={() => {
                setShowSendSmsForm(false);
                setShowSendEmailForm(false);
              }}
              text="Retour"
              icon={arrowBackCircleOutline}
              iconSize="icon-size-invitation"
            />
          </IonCol>
          <IonCol>
            <Button
              className={"btn-small"}
              color={"success"}
              onClick={() => {
                showSendSmsForm && sendInvitationBySms();
                showSendEmailForm && sendInvitationByEmail();
              }}
              disabled={sendSmsFormLoading}
              text={
                sendSmsFormLoading
                  ? "Envoi en cours ..."
                  : "Envoyer l'invitation"
              }
              iconSize="icon-size-invitation"
              icon={sendOutline}
            />
          </IonCol>
        </IonRow>
      </div>
    );
  };

  const sendInvitationBySms = () => {
    if (
      checkIsPhoneFormat(telephone) &&
      telephone.length == 10 &&
      (_.startsWith(telephone, "06") || _.startsWith(telephone, "07"))
    ) {
      setSendSmsFormLoading(true);
      inviteByTelephone(
        telephone,
        `${
          currentUserFirstName ? currentUserFirstName : "Un proche"
        } vous invite à rejoindre son appel visio. Vous pouvez vous y connecter en cliquant sur le lien suivant: ${callUrl} ou en mode audio en appelant le ${
          audioInfos.phoneNumber
        } puis en tapant le code ${
          audioInfos.pin
        }. MON LIEN VISIO vous remercie et vous souhaite une bonne journée.`
      ).then(() => {
        setSendSmsFormLoading(false);
        saveInvited(callId,telephone,"sms");
        toast.success("L'invitation a bien été envoyée :" + telephone);
        setTelephone("");
        cancelHandler();
      });
    } else {
      toast.error("Le numéro de téléphone n'est pas valide");
    }
  };
  const sendInvitationByEmail = () => {
    if (
      validateEmail(email) && email.length > 0 && currentUserEmail) {
      setSendEmailFormLoading(true);
      const audioObj = {
        urlCall:callUrl,
        pin:audioInfos.pin,
        phoneNumber:audioInfos.phoneNumber
      } as AudioInfos

      let message = ''
      sendMailRequest(currentUserEmail,MailType.Invitation,message,MailType.Invitation,email,[], audioObj)
      .then(() => {
        saveInvited(callId,email,"email");
        setSendEmailFormLoading(false); 
        toast.success("L'invitation a bien été envoyée :" + email);
        setEmail("");
        cancelHandler();
      });
    } else {
      toast.error("L'adresse e-mail n'est pas valide");
    }
  };

  return (
    <Popup
      open={true}
      nested
      modal
      className="modal"
      onClose={() => cancelHandler()}
    >
      <div>
        <button className="close" onClick={() => cancelHandler()}>
          &times;
        </button>
        <div className="popup-header">
          {showSendSmsForm
            ? "Invitation SMS"
            : showSendEmailForm
            ? "Invitation Email"
            : "Invitation"}
        </div>
        {!showSendSmsForm && !showSendEmailForm && (
          <div className="content">
            <p>
              Cliquer ci-dessous pour envoyer une invitation et être rejoint
              dans l'appel actuel en mode visio ou en mode audio
            </p>
          </div>
        )}
        {!showSendSmsForm && !showSendEmailForm && (
          <div>
            <IonRow>
              <IonCol size="6">
                <Button
                  className={"btn-small"}
                  color={"success"}
                  onClick={() => {
                    setShowSendEmailForm(true);
                  }}
                  text="Email"
                  icon={atOutline}
                  iconSize="icon-size-invitation"
                />
              </IonCol>
              <IonCol size="6">
                <Button
                  className={"btn-small"}
                  color={"success"}
                  onClick={() => {
                    setShowSendSmsForm(true);
                  }}
                  text="SMS"
                  icon={chatboxEllipsesOutline}
                  iconSize="icon-size-invitation"
                />
              </IonCol>
            </IonRow>
          </div>
        )}
        {(showSendSmsForm || showSendEmailForm) && sendSmsForm()}
        <div className="actions">
          <Button
            color={"light"}
            className="button popup-btn"
            onClick={() => {
              cancelHandler();
            }}
            icon={closeCircleOutline}
            text="Annuler"
            iconSize="icon-size-invitation"
          />
        </div>
      </div>
    </Popup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {},
  mapStateToProps: (state) => ({
    currentUserFirstName: state.user.userFirestore
      ? state.user.userFirestore.firstName
      : undefined,
    currentUserLastName: state.user.userFirestore
      ? state.user.userFirestore.lastName
      : undefined,
    currentUserEmail: state.user.username,
  }),
  component: React.memo(CallInvitation),
});
