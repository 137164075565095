import firebase from "../FirebaseConfig";
import { ContactGroup } from "../models/ContactGroup";
import { UserSupervised } from "../models/UserSupervised";
import { createGuid } from "../util/commons";
import { random } from "../util/functions";
import { getUser } from "./user";

export const createContactGroupRequest = async (
  id: string,
  creator: string,
  name: string,
  picture: string,
  members: string[]
) => {
  const creatorUser = await getUser(creator);
  const creatorName = creatorUser
    ? creatorUser.firstName + " " + creatorUser.lastName[0]
    : creator;
  members.push(creator);
  try {
    const contactGroup = {
      id: id,
      creator: creator,
      creatorName: creatorName,
      name: name,
      picture: picture,
      members: members,
      dateCreate: new Date(),
      callsCounter: 0,
      status: true,
    } as ContactGroup;
    const db = firebase.firestore();
    await db.collection("contactGroups").doc(id).set(contactGroup);
    return contactGroup;
  } catch (e) {
    alert("Erreur createContactGroupRequest: " + JSON.stringify(e));
  }
  return false;
};

export const deleteContactGroupRequest = async (id: string) => {
  try {
    const db = firebase.firestore();
    await db.collection("contactGroups").doc(id).delete();
  } catch (e) {
    alert("Erreur deleteContactGroupRequest: " + JSON.stringify(e));
  }
  return false;
};

export const getContactGroupRequest = async (id: string) => {
  try {
    const db = firebase.firestore();
    var contactGroup = await db.collection("contactGroups").doc(id).get();
    return contactGroup.data() as ContactGroup;
  } catch (e) {
    alert("Erreur getContactGroupRequest: " + JSON.stringify(e));
  }
  return null;
};

export const updateContactGroupRequest = async (
  contactGroup: ContactGroup,
  supervisedAccount: UserSupervised | null
) => {
  try {
    if (!supervisedAccount) {
      const db = firebase.firestore();
      return await db
        .collection("contactGroups")
        .doc(contactGroup.id)
        .update(contactGroup);
    } else {
      const updateContactGroupFunc = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("ContactGroup-updateContactGroup");
      return updateContactGroupFunc({
        contactGroup: contactGroup,
      }).then((result) => result.data as ContactGroup);
    }
  } catch (e) {
    alert("Erreur updateContactGroupRequest: " + JSON.stringify(e));
  }
  return Promise.resolve(false);
};

/**
 * Upload group picture and return url
 * @param fileBase64
 * @param fileExtension
 * @param groupId
 */
export const uploadGroupPictureRequest = async (
  fileBase64: string,
  fileExtension: string,
  groupId: string
) => {
  const userAuth = firebase.auth().currentUser;
  if (userAuth) {
    try {
      const storage = firebase.storage();
      const filepath =
        "private/groups/" + groupId + "_" + random(5) + "." + fileExtension;
      const docref = storage.ref(filepath);
      return await docref
        .putString(fileBase64, "base64")
        .then(async (snapshot) => {
          return await snapshot.ref.getDownloadURL();
        });
    } catch (e) {
      alert("Erreur storage: " + JSON.stringify(e));
    }
  }
  return false;
};
