import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonContent,
  IonChip,
  IonCard,
} from "@ionic/react";
import Header from "../components/Header";
import { connect } from "../data/connect";
import "./Help.scss";
import { attachOutline, bookOutline, sendOutline } from "ionicons/icons";
import { setShowOffline, setUserIsActive } from "../data/global/global.actions";
import { MessageType, showToast } from "../util/toast.message";
import { sendEmail } from "../data/user/user.actions";
import { MailType } from "../models/Mail";
import { MailSupport, _fileSizeLimitPhoto } from "../appConfig";
import { User } from "../models/User";
import Button from "../components/Button";
import _ from "lodash";
import { FileData } from "../models/FileData";
import { getGlobalConfig } from "../api/globalConfig";
import * as selectors from "../data/selectors";
interface OwnProps {}

interface StateProps {
  currentUserEmail?: string;
  currentUser?: User;
  appOnline: boolean;
  isHelpedProfil:boolean,
}
interface DispatchProps {
  sendEmail: typeof sendEmail;
  setShowOffline: typeof setShowOffline;
  setUserIsActive: typeof setUserIsActive;
}
interface HelpProps extends OwnProps, StateProps, DispatchProps {}
const options = {
  cssClass: "popover-interface",
};
const Help: React.FC<HelpProps> = ({
  currentUserEmail,
  currentUser,
  appOnline,
  isHelpedProfil,
  setUserIsActive,
  setShowOffline,
  sendEmail,
}) => {
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [attachments, setAttachments] = useState<FileData[]>([]);

  const inputFile = React.createRef<HTMLInputElement>();

  useEffect(() => {
    setUserIsActive(true);
    return () => {
      setUserIsActive(false);
    } 
  }, [])

  const removeAttachment = (index: number) => {
    console.log(attachments);
    const list = attachments.filter((a, i) => i !== index);
    setAttachments(list);
  };

  const checkSizeFiles = async (files: FileData[]) => {
    const config = await getGlobalConfig(isHelpedProfil,currentUserEmail);
    const fileSizeLimitPhoto = config.album?.maxSizePicture ? config.album?.maxSizePicture : _fileSizeLimitPhoto;
    let countSizeError = 0;
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.sizeInKb > fileSizeLimitPhoto) {
        countSizeError++;
        break;
      }
    }
    if (countSizeError > 0) {
      showToast(
        "Votre fichier dépasse la taille limite pour être importé",
        3000,
        MessageType.error
      );
      return false;
    }
    return true;
  };

  async function onSelectFile(e: any) {
    let selectedFiles = e.target.files as FileList;
    if (selectedFiles.length > 0) {
      let filesName: string[] = [];
      const filesData = Array.from<File>(selectedFiles).map((file: File) => {
        filesName.push(file.name);
        return {
          file: file,
          extension: _.last(_.split(file.type, "/")),
          sizeInKb: file.size / 1000,
        } as FileData;
      });
      const isValid = await checkSizeFiles(filesData);
      if (isValid) {
        setAttachments((currentFilesData) => [
          ...currentFilesData,
          ...filesData,
        ]);
      }
    }
  }

  const attachFiles = () => {
    if (inputFile.current) inputFile.current.click();
  };

  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!appOnline) {
      setShowOffline(true);
      return;
    }
    let haveErrors = false;
    if (!message.trim()) {
      showToast("Veuillez saisir votre message", 3000, MessageType.error);
      haveErrors = true;
    }
    if (!subject) {
      showToast(
        "Veuillez choisir l'objet de la demande",
        3000,
        MessageType.error
      );
      haveErrors = true;
    }
    if (!haveErrors && currentUserEmail && currentUser) {
      let msg =
        "<p>Message de <b>" +
        currentUser.firstName +
        " " +
        currentUser.lastName +
        " (" +
        currentUser.email +
        ") " +
        ":</b><br>" +
        message +
        "</p>";
      sendEmail(
        currentUserEmail,
        subject,
        msg,
        MailType.Help,
        MailSupport,
        attachments
      );
      setSubject("");
      setMessage("");
      setAttachments([]);
    }
  };

  return (
    <IonPage>
      <Header title="Information" />
      <IonContent>
        <div className={"container"}>
          <IonList className={"list"}>
            <IonItemGroup>
              <IonItemDivider>
                <IonLabel>Consultez le guide utilisateur</IonLabel>
              </IonItemDivider>
              <div className="ion-padding">
                <IonButton
                  color="light"
                  expand="block"
                  class="ion-no-margin"
                  onClick={() =>
                    window.open("https://monlienvisio.fr/centre-daide/")
                  }
                >
                  <IonIcon
                    icon={bookOutline}
                    className="icon-size help-button-icon"
                  />
                  <IonText className="help-button-text">
                    Guide utilisateur
                  </IonText>
                </IonButton>
              </div>
              <IonItemDivider>
                <IonLabel>Ecrivez nous un message</IonLabel>
              </IonItemDivider>
              <form noValidate onSubmit={sendMessage} className="contact-form">
                <IonList
                  lines="full"
                  class="ion-no-margin ion-no-padding"
                  className="contact-form-list"
                >
                  <IonItem>
                    <IonSelect
                      name="subject"
                      value={subject}
                      interface="popover"
                      interfaceOptions={options}
                      placeholder="Choisir"
                      onIonChange={(e) => {
                        setSubject(e.detail.value!);
                      }}
                    >
                      <IonSelectOption value="Suppression de compte">
                        Suppression de compte
                      </IonSelectOption>
                      <IonSelectOption value="Signaler un problème">
                        Signaler un problème
                      </IonSelectOption>
                      <IonSelectOption value="Transfert de compte">
                        Transfert de compte
                      </IonSelectOption>
                      <IonSelectOption value="Autre">Autre</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">
                      Message <IonText color="danger">*</IonText>
                    </IonLabel>
                    <IonTextarea
                      name="message"
                      value={message}
                      onIonChange={(e) => {
                        setMessage(e.detail.value!);
                      }}
                      required
                      rows={5}
                    ></IonTextarea>
                  </IonItem>
                  <IonItem>
                    <input
                      ref={inputFile}
                      style={{ display: "none" }}
                      type="file"
                      id="file"
                      multiple
                      onChange={onSelectFile}
                    />
                    <Button
                      color="light"
                      onClick={attachFiles}
                      text="Joindre un document"
                      icon={attachOutline}
                    />
                  </IonItem>
                  {attachments.length > 0 && (
                    <IonItem>
                      <IonCard style={{ width: "100%" }}>
                        {attachments.map((attachment, index) => (
                          <IonChip
                            style={{ maxWidth: "100%", height: "max-content" }}
                          >
                            <IonLabel>{attachment.file.name}</IonLabel>
                            <IonIcon
                              icon={"assets/icon/close.svg"}
                              style={{ minWidth: "25px", minHeight: "25px" }}
                              onClick={() => removeAttachment(index)}
                            ></IonIcon>
                          </IonChip>
                        ))}{" "}
                      </IonCard>
                    </IonItem>
                  )}
                </IonList>

                <div className="ion-padding">
                  <IonButton expand="block" type="submit" class="ion-no-margin">
                    <IonIcon
                      icon={sendOutline}
                      className="icon-size help-button-icon"
                    />
                    <IonText className="help-button-text">Envoyer</IonText>
                  </IonButton>
                </div>
              </form>
            </IonItemGroup>
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    sendEmail,
    setShowOffline,
    setUserIsActive
  },
  mapStateToProps: (state) => ({
    currentUserEmail: state.user.username,
    currentUser: state.user.userFirestore,
    appOnline: state.global.appOnline,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
  }),
  component: React.memo(Help),
});
