import React, { useEffect, useRef, useState } from 'react';
import { IonAvatar, IonCol, IonGrid, IonHeader, IonModal, IonRow, IonText, IonToolbar } from '@ionic/react';
import {isPlatform} from "@ionic/core";
import SpeechRecognition from 'react-speech-recognition';
import  { audioType } from './AudioPlayer'
import './IncomingCall.scss';
import { UserWithRelationship } from '../models/UserWithRelationship';
import { defaultPicture } from '../util/commons';
import { setAudioData } from '../data/user/user.actions';
import VisioCall from '../models/VisioCall';
import Button from './Button';
import { readFileFromCache } from '../util/localStorage';
import { App } from '@capacitor/app';

import { BackgroundTask } from '@robingenz/capacitor-background-task';

interface IncomingCallProps {
  caller?: UserWithRelationship;
  callbackCancel(): any;
  callbackAccept(): any;
  setAudioData: typeof setAudioData;
  visioCallData: VisioCall;
  showModal: boolean;
}

const IncomingCall: React.FC<IncomingCallProps> = ({ caller, visioCallData, showModal, callbackCancel, callbackAccept, setAudioData }) => {

  /*const [vocalCommandValidate, setVocalCommandValidate] = useState(false);
  const commands = [
    { command: ['oui', 'accepter', 'répondre'], callback: () => {setVocalCommandValidate(true); acceptCall()}},
    { command: ['non', 'refuser', 'raccrocher'], callback: () => {setVocalCommandValidate(true); refuseCall()}},
  ]*/
  const callerPicture = caller ? caller.picture : defaultPicture;
  

  const [src, setSrc] = useState("");

  useEffect(()=>{
    readFileFromCache(callerPicture, setSrc);
  }, [callerPicture]);


  useEffect(() => {
    App.addListener('appStateChange', (state) => {
      if (!state.isActive && isPlatform("mobile")) {
        let taskId : any = BackgroundTask.beforeExit(async () => {
          BackgroundTask.finish({ taskId });
        });
      }
    });
    return () => {
      setAudioData(audioType.IncomingCall, false);
    }
  }, []);


  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    console.log("browserSupportsSpeechRecognition = false")
  }


  if(!showModal)
  {
    // hack for delete incoming call modal. Else a blank/white screen is shown (ionic issue ?)
    const modal = document.getElementsByClassName("modal-incoming-call");
    if(modal && modal[0] && modal[0].parentNode) {
      modal[0].parentNode.removeChild(modal[0]);
    }
  }



  return (
    <IonModal 
      isOpen={showModal} 
      cssClass={"modal-incoming-call"}>
      <IonHeader translucent={true}>
        <IonToolbar className="ionToolbar">
          <IonText> {caller ? caller.firstName.toUpperCase() : ""}</IonText>
          <br />
          <span>Appel Entrant</span>
        </IonToolbar>
      </IonHeader>
      {/*<Loading enable={!caller} text="" />*/}
      {/*<VoiceCommand
        commandsCustom={commands}
        text={"Accepter ou refuser l'appel vidéo ?"}
        onOpen={() => {
          setAudioData(audioType.IncomingCall, false);
        }}
        onClose={() =>
          setAudioData(audioType.IncomingCall, !vocalCommandValidate)
        }
      />*/}
      <IonGrid className="grid">
        <IonRow style={{ width: "100%" }}>
          <IonCol size="12">
            <IonAvatar className="userImage">
              <img
                src={src}
                alt="avatar"
              />
            </IonAvatar>
          </IonCol>
        </IonRow>
        <IonRow style={{ width: "100%" }}>
          <IonCol size="6" className="buttonContainer">
            <Button
              className="bigButton"
              color="danger"
              onClick={() => callbackCancel()}
              text="Refuser"
              icon={"assets/icon/cancel-white.svg"}
              textSize="fontSizeText"
              iconSize="heightIcon"
              fullWidth={false}
            />
          </IonCol>
          <IonCol size="6" className="buttonContainer">
            <Button
              className="bigButton"
              color="success"
              onClick={() => callbackAccept()}
              text="Accepter"
              icon={"assets/icon/ok-white.svg"}
              textSize="fontSizeText"
              iconSize="heightIcon"
              fullWidth={false}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
      <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
        <IonRow style={{ height: "100%" }}>
          <IonCol size="12" className="textFooterContainer">
            <IonText className="textFooter">Mon lien visio</IonText>
          </IonCol>
        </IonRow>
      </div>
    </IonModal>
  );
};

export default IncomingCall;