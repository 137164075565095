import React from "react";
import { IonImg } from "@ionic/react";
import { connect } from "../data/connect";
import classes from "./SupervisorShortcut.module.scss";
import { RouteComponentProps, withRouter } from "react-router";
import { menuController } from "@ionic/core";
import {
  SupervisorRelationship,
  SupervisorRelationshipState,
} from "../models/SupervisorRelationship";
import { loadAndSetUserSupervised } from "../data/supervisor/supervisor.actions";
import { setShowOffline } from "../data/global/global.actions";

interface DispatchProps {
  loadAndSetUserSupervised: typeof loadAndSetUserSupervised;
  setShowOffline: typeof setShowOffline;
}
interface OwnProps {}
interface StateProps {
  supervisorRequests: SupervisorRelationship[];
  currentUserEmail: string | undefined;
  appOnline: boolean;
}
interface SupervisorShortcutProps
  extends RouteComponentProps,
    OwnProps,
    DispatchProps,
    StateProps {}

const SupervisorShortcut: React.FC<SupervisorShortcutProps> = ({
  history,
  supervisorRequests,
  currentUserEmail,
  appOnline,
  setShowOffline
}) => {
  const userAdministered = supervisorRequests.filter(
    (m) =>
      m.administrator == currentUserEmail &&
      m.state == SupervisorRelationshipState.Accepted
  );
  const onClickHandler = () => {
    if (!appOnline) {
      setShowOffline(true);
      return;
    }
    menuController.close();
    if (supervisorRequests.length === 1) {
      loadAndSetUserSupervised(supervisorRequests[0].administered);
    }
    history.push({
      pathname: "/supervisor/list",
      state: {
        isFromSupervisorShortcut: true,
      },
    });
  }
  if (userAdministered.length > 0) {
    return (
      <div className={classes.img}>
        <IonImg
          src={"assets/img/handshake-white.png"}
          onClick={onClickHandler}
        />
      </div>
    );
  }
  return null;
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    supervisorRequests: state.supervisor.requests || [],
    currentUserEmail: state.user.username,
    appOnline: state.global.appOnline
  }),
  mapDispatchToProps: {
    loadAndSetUserSupervised,
    setShowOffline
  },
  component: withRouter(SupervisorShortcut),
});
