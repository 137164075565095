import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonTitle,
} from "@ionic/react";
import { addOutline } from "ionicons/icons";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "../data/connect";
import IconSvg, { IconSvgEnum } from "./IconSvg";
import * as selectors from "../data/selectors";
import { menuController } from "@ionic/core";
import classes from "./header.module.scss";
import { setUserIsActive, setSearchTerm } from "../data/global/global.actions";
import { UserSupervised } from "../models/UserSupervised";
import isProdEnv from "../util/isProdEnv";
import "./Header.scss";

export interface ButtonData {
  iconSvg: IconSvgEnum;
  title: string;
  onClick: Function;
  addPlusIcon: boolean;
}

interface StateProps {
  /**True if current user have at least one friend */
  friendsListNotEmpty: boolean;
  isHelpedProfil: boolean;
  /**Account that is supervised by current user */
  supervisedAccount?: UserSupervised;
  subtitling: boolean;
}

interface OwnProps {
  title: string;
  showCallBtn?: boolean;
  showMessageBtn?: boolean;
  leftButtons?: ButtonData[];
  hideHome?: boolean;
  showBack?: boolean;
  showAddGroupBtn?: boolean;
  showSendMediaBtn?: boolean;
  action?: () => void;
  showHelp?: boolean;
}

interface DispatchProps {
  setSearchTerm: typeof setSearchTerm;
  setUserIsActive: typeof setUserIsActive;
}

interface HeaderProps
  extends RouteComponentProps,
  DispatchProps,
  OwnProps,
  StateProps { }

const Header: React.FC<HeaderProps> = ({
  title,
  history,
  isHelpedProfil,
  friendsListNotEmpty,
  leftButtons,
  showCallBtn = false,
  showMessageBtn = false,
  hideHome = false,
  showBack = false,
  showSendMediaBtn = false,
  showHelp = false,
  supervisedAccount,
  subtitling,
  setSearchTerm,
  setUserIsActive,
  action,
}) => {
  return (
    <IonHeader translucent={true} className={"header-border"}>
      {/* If no supervised account selected, normal header */}
      {!supervisedAccount && (
        <IonToolbar>
          <IonButtons slot="start">
            {showBack && <IonBackButton style={{ color: "#424242" }} />}
            {!hideHome && (
              <IonButton
                type="button"
                onClick={action ? action : () => menuController.open()}
              >
                <div>
                  <IconSvg icon={IconSvgEnum.home_orange} />{" "}
                  {subtitling && (
                    <span className={classes.iconTitle}>Accueil</span>
                  )}
                  {!+isProdEnv() && (
                    <div className="env-developpement">dev</div>
                  )}
                </div>
              </IonButton>
            )}
          </IonButtons>

          {(friendsListNotEmpty || showHelp) && (
            <IonButtons slot="end">
              {showSendMediaBtn && friendsListNotEmpty && (
                <IonButton
                  type="button"
                  onClick={() => {
                    menuController.close();
                    history.push("/tabs/send/media");
                  }}
                >
                  <div>
                    <IconSvg icon={IconSvgEnum.picture} />
                    {subtitling && (
                      <span className={classes.iconTitle}>Envoyer</span>
                    )}
                  </div>
                </IonButton>
              )}
              {showCallBtn && friendsListNotEmpty && (
                <IonButton
                  type="button"
                  onClick={() => {
                    menuController.close();
                    setSearchTerm("");
                    history.push("/tabs/contacts/create");
                  }}
                >
                  <div>
                    <IconSvg icon={IconSvgEnum.video_camera} />{" "}
                    {subtitling && (
                      <span className={classes.iconTitle}>Appeler</span>
                    )}
                  </div>
                </IonButton>
              )}
              {showMessageBtn && friendsListNotEmpty && (
                <IonButton
                  type="button"
                  onClick={() => {
                    menuController.close();
                    setSearchTerm("");
                    setUserIsActive(true);
                    history.push("/tabs/contacts/message/create");
                  }}
                >
                  <div>
                    <IconSvg icon={IconSvgEnum.record_message} />
                    {subtitling && (
                      <span className={classes.iconTitle}>Enregistrer</span>
                    )}
                  </div>
                </IonButton>
              )}
              {showHelp && (
                <IonButton
                  type="button"
                  onClick={() => {
                    history.push("/tabs/help");
                    menuController.close();
                  }}
                >
                  <div>
                    <IconSvg icon={IconSvgEnum.info} />{" "}
                    {subtitling && (
                      <span className={classes.iconTitle}>Aide</span>
                    )}
                  </div>
                </IonButton>
              )}
            </IonButtons>
          )}
          {leftButtons && (
            <IonButtons slot="end">
              {leftButtons.map((btn, i) => {
                return (
                  <IonButton
                    key={i}
                    type="button"
                    onClick={() => btn.onClick()}
                  >
                    <div>
                      {btn.addPlusIcon && (
                        <IonIcon icon={addOutline} className="over-icon" />
                      )}
                      <IconSvg icon={btn.iconSvg} />
                      {subtitling && (
                        <span className={classes.iconTitle}>{btn.title}</span>
                      )}
                    </div>
                  </IonButton>
                );
              })}
            </IonButtons>
          )}
          <div className={classes.headerTitle + " header-title-size"}>
            {title ? title : "Accueil"}
          </div>
        </IonToolbar>
      )}

      {/* If  supervised account selected, current user actions are limited*/}
      {supervisedAccount && (
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              type="button"
              onClick={() => history.push("/supervisor/list")}
            >
              <div>
                <IconSvg icon={IconSvgEnum.home_orange} />{" "}
                {subtitling && (
                  <span className={classes.iconTitle}>Accueil</span>
                )}
              </div>
            </IonButton>
          </IonButtons>
          {leftButtons && (
            <IonButtons slot="end">
              {leftButtons.map((btn, i) => {
                return (
                  <IonButton
                    key={i}
                    type="button"
                    onClick={() => btn.onClick()}
                  >
                    <div>
                      {btn.addPlusIcon && (
                        <IonIcon icon={addOutline} className="over-icon" />
                      )}
                      <IconSvg icon={btn.iconSvg} />
                      {subtitling && (
                        <span className={classes.iconTitle}>{btn.title}</span>
                      )}
                    </div>
                  </IonButton>
                );
              })}
            </IonButtons>
          )}
          {showHelp && (
            <IonButtons slot="end">
              <IonButton
                type="button"
                onClick={() => {
                  history.push("/tabs/help");
                  menuController.close();
                }}
              >
                <div>
                  <IconSvg icon={IconSvgEnum.info} />{" "}
                  {subtitling && (
                    <span className={classes.iconTitle}>Aide</span>
                  )}
                </div>
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      )}
      {supervisedAccount && (
        <p className={classes.headerTitle + " header-title-size"}>
          {title ? title : "La page d'accueil"} de{" "}
          {supervisedAccount.user.firstName}
        </p>
      )}
    </IonHeader>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setSearchTerm,
    setUserIsActive,
  },
  mapStateToProps: (state) => ({
    subtitling: selectors.getSubtitlingConfig(state),
    friendsListNotEmpty: selectors.getUsers(state).length > 0,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
    supervisedAccount: state.supervisor.supervisedAccount,
  }),
  component: withRouter(Header),
});
