
import React, { useEffect } from 'react';
import { IonContent, IonPage} from '@ionic/react';

import {
  setIsLoggedIn,
  setUsername,
} from '../data/user/user.actions';
import { connect } from '../data/connect';
import { RouteComponentProps } from 'react-router';
import Loading from '../components/Loading';
import firebase from 'firebase';


interface OwnProps extends RouteComponentProps { 

}
interface StateProps {
}
interface DispatchProps {
}


interface LogoutProps extends RouteComponentProps, OwnProps, StateProps, DispatchProps { }

const Logout: React.FC<LogoutProps> = ({ history }) => {

  useEffect(() => {
    firebase.auth().signOut().then(() => {
      setIsLoggedIn(false);
      setUsername(undefined);
      history.push("/login");
    })
  }, []);
  return (
    <IonPage id="account-page">
      <IonContent>
       <Loading enable={true} text="Déconnexion en cours"/>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
  }),
  mapDispatchToProps: {
  },
  component: Logout
})