import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import {
  optionsOutline,
  peopleOutline,
  recordingOutline,
} from "ionicons/icons";
import { connect } from "../data/connect";
import { _appVersion } from "../appConfig";
import "./Menu.scss";
import firebase from "../FirebaseConfig";
import * as selectors from "../data/selectors";
import _ from "lodash";
import isProdEnv from "../util/isProdEnv";
import MenuItem from "./MenuItem";
import Header from "./Header";
import EmergencyShortcut from "./EmergencyShortcut";
import SupervisorShortcut from "./SupervisorShortcut";
import { menuController } from "@ionic/core";

const routes = {
  appPages: [
    /*{
      id: 0,
      title: "Appel en cours",
      path: "/tabs/conference/video",
      icon: videocamOutline,
      onlyCallingMode: true,
      color: "#eb4444",
    }, */
    {
      id: 1,
      title: "Contacts",
      path: "/tabs/contacts",
      icon: peopleOutline,
      onlyContactsMode: true,
      onlyCallingMode: false,
      color: "#F1933D",
    },
    {
      id: 2,
      title: "Messages",
      path: "/tabs/mailbox",
      icon: recordingOutline,
      onlyMailboxMode: true,
      onlyCallingMode: false,
      color: "#195EA4",
    },
    {
      id: 3,
      title: "Albums",
      path: "/tabs/media/shared",
      icon: "/assets/icon/album.svg",
      onlySharedFilesMode: true,
      onlyCallingMode: false,
      color: "#439423",
    },
    {
      id: 4,
      title: "Réglages",
      path: "/settings",
      icon: optionsOutline,
      onlyCallingMode: false,
      color: "#D43974",
    },
  ],
};

interface StateProps {
  isAuthenticated: boolean;
  menuEnabled: boolean;
  isVideoCalling: boolean;
  isHelpedProfil: boolean;
}

interface DispatchProps {}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps {}

const Menu: React.FC<MenuProps> = ({
  isAuthenticated,
  menuEnabled,
  isVideoCalling,
  isHelpedProfil,
}) => {


  if (!isAuthenticated) {
    return null;
  }

  /**
   * When menu open, check if call in running for not showing menu
   * @returns 
   */
  function onDidOpen(){
    if(isVideoCalling) {
      menuController.close();
      return false;
    }
  }

  return (
    <IonMenu type="overlay" onIonDidOpen={onDidOpen} disabled={!menuEnabled} contentId="main">
      <Header title="" showHelp/>
      <div style={{ flexGrow: 1, overflow: "auto" }}>
        <IonGrid fixed className={"grid-height"}>
          <IonRow style={{ height: "100%" }}>
            {routes.appPages
              .filter((route) => !!route.path)
              .map((route) => (
                <IonCol
                  key={route.title}
                  size="12"
                  size-lg="6"
                  size-md="6"
                  size-sm="6"
                  size-xs="6"
                  auto-hide="false"
                  hidden={route.onlyCallingMode && !isVideoCalling}
                >
                  <IonMenuToggle>
                    <MenuItem route={route} />
                  </IonMenuToggle>
                </IonCol>
              ))}
          </IonRow>
        </IonGrid>
      </div>
      <div className="menu-version">
        <div className={"btn-emergency"}>
          {isHelpedProfil && <EmergencyShortcut />}
        </div>
        <div className={"btn-shortcut"}>
          {!isHelpedProfil && <SupervisorShortcut />}
        </div>
        <div className={"version" + (isProdEnv() ? "" : " dev-env")}>
          <IonLabel>
            <small>
              {_appVersion} {isProdEnv() ? "" : "d"}
            </small>
          </IonLabel>
        </div>
      </div>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    isAuthenticated: !!firebase.auth().currentUser, //state.user.isLoggedin,
    menuEnabled: state.data.menuEnabled,
    isVideoCalling: !!state.visioCall.roomData,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
  }),
  mapDispatchToProps: {},
  component: withRouter(Menu),
});
