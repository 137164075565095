import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { SharedFile } from "../models/SharedFile";
import { SharedFolder } from "../models/SharedFolder";
import "./FileActions.scss";
import { connect } from "../data/connect";
import * as selectors from "../data/selectors";
import { pencil } from "ionicons/icons";
import EditImage from "./Modals/EditImage";
import { updateAlbumFile } from "../data/sharedFolder/sharedFolder.actions";
import { setShowOffline } from "../data/global/global.actions";
import { isVideoFile } from "../util/commons";
import { UserSupervised } from "../models/UserSupervised";

interface OwnProps {
  folder: SharedFolder;
  currentPicture: SharedFile | null;
  currentUserEmail: string;
  deletePicture: () => {};
  downloadPicture: (picture: SharedFile | null) => void;
  showDetails: () => void;
  addAudioComment: () => void;
}
interface StateProps {
  isHelpedProfil: boolean;
  subtitling: boolean;
  supervisedAccount: UserSupervised | null;
  appOnline: boolean;
}
interface DispatchProps {
  updateAlbumFile: typeof updateAlbumFile;
  setShowOffline: typeof setShowOffline;
}
interface FileActionsProps extends OwnProps, StateProps, DispatchProps {}

const FileActions: React.FC<FileActionsProps> = ({
  folder,
  currentPicture,
  currentUserEmail,
  isHelpedProfil,
  subtitling,
  supervisedAccount,
  appOnline,
  deletePicture,
  downloadPicture,
  showDetails,
  addAudioComment,
  updateAlbumFile,
  setShowOffline
}) => {
  const [showEditFile, setShowEditFile] = useState(false);
  const [fileFormat, setFileFormat] = useState("");
  const [fileData, setFileData] = useState("");

  const isVideo = currentPicture
    ? isVideoFile(currentPicture.extension)
    : false;

  const saveCroppedFile = (data: string, foramt: string) => {
    if (data && foramt && currentPicture) {
      if(!appOnline){
        setShowOffline(true)
        return;
      }
      updateAlbumFile(folder, currentPicture, data, foramt, supervisedAccount);
    }
  };

  useEffect(() => {
    return () => {
      setFileFormat("")
      setFileData("")
      setShowEditFile(false);
    };
  }, []);

  let ionFabItemClass =
    currentPicture && currentPicture.uploadBy == currentUserEmail
      ? !isHelpedProfil
        ? !isVideo
          ? "ion-fab-item-4"
          : "ion-fab-item-3"
        : !isVideo
        ? "ion-fab-item-3"
        : "ion-fab-item-2"
      : !isHelpedProfil
      ? "ion-fab-item-2"
      : "ion-fab-item-1";

  return (
    <Fragment>
      <IonFab
        className={"ion-fab"}
        vertical="top"
        horizontal="end"
        edge
        slot="fixed"
      >
        {/*<div className={ionFabItemClass}>
          <IonFabButton
            onClick={addAudioComment}
            color="light"
            className="ion-fab-button"
            slot="end"
          >
            <IonIcon className="ion-icon" icon={"assets/icon/voice.svg"} />
          </IonFabButton>
          {subtitling && (
            <span className={"ion-fab-button-title"}>Message vocal</span>
          )}
          </div>*/}
        <div className={ionFabItemClass}>
          <IonFabButton
            onClick={() => downloadPicture(currentPicture)}
            color="light"
            className="ion-fab-button"
            slot="end"
          >
            <IonIcon className="ion-icon" icon={"assets/icon/download.svg"} />
          </IonFabButton>
          {subtitling && (
            <span className={"ion-fab-button-title"}>Télécharger</span>
          )}
        </div>
        {!isVideo &&
          currentPicture &&
          currentPicture.uploadBy == currentUserEmail && (
            <div className={ionFabItemClass}>
              <IonFabButton
                onClick={() => setShowEditFile(true)}
                color="light"
                className="ion-fab-button"
                slot="end"
              >
                <IonIcon className="ion-icon" icon={pencil} />
              </IonFabButton>
              {subtitling && (
                <span className={"ion-fab-button-title"}>Modifier</span>
              )}
            </div>
          )}
        {currentPicture && currentPicture.uploadBy == currentUserEmail && (
          <div className={ionFabItemClass}>
            <IonFabButton
              onClick={deletePicture}
              color="light"
              className="ion-fab-button"
              slot="end"
            >
              <IonIcon className="ion-icon" icon={"assets/icon/delete.svg"} />
            </IonFabButton>
            {subtitling && (
              <span className={"ion-fab-button-title"}>Supprimer</span>
            )}
          </div>
        )}
        {!isHelpedProfil && (
          <div className={ionFabItemClass}>
            <IonFabButton
              onClick={showDetails}
              color="light"
              className="ion-fab-button"
              slot="end"
            >
              <IonIcon className="ion-icon" icon={"assets/icon/settings.svg"} />
            </IonFabButton>
            {subtitling && (
              <span className={"ion-fab-button-title"}>Détails</span>
            )}
          </div>
        )}
      </IonFab>
      {showEditFile && currentPicture && (
        <EditImage
          onCancel={() => setShowEditFile(false)}
          src={
            fileData
              ? `data:image/${fileFormat};base64,${fileData}`
              : currentPicture
              ? currentPicture.url
              : ""
          }
          setImageFormat={setFileFormat}
          setImageData={setFileData}
          onSave={saveCroppedFile}
        />
      )}
    </Fragment>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    updateAlbumFile,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    isHelpedProfil: selectors.getIsHelpedProfil(state),
    subtitling: selectors.getSubtitlingConfig(state),
    supervisedAccount: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount
      : null,
    appOnline: state.global.appOnline
  }),
  component: React.memo(FileActions),
});
