import { IonButton, IonCol, IonIcon, IonModal, IonRow } from "@ionic/react";
import { cameraOutline, imagesOutline, pencil } from "ionicons/icons";
import React, { useState } from "react";
import { connect } from "../../data/connect";
import { CameraResultType, CameraSource, Camera } from "@capacitor/camera";
import { toBase64 } from "../../util/commons";
import _ from "lodash";
import Header from "../Header";
import { ContactGroup } from "../../models/ContactGroup";
import BottomButtons from "../BottomButtons";
import { uploadGroupPicture } from "../../data/user/user.actions";
import { setShowOffline, setToastNotification } from "../../data/global/global.actions";
import { ToastNotification } from "../../models/ToastNotification";
import EditImage from "../Modals/EditImage";
import { UserSupervised } from "../../models/UserSupervised";

interface OwnProps {
  group: ContactGroup;
  onCancel: () => void;
  hist: any;
}
interface StateProps {
  appOnline: boolean;
  supervisedAccount: UserSupervised | null
}
interface DispatchProps {
  uploadGroupPicture: typeof uploadGroupPicture;
  setToastNotification: typeof setToastNotification;
  setShowOffline: typeof setShowOffline;
}
interface UpdateContactGroupPictureProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const UpdateContactGroupPicture: React.FC<UpdateContactGroupPictureProps> = ({
  group,
  appOnline,
  supervisedAccount,
  onCancel,
  uploadGroupPicture,
  setToastNotification,
  setShowOffline,
  hist,
}) => {
  const [fileFormat, setFileFormat] = useState("");
  const [fileData, setFileData] = useState("");
  const [showEditImage, setShowEditImage] = useState(false);

  const inputFile = React.createRef<HTMLInputElement>();
  /*
   * take picture : this function open the camera or the gallery and take/choose picture
   */
  async function takePicture(type: CameraSource) {
    if (type == CameraSource.Photos) {
      if (inputFile.current) inputFile.current.click();
    } else {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        source: CameraSource.Camera,
        resultType: CameraResultType.Base64,
        promptLabelHeader: "Sélectionner une photo",
        promptLabelCancel: "Annuler",
        promptLabelPhoto: "Depuis la galerie",
        promptLabelPicture: "Depuis l'appareil photo",
      });
      if (image.base64String) {
        setFileData(image.base64String);
        setFileFormat(image.format);
      }
    }
  }

  async function onSelectFile(e: any) {
    if (e.target.files[0] && e.target.files[0].type) {
      const file = e.target.files[0];
      const base64 = (await toBase64(file)) as string;
      if (base64) {
        const format = _.last(_.split(file.type, "/"));
        setFileData(base64.split(",")[1]);
        setFileFormat(format || "");
      }
    }
  }

  const validateHandler = () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    if (fileData && fileFormat) {
      uploadGroupPicture(group, fileData, fileFormat, supervisedAccount);
      setToastNotification({
        message: "La photo du groupe a bien été modifiée",
        color: "success",
      } as ToastNotification);
    }
  };

  return (
    <IonModal
      isOpen={true}
      cssClass="add-friend-modal"
      onDidDismiss={() => onCancel()}
    >
      <Header hideHome title="Choisir une photo" />
      <IonRow>
        <IonCol
          size="12"
          className="center-flex"
          style={{ flexDirection: "column" }}
        >
          <img
            style={!group.picture ? {padding: "10px"}: {}}
            className={"groupUserPicture"}
            src={
              fileData
                ? `data:image/${fileFormat};base64,${fileData}`
                : group.picture
                ? group.picture
                : "assets/icon/group-white.svg"
            }
            alt="avatar"
          />
        </IonCol>
      </IonRow>
      <input
        ref={inputFile}
        style={{ display: "none" }}
        type="file"
        id="file"
        multiple={false}
        onChange={onSelectFile}
      />
      <IonButton onClick={() => takePicture(CameraSource.Camera)}>
        <IonIcon
          icon={cameraOutline}
          style={{ marginRight: "10px" }}
          className="icon-size"
        />
        <b>Depuis l'appareil photo</b>{" "}
      </IonButton>
      <IonButton onClick={() => takePicture(CameraSource.Photos)}>
        <IonIcon
          icon={imagesOutline}
          style={{ marginRight: "10px" }}
          className="icon-size"
        />
        <b>Depuis la galerie</b>
      </IonButton>
      <IonButton
        onClick={() => setShowEditImage(true)}
      >
        <IonIcon
          icon={pencil}
          style={{ marginRight: "10px" }}
          className="icon-size"
        />
        <b>Modifier l'image</b>
      </IonButton>
      <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
        <BottomButtons onCancel={onCancel} onValidate={validateHandler} />
      </div>
      {showEditImage && (
        <EditImage
          successMessage="La photo du groupe a été modifiée"
          onCancel={() => setShowEditImage(false)}
          src={
            fileData
              ? `data:image/${fileFormat};base64,${fileData}`
              : group.picture
          }
          setImageFormat={setFileFormat}
          setImageData={setFileData}
        />
      )}
    </IonModal>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    uploadGroupPicture,
    setToastNotification,
    setShowOffline,
  },
  mapStateToProps: (state) => ({
    appOnline: state.global.appOnline,
    supervisedAccount: state.supervisor.supervisedAccount || null
  }),
  component: React.memo(UpdateContactGroupPicture),
});
