import { IonCol, IonContent, IonModal, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "../../data/connect";
import _ from "lodash";
import Header from "../Header";
import Button from "../Button";
import "../contacts/ContactGroup.scss";
import {
  download,
  ellipsisHorizontalCircleOutline,
  pencil,
  trash,
  arrowBackCircleOutline
} from "ionicons/icons";
import { SharedFile } from "../../models/SharedFile";
import Loading from "../Loading";
import EditImage from "../Modals/EditImage";
import { isVideoFile } from "../../util/commons";
import { updateAlbumFile } from "../../data/sharedFolder/sharedFolder.actions";
import { SharedFolder } from "../../models/SharedFolder";
import { UserSupervised } from "../../models/UserSupervised";

interface OwnProps {
  currentPicture: SharedFile | null;
  loadingDownloadFile: boolean;
  folder: SharedFolder;
  onDelete: () => void;
  showDetails: () => void;
  onDownload: (picture: SharedFile | undefined) => void;
  onCancel: () => void;
}

interface StateProps {
  supervisedAccount: UserSupervised | null;
}

interface DispatchProps {
  updateAlbumFile: typeof updateAlbumFile;
}
interface FileDetailsProps extends OwnProps, StateProps, DispatchProps {}

const FileDetails: React.FC<FileDetailsProps> = ({
  currentPicture,
  loadingDownloadFile,
  folder,
  supervisedAccount,
  onCancel,
  onDelete,
  showDetails,
  onDownload,
  updateAlbumFile,
}) => {
  const [showEditFile, setShowEditFile] = useState(false);
  const [fileFormat, setFileFormat] = useState("");
  const [fileData, setFileData] = useState("");

  useEffect(() => {
    return () => {
      setFileFormat("")
      setFileData("")
      setShowEditFile(false);
    };
  }, []);
  
  const isVideo = currentPicture
    ? isVideoFile(currentPicture.extension)
    : false;

  const saveCroppedFile = (data: string, foramt: string) => {
    if (data && foramt && currentPicture) {
      updateAlbumFile(folder, currentPicture, data, foramt, supervisedAccount);
    }
  };

  return (
    <IonModal isOpen={true} onDidDismiss={() => onCancel()}>
      <Header hideHome title="MENU" />
      <IonContent>
        <div className="actions-container">
          <Button
            color="primary"
            onClick={() => showDetails()}
            text="Détails"
            icon={ellipsisHorizontalCircleOutline}
          />
          <Button
            color="primary"
            onClick={() => currentPicture && onDownload(currentPicture)}
            text="Télécharger"
            icon={download}
          />
          {!isVideo && (
            <Button
              color="primary"
              onClick={() => setShowEditFile(true)}
              text="Modifier"
              icon={pencil}
            />
          )}
          <Button
            color="danger"
            onClick={() => onDelete()}
            text="Supprimer"
            icon={trash}
          />
        </div>
      </IonContent>
      <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
        <IonRow class="ion-no-padding">
          <IonCol size="12" class="ion-no-padding">
            <Button
              color="light"
              onClick={() => onCancel()}
              text="Retour"
              icon={arrowBackCircleOutline}
            />
          </IonCol>
        </IonRow>
      </div>
      {loadingDownloadFile && (
        <Loading enable={true} text={"Fichier en cours de téléchargement"} />
      )}
      {showEditFile && (
        <EditImage
          onCancel={() => setShowEditFile(false)}
          src={
            fileData
              ? `data:image/${fileFormat};base64,${fileData}`
              : currentPicture
              ? currentPicture.url
              : ""
          }
          setImageFormat={setFileFormat}
          setImageData={setFileData}
          onSave={saveCroppedFile}
        />
      )}
    </IonModal>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    updateAlbumFile,
  },
  mapStateToProps: (state) => ({
    supervisedAccount: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount: null
  }),
  component: React.memo(FileDetails),
});
