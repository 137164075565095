import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteComponentProps, Switch } from 'react-router-dom';

import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Menu from './components/Menu';
import Loading from './components/Loading';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import './theme/global.scss';
import { connect } from './data/connect';
import { AppContextProvider } from './data/AppContext';
import { setIsLoggedIn, setUsername, loadUserData, updateLastActivity, setAudioData, updateUserPushToken } from './data/user/user.actions';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Notification from './pages/Notification';
import DatabaseListenerManager from './pages/DatabaseListenerManager'
import MessageRecording from './pages/MessageRecording';
import VideoConference from './pages/VideoConference';
import MediaShared from './pages/MediaShared';
import SharedFolderDetails from './pages/SharedFolderDetails';
import ToastManager from './pages/ToastManager';
import Screensaver from './pages/Screensaver';
import CreateVisioCall from './pages/CreateVisioCall';
import AppStateManager from './pages/AppStateManager';
import firebase from './FirebaseConfig';
import { StaticContext } from 'react-router';
import * as selectors from './data/selectors';
import AudioPlayer from './components/AudioPlayer';
import { AudioData } from './models/AudioData';
import MailboxPage from './pages/MailboxPage';
import UpdateUsername from './pages/UpdateUsername';
import ResetPassword from './pages/ResetPassword';
import PasswordLost from './pages/PasswordLost';
import Logout from './pages/Logout';
import { UserConfiguration } from './models/User';
import EmergencyCommand from './pages/EmergencyCommand';
import { setAppIsActive, setAppOnline, setScreenSaveEnable, setShowOffline } from './data/global/global.actions';
import IdleTimerPage from './pages/IdleTimerPage';
import Contacts from './pages/Contacts';
import SavedMessages from './pages/SavedMessages';
import MissedCalls from './pages/MissedCalls';
import Offline from './components/Offline';
import AccountList from './pages/supervisorAccount/AccountList';
import Settings from './pages/settings/Settings';
import AccountSettings from './pages/settings/AccountSettings';
import ContactsSettings from './pages/settings/ContactsSettings';
import SoundSettings from './pages/settings/SoundSettings';
import AppearanceSettings from './pages/settings/AppearanceSettings';
import { SupervisorRelationship } from './models/SupervisorRelationship';
import { UserWithRelationship } from './models/UserWithRelationship';
import ProcessRequestModal from './pages/supervisorAccount/ProcessRequestModal';
import { processSupervisorRequest } from './data/supervisor/supervisor.actions';

import UpdateUserPicture from './pages/UpdateUserPicture';
import { Diagnostic } from '@ionic-native/diagnostic';
import { createCacheFolder } from './util/localStorage';
import { setUserActionFromPush, setVisioCallData } from './data/visioCall/visioCall.actions';
import VisioCall from './models/VisioCall';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { registerPlugin } from '@capacitor/core';

import { isPlatform } from '@ionic/core';
import SupervisorList from './pages/supervisorAccount/SupervisorList';
import CreateContactGroup from './pages/contacts/CreateContactGroup';
import ContactGroupList from './pages/contacts/ContactGroupList';
import ContactGroupDetails from './pages/contacts/ContactGroupDetails';
import sendMedia from './pages/sendMedia';
import { MediaShareRequest } from './models/MediaShareRequest';
import MediaShareRequestModal from './components/MediaShareRequestModal';
import Help from './pages/Help';
import InvitationToMLV from './pages/InvitationToMLV';
import { UserSupervised } from './models/UserSupervised';

const CustomNativePlugin = registerPlugin('CustomNativePlugin') as any;

const _App: React.FC = () => {
  return (
    <AppContextProvider>
      <IonicAppConnected />
    </AppContextProvider>
  );
};

interface OwnProps {

}


interface StateProps {
  currentUserName?: string;
  isAuthenticated: boolean;
  audioData: AudioData;
  /**Is true if user is in call OR there is a call incoming*/
  haveIncomingCall: boolean;
  /**Size of the texte (can be changed by user in account > option > police size)*/
  fontSizeName: string;
  showScreensave:boolean;

  loadingUserData: boolean;
  config: UserConfiguration;
  isHelpedProfil: boolean;
  loadingCall: boolean;
  hideEmergencyCommand: boolean;
  supervisorRequestWaiting: SupervisorRelationship | undefined;
  contacts: UserWithRelationship[];
  userIsActive: boolean;
  callData: VisioCall;
  mediaShareRequest: MediaShareRequest | undefined;
  appOnline: boolean;
  showOffline: boolean;
  supervisedAccount?: UserSupervised;
}

interface DispatchProps {
  loadUserData: typeof loadUserData;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  setScreenSaveEnable: typeof setScreenSaveEnable;
  updateLastActivity: typeof updateLastActivity;
  setAudioData: typeof setAudioData;
  processSupervisorRequest: typeof processSupervisorRequest;
  setUserActionFromPush: typeof setUserActionFromPush;
  setAppIsActive: typeof setAppIsActive;
  updateUserPushToken: typeof updateUserPushToken;
  setAppOnline: typeof setAppOnline;
  setShowOffline: typeof setShowOffline;
  setVisioCallData: typeof setVisioCallData;
}

/**
 * All data that can be passed by state when using routeComponent
 */
interface GlobalRouteState {

}

interface IonicAppProps extends RouteComponentProps<{}, StaticContext, GlobalRouteState>, OwnProps, StateProps, DispatchProps { }

const IonicApp: React.FC<IonicAppProps> = ({ supervisedAccount, currentUserName, callData, userIsActive, config, isAuthenticated, audioData, haveIncomingCall, fontSizeName, showScreensave, loadingUserData, isHelpedProfil, supervisorRequestWaiting, contacts, mediaShareRequest, appOnline, showOffline, 
  loadUserData, setAudioData, updateLastActivity, setScreenSaveEnable, processSupervisorRequest, setAppIsActive, setUserActionFromPush, updateUserPushToken, setAppOnline, setShowOffline, setVisioCallData }) => {

  const [fontSizeNameCurrentUser, setFontSizeNameCurrentUser] = useState(fontSizeName);

  useEffect(() => {
    if(fontSizeName){
      setFontSizeNameCurrentUser(fontSizeName);
    }
  }, [fontSizeName])

  useEffect(() => {
    if(currentUserName){
      
      if(isPlatform("mobile") )
      {
        console.log("AAAA send requestPushToken from IONIC with username " + currentUserName);
        try {
          // ask native app to send current user push token.
          // native reponse wil be catch in "set_push_token" event
          CustomNativePlugin.requestPushToken({userName: currentUserName});
        } catch (error:any) {
          console.log("AAAA Error custom function useEffect: " + error.message)
        }

        
        try {
          CustomNativePlugin.appReady();
        } catch (error:any) {
          console.log("AAAA Error custom function useEffect: " + error.message)
        }
      }
    }
  }, [currentUserName])

  

  useEffect(() => {
    loadUserData()


    if(isPlatform("mobile"))
    {
      // event when device lose internet connection
      CustomNativePlugin.addListener("network_changed", function(data:any){
        const { is_online } = data;
        setAppOnline(is_online);
        if(is_online){
          setShowOffline(false);
        }
      });

      // native app will send  current user push token for saving in database
      CustomNativePlugin.addListener("set_push_token", function(data:any){
        const { token, userName } = data;
        // console.log("AAAA set_push_token token received: " + token);
        console.log("AAAA set_push_token token ("+userName+") received: " + token);
        if(token && userName)
        {
          updateUserPushToken(userName, token);
        }
      }.bind(this));

      /*try {
        CustomNativePlugin.logConsoleMessage({ message: 'AAAA HELLO FROM IONIC' });
      } catch (error:any) {
        console.log("AAAA Error custom function: " + error.message)
      }*/

      // bind app state change for detecting if app is foreground (true) or background (false)
      App.addListener('appStateChange', ({ isActive }) => {
        setAppIsActive(isActive);
      });
    }else{
      document.addEventListener("visibilitychange", ()=>{
        if(document["hidden"]){
          setAppIsActive(false);
        }else{
          setAppIsActive(true);
        }
      }, false);
    }



    if(isPlatform("android") || isPlatform("ios")){
      Diagnostic.requestLocationAuthorization().then(()=>{
        Diagnostic.requestCameraAuthorization().then(()=>{
            Diagnostic.requestMicrophoneAuthorization();
          });
      });
    }else{
      const successCallback = () => {};
      const errorCallback = () => {};
      if(navigator.geolocation){
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      }
      
      navigator.mediaDevices.getUserMedia({video: true}).then(stream => {
        stream.getTracks().forEach( (track) => {
          track.stop();
          });
      });
      navigator.mediaDevices.getUserMedia({audio: true }).then(stream => {
        stream.getTracks().forEach( (track) => {
          track.stop();
          });
      });
    }
    createCacheFolder();

    if (Capacitor.isNativePlatform()) {
      App.addListener('backButton', () => {
        const have_stacks = window.history.length;
        have_stacks <= 1 ? App.exitApp()  : window.history.back();
        if(window.location.pathname === "/tabs/contacts"){
          //App.exitApp() 
          //TODO: afficher le menu quand on clique sur back sur la page contact
          //TODO: afficher la page contacte quand on clique sur back dans le menu
        }
      });
    }

    updateLastActivity();
  }, []);

  useEffect(() => {
    if (!appOnline && callData && currentUserName) {
      setShowOffline(true)
      setVisioCallData(undefined)
    }
  }, [callData, appOnline])

  const isMobile = isPlatform("mobile") && !isPlatform("tablet");

  return (
    <IonApp className={`${isHelpedProfil ? 'helped-profil' : ''} ${fontSizeNameCurrentUser}`}>
      <Loading enable={loadingUserData} text="" />
      {showOffline &&  <Offline/>}
      <IonReactRouter>

        {isAuthenticated && <Route component={Notification} />}
        {isAuthenticated && <Route component={DatabaseListenerManager} />}
        {/*isAuthenticated && !loadingCall && <Route component={IncomingFriendRequest} />*/}
        {!callData && isAuthenticated && !haveIncomingCall && showScreensave && isHelpedProfil && !isMobile && <Route component={Screensaver} />}

        {/*isAuthenticated && !haveIncomingCall && !hideEmergencyCommand && isHelpedProfil && appOnline && <Route component={EmergencyCommand} />*/}
        {/*isAuthenticated && <Route component={VoiceCommand}/>*/}
        <Route component={ToastManager} />
        {isAuthenticated && <Route component={AppStateManager} />}
        {/*manage showing or not screensaver*/}
        {isAuthenticated && !userIsActive && <Route component={IdleTimerPage}/>}

        <IonSplitPane contentId="main">
          {!supervisedAccount && <Menu />}
          <IonRouterOutlet id="main">

            {/*
                    We use IonRoute here to keep the tabs state intact,
                    which makes transitions between tabs and non tab pages smooth
                  */}
                  

            {(!showScreensave || haveIncomingCall) ?
              <Switch>
                {/*isAuthenticated && <Route component={TestComponent}/>*/}
                {!isAuthenticated ? <Redirect exact from="/tabs/contacts" to="/login" /> : null}



                <Route path="/tabs/contacts" exact={true} component={Contacts} />
                <Route path="/tabs/contacts/group/create" exact={true} component={CreateContactGroup} />

                <Route path="/tabs/media/shared" exact={true} component={MediaShared} />
                <Route path="/tabs/media/shared/:id" exact={true} component={SharedFolderDetails} />
                <Route path="/tabs/conference/video" exact={true} component={VideoConference} />

                {/* 2 urls for createVisioCall for active menu item feature */}
                <Route path="/tabs/mailbox/create" exact={true} component={CreateVisioCall} />
                <Route path="/tabs/contacts/create" exact={true} component={CreateVisioCall} />

                {/* 2 urls for MessageRecording for active menu item feature */}
                <Route path="/tabs/mailbox/message/create" exact={true} component={MessageRecording} />
                <Route path="/tabs/contacts/message/create" exact={true} component={MessageRecording} />

                <Route path="/tabs/send/media" exact={true} component={sendMedia} />

                <Route path="/tabs/mailbox" exact={true} component={MailboxPage} />
                <Route path="/tabs/mailbox/savedmessages" exact={true} component={SavedMessages} />
                <Route path="/tabs/mailbox/missedcalls" exact={true} component={MissedCalls} />

                <Route path="/settings" exact={true} component={Settings} />
                <Route path="/settings/account" exact={true} component={AccountSettings} />
                <Route path="/settings/contacts" exact={true} component={ContactsSettings} />
                <Route path="/settings/sound" exact={true} component={SoundSettings} />
                <Route path="/settings/appearance" exact={true} component={AppearanceSettings} />
                <Route path="/tabs/settings/contacts/groups" exact={true} component={ContactGroupList} />
                <Route path="/tabs/settings/contacts/groups/:id" exact={true} component={ContactGroupDetails} />
                <Route path="/tabs/settings/contacts/invitation" exact={true} component={InvitationToMLV} />

                <Route path="/login" exact={true} component={Login} />
                <Route path="/updatephoto" exact={true} component={UpdateUserPicture} />
                <Route path="/updateusername" exact={true} component={UpdateUsername} />
                <Route path="/resetpassword" exact={true} component={ResetPassword} />
                <Route path="/lostpassword" exact={true} component={PasswordLost} />
                <Route path="/signup" exact={true} component={Signup} />
                
                <Route path="/supervisor/list" exact={true} component={AccountList} />
                <Route path="/supervisorlist" exact={true} component={SupervisorList} />

                <Route path="/emergencycall" exact={true} component={EmergencyCommand} />

                <Route path="/tabs/help" exact={true} component={Help} />

                <Route path="/logout" exact={true} component={Logout} />


                {isAuthenticated ? <Redirect exact from="/" to="/tabs/contacts" /> : <Redirect exact from="/" to="/login" />}
                {/* <Redirect exact from="/tabs" to="/tabs/contacts" />*/}
              </Switch> :
              <Switch>
                {isAuthenticated ? <Redirect exact from="/" to="/tabs/contacts" /> : <Redirect exact from="/" to="/login" />}
              </Switch>
            }

          </IonRouterOutlet>
        </IonSplitPane>
        {/*Modal shown when an user send current user supervisor request. Force current user to accepte or refuse it*/}
        {supervisorRequestWaiting && <ProcessRequestModal contacts={contacts} relationship={supervisorRequestWaiting} processSupervisorRequest={processSupervisorRequest} />}
        {/*Modal shown when an user send current user media. Force current user to accepte or refuse it*/}
        {mediaShareRequest && <MediaShareRequestModal mediaShareRequest={mediaShareRequest} />}
      </IonReactRouter>
      <AudioPlayer type={audioData.type} 
                  play={audioData.play} 
                  settingSoundEnable={config.soundEnabled ? true : false}
                  setAudioData={setAudioData} />

      
    </IonApp>
  )


}

export default _App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    currentUserName: state.user.username,
    isAuthenticated: !!firebase.auth().currentUser, //state.user.isLoggedin,
    //callData: selectors.getVisioCall(state),
    haveIncomingCall: !!selectors.getVisioCallWaitingResponse(state) || !!selectors.getVisioCall(state),
    audioData: state.user.audioData,
    fontSizeName: selectors.getFontSizeConfigCurrentUser(state),
    config: selectors.getUserConfig(state),
    showScreensave: state.global.screenSaverEnable,
    // global loading for fetching user data (first connexion)
    loadingUserData: state.user.loadingData,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
    loadingCall: state.visioCall.loading,
    hideEmergencyCommand: state.global.hideEmergencyCommand,
    supervisorRequestWaiting: selectors.getSupervisorRequestWaiting(state),
    contacts: selectors.getUsers(state),
    userIsActive: state.global.userIsActive,
    callData: state.visioCall.roomData as VisioCall, //selectors.getVisioCall(state),
    mediaShareRequest: selectors.getMediaShareRequests(state),
    appOnline: state.global.appOnline,
    showOffline: state.global.showOffline,
    supervisedAccount: state.supervisor.supervisedAccount
  }),
  mapDispatchToProps: {
    loadUserData,
    setIsLoggedIn,
    setUsername,
    updateLastActivity,
    setScreenSaveEnable,
    setAudioData,
    processSupervisorRequest,
    setUserActionFromPush,
    setAppIsActive,
    updateUserPushToken,
    setAppOnline,
    setShowOffline,
    setVisioCallData
  },
  component: IonicApp
});


