import React, { useEffect, useRef, useState } from 'react';
import { IonPage } from '@ionic/react';
import { } from 'ionicons/icons';
import { connect } from '../data/connect';
import { SharedFolder } from '../models/SharedFolder';
import _ from 'lodash';
import './Screensaver.scss';
import { Location } from '../models/Location';
import WeatherWidget from '../components/WeatherWidget';
import AwesomeSlider from 'react-awesome-slider';
// @ts-ignore
import withAutoplay from 'react-awesome-slider/dist/autoplay';
// @ts-ignore
import AwesomeSliderStyles from 'react-awesome-slider/src/styled/fold-out-animation/index';

import 'react-awesome-slider/dist/styles.css';
import { UserWithRelationship } from '../models/UserWithRelationship';
import Clock from 'react-live-clock';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
// @ts-ignore
//import ReactWeather, { useOpenWeather } from 'react-open-weather';
import { validFormatsForSharedFolderVideo } from '../util/commons';
import * as selectors from '../data/selectors';
import { User, UserConfiguration } from '../models/User';
import { RouteComponentProps } from 'react-router';
import ScreensaverItem from '../components/ScreensaverItem';
import { Geolocation } from '@capacitor/geolocation';
dayjs.locale('fr');

const AutoplaySlider = withAutoplay(AwesomeSlider);
//declare var NavigationBar: any;
const defaultImages: string[] = [
  "/assets/img/screensaver/1.jpeg",
  "/assets/img/screensaver/2.jpg",
  "/assets/img/screensaver/3.jpg",
  "/assets/img/screensaver/4.jpeg",
  "/assets/img/screensaver/5.jpeg",
  "/assets/img/screensaver/6.jpg",
  "/assets/img/screensaver/7.jpeg",
  "/assets/img/screensaver/8.jpg",
  "/assets/img/screensaver/9.jpeg",
  "/assets/img/screensaver/10.png",
];
interface OwnProps { };

interface StateProps {
  folders: SharedFolder[],
  annuaire: UserWithRelationship[],
  currentUserMail?: string,
  /**Current user config */
  config: UserConfiguration;
  currentUser: User | undefined;
};

interface DispatchProps {
};

interface ScreensaverProps extends RouteComponentProps, OwnProps, StateProps, DispatchProps {

};

const Screensaver: React.FC<ScreensaverProps> = ({ folders, annuaire, currentUserMail, config, currentUser }) => {

  const sliderRef = useRef<typeof AutoplaySlider>(null);
  const intervalRef = useRef<any>(null);
  const [isEnable, setIsEnable] = useState(true);
  const [currentPosition, setCurrentPosition] = useState<Location>();
  const [currentCity, setCurrentCity] = useState<string>();

  useEffect(() => {
    const now = new Date();
    const start = new Date();
    if(config.timeoutEnableScreenSaverStart){
      start.setHours(config.timeoutEnableScreenSaverStart.hours);
      start.setMinutes(config.timeoutEnableScreenSaverStart.minutes);
    }else {
      start.setHours(6);
      start.setMinutes(0);
    }
    if(now.getHours() === start.getHours() && now.getMinutes() === start.getMinutes() && isEnable){
      const audio = new Audio(config.alarmClockRingtone ? "assets/rings/alarmClock/" + config.alarmClockRingtone: undefined);
      audio.volume = config.alarmClockVolume !== undefined ? config.alarmClockVolume / 100 : 0.8;
      audio.play();
    }
    
  }, [config.timeoutEnableScreenSaverStart, new Date()]);

  useEffect(() => {
    checkTime();
    intervalRef.current = setInterval(() => {
      checkTime();
    }, 5000);

    Geolocation.getCurrentPosition().then((position) => {
      fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + position.coords.latitude + "," + position.coords.longitude + "&key=AIzaSyBKsEmaGqc2RTysC2HUwuCAO9WrxKWhUbE")
        .then(res => res.json())
        .then((result) => {
          if (result.results && result.results.length > 0) {

            const firstResult = result.results[0];
            const city = firstResult.address_components.find((row: any) => _.some(row.types, (type) => type === "locality"))

            if (city && city.short_name) {
              setCurrentCity(city.short_name);
              setCurrentPosition({
                lat: position.coords.latitude,
                lng: position.coords.longitude
              } as Location);
            }
          }
        })
    })

    return () => {
      clearInterval(intervalRef.current)
    };
  }, []);



  /**
   * screensaver is enable the day and disable the night (can be edited in 'account' > 'options' section), check current time and disable screensaver if it's the night
   */
  function checkTime() {

    const now = new Date()
    const start = new Date();
    const end = new Date();

    if (config.timeoutEnableScreenSaverStart && config.timeoutEnableScreenSaverEnd) {
      start.setHours(config.timeoutEnableScreenSaverStart.hours);
      start.setMinutes(config.timeoutEnableScreenSaverStart.minutes);
      end.setHours(config.timeoutEnableScreenSaverEnd.hours);
      end.setMinutes(config.timeoutEnableScreenSaverEnd.minutes);
    }
    else {
      start.setHours(6);
      start.setMinutes(0);
      end.setHours(23);
      end.setMinutes(0);
    }
    setIsEnable(now >= start && now < end)
  }

  const screensaverExcludedFiles =
    currentUser && currentUser.screensaverExcludedFiles
      ? currentUser.screensaverExcludedFiles
      : [];
  const allFilesOrdered = _.orderBy(
    _.flatten(
      folders.map((folder) =>
        folder.files.filter(
          (file) => screensaverExcludedFiles.indexOf(file.id) === -1
        )
      )
    ),
    ["dateCreate"],
    ["desc"]
  );
  let filesFolderName : { [key: string]: string } = {};
  folders.forEach((folder) => {
    folder.files.forEach((file) => {filesFolderName[file.id] = folder.name;});
    return filesFolderName;
  });

  const clockComponent =  <div className="clock caption">
                            <div className="time"><Clock format={'HH:mm'} ticking={true} timezone={'Europe/Paris'} /></div>
                            <div className="date">{new Date().toLocaleDateString(undefined, { weekday: 'long', day: 'numeric' })}</div>
                            <div className="date">{new Date().toLocaleDateString(undefined, { month: 'long' })}</div>
                          </div>;


  return (
    <IonPage id="screensaver">
      {isEnable && clockComponent}
      {isEnable && currentPosition && <div className="weather caption">
        {currentPosition && currentCity && <WeatherWidget location={currentPosition} city={currentCity} />}
        </div>}


      <div className="blackscreen ">

      {!isEnable && (
          <div>
            {clockComponent}
            <img
              className="image-disable"
              src="/assets/img/screensaver-moon.jpg"
              alt="moon"
            />
          </div>
        )}

        {isEnable && <AutoplaySlider

          ref={sliderRef}
          play={true}
          fillParent={true}
          cssModule={AwesomeSliderStyles}
          bullets={false}
          organicArrows={false}
          interval={10000}>
          {allFilesOrdered.length > 0 ? allFilesOrdered.map((file) => {
            const creator = _.find(annuaire, (user) => user.email === file.uploadBy);

            let creatorName = creator ? creator.firstName : null;
            if (currentUserMail && currentUserMail === file.uploadBy) creatorName = "moi";

            const isVideo = _.some(validFormatsForSharedFolderVideo, (f) => f === file.extension);

            //awssld__content 
            return(
              <div key={file.id} className="awssld__content">
                <ScreensaverItem
                  isVideo={isVideo}
                  file={file}
                  creatorName={creatorName}
                  albumName={
                    filesFolderName[file.id] ? filesFolderName[file.id].toUpperCase() : ""
                  }
                  isDefault={false}
                />
              </div>
            )
          }) :
            allFilesOrdered.length === 0 && defaultImages.map((file) => {
              return(
                <div key={file} className="awssld__content">
                  <ScreensaverItem
                    srcFile={file}
                    isDefault={true}
                  />
                </div>
              )
            })}
        </AutoplaySlider>}
      </div>
    </IonPage>
  );

};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
  },
  mapStateToProps: (state) => ({
    folders: state.sharedFolder.folders ? state.sharedFolder.folders : [],
    annuaire: state.user.annuaire ? state.user.annuaire : [],
    currentUserMail: state.user.username ? state.user.username : undefined,
    config: selectors.getUserConfig(state),
    currentUser: state.user.userFirestore,
  }),
  component: React.memo(Screensaver)
});