import { AudioData } from "../../models/AudioData";
import { UserActions } from "./user.actions";
import { UserState } from "./user.state";

export function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case "set-user-loading-load-data":
      return { ...state, loadingData: action.isLoading };
    case "set-user-loading-annuaire":
      return { ...state, loadingAnnuaire: action.isLoading };
    case "set-user-loading-search":
      return { ...state, loadingSearch: action.isLoading };
    case "set-annuaire-users-data":
      return { ...state, annuaire: action.data };
    case "set-user-data":
      return { ...state, ...action.data };
    case "set-current-user-data":
      return { ...state, userFirestore: action.user };
    case "set-username":
      return { ...state, username: action.username };
    case "set-has-seen-tutorial":
      return { ...state, hasSeenTutorial: action.hasSeenTutorial };
    case "set-dark-mode":
      return { ...state, darkMode: action.darkMode };
    case "set-is-loggedin":
      return { ...state, isLoggedin: action.loggedIn };
    case "set-user-friend-request-pending":
      return { ...state, requestPending: action.data };
    case "set-user-search-filtered":
      return { ...state, searchUsersFiltered: action.users };
    case "set-user-relations-data":
      return { ...state, allUserRelations: action.data };
    case "set-user-mailbox":
      return { ...state, mailbox: action.mailbox };
    case "set-user-config":
      if (state.userFirestore) state.userFirestore.config = action.config;
      return { ...state };
    case "set-audio-data":
      return {
        ...state,
        audioData: {
          type: action.audioType,
          play: action.audioPlay,
        } as AudioData,
      };
    case "set-user-groups":
      return { ...state, groups: action.groups };
  }
}
