import React from 'react';
import {IonModal, IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonCol, IonRow } from '@ionic/react';
import './ProcessRequestModal.scss'
import { UserWithRelationship } from '../../models/UserWithRelationship';
import Button from '../../components/Button';
import { IconSvgEnum } from '../../components/IconSvg';
import UserItem, { userCardType } from '../../components/UserItem';
import { processSupervisorRequest } from '../../data/supervisor/supervisor.actions';
import { SupervisorRelationship } from '../../models/SupervisorRelationship';
import _ from 'lodash';
import { setShowOffline } from '../../data/global/global.actions';
import { connect } from '../../data/connect';
import { sendSms } from '../../api/sms';
import { checkIsValidPhone } from '../../util/checkIsPhoneFormat';
import { User } from '../../models/User';
import { _UrlMLV } from '../../appConfig';
import { getUser } from '../../api/user';
import { validateEmail } from "../../util/email.validation";
import { sendMailRequest } from "../../api/mail";
import { MailType } from '../../models/Mail';
interface OwnProps {
    relationship: SupervisorRelationship;
    contacts: UserWithRelationship[];
    processSupervisorRequest: typeof processSupervisorRequest;
  }
  
  interface StateProps {
    appOnline: boolean;
    currentUser?: User;
  }
  
  interface DispatchProps {
    setShowOffline: typeof setShowOffline;
  }
  
  interface ProcessRequestModalProps extends OwnProps, StateProps, DispatchProps { }

const ProcessRequestModal: React.FC<ProcessRequestModalProps> = ({ currentUser, appOnline, relationship, contacts, processSupervisorRequest, setShowOffline }) => {


    async function validateRequest(accept: boolean) {
        if(!appOnline){
            setShowOffline(true)
            return;
          }
        try {
            await processSupervisorRequest(relationship.administered, relationship.administrator, accept);
            if(accept && checkIsValidPhone(relationship.administrator) && currentUser){
              const administrator = await getUser(relationship.administrator);
              const message = `Bonjour ${administrator ? administrator.firstName: ""}, ${currentUser.firstName} ${currentUser.lastName} a accepté votre demande de supervision Mon Lien Visio. Pour accéder à la supervision de votre contact, veuillez cliquer sur le lien  ${_UrlMLV+"supervisor/list"}`;
              sendSms(relationship.administrator, message)
            }
            if (accept && currentUser && validateEmail(relationship.administrator)) {
              let message = '';
              sendMailRequest(currentUser.email, MailType.AcceptSupervision, message, MailType.AcceptSupervision, relationship.administrator)
            }
        } catch (e) {
          alert("Exception ProcessRequestModal>validateRequest(): " + JSON.stringify(e));
        }
      }


    const administrator = contacts.find(m => m.email == relationship.administrator);



    return <IonModal isOpen={true} cssClass='add-user-modal  modal3'>
        <IonPage> 
            <IonHeader translucent={true}>
                <IonToolbar>
                    <IonTitle style={{padding: "20px"}}>Demande de supervision</IonTitle>
                </IonToolbar>
            </IonHeader>
                <IonContent>
                    <div className="" style={{marginTop:"30px", textAlign:"center"}}>
                        <b>{administrator && `Voulez-vous autoriser ${administrator.firstName} à superviser votre compte ?`}</b>
                    </div>
                    <IonRow>
                        <IonCol 
                        size-lg="4" 
                        offsetLg="4"
                        size-md="6" 
                        offsetMd="3"
                        size-sm="12"> {administrator && <UserItem user={administrator} disableCallOnClick={true} type={userCardType.SupervisorUserList}/>}</IonCol>
                    </IonRow>

                </IonContent>
        <div slot="fixed" style={{ width:'100%', bottom: "0"}}>
            <IonRow>
                <IonCol><Button color="danger"  onClick={() => validateRequest(false) } text="Refuser" svg={IconSvgEnum.cancel_white}/></IonCol>
                <IonCol><Button color="success"  onClick={() => validateRequest(true) } text="Accepter" svg={IconSvgEnum.ok_white}/></IonCol>
            </IonRow>
        </div>
        </IonPage>
    </IonModal>;
};

export default connect<OwnProps, {}, DispatchProps>({
    mapStateToProps: (state) => ({
      appOnline: state.global.appOnline,
      currentUser: state.user.userFirestore
    }),
    mapDispatchToProps: {
      setShowOffline
    },
    component: ProcessRequestModal,
  });