import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "../data/connect";
import * as selectors from "../data/selectors";
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonIcon,
  IonSkeletonText,
  IonLabel,
  IonItem
} from "@ionic/react";
import { UserWithRelationship } from "../models/UserWithRelationship";
import { SharedFolder } from "../models/SharedFolder";
import _ from "lodash";
import "./FolderItem.scss";
import { Comment } from "../models/Comment";
import { validFormatsForSharedFolderVideo } from "../util/commons";
import VideoPlayer from "./VideoPlayer";
import { sortAlphanumeric } from "../util/functions";
import { User } from "../models/User";
import { readFileFromCache } from "../util/localStorage";
import { chevronUp,chevronDown } from 'ionicons/icons';
import { UserSupervised } from "../models/UserSupervised";
import { loadAndSetUserSupervised } from "../data/supervisor/supervisor.actions";
import { updateUserRequest } from "../api/user";
import { setShowOffline } from "../data/global/global.actions";

interface StateProps {
  currentUserEmail?: string;
  users: UserWithRelationship[];
  currentUser: User | undefined;
  isHelpedProfil: boolean;
  supervisedAccount : UserSupervised | null;
  appOnline: boolean;
}

interface OwnProps {
  folder: SharedFolder;
  firstName: string;
  lastName: string;
}

interface DispatchProps {
  loadAndSetUserSupervised: typeof loadAndSetUserSupervised;
  setShowOffline: typeof setShowOffline;
}

interface FolderItemProps
  extends RouteComponentProps,
    DispatchProps,
    OwnProps,
    StateProps {}

const FolderItem: React.FC<FolderItemProps> = ({
  currentUserEmail,
  users,
  firstName,
  lastName,
  folder,
  history,
  currentUser,
  isHelpedProfil,
  supervisedAccount,
  appOnline,
  loadAndSetUserSupervised,
  setShowOffline
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isScreenSaverFolder, setIsScreenSaverFolder] = useState(true)
  const countNewFiles = selectors.getNewSharedFileCountByFolder(
    folder,
    currentUserEmail ? currentUserEmail : ""
  );
  const sharedWith = _.filter(
    folder.users
      .filter((user) => user !== folder.creator)
      .map((mail) => {
        const userInfos = _.find(users, (u) => u.email == mail);
        if (userInfos) return userInfos.firstName + " " + userInfos.lastName[0];
        else return null;
      }),
    (u) => u != null
  );
  if (folder.creator !== currentUserEmail) {
    sharedWith.push(firstName + " " + lastName);
  }
  sharedWith.sort(sortAlphanumeric);

  let picture = "assets/img/default-album.jpg";
  let videoExtension = undefined;
  let isVideo = false;
  if (folder.files.length > 0) {
    picture = folder.files[0].url;
    isVideo = _.some(
      validFormatsForSharedFolderVideo,
      (f) => f === folder.files[0].extension
    ); // folder.files[0].extension == "mp4";
    videoExtension = folder.files[0].extension;
  }

  const [src, setSrc] = useState("");

  useEffect(() => {
    readFileFromCache(picture, setSrc);
  }, [picture]);

  let totalCommentsNotRead = 0;
  folder.files.forEach((file) => {
    if (file.comments) {
      const commentsNotRead = _.filter(
        file.comments,
        (comment: Comment) =>
          comment.senderEmail !== currentUserEmail &&
          !_.some(comment.viewBy, (viewBy) => viewBy === currentUserEmail)
      );
      if (commentsNotRead.length > 0)
        totalCommentsNotRead += commentsNotRead.length;
      //file.comments.forEach((comment) => comments.push(comment));
    }
  });

  useEffect(() => {
    folder.files.forEach((file) => {
      if (
        currentUser &&
        currentUser.screensaverExcludedFiles &&
        currentUser.screensaverExcludedFiles.indexOf(file.id) !== -1
      ) {
        setIsScreenSaverFolder(false);
      }else{
        setIsScreenSaverFolder(true);
      }
    });
  }, [folder, currentUser])

  const toggleToScreenSaver = async (e: any) => {
    e.stopPropagation();
    if(!appOnline){
      setShowOffline(true)
      return;
    } 
    if (currentUser) {
      currentUser.screensaverExcludedFiles =
        currentUser.screensaverExcludedFiles
          ? currentUser.screensaverExcludedFiles
          : [];
      folder.files.forEach((file) => {
        if (isScreenSaverFolder) {
          if (
            currentUser.screensaverExcludedFiles &&
            currentUser.screensaverExcludedFiles.indexOf(file.id) === -1
          ) {
            currentUser.screensaverExcludedFiles.push(file.id);
          }
        } else {
          if (currentUser.screensaverExcludedFiles) {
            const updatedList = currentUser.screensaverExcludedFiles.filter(
              (id) => file.id !== id
            );
            currentUser.screensaverExcludedFiles = updatedList;
          }
        }
      });
      setIsScreenSaverFolder((current) => !current);
      await updateUserRequest(currentUser, supervisedAccount);
      currentUserEmail && supervisedAccount && loadAndSetUserSupervised(currentUserEmail);
    }
  };
  const collapse=function () {
setCollapsed(!collapsed)
  }

  return (
    <>
      <IonCard
        className="folder-card border-green"
        style={{ marginTop: "30px" }}
      >
        <IonCardHeader
          onClick={() => history.push("/tabs/media/shared/" + folder.id)}
          style={{
            height: "300px",
            padding: "0",
          }}
        >
          <div className={"card-header-folder"}>
            <div className="card-header-folder-content">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  padding: "8px 0",
                }}
              >
                <IonLabel className={"title-header"}>{folder.name}</IonLabel>
                {(isHelpedProfil || supervisedAccount) && (
                  <IonIcon
                    className="heart-icon flex-end"
                    icon={
                      isScreenSaverFolder
                        ? "assets/icon/heart-black.svg"
                        : "assets/icon/heart.svg"
                    }
                    slot="end"
                    onClick={(e) => toggleToScreenSaver(e)}
                  />
                )}
              </div>
              {isHelpedProfil && (
                <span className="flex-end subtitle-size">cadre numérique</span>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              {countNewFiles > 0 && (
                <span className="subtitle-size new-badge">
                  {countNewFiles > 1 ? "Nouveaux fichiers" : "Nouveau fichier"}
                </span>
              )}
              {totalCommentsNotRead > 0 && (
                <span className="subtitle-size new-badge" color={"danger"}>
                  {totalCommentsNotRead > 1
                    ? "Nouveaux commentaires"
                    : "Nouveau commentaire"}
                </span>
              )}
            </div>
          </div>
          {!isVideo && (
            <div className="render-video">
              {src && (
                <img
                  src={src}
                  style={{
                    objectFit: "contain",
                    height: "300px",
                    width: "100%",
                    backgroundColor: "rgb(0 0 0 / 5%)",
                  }}
                />
              )}
              {src === "" && <IonSkeletonText animated />}
            </div>
          )}
          {isVideo && (
            <div className="render-video">
              {src && (
                <VideoPlayer
                  classname="image-gallery-image"
                  url={src}
                  height={"100%"}
                  type={"video/" + videoExtension}
                  play={true}
                  loop={true}
                  muted={true}
                  controls={false}
                />
              )}
              {src === "" && <IonSkeletonText animated />}
            </div>
          )}
        </IonCardHeader>
        <IonCardContent>
          <div  onClick={() => history.push("/tabs/media/shared/" + folder.id)}>
            {folder.creatorName ? (
              <span>
                <b>{"Album de "}{folder.creatorName}</b>
              </span>

            ) : null}{" "}


          </div>
          {sharedWith.length !== 0 && <IonItem className='ion-text-center' onClick={collapse}>


            <p  style={{marginLeft:'auto',marginRight:'auto'}}>Voir plus</p>

            {collapsed?<IonIcon  size="large" slot='end' icon={chevronUp}></IonIcon>:<IonIcon   size="large" slot='end' icon={chevronDown}></IonIcon>}

          </IonItem>}
          {collapsed?(  <IonItem >
                  <span style={{ textTransform: "capitalize" }}>
              {_.join(sharedWith, ", ")}
            </span>
          </IonItem>) : null}
        </IonCardContent>
      </IonCard>
    </>
  );
};

// export default FolderItem;
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    loadAndSetUserSupervised,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    supervisedAccount: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount: null,
    currentUserEmail: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username,
    users: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.annuaire : state.user.annuaire ? state.user.annuaire : [],
    currentUser: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
    appOnline: state.global.appOnline
  }),
  component: withRouter(FolderItem),
});
