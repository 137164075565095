import React, { useEffect, useState } from "react";
import { IonPage, IonContent } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { connect } from "../../data/connect";
import "./ContactGroupDetails.scss";
import _ from "lodash";
import Header from "../../components/Header";
import { ContactGroup } from "../../models/ContactGroup";
import {
  createOutline,
  ellipsisHorizontalCircleOutline,
  exitOutline,
  personAddOutline,
  trashOutline,
} from "ionicons/icons";
import Button from "../../components/Button";
import DeleteContactGroup from "../../components/contacts/DeleteContactGroup";
import UpdateContactGroupName from "../../components/contacts/UpdateContactGroupName";
import UpdateContactGroupMembers from "../../components/contacts/UpdateContactGroupMembers";
import ShowContactGroupDetails from "../../components/contacts/ShowContactGroupDetails";
import QuitContactGroup from "../../components/contacts/QuitContactGroup";
import { IconSvgEnum } from "../../components/IconSvg";
import UpdateContactGroupPicture from "../../components/contacts/UpdateContactGroupPicture";
import { arrowBackCircleOutline } from 'ionicons/icons';

interface OwnProps {}

interface StateProps {
  groups: ContactGroup[];
  currentUserEmail: string;
}

interface DispatchProps {}

interface ContactGroupDetailsProps
  extends RouteComponentProps<{ id: string }>,
    OwnProps,
    StateProps,
    DispatchProps {}

const ContactGroupDetails: React.FC<ContactGroupDetailsProps> = ({
  match,
  groups,
  currentUserEmail,
  history,
}) => {
  useEffect(() => {
    return () => {
      setShowDeleteContactGroup(false);
      setShowUpdateContactGroupName(false);
      setShowUpdateContactGroupMembers(false);
    };
  }, []);
  const group = _.find(groups, (group) => group.id == match.params.id);
  const [showDeleteContactGroup, setShowDeleteContactGroup] = useState(false);
  const [showUpdateContactGroupName, setShowUpdateContactGroupName] =
    useState(false);
  const [showUpdateContactGroupMembers, setShowUpdateContactGroupMembers] =
    useState(false);
  const [showQuitGroup, setShowQuitGroup] = useState(false);
  const [showUpdateContactGroupPhoto, setShowUpdateContactGroupPhoto] =
    useState(false);
  const [showContactGroupDetails, setShowDetailsContactGroup] = useState(false);

  const isCreator = group && currentUserEmail === group.creator;

  return (
    <IonPage>
      <Header title={group ? group.name : ""} showBack hideHome />
      <IonContent fullscreen={true}>
        <div className="actions-container">
          <Button
            color="primary"
            onClick={() => setShowDetailsContactGroup(true)}
            text="Détails du groupe"
            icon={ellipsisHorizontalCircleOutline}
          />
          {isCreator && (
            <Button
              color="primary"
              onClick={() => setShowUpdateContactGroupName(true)}
              text="Modifier le nom"
              icon={createOutline}
            />
          )}
          {isCreator && (
            <Button
              color="primary"
              onClick={() => setShowUpdateContactGroupPhoto(true)}
              text="Modifier la photo"
              icon={"assets/icon/edit-image.svg"}
            />
          )}
          {isCreator && (
            <Button
              color="primary"
              onClick={() => setShowUpdateContactGroupMembers(true)}
              text="Modifier les membres"
              icon={personAddOutline}
            />
          )}
          {isCreator && (
            <Button
              color="danger"
              onClick={() => setShowDeleteContactGroup(true)}
              text="Supprimer le groupe"
              icon={trashOutline}
            />
          )}
          {!isCreator && (
            <Button
              color="danger"
              onClick={() => setShowQuitGroup(true)}
              text="Quitter le groupe"
              icon={exitOutline}
            />
          )}
          <Button
            color="light"
            onClick={() => history.push("/tabs/settings/contacts/groups")}
            text="Retour"
            icon={arrowBackCircleOutline}
          />
        </div>
        {showDeleteContactGroup && group && (
          <DeleteContactGroup
            group={group}
            hist={history}
            onCancel={() => setShowDeleteContactGroup(false)}
          />
        )}
        {showUpdateContactGroupName && group && (
          <UpdateContactGroupName
            group={group}
            hist={history}
            onCancel={() => setShowUpdateContactGroupName(false)}
          />
        )}
        {showUpdateContactGroupMembers && group && (
          <UpdateContactGroupMembers
            group={group}
            hist={history}
            onCancel={() => setShowUpdateContactGroupMembers(false)}
          />
        )}
        {showContactGroupDetails && group && (
          <ShowContactGroupDetails
            group={group}
            hist={history}
            onCancel={() => setShowDetailsContactGroup(false)}
          />
        )}
        {showQuitGroup && group && (
          <QuitContactGroup
            group={group}
            hist={history}
            onCancel={() => setShowQuitGroup(false)}
          />
        )}
        {showUpdateContactGroupPhoto && group && (
          <UpdateContactGroupPicture
            group={group}
            hist={history}
            onCancel={() => setShowUpdateContactGroupPhoto(false)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {},
  mapStateToProps: (state) => ({
    groups: state.supervisor.supervisedAccount
    ? state.supervisor.supervisedAccount.contactGroups
    : state.user.groups || [],
    currentUserEmail: state.supervisor.supervisedAccount
    ? state.supervisor.supervisedAccount.user.email
    : state.user.username
    ? state.user.username
    : "",
  }),

  component: React.memo(ContactGroupDetails),
});
