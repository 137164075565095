import { IonCol, IonHeader, IonModal, IonRow, IonSkeletonText, IonToolbar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { SharedFile } from "../../models/SharedFile";
import Button from "../Button";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { isVideoFile } from "../../util/commons";
import VideoPlayer from "../VideoPlayer";
import _ from "lodash";
import { readFileFromCache } from "../../util/localStorage";
import { Comment } from "../../models/Comment";
import { readText } from "../../util/textToSpeech";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";

interface OwnProps {
  openPicture: (open: boolean) => void;
  currentPicture: SharedFile | undefined;
  extension: string;
}
interface StateProps {
  isHelpedProfil: boolean;
}
interface DispatchProps { }
interface ShowPictureProps extends OwnProps, StateProps, DispatchProps { }

const ShowPicture: React.FC<ShowPictureProps> = ({
  extension,
  currentPicture,
  isHelpedProfil,
  openPicture,
}) => {
  const isVideo = isVideoFile(extension);
  let src = "";
  let comments: Comment[] = [];
  if (currentPicture) {
    src = currentPicture.url;
    comments = currentPicture.comments;
  }
  const showListenToComments = comments.length > 0 && isHelpedProfil;

  const [srcFile, setSrcFile] = useState("");
  const [listen, setListen] = useState(true);

  useEffect(() => {
    readFileFromCache(src, setSrcFile);
  }, [src]);

  useEffect(() => {
    return () => {
      readText("");
    };
  }, []);

  const onFinish = () => {
    setListen(true);
  }

  const listenToComments = async (comments: Comment[]) => {

    if (listen) {
      for (let i = 0; i < comments.length; i++) {
        const comment = comments[i];
        if (comment.type !== "audio") {
          if (i === comments.length - 1) {
            await readText(comment.text, onFinish);
          } else {
            await readText(comment.text);
          }
        }
      }
      setListen(false);
    } else {
      readText("");
      setListen(true);
    }
  };

  return (
    <TransformWrapper>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <IonModal
          isOpen={true}
          cssClass="add-friend-modal"
          onDidDismiss={() => openPicture}
        >
          <IonHeader class="ion-no-border">
            <IonToolbar>
              <IonRow class="ion-no-padding" style={{ width: "100%" }}>
                {showListenToComments && (
                  <IonCol class="ion-no-padding" size="4">
                    <Button
                      color="light"
                      text="Écouter"
                      icon={"assets/icon/ear.svg"}
                      onClick={() => { listenToComments(comments) }}
                    />
                  </IonCol>
                )}
                <IonCol
                  class="ion-no-padding"
                  size={showListenToComments ? "4" : "6"}
                >
                  <Button
                    color="light"
                    text="RÉDUIRE"
                    icon={"assets/icon/zoom-out.svg"}
                    onClick={() => zoomOut()}
                  />
                </IonCol>
                <IonCol
                  class="ion-no-padding"
                  size={showListenToComments ? "4" : "6"}
                >
                  <Button
                    color="light"
                    text="AGRANDIR"
                    icon={"assets/icon/zoom-in.svg"}
                    onClick={() => zoomIn()}
                  />
                </IonCol>
              </IonRow>
            </IonToolbar>
          </IonHeader>
          <TransformComponent
            contentStyle={{
              width: "100%",
              height: "100%",
              backgroundImage: !isVideo
                ? "url(" + srcFile + ")"
                : "",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat"
            }}
            wrapperStyle={{ width: "100%", height: "100%", margin: 0 }}
          >
            {isVideo && (
              <div className="render-video">
                {srcFile && <VideoPlayer
                  classname="image-gallery-image"
                  url={srcFile}
                  width={"100%"}
                  type={"video/" + extension}
                  play={true}
                  loop={false}
                  muted={false}
                  controls={true}
                />}
                {srcFile === "" && <IonSkeletonText animated />}
              </div>
            )}
          </TransformComponent>

          <IonRow class="ion-no-padding" style={{ width: "100%" }}>
            <IonCol class="ion-no-padding" size="12">
              <Button
                color="light"
                text="FERMER"
                icon={"assets/icon/cancel.svg"}
                onClick={() => {
                  readText("");
                  openPicture(false);
                }}
              />
            </IonCol>
          </IonRow>
        </IonModal>
      )}
    </TransformWrapper>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {},
  mapStateToProps: (state) => ({
    isHelpedProfil: selectors.getIsHelpedProfil(state),
  }),
  component: React.memo(ShowPicture),
});
