import { IonAvatar, IonChip, IonIcon, IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { ContactGroup } from "../models/ContactGroup";
import { User } from "../models/User";
import { readFileFromCache } from "../util/localStorage";

interface ContactChipProps {
  image: string;
  label: string;
  contact: User | ContactGroup;
  removeContact?: null | ((user: User | ContactGroup) => void);
}

const ContactChip: React.FC<ContactChipProps> = ({
  image,
  label,
  contact,
  removeContact,
}) => {
  const [src, setSrc] = useState("");

  useEffect(() => {
    readFileFromCache(image, setSrc);
  }, [image]);

  return (
    <IonChip
      style={{ maxWidth: "100%", height: "max-content" }}
      onClick={() => (removeContact ? removeContact(contact) : {})}
    >
      <IonAvatar style={{ minWidth: "24px" }}>
        <img src={src} />
      </IonAvatar>
      <IonLabel>{label}</IonLabel>
      {removeContact && (
        <IonIcon
          icon={"assets/icon/close.svg"}
          style={{ minWidth: "25px", minHeight: "25px" }}
        ></IonIcon>
      )}
    </IonChip>
  );
};

export default ContactChip;
