import React from "react";
import { IonPage, IonRow, IonCol, IonGrid } from "@ionic/react";
import { invertModeOutline, notifications, people, person } from "ionicons/icons";
import { connect } from "../../data/connect";
import _ from "lodash";
import Header from "../../components/Header";
import MenuItem from "../../components/MenuItem";
import { UserSupervised } from "../../models/UserSupervised";

interface OwnProps {}
interface StateProps {
  supervisedAccount?: UserSupervised;
}
interface DispatchProps {}
interface SettingsProps extends OwnProps, StateProps, DispatchProps {}

const Settings: React.FC<SettingsProps> = ({ supervisedAccount }) => {
  const routes = [
    {
      id: 0,
      title: "Compte et profil",
      path: "/settings/account",
      icon: person,
      color: "#D43974",
      isSetting: true,
    },
    {
      id: 1,
      title: "Contacts",
      path: "/settings/contacts",
      icon: people,
      color: "#D43974",
      isSetting: true,
    },
    {
      id: 2,
      title: "Sonneries",
      path: "/settings/sound",
      icon: notifications,
      color: "#D43974",
      isSetting: true,
    },
    {
      id: 3,
      title: "Accessibilité",
      path: "/settings/appearance",
      icon: invertModeOutline,
      color: "#D43974",
      isSetting: true,
    },
  ];

  return (
    <IonPage>
      <Header title="Réglages" showHelp/>
      <div style={{ flexGrow: 1, overflow: "auto" }}>
        <IonGrid fixed style={{height: "90%"}}>
          <IonRow style={{height: "100%"}}>
            {routes
              .filter((route) => !!route.path)
              .map((route) => (
                <IonCol
                  key={route.title}
                  size="12"
                  size-lg="6"
                  size-md="6"
                  size-sm="6"
                  size-xs="6"
                  auto-hide="false"
                >
                  <MenuItem route={route} />
                </IonCol>
              ))}
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {},
  mapStateToProps: (state) => ({
    supervisedAccount: state.supervisor.supervisedAccount,
  }),
  component: React.memo(Settings),
});
