import { VisioCallActions } from "./visioCall.actions";
import { VisioCallState } from "./visioCall.state";

export function visioCallReducer(
  state: VisioCallState,
  action: VisioCallActions
): VisioCallState {
  switch (action.type) {
    case "set-visioCall-loading":
      return { ...state, loading: action.isLoading };
    case "set-visioCall-setVisioCallData":
      return { ...state, roomData: action.visio };
    case "set-visioCall-setUserActionFromPush":
      return { ...state, userActionFromPush: action.response };
  }
}
