import React, { useState } from "react";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import _ from "lodash";
import Header from "../../components/Header";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import SelectMultipleUsers from "../../components/SelectMultipleUsers";
import { UserWithRelationship } from "../../models/UserWithRelationship";
import classes from "./CreateContactGroup.module.scss";
import ChoosePictureModal from "../../components/Modals/ChoosePictureModal";
import { RouteComponentProps } from "react-router";
import BottomButtons from "../../components/BottomButtons";
import { setShowOffline, setToastNotification } from "../../data/global/global.actions";
import { ToastNotification } from "../../models/ToastNotification";
import { createContactGroup } from "../../data/user/user.actions";
import { UserSupervised } from "../../models/UserSupervised";
import { getGlobalConfig } from "../../api/globalConfig";
import { ContactGroup } from "../../models/ContactGroup";
interface OwnProps {}

interface StateProps {
  users: UserWithRelationship[];
  currentUserEmail: string | undefined;
  appOnline: boolean;
  supervisedAccount: UserSupervised | null;
  groups: ContactGroup[];
  isHelpedProfil: boolean;
}

interface DispatchProps {
  setToastNotification: typeof setToastNotification;
  createContactGroup: typeof createContactGroup;
  setShowOffline: typeof setShowOffline;
}

interface CreateContactGroupProps
  extends RouteComponentProps,
    OwnProps,
    StateProps,
    DispatchProps {}

const CreateContactGroup: React.FC<CreateContactGroupProps> = ({
  users,
  currentUserEmail,
  history,
  appOnline,
  supervisedAccount,
  groups,
  isHelpedProfil,
  setToastNotification,
  createContactGroup,
  setShowOffline
}) => {
  const [fileFormat, setFileFormat] = useState("");
  const [fileData, setFileData] = useState("");

  const [formGroupName, setFormGroupName] = useState("");
  const [formMembres, setFormMembres] = useState<string[]>([]);
  const [showChoosePictureSource, setShowChoosePictureSource] = useState(false);

  const updateSelectedUsers = (selectedUsers: string[]) => {
    setFormMembres(selectedUsers);
  };
 
  const cancelHandler = () => {
    history.push("/tabs/settings/contacts/groups");
  };
  const myGroups = _.filter(groups,(group) => group.creator == currentUserEmail);
  const validateHandler = async () => {
    const Config = await getGlobalConfig(isHelpedProfil,currentUserEmail); 
    const maxNumberGroup = Config.contact?.maxNumberGroup;
    const maxNumberMembersPerGroup = Config.contact?.maxNumberMembersPerGroup;
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    if (!currentUserEmail) return;
    if (!formGroupName) {
      setToastNotification({
        message: "Le nom du groupe est nécessaire",
        color: "danger",
      } as ToastNotification);
      return;
    }

    if (formMembres.length == 0) {
      setToastNotification({
        message: "Vous devez sélectionner au moins un contact",
        color: "danger",
      } as ToastNotification);
      return;
    }
    if (maxNumberGroup && +maxNumberGroup <= myGroups.length) {
      setToastNotification({
        message: "Le nombre de groupes possible a déjà été atteint",
        color: "danger",
      } as ToastNotification);
      return;
    }
    if (maxNumberMembersPerGroup && +maxNumberMembersPerGroup < formMembres.length) {
      setToastNotification({
        message: "Le nombre de contacts possible par groupe a déjà été atteint",
        color: "danger",
      } as ToastNotification);
      return;
    }
    
    createContactGroup(
      currentUserEmail,
      formGroupName,
      formMembres,
      fileData,
      fileFormat,
      supervisedAccount
    );
    history.push(`/tabs/settings/contacts/groups`);
    setFormGroupName("");
    setFormMembres([]);
  };
  return (
    <IonPage>
      <Header title="Créer un nouveau groupe" showBack hideHome />
      <IonContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol
              size="12"
              className="center-flex"
              style={{ flexDirection: "column" }}
            >
              <img
                style={!fileData ? {padding: "10px"}: {}}
                className={classes.groupUserPicture}
                src={
                  fileData
                    ? `data:image/${fileFormat};base64,${fileData}`
                    : "assets/icon/group-white.svg"
                }
                alt="avatar"
                onClick={() => setShowChoosePictureSource(true)}
              />
              <span
                style={{ color: "var(--ion-color-primary)" }}
                onClick={() => setShowChoosePictureSource(true)}
              >
                Image du groupe
              </span>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" style={{ marginTop: "20px" }}>
              <IonItem>
                <IonLabel position="stacked">Nom du groupe</IonLabel>
                <IonInput
                  size={30}
                  value={formGroupName}
                  onIonChange={(e) => setFormGroupName(e.detail.value!)}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
        <SelectMultipleUsers
          users={users}
          currentUserEmail={currentUserEmail ? currentUserEmail : ""}
          selectedUsers={formMembres}
          checkedAll={false}
          updateSelectedUsers={updateSelectedUsers}
        />
        {showChoosePictureSource && (
          <ChoosePictureModal
            showChoosePicture={setShowChoosePictureSource}
            fileFormat={fileFormat}
            fileData={fileData}
            setFileFormat={setFileFormat}
            setFileData={setFileData}
          />
        )}
      </IonContent>
      <div
        slot="fixed"
        style={{ width: "100%", bottom: "0", marginTop: "20px" }}
      >
        <BottomButtons onCancel={cancelHandler} onValidate={validateHandler} />
      </div>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    users: selectors.getUsersFilteredByNumberOfCalls(state),
    appOnline: state.global.appOnline,
    currentUserEmail: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.user.email
      : state.user.username,
    supervisedAccount: state.supervisor.supervisedAccount || null,
    groups: state.supervisor.supervisedAccount
      ? state.supervisor.supervisedAccount.contactGroups
      : state.user.groups || [],
    isHelpedProfil: selectors.getIsHelpedProfil(state),
  }),
  mapDispatchToProps: {
    setToastNotification,
    createContactGroup,
    setShowOffline
  },
  component: React.memo(CreateContactGroup),
});
