import {
  IonBadge,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCheckbox,
  IonLabel,
} from "@ionic/react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "../data/connect";
import { MailboxItem, MailboxItemType } from "../models/Mailbox";
import { UserWithRelationship } from "../models/UserWithRelationship";
import * as selectors from "../data/selectors";
import { getDateString } from "../util/commons";
import classes from "./MessageItem.module.scss";
import { deleteMailboxMessage } from "../data/user/user.actions";
import { readFileFromCache } from "../util/localStorage";
import { UserSupervised } from "../models/UserSupervised";
import { setShowOffline } from "../data/global/global.actions";

interface OwnProps {
  message: MailboxItem;
  title: string;
  onClick: () => void;
  checked: boolean;
  updateListMessagesToDelete: (message: MailboxItem, checked: boolean) => void;
}

interface StateProps {
  annuaire: UserWithRelationship[];
  currentUserEmail?: string;
  supervisedAccount?:UserSupervised;
  appOnline: boolean;
}

interface DispatchProps {
  deleteMailboxMessage: typeof deleteMailboxMessage;
  setShowOffline: typeof setShowOffline;
}

interface MessageItemProps extends OwnProps, StateProps, DispatchProps {}

const MessageItem: React.FC<MessageItemProps> = ({
  message,
  annuaire,
  checked,
  title,
  currentUserEmail,
  supervisedAccount,
  appOnline,
  onClick,
  updateListMessagesToDelete,
  deleteMailboxMessage,
  setShowOffline
}) => {
  const [checkedMessage, setCheckedMessage] = useState(checked);
  const [cardClasses, setCardClasses] = useState("speaker-card "+ classes.blueBorder);

  useEffect(() => {
    setCheckedMessage(checked);
  }, [checked]);

  const userPicture = selectors.getPictureUser(
    annuaire,
    message ? message.fromUser : ""
  );
  const [src, setSrc] = useState("");

  useEffect(()=>{
    readFileFromCache(userPicture, setSrc);
  }, [userPicture]);

  const userName = selectors.getUserName(
    annuaire,
    message ? message.fromUser : ""
  );
  const date = getDateString(message ? message.date : null);

  const checkMessage = (e: CustomEvent, message: MailboxItem) => {
    if (!e.detail.checked) {
      setCheckedMessage(false);
      updateListMessagesToDelete(message, false);
      setCardClasses("speaker-card " + classes.blueBorder);
    } else if (e.detail.checked) {
      setCheckedMessage(true);
      updateListMessagesToDelete(message, true);
      setCardClasses("speaker-card selected-card");
    }
  };
  async function deleteMessage(message: MailboxItem) {
    if (!appOnline) {
      setShowOffline(true);
      return;
    }

    if (currentUserEmail) {
      const successMessage =
        message.type === MailboxItemType.MissingCall
          ? "Votre appel manqué a été supprimé"
          : "Votre message enregistré a été supprimé";
      deleteMailboxMessage(
        currentUserEmail,
        message,
        successMessage,
        supervisedAccount
      );
    }
  }
  return (
    <IonCard id={message.id} className={cardClasses} onClick={onClick}>
      <IonCardHeader style={{ height: "90px", marginBottom: "20px", paddingLeft: 0 }}>
        <div className={classes.cardHeaderTextMessage}>
          <button 
            slot="start" 
            style={{backgroundColor: "transparent", padding: 0}} 
            onClick={(e) => {e.stopPropagation();deleteMessage(message);}}
          >
            <div style={{height: "25px", width: "25px", position: "relative"}}>
              <img src={"/assets/icon/delete-white.svg"} style={{position: "relative", right: 0}}/>
            </div>
          </button>

          <div
            className={` ${classes["center-title-header-card"]}`}
          >
            {!message.viewed && (
              <IonBadge slot="end" className={"ion-text-wrap"} color="danger">
                Nouveau
              </IonBadge>
            )}
            <IonLabel>{title}</IonLabel>
          </div>
          <IonCheckbox
              style={{ float: "right" }}
              slot="end"
              value={message.id}
              onClick={(e) => e.stopPropagation()}
              onIonChange={(e) => checkMessage(e, message)}
              checked={checkedMessage}
          />
        </div>
      </IonCardHeader>
      <IonCardSubtitle style={{ textAlign: "center" }}>
        <IonLabel>
          <span className={"ion-text-wrap"}>
            <b>{userName}</b> <br /> Le {date}
          </span>
        </IonLabel>
      </IonCardSubtitle>
      <IonCardContent style={{ height: "160px" }}>
        <div style={{ textAlign: "center" }}>
          <img
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
            src={src}
            alt="avatar"
          />
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    annuaire: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.annuaire : state.user.annuaire ? state.user.annuaire : [],
    currentUserEmail: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user.email : state.user.username,
    supervisedAccount: state.supervisor.supervisedAccount,
    appOnline: state.global.appOnline
  }),
  mapDispatchToProps: {
    deleteMailboxMessage,
    setShowOffline
  },
  component: MessageItem,
});
