import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonContent,
  IonText,
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonList,
  IonListHeader,
  IonLabel,
  IonIcon,
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { connect } from "../data/connect";
import _ from "lodash";
import Header from "../components/Header";
import "./InvitationToMLV.scss";
import { add, trash } from "ionicons/icons";
import BottomButtons from "../components/BottomButtons";
import { IconSvgEnum } from "../components/IconSvg";
import { validateEmail } from "../util/email.validation";
import {
  checkIsPhoneFormat,
  checkIsValidPhone,
} from "../util/checkIsPhoneFormat";
import { MessageType, showToast } from "../util/toast.message";
import Button from "../components/Button";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { _UrlMLVAndroid, _UrlMLVIos } from "../appConfig";
import { MailType } from "../models/Mail";
import { inviteByTelephone } from "../api/visioCall";
import { sendMailRequest } from "../api/mail";
import { setShowOffline, setUserIsActive } from "../data/global/global.actions";
import { removeDiacritics } from "../util/functions";

interface OwnProps {}

interface StateProps {
  firstName: string;
  lastName: string;
  currentUserEmail?: string;
  appOnline: boolean;
}

interface DispatchProps {
  setShowOffline: typeof setShowOffline
  setUserIsActive: typeof setUserIsActive;
}

interface InvitationToMLVProps
  extends RouteComponentProps,
    OwnProps,
    StateProps,
    DispatchProps {}

const ContactGroupList: React.FC<InvitationToMLVProps> = ({
  history,
  firstName,
  lastName,
  currentUserEmail,
  appOnline,
  setShowOffline,
  setUserIsActive,
}) => {
  const [contactsToInvite, setContactsToInvite] = useState<string[]>([]);
  const [contactId, setContactId] = useState<string>("");
  const [sendLoading, setSendLoading] = useState(false);

  useEffect(() => {
    setUserIsActive(true);
    return () => {
      setUserIsActive(false);
    };
  }, []);

  const addContact = () => {
    if (!validateEmail(contactId) && !checkIsValidPhone(contactId)) {
      showToast(
        checkIsPhoneFormat(contactId)
          ? "Le format du numéro de téléphone incorrect"
          : "Le format de l'adresse email est invalide",
        3000,
        MessageType.error
      );
      return;
    }
    let exist = false;
    contactsToInvite.forEach((contact) => {
      if (contact === contactId) {
        exist = true;
        return;
      }
    });
    if (exist) {
      showToast(
        checkIsPhoneFormat(contactId)
          ? "Ce numéro de téléphone mobile est déjà ajouté"
          : "Cet e-mail est déjà ajouté",
        3000,
        MessageType.error
      );
      return;
    }
    const list = contactsToInvite;
    list.push(
      contactId
    );
    setContactsToInvite(list);
    setContactId("");
  };
  const removeContact = (contact: string) => {
    let list = contactsToInvite;
    list = list.filter((item) => item !== contact);
    setContactsToInvite(list);
  };
  const onCancelHandler = () => {
    history.push("/settings/contacts");
  };
  const onValidateHandler = () => {
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    contactsToInvite.forEach((contact) => {
      if (checkIsValidPhone(contact)) {
        sendInvitationBySms(contact);
      } else if (validateEmail(contact)) {
        sendInvitationByEmail(contact);
      }
    });
    setContactsToInvite([]);
  };
  const sendInvitationBySms = (contact: string) => {
    setSendLoading(true);
    const message = `Bonjour, ${firstName} ${lastName} vous invite à installer l'application Mon lien visio en cliquant sur le lien de Google Play ${_UrlMLVAndroid} ou en cliquant sur le lien de l'Apple Store ${_UrlMLVIos}`;
    inviteByTelephone(contact, message).then(() => {
      setSendLoading(false);
      toast.success("L'invitation a bien été envoyée: " + contact);
    });
  };
  const sendInvitationByEmail = (contact: string) => {
    setSendLoading(true);
    let message = " ";
    sendMailRequest(
      currentUserEmail!,
      MailType.Invitation,
      message,
      MailType.Installation,
      contact
    ).then(() => {
      setSendLoading(false);
      toast.success("L'invitation a bien été envoyée: " + contact);
    });  
  };

  return (
    <IonPage>
      <Header title="Invitation" showHelp />
      <IonContent fullscreen={true}>
        {sendLoading && <Loading enable={true} text={"Envoi en cours ..."} />}
        <IonText className="invitation-text">
          <h3>
            Veuillez ajouter ci-dessous les coordonnées de vos contacts <br />
            pour leur proposer de vous rejoindre sur <b>Mon lien visio</b>.
          </h3>
        </IonText>
        <IonRow style={{ width: "100%", padding: "20px" }}>
          <IonCol size-xs="12" size-md="12" size-lg="12" size="12">
            <IonItem style={{ margin: "20px 0" }}>
              <IonInput
                value={contactId ? removeDiacritics(contactId.toLowerCase()): contactId}
                type="text"
                placeholder="Email ou mobile du contact à inviter"
                onIonChange={(e) => setContactId(e.detail.value!)}
              ></IonInput>
            </IonItem>
          </IonCol>

          <IonCol size-xs="12" size-md="12" size-lg="12" size="12">
            <Button
              color="success"
              onClick={addContact}
              text="Ajouter"
              icon={add}
            />
          </IonCol>
        </IonRow>
        {contactsToInvite.length > 0 && (
          <IonList>
            <IonListHeader>
              <h3>
                Liste de vos nouveaux contacts qui vont recevoir l'invitation
              </h3>
            </IonListHeader>
            {contactsToInvite.map((contact) => {
              return (
                <IonItem key={contact}>
                  <IonLabel>
                    {contact}
                  </IonLabel>
                  <IonIcon
                    slot="end"
                    color="danger"
                    src={trash}
                    onClick={() => removeContact(contact)}
                    style={{ width: "50px", height: "50px" }}
                  ></IonIcon>
                </IonItem>
              );
            })}
          </IonList>
        )}
      </IonContent>
      <div slot="fixed" style={{ width: "100%", bottom: "0" }}>
        <BottomButtons
          cancelBtnColor="light"
          validateBtnColor="success"
          cancelBtnLabel="Retour"
          validateBtnLabel="Envoyer"
          cancelBtnIcon={IconSvgEnum.cancel}
          validateBtnIcon={IconSvgEnum.send}
          validateBtnDisabled={contactsToInvite.length > 0 ? false : true}
          onCancel={() => onCancelHandler()}
          onValidate={() => onValidateHandler()}
        />
      </div>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setShowOffline,
    setUserIsActive,
  },
  mapStateToProps: (state) => ({
    currentUserEmail: state.user.username,
    firstName: state.user.userFirestore
      ? state.user.userFirestore.firstName
      : "",
    lastName: state.user.userFirestore ? state.user.userFirestore.lastName : "",
    appOnline: state.global.appOnline
  }),

  component: React.memo(ContactGroupList),
});
