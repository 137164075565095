import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonIcon,
  IonLabel,
  IonSkeletonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import classes from "./PictureItem.module.scss";
import { validFormatsForSharedFolderVideo } from "../util/commons";
import _ from "lodash";
import VideoPlayer from "./VideoPlayer";
import { Comment } from "../models/Comment";
import { readFileFromCache } from "../util/localStorage";
import { connect } from "../data/connect";
import * as selectors from "../data/selectors";
import { User } from "../models/User";
import { SharedFile } from "../models/SharedFile";
import { openOutline } from "ionicons/icons";
import { UserSupervised } from "../models/UserSupervised";
import { loadAndSetUserSupervised } from "../data/supervisor/supervisor.actions";
import { setShowOffline } from "../data/global/global.actions";
import { updateUserRequest } from "../api/user";

interface OwnProps {
  extension: string;
  src: string;
  comments: Comment[];
  currentUserEmail: string;
  picture: SharedFile;
  showDetails: () => void;
  openPicture: () => void;
}

interface StateProps {
  isHelpedProfil: boolean;
  currentUser: User | undefined;
  supervisedAccount : UserSupervised | null;
  appOnline: boolean;
}

interface DispatchProps {
  loadAndSetUserSupervised: typeof loadAndSetUserSupervised;
  setShowOffline: typeof setShowOffline;
}

interface PictureItemProps extends DispatchProps, OwnProps, StateProps {}

const PictureItem: React.FC<PictureItemProps> = ({
  extension,
  src,
  comments,
  currentUserEmail,
  isHelpedProfil,
  currentUser,
  picture,
  supervisedAccount,
  appOnline,
  showDetails,
  openPicture,
  loadAndSetUserSupervised,
  setShowOffline
}) => {
  const [srcFile, setSrcFile] = useState("");

  useEffect(() => {
    readFileFromCache(src, setSrcFile);
  }, [src]);

  const isVideo = _.some(
    validFormatsForSharedFolderVideo,
    (f) => f === extension
  );
  const commentsNotRead = _.filter(
    comments,
    (comment: Comment) =>
      comment.senderEmail !== currentUserEmail &&
      !_.some(comment.viewBy, (viewBy) => viewBy === currentUserEmail)
  );
  const numberCommentsNotRead = commentsNotRead.length;

  const [screenSaverFile, setScreenSaverFile] = useState(true);

  useEffect(() => {
    const visibleInScreenSaver =
    picture && currentUser && currentUser.screensaverExcludedFiles
      ? currentUser.screensaverExcludedFiles.indexOf(picture.id) === -1
        ? true
        : false
      : true;
    setScreenSaverFile(visibleInScreenSaver);
  }, [picture, currentUser])
  /**
   * add file to screenSaver: access to DB and update the screenSaverFile
   * @return  {void}
   */
  async function addToScreenSaver(e: any) {
    e.stopPropagation();
    if(!appOnline){
      setShowOffline(true)
      return;
    }
    if (currentUser && picture) {
      currentUser.screensaverExcludedFiles =
        currentUser.screensaverExcludedFiles
          ? currentUser.screensaverExcludedFiles
          : [];
      if (currentUser.screensaverExcludedFiles.indexOf(picture.id) !== -1) {
        let updatedList = currentUser.screensaverExcludedFiles.filter(
          (id) => id !== picture.id
        );
        currentUser.screensaverExcludedFiles = updatedList;
      } else {
        currentUser.screensaverExcludedFiles.push(picture.id);
      }
      const visibleInScreenSaver =
        picture && currentUser && currentUser.screensaverExcludedFiles
          ? currentUser.screensaverExcludedFiles.indexOf(picture.id) === -1
            ? true
            : false
          : true;
      setScreenSaverFile(visibleInScreenSaver);
      await updateUserRequest(currentUser, supervisedAccount);
      currentUserEmail && supervisedAccount && loadAndSetUserSupervised(currentUserEmail);
    }
  }
  return (
    <IonCol
      size="12"
      size-lg="4"
      size-md="6"
      size-sm="12"
      style={{ marginBottom: 0 }}
    >
      <IonCard
        style={{ margin: 0 }}
        onClick={openPicture}
        className="border-green"
      >
        <IonCardHeader
          style={{
            height: "300px",
            padding: "0",
          }}
        >
          {(isHelpedProfil || supervisedAccount) && (
            <div className={classes.cardHeaderPicture}>
              <IonIcon
                className={classes.heartIcon}
                icon={
                  screenSaverFile
                    ? "assets/icon/heart-black.svg"
                    : "assets/icon/heart.svg"
                }
                slot="end"
                onClick={(e) => addToScreenSaver(e)}
              />
            </div>
          )}
          <div className="render-video">
            {srcFile && !isVideo && (
              <img
                src={srcFile}
                style={{
                  objectFit: "contain",
                  height: "300px",
                  width: "100%",
                  backgroundColor: "rgb(0 0 0 / 5%)",
                }}
              />
            )}
            {srcFile && isVideo && (
              <VideoPlayer
                classname="image-gallery-image"
                url={srcFile}
                height={"100%"}
                type={"video/" + extension}
                play={true}
                loop={true}
                muted={true}
                controls={false}
              />
            )}
            {srcFile === "" && <IonSkeletonText animated />}
          </div>
        </IonCardHeader>
        <IonCardContent
          onClick={(e) => e.stopPropagation()}
          style={{ padding: 0 }}
        >
          <IonButtons slot="center">
            <IonButton
              onClick={() => openPicture()}
              style={{
                width: "50%",
                borderRight: "1px solid grey",
                color: "#424242",
              }}
            >
              <IonIcon
                icon={openOutline}
                className={classes.ionIcon}
              />
              <IonLabel>OUVRIR</IonLabel>
            </IonButton>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <IonButton
                style={{ marginRight: "5px", width: "100%", color: "#424242" }}
                onClick={() => showDetails()}
              >
                <div style={{ position: "relative" }}>
                  <IonIcon
                    icon={"assets/icon/menu-outline.svg"}
                    className={classes.ionIcon}
                  />
                  {numberCommentsNotRead > 0 && (
                    <IonBadge
                      color="danger"
                      className={classes.badge + " subtitle-size"}
                    >
                      {numberCommentsNotRead > 99
                        ? "99+"
                        : numberCommentsNotRead}
                    </IonBadge>
                  )}
                </div>
                <IonLabel>MENU</IonLabel>
              </IonButton>
            </div>
          </IonButtons>
        </IonCardContent>
      </IonCard>
    </IonCol>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    loadAndSetUserSupervised,
    setShowOffline
  },
  mapStateToProps: (state) => ({
    supervisedAccount: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount: null,
    currentUser: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount.user : state.user.userFirestore,
    isHelpedProfil: selectors.getIsHelpedProfil(state),
    appOnline: state.global.appOnline
  }),
  component: PictureItem,
});
