import React, { useEffect } from "react";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import { loadAnnuaire, loadUserData, setCurrentUserData, setUserGroups } from "../data/user/user.actions";
import firebase from "../FirebaseConfig";
import { UserRelationship,RelationshipState } from "../models/UserRelationship";
import {
  processFriendRequest,
  setUserRelations,
  setUserMailbox,
  setAudioData,
  setMessageNotificationShownByUser,
  notifAcceptFriend,
  notifAcceptSpervision,
  setIsLoggedIn,
  setUsername,
} from "../data/user/user.actions";
import { setFolders } from "../data/sharedFolder/sharedFolder.actions";
import VisioCall, { ParticipantState } from "../models/VisioCall";
import { SharedFolder } from "../models/SharedFolder";
import {
  acceptOrRefuseCall,
  setVisioCallData,
} from "../data/visioCall/visioCall.actions";
import _ from "lodash";
import { Mailbox, MailboxItemType } from "../models/Mailbox";
import { audioType } from "../components/AudioPlayer";
import {
  setScreenSaveEnable,
  setToastNotification,
} from "../data/global/global.actions";
import { ToastNotification } from "../models/ToastNotification";
import { setFolderNotificationShownByUser } from "../data/sharedFolder/sharedFolder.actions";
import { setSupervisorRequests, setUserSupervised } from "../data/supervisor/supervisor.actions";
import { SupervisorRelationship, SupervisorRelationshipState } from "../models/SupervisorRelationship";
import { UserSupervised } from "../models/UserSupervised";
import { ContactGroup } from "../models/ContactGroup";
import { menuController } from "@ionic/core";
import { MediaShareRequest } from "../models/MediaShareRequest";
import { setRequestsReducer } from "../data/mediaShareRequest/mediaShareRequest.actions";
import { User } from "../models/User";
import { getUser } from '../api/user';
import { UserWithRelationship } from '../models/UserWithRelationship';
interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
  processFriendRequest: typeof processFriendRequest;
  setUserRelations: typeof setUserRelations;
  loadAnnuaire: typeof loadAnnuaire;
  setVisioCallData: typeof setVisioCallData;
  setFolders: typeof setFolders;
  setUserMailbox: typeof setUserMailbox;
  setAudioData: typeof setAudioData;
  setToastNotification: typeof setToastNotification;
  setMessageNotificationShownByUser: typeof setMessageNotificationShownByUser;
  setFolderNotificationShownByUser: typeof setFolderNotificationShownByUser;
  setSupervisorRequests: typeof setSupervisorRequests;
  setScreenSaveEnable: typeof setScreenSaveEnable;
  acceptOrRefuseCall: typeof acceptOrRefuseCall;
  setUserGroups: typeof setUserGroups;
  setRequestsReducer: typeof setRequestsReducer;
  setCurrentUserData: typeof setCurrentUserData;
  notifAcceptFriend: typeof notifAcceptFriend;
  notifAcceptSpervision: typeof notifAcceptSpervision;
  setUserSupervised: typeof setUserSupervised;
}
interface NotificationProps extends OwnProps, DispatchProps {
  currentUserEmail?: string;
  supervisedAccount: UserSupervised | null;
  showScreensave?: boolean;
  users: UserWithRelationship[];

}

const DatabaseListenerManager: React.FC<NotificationProps> = ({
  supervisedAccount,
  history,
  currentUserEmail,
  showScreensave,
  users,
  processFriendRequest,
  setUserRelations,
  loadAnnuaire,
  setVisioCallData,
  setFolders,
  setUserMailbox,
  setSupervisorRequests,
  setToastNotification,
  acceptOrRefuseCall,
  setMessageNotificationShownByUser,
  setFolderNotificationShownByUser,
  setScreenSaveEnable,
  setUserGroups,
  setRequestsReducer,
  setCurrentUserData,
  notifAcceptFriend,
  notifAcceptSpervision,
  setUserSupervised,
}) => {
  /**
   * New friend demand
   * @returns
   */
  function initFriendRequestListener() {
    const db = firebase.firestore();
    var unsubscribe = db
      .collection("userRelationship")
      .where("users", "array-contains", currentUserEmail)
      .onSnapshot(
        async (querySnapshot) => {
          let allUsersRelation: UserRelationship[] = [];
          let userRelationNeedAction: UserRelationship[] = [];

          querySnapshot.forEach((doc) => {
            let relation = doc.data() as UserRelationship;
            relation.id = doc.id;
            allUsersRelation.push(relation);
            if (relation.emailTo == currentUserEmail && !relation.accepted && relation.state?.state == RelationshipState.Waiting)
              userRelationNeedAction.push(relation);
          });

          processFriendRequest(userRelationNeedAction);
          setUserRelations(allUsersRelation);
        },
        (error) => console.error("initFriendRequestListener error", error)
      );
    return () => unsubscribe();
  }

  /**
   * Refresh annuaire when new friendship or on app loaded
   * @returns
   */
  function initAnnuaireRequestListener() {
    const db = firebase.firestore();
    var unsubscribe = db
      .collection("userRelationship")
      .where("users", "array-contains", currentUserEmail)
      .where("accepted", "==", true)
      .onSnapshot(
        async (querySnapshot) => {
          if (currentUserEmail) loadAnnuaire(currentUserEmail, true);
        },
        (error) => console.error("initAnnuaireRequestListener error", error)
      );

    return () => unsubscribe();
  }
  /**
   * display popup if friendRequested accepted
   * @returns
   */
  function initAcceptRequestListener() {
    const db = firebase.firestore();
    var unsubscribe = db
      .collection("userRelationship")
      .where("emailFrom", '==', currentUserEmail)
      .where("accepted", "==", true)
      .where("notifSender", "==", false)
      .onSnapshot(
        async (querySnapshot) => {
          let userRelationNeedAction: UserRelationship[] = [];
          querySnapshot.forEach((doc) => {
            let relation = doc.data() as UserRelationship;
            relation.id = doc.id;
            userRelationNeedAction.push(relation);
          });
          if (userRelationNeedAction.length > 0) {

            userRelationNeedAction.map((relation) => {

              getUser(relation.emailTo).then((user) => {

                let m = user.firstName + " " + user.lastName + " a accepté votre demande de contact";

                setToastNotification({
                  message: m,
                  onClickMessage: () => {
                    history.push("/tabs/contacts");
                  },
                  color: "secondary",
                  duration: 5000,
                  buttons: [],
                  playAudio: audioType.NewSharedFile,
                } as ToastNotification);
                notifAcceptFriend(relation.id);
              })


            })
          }
        },
        (error) => console.error("initAcceptRequestListener error", error)
      );

    return () => unsubscribe();
  }
  /**
     * display pop-up if supervisionRequest accepted
     * @returns
     */
  function initAcceptRequestSuperListener() {
    const db = firebase.firestore();
    var unsubscribe = db
      .collection("supervisorRelationship")
      .where("administrator", '==', currentUserEmail)
      .where("state", '==', SupervisorRelationshipState.Accepted)
      .where("notifAdministrator", '==', false)
      .onSnapshot(
        async (querySnapshot) => {
          let requests: SupervisorRelationship[] = [];
          querySnapshot.forEach((doc) => {
            let relation = doc.data() as SupervisorRelationship;
            relation.id = doc.id;
            requests.push(relation);
          });
          if (requests.length > 0) {
            requests.map((relation) => {
              getUser(relation.administered).then((user) => {
                let m = user.firstName + " " + user.lastName + " a accepté votre demande de supervision";
                setToastNotification({
                  message: m,
                  color: "secondary",
                  duration: 5000,
                  buttons: [],
                  playAudio: audioType.NewSharedFile,
                } as ToastNotification);
                notifAcceptSpervision(relation.id);

              })
            })
          }
        },
        (error) => console.error("initAcceptRequestSuperListener error", error)
      );

    return () => unsubscribe();
  }
  /**
   * New call incoming, show accept/refuse page
   * @returns
   */
  function initIncomeCallListener() {
    const db = firebase.firestore();
    var unsubscribe = db
      .collection("visioCalls")
      .where("participantEmails", "array-contains", currentUserEmail)
      .where("c_active", "==", true)
      .onSnapshot(
        async (querySnapshot) => {
          let calls: VisioCall[] = [];
          querySnapshot.forEach((doc) => {
            let call = doc.data() as VisioCall;
            call.id = doc.id;
            calls.push(call);
          });



          const incomingCall = _.find(
            _.orderBy(calls, (c) => c.dateCreate, "desc"),
            (call) =>
              _.some(
                call.participantStates,
                (participant) =>
                  participant.email == currentUserEmail &&
                  (participant.state == ParticipantState.Waiting ||
                    participant.state == ParticipantState.Accepted)
              ) /*&&
              _.some(
                call.participantStates,
                (participant) =>
                  participant.email != currentUserEmail &&
                  participant.state != ParticipantState.Refused &&
                  participant.state != ParticipantState.Completed
              )*/
          );
          // if an incoming call exist, quit screensaver if shown
          // if(incomingCall){
          //  setScreenSaveEnable(false);
          //   history.push("/tabs/conference/video");
          // }

          if (calls.length <= 1) {
            setVisioCallData(incomingCall);
          }
          calls = _.orderBy(calls, ["dateCreate"], ["desc"]);

          // if reciever is busy refuse call incoming
          if (calls.length > 1) {
            acceptOrRefuseCall(
              calls[0].id,
              currentUserEmail ? currentUserEmail : "",
              ParticipantState.Refused,
              false
            );
          }
        },
        (error) => console.error("initIncomeCallListener error", error)
      );

    return () => unsubscribe();
  }

  /**
   * Listen new shared file
   * @returns
   */
  function initSharedFoldersListener() {
    const db = firebase.firestore();
    var unsubscribe = db
      .collection("sharedFolder")
      .where("users", "array-contains", currentUserEmail)
      .onSnapshot(
        async (querySnapshot) => {
          let folders: SharedFolder[] = [];
          querySnapshot.forEach((doc) => {
            let folder = doc.data() as SharedFolder;
            folders.push(folder);
          });
          setFolders(folders);

          const countTotal = folders.map((folder) => {
            const result = folder.files.map((file) => {
              let needToastNotif = false;
              if (file.needToastNotification) {
                needToastNotif =
                  file.needToastNotification.indexOf(
                    currentUserEmail ? currentUserEmail : ""
                  ) !== -1;
              }
              return _.some(file.viewBy, (user) => user == currentUserEmail) ||
                !needToastNotif
                ? 0
                : 1;
            });

            return _.sum(result);
          });

          // if new file, show toast notification
          if (_.sum(countTotal) > 0) {
            if (showScreensave) {
              setScreenSaveEnable(false);
              menuController.open();
            }
            setToastNotification({
              message: "VOIR LES NOUVELLES PHOTOS ET VIDEOS",
              onClickMessage: () => {
                history.push("/tabs/media/shared");
              },
              color: "secondary",
              duration: 6000,
              buttons: [],
              playAudio: audioType.NewSharedFile,
            } as ToastNotification);
            folders.forEach((folder) => {
              if (currentUserEmail)
                setFolderNotificationShownByUser(folder, currentUserEmail, supervisedAccount);
            });
          }
        },
        (error) => console.error("initSharedFoldersListener error", error)
      );
    return () => unsubscribe();
  }

  /**
   * Listen mailbox new message
   * @returns
   */
  function initMailboxListener() {
    const db = firebase.firestore();
    var unsubscribe = db
      .collection("mailbox")
      .doc(currentUserEmail)
      .onSnapshot(
        async (querySnapshot) => {
          const newMailbox = querySnapshot.data() as Mailbox;

          if (newMailbox) {
            const countNewMsgNotViewed = _.filter(
              newMailbox.items,
              (msg) => msg.needToastNotification
            ).length;
            const countMissingCall = _.filter(
              newMailbox.items,
              (msg) =>
                msg.needToastNotification &&
                msg.type === MailboxItemType.MissingCall
            ).length;
            const countMessageVideo = _.filter(
              newMailbox.items,
              (msg) =>
                msg.needToastNotification &&
                msg.type === MailboxItemType.MessageVideo
            ).length;
            if (countNewMsgNotViewed > 0) {
              let message = "Vous avez un nouveau message vidéo";
              if (countMissingCall > 0 && countMessageVideo === 0)
                message = "VOUS AVEZ UN NOUVEL APPEL MANQUÉ";

              setToastNotification({
                message: message,
                onClickMessage: () => {
                  history.push("/tabs/mailbox");
                },
                color: "success",
                duration: 6000,
                buttons: [
                  {
                    side: "start",
                    text: "ECOUTER",
                    handler: () => {
                      history.push("/tabs/mailbox");
                    },
                  },
                ],
                playAudio: audioType.NewMessage,
              } as ToastNotification);
              if (currentUserEmail)
                setMessageNotificationShownByUser(currentUserEmail);
            }
          }
          setUserMailbox(newMailbox);
        },
        (error) => console.error("initMailboxListener error", error)
      );
    return () => unsubscribe();
  }

  function initSupervisorRequestsListener() {
    const db = firebase.firestore();
    var unsubscribe = db
      .collection("supervisorRelationship")
      .where("users", "array-contains", currentUserEmail)
      .onSnapshot(
        async (querySnapshot) => {
          let requests: SupervisorRelationship[] = [];
          querySnapshot.forEach((doc) =>
            requests.push(doc.data() as SupervisorRelationship)
          );
          // cancel the current supervision when the contact or supervision relationship is deleted
          if(supervisedAccount){
            const result = requests.filter(request => request.administered === supervisedAccount.user.email);
            if(result.length === 0){
              setUserSupervised(undefined);
              localStorage.removeItem('userSupervised');
            }
          }
          setSupervisorRequests(requests);
        },
        (error) => console.error("initSupervisorRequestsListener error", error)
      );

    return () => unsubscribe();
  }

  function initContactGroupsRequestsListener() {
    const db = firebase.firestore();
    var unsubscribe = db
      .collection("contactGroups")
      .where("members", "array-contains", currentUserEmail)
      .onSnapshot(
        async (querySnapshot) => {
          let requests: ContactGroup[] = [];
          querySnapshot.forEach((doc) => {
            let grp = doc.data() as ContactGroup
            if (grp.status == undefined || grp.status === true) {
              requests.push(grp)
            }
          }
          );
          setUserGroups(requests);
        },
        (error) =>
          console.error("initContactGroupsRequestsListener error", error)
      );

    return () => unsubscribe();
  }

  function initMediaShareRequestsListener() {
    const db = firebase.firestore();
    var unsubscribe = db
      .collection("mediaShareRequest")
      .where("receivers", "array-contains", currentUserEmail)
      .onSnapshot(
        async (querySnapshot) => {
          let requests: MediaShareRequest[] = [];
          querySnapshot.forEach((doc) =>
            requests.push(doc.data() as MediaShareRequest)
          );
          setRequestsReducer(requests);
        },
        (error) => console.error("initMediaShareRequestsListener error", error)
      );

    return () => unsubscribe();
  }

  function initCurrentUserDataRequestsListener() {
    const db = firebase.firestore();
    var unsubscribe = db
      .collection("users")
      .doc(currentUserEmail)
      .onSnapshot(
        async (querySnapshot) => {
          const currentUser = querySnapshot.data() as User;
          if (!currentUser || currentUser.status === false) {
            await firebase.auth().signOut();
            setIsLoggedIn(false);
            setUsername(undefined);
            history.push("/login");
          }
          if (currentUser) {
            setCurrentUserData(currentUser);
          }
        },
        (error) => console.error("initCurrentUserDataRequestsListener error", error)
      );

    return () => unsubscribe();
  }

  useEffect(() => {
    try {
      if (currentUserEmail) {
        if(!supervisedAccount){
          const friendRequest = initFriendRequestListener();
          const annuaireRequest = initAnnuaireRequestListener();
          const incomeCallRequest = initIncomeCallListener();
          const sharedFolderRequest = initSharedFoldersListener();
          const mailboxRequest = initMailboxListener();
          const contactGroupsRequest = initContactGroupsRequestsListener();
          const MediaShareRequests = initMediaShareRequestsListener();
          const currentUserDataRequests = initCurrentUserDataRequestsListener();
          const acceptFriendRequest = initAcceptRequestListener()
          const acceptSupervisionRequest = initAcceptRequestSuperListener();
          const SupervisorRequest = initSupervisorRequestsListener();
          return () => {  
              friendRequest();
              annuaireRequest();
              incomeCallRequest();
              sharedFolderRequest();
              mailboxRequest();
              contactGroupsRequest();
              MediaShareRequests();
              currentUserDataRequests();
              acceptFriendRequest();
              acceptSupervisionRequest();
              SupervisorRequest();
          };
        }else{
          const SupervisorRequest = initSupervisorRequestsListener();
          return () => {
            SupervisorRequest();
          };
        }
      }
    } catch (e) {
      alert("DatabaseListenerManager error:" + JSON.stringify(e));
    }
  }, [currentUserEmail, supervisedAccount, showScreensave]);

  return <div></div>;
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    currentUserEmail: state.user.username,
    supervisedAccount: state.supervisor.supervisedAccount ? state.supervisor.supervisedAccount : null,
    showScreensave: state.global.screenSaverEnable,
    users: state,

  }),
  mapDispatchToProps: {
    processFriendRequest,
    setUserRelations,
    loadAnnuaire,
    setVisioCallData,
    setFolders,
    setUserMailbox,
    setAudioData,
    setToastNotification,
    setMessageNotificationShownByUser,
    setFolderNotificationShownByUser,
    setSupervisorRequests,
    setScreenSaveEnable,
    acceptOrRefuseCall,
    setUserGroups,
    setRequestsReducer,
    setCurrentUserData,
    notifAcceptFriend,
    notifAcceptSpervision,
    setUserSupervised,
  },
  component: DatabaseListenerManager,
});
