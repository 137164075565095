import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "../../data/connect";
import BottomButtons from "../BottomButtons";
import _ from "lodash";
import Header from "../Header";
import CropperImage from "../Cropper";
import { setToastNotification } from "../../data/global/global.actions";
import { ToastNotification } from "../../models/ToastNotification";

interface OwnProps {
  src: string;
  successMessage?: string;
  setImageFormat: (format: string) => void;
  setImageData: (data: string) => void;
  onSave?: (data: string, format: string) => void;
  onCancel: () => void;
}
interface StateProps {}
interface DispatchProps {
  setToastNotification: typeof setToastNotification;
}
interface EditImageProps extends OwnProps, StateProps, DispatchProps {}

const EditImage: React.FC<EditImageProps> = ({
  src,
  successMessage,
  setImageFormat,
  setImageData,
  onSave,
  onCancel,
  setToastNotification
}) => {
  const [croppedImage, setCroppedImage] = useState("");

  useEffect(() => {
    return () => {
      setCroppedImage("");
    };
  }, []);

  const cancelHandler = () => {
    onCancel();
  };

  const validateHandler = () => {
    setImageFormat("jpeg");
    setImageData(croppedImage.split(",")[1]);
    if (onSave) {
      onSave(croppedImage.split(",")[1], "jpeg");
    }
    onCancel();
    successMessage && setToastNotification({
      message: successMessage,
      onClickMessage: () => {},
      color: "success",
      duration: 6000,
      buttons: [],
    } as ToastNotification);  
  };

  return (
    <IonModal isOpen={true} onDidDismiss={cancelHandler}>
      <Header action={cancelHandler} title="Modifier l'image" showBack />
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <CropperImage src={src} setCroppedImage={setCroppedImage} />
      </div>
      <div slot="fixed" style={{ width: "100%", bottom: 0 }}>
        <BottomButtons onCancel={cancelHandler} onValidate={validateHandler} />
      </div>
    </IonModal>
  );
};
export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setToastNotification
  },
  mapStateToProps: (state) => ({}),
  component: React.memo(EditImage),
});
